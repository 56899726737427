import { useMutation } from "@apollo/client";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import Box from "components/box/Box";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import Timestamp from "components/time/Timestamp";
import useTypedContext from "hooks/useTypedContext";
import { Session } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import { SESSION_DELETE } from "../gql";
import { showSessionsConfirmationModal } from "../ConfirmationModal";
import styles from "./styles.module.css";

type ItemProps = {
  session: Session;
};

const Item = ({ session }: ItemProps) => {
  const navigate = useNavigate();

  const { viewer } = useTypedContext(AccountContext);
  const { reportSuccess, onError } = useTypedContext(FlashContext);

  const [sessionDelete, { loading }] = useMutation(SESSION_DELETE, {
    variables: { id: session.id },
    refetchQueries: ["GetSessions"],
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSessions,
  });

  const handleDeactivation = () => {
    const asyncFn = async () => {
      if (session.current) {
        trackSegmentAnalyticsEvent("Logout");
        return navigate("/logout");
      }

      trackSegmentAnalyticsEvent("Deactivate", { deactivatedUser: session.name });

      try {
        await sessionDelete();
        reportSuccess({ message: `Session ${session.id} was deactivated` });
      } catch (error) {
        onError(error);
      }
    };

    void asyncFn();
  };

  const handleDeactivationConfirmation = () =>
    showSessionsConfirmationModal({
      confirmationCallback: handleDeactivation,
      sessionId: session.id,
      userId: session.login,
      isCurrentSession: session.current,
    });

  return (
    <Box className={styles.wrapper}>
      {loading && <PageLoading />}

      <div className={styles.content}>
        <span className={cx(styles.title, styles.login)}>{session.login}</span>
        <span className={cx(styles.title, styles.id)}>{session.id}</span>

        <ul className={styles.panel}>
          <li>
            Created <Timestamp timestamp={session.createdAt} />
          </li>
          <li>
            Expires <Timestamp timestamp={session.expiresAt} />
          </li>
          <li>{session.ipAddress}</li>
          <li>{session.type}</li>
        </ul>
      </div>

      {viewer.admin && (
        <button className={styles.action} onClick={handleDeactivationConfirmation} type="button">
          {session.current ? "Logout" : "Deactivate"}
        </button>
      )}
    </Box>
  );
};

export default Item;
