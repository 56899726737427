import { SamlNameIdFormat } from "types/generated";

export const SAML_NAME_ID_FORMATS_DICTIONARY = {
  [SamlNameIdFormat.Transient]: "Transient",
  [SamlNameIdFormat.EmailAddress]: "Email Address",
  [SamlNameIdFormat.Persistent]: "Persistent",
};

export const SAML_NAME_ID_OPTIONS = [
  SamlNameIdFormat.Transient,
  SamlNameIdFormat.EmailAddress,
  SamlNameIdFormat.Persistent,
].map((value) => ({ value, label: SAML_NAME_ID_FORMATS_DICTIONARY[value] }));
