import fromUnixTime from "date-fns/fromUnixTime";

import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import { AuditTrailEntry } from "types/generated";
import { formatDateTimeByLocale } from "utils/date";
import Link from "ds/components/LinkNew";

import { titleCase, formatMetadata } from "../../helpers";
import CreatedBy from "../ListItem/CreatedBy";
import { AuditTrailEntrySuggestions } from "../../constants";

type AuditTrailEntriesDetailsMetadataProps = {
  item: AuditTrailEntry;
  applyFilter: (filterName: AuditTrailEntrySuggestions) => (value: string) => void;
};

const AuditTrailEntriesDetailsMetadata = ({
  item,
  applyFilter,
}: AuditTrailEntriesDetailsMetadataProps) => {
  const {
    typeLabel: affectedResourceTypeLabel,
    detailLabel: affectedResourceDetailLabel,
    detailUrl: affectedResourceDetailUrl,
  } = formatMetadata(item.affectedResource);

  const {
    typeLabel: relatedResourceTypeLabel,
    detailLabel: relatedResourceDetailLabel,
    detailUrl: relatedResourceDetailUrl,
  } = formatMetadata(item.relatedResource);

  return (
    <>
      <FormField label="Created by" noMargin align="start">
        <CreatedBy value={item.actor} applyFilter={applyFilter} />
      </FormField>

      <FormField label="Timestamp">
        <Typography tag="span" variant="p-body2">
          {formatDateTimeByLocale({ date: fromUnixTime(item.createdAt), format: "dateTimeFull" })}
        </Typography>
      </FormField>

      <FormField label="Affected resource type">
        <Typography tag="span" variant="p-body2">
          {affectedResourceTypeLabel}
        </Typography>
      </FormField>

      {affectedResourceDetailLabel && (
        <FormField label="Affected resource" align="start">
          {affectedResourceDetailUrl ? (
            <Link href={affectedResourceDetailUrl}>{affectedResourceDetailLabel}</Link>
          ) : (
            <Typography tag="span" variant="p-body2">
              {affectedResourceDetailLabel}
            </Typography>
          )}
        </FormField>
      )}

      {item.relatedResource !== null && (
        <>
          <FormField label="Related resource type">
            <Typography tag="span" variant="p-body2">
              {relatedResourceTypeLabel}
            </Typography>
          </FormField>

          <FormField label="Related resource" align="start">
            {relatedResourceDetailUrl ? (
              <Link href={relatedResourceDetailUrl}>{relatedResourceDetailLabel}</Link>
            ) : (
              <Typography tag="span" variant="p-body2">
                {relatedResourceDetailLabel}
              </Typography>
            )}
          </FormField>
        </>
      )}

      <FormField label="Action">
        <Typography tag="span" variant="p-body2">
          {item.action}
        </Typography>
      </FormField>

      <FormField label="Event type">
        <Typography tag="span" variant="p-body2">
          {titleCase(item.eventType)}
        </Typography>
      </FormField>
    </>
  );
};

export default AuditTrailEntriesDetailsMetadata;
