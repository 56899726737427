import React from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import DSButton from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import DocumentationIconButton from "components/DocumentationIconButton";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { getDocsUrl } from "utils/getDocsUrl";

import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import { SpacesContext } from "../SpacesProvider";

type StacksPageLayoutProps = {
  children: React.ReactNode;
};

const StacksPageLayout = ({ children }: StacksPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Stacks · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Stacks</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          <SearchInput
            placeholder="Search by name, ID or labels"
            filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
          />

          <DocumentationIconButton
            href={getDocsUrl("/concepts/stack")}
            tooltipText="Go to stacks documentation"
          />

          {hasEntityCreateAccess && (
            <DSButton
              to="/new/stack"
              variant="primary"
              analyticsPage={AnalyticsPageStack.StacksList}
              analyticsTitle="Create Stack"
            >
              Create stack
            </DSButton>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>

      {children}
    </>
  );
};

export default StacksPageLayout;
