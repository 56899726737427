import { ReactNode } from "react";

import CardWrapper from "components/CardWrapper";

import SegmentedControl, {
  SegmentedControlOption,
  SegmentedControlProps,
} from "../SegmentedControl";
import FormField, { FormFieldProps } from "../Form/Field";
import Box from "../Box";

type SegmentedControlFormFieldProps<T extends string> = {
  value: SegmentedControlProps<T>["value"];
  segments: SegmentedControlOption<T>[];
  onChange: (currentSegment: SegmentedControlOption<T>["value"]) => void;
  label: FormFieldProps["label"];
  tooltipInfo?: FormFieldProps["tooltipInfo"];
  tooltipWidthMode?: FormFieldProps["tooltipWidthMode"];
  children: ReactNode;
  noMargin?: boolean;
};

const SegmentedControlFormField = <T extends string>({
  value,
  segments,
  onChange,
  label,
  tooltipInfo,
  tooltipWidthMode,
  children,
  noMargin,
}: SegmentedControlFormFieldProps<T>) => {
  return (
    <CardWrapper variant="filled" direction="column" margin={noMargin ? "0" : "large 0 0"}>
      <FormField
        label={label}
        tooltipInfo={tooltipInfo}
        tooltipWidthMode={tooltipWidthMode}
        noMargin
      >
        <Box direction="column" gap="large" padding="medium 0 0" fullWidth>
          <SegmentedControl value={value} onChange={onChange} options={segments} />

          {children}
        </Box>
      </FormField>
    </CardWrapper>
  );
};

export default SegmentedControlFormField;
