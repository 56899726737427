import ButtonIconNew from "ds/components/ButtonIcon/New";
import Box from "ds/components/Box";
import { External } from "components/icons/generated";
import CodeEditor from "components/CodeEditor";

import styles from "./styles.module.css";
import { useNewModuleAnalyticsSegmentEvent } from "../../useNewModuleAnalyticsSegmentEvent";
import { showNewModuleAttachedPolicyPreviewDrawer } from "./PreviewDrawer";

type NewModuleAttachedPolicyContentProps = {
  policyBody: string;
};

const NewModuleAttachedPolicyContent = ({ policyBody }: NewModuleAttachedPolicyContentProps) => {
  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const handleCloseDrawer = () => {
    trackSegmentEvent("Close policy preview");
  };
  const handleOpenDrawer = () => {
    showNewModuleAttachedPolicyPreviewDrawer({
      policyBody,
      onClose: handleCloseDrawer,
    });
    trackSegmentEvent("Open policy preview");
  };

  return (
    <>
      <Box margin="0 large large" className={styles.codeEditorWrapper}>
        <ButtonIconNew
          className={styles.codeEditorExpandButton}
          icon={External}
          onPress={handleOpenDrawer}
          variant="secondary"
        >
          Preview
        </ButtonIconNew>
        <CodeEditor body={policyBody} language="rego" readOnly />
      </Box>
    </>
  );
};

export default NewModuleAttachedPolicyContent;
