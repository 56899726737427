import { Rocket } from "components/icons/generated";
import Box from "ds/components/Box";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import IconTile from "ds/components/IconTile";
import Button from "ds/components/Button";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";

import styles from "./styles.module.css";

const DashboardOverviewColumnTestPilot = () => {
  return (
    <TileWrapper grow="0" noBorder>
      <TileContent direction="column">
        <Box fullWidth margin="0 0 small 0" gap="medium" justify="between" align="start">
          <IconTile className={styles.yellowWeakBackground} variant="secondary" icon={Rocket} />
          <Button
            variant="secondary"
            size="medium"
            target="_blank"
            rel="noopener noreferrer"
            href="https://spacelift.io/join-test-pilot-program"
            analyticsPage={AnalyticsPageDashboard.Dashboard}
            analyticsTitle="Dashboard - Test Pilot Clicked"
          >
            Sign up
          </Button>
        </Box>
        <TileTitle variant="p-t6">Spacelift TestPilot program</TileTitle>
        <Typography tag="span" variant="p-body3">
          Help us tailor Spacelift to your needs by joining our testing program!
        </Typography>
      </TileContent>
    </TileWrapper>
  );
};

export default DashboardOverviewColumnTestPilot;
