import { gql, useQuery } from "@apollo/client";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import { AwsIntegration, AzureIntegration, BillingTierFeature } from "types/generated";
import Box from "components/box/Box";
import DocumentationIconButton from "components/DocumentationIconButton";
import Form from "components/FormDefault";
import FormNavLink from "components/link/FormNavLink";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import Row from "components/row/Row";
import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import { isSaasDistribution } from "utils/distribution";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import PageWrapper from "components/PageWrapper";
import TierInfoLegacy from "components/TierInfo/Legacy";
import { getDocsUrl } from "utils/getDocsUrl";

import AwsIntegrations from "./AwsIntegrations";
import AzureIntegrations from "./AzureIntegrations";
import GcpIntegrations from "./GcpIntegrations";
import styles from "./styles.module.css";

type GetCloudIntegrationsGql = {
  azureIntegrations: AzureIntegration[];
  awsIntegrations: AwsIntegration[];
};

// FYI: exported for storybook mocking
// ts-prune-ignore-next
export const GET_CLOUD_INTEGRATIONS = gql`
  query GetCloudIntegrations {
    azureIntegrations {
      adminConsentProvided
      adminConsentURL
      applicationId
      createdAt
      updatedAt
      displayName
      id
      labels
      name
      tenantId
      defaultSubscriptionId
      spaceDetails {
        id
        name
        accessLevel
      }
      attachedStacks {
        id
        isModule
        stackId
        stackName
        read
        write
        subscriptionId
      }
    }
    awsIntegrations(includeLegacy: false) {
      attachedStacks {
        isModule
        stackId
        stackName
        read
        write
      }
      id
      name
      roleArn
      durationSeconds
      generateCredentialsInWorker
      externalId
      labels
      spaceDetails {
        id
        name
        accessLevel
      }
    }
  }
`;

const isSaas = isSaasDistribution();

const CloudIntegrations = () => {
  const { onError } = useTypedContext(FlashContext);
  const location = useLocation();

  const { loading, data, error } = useQuery<GetCloudIntegrationsGql>(GET_CLOUD_INTEGRATIONS, {
    onError,
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  return (
    <>
      <ViewHeader>
        <ViewHeaderTitle titleSize="p-t3">Cloud integrations</ViewHeaderTitle>
        <ViewHeaderWrapper direction="row">
          <DocumentationIconButton
            href={getDocsUrl("/integrations/cloud-providers")}
            tooltipText="Go to cloud integrations documentation"
          />
        </ViewHeaderWrapper>
      </ViewHeader>
      <PageWrapper>
        <TierInfoLegacy
          feature={BillingTierFeature.CloudCredentialGeneration}
          upgradeText="to a plan that supports Cloud Integrations to make use of this feature."
        >
          Cloud integrations are not supported by your plan. The integration can be set up, but
          temporary credentials will not be generated.
        </TierInfoLegacy>
        <Box className={styles.wrapper}>
          <Row className={styles.navWrapper}>
            <FormNavLink to="/cloud-integrations/aws" isActive={location.pathname.includes("aws")}>
              AWS
            </FormNavLink>
            {isSaas && (
              <>
                <FormNavLink
                  to="/cloud-integrations/azure"
                  isActive={location.pathname.includes("azure")}
                >
                  Azure
                </FormNavLink>
                <FormNavLink
                  to="/cloud-integrations/gcp"
                  isActive={location.pathname.includes("gcp")}
                >
                  GCP
                </FormNavLink>
              </>
            )}
          </Row>
          <Form>
            <Routes>
              <Route index element={<Navigate to="aws" replace />} />

              <Route
                path="aws/*"
                element={<AwsIntegrations integrations={data.awsIntegrations} />}
              />
              {isSaas && (
                <Route
                  path="azure/*"
                  element={<AzureIntegrations integrations={data.azureIntegrations} />}
                />
              )}
              {isSaas && <Route path="gcp" element={<GcpIntegrations />} />}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Form>
        </Box>
      </PageWrapper>
    </>
  );
};

export default CloudIntegrations;
