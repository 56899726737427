import { createDefaultMutationHook } from "hooks/useMutationHandler";
import setLocation from "shared/setLocation";
import { isSaasDistribution } from "utils/distribution";

import { SLACK_OAUTH_REDIRECT } from "./gql";
import { SLACK_COOKIE_KEY } from "./constants";

const isSaas = isSaasDistribution();
const useSlackOauthRedirectMutation = createDefaultMutationHook(SLACK_OAUTH_REDIRECT);

const useConnectSlackIntegration = () => {
  const [slackRedirect, { loading }] = useSlackOauthRedirectMutation();

  const onIntegrationConnection = () => {
    slackRedirect({}, (data) => {
      const { host } = window.location;
      const date = new Date();

      let domain = host;
      if (isSaas) {
        domain = host.substring(host.indexOf(".") + 1);
      }

      if (data) {
        const {
          slackOauthRedirect: { providerUrl, temporaryToken },
        } = data;

        date.setTime(date.getTime() + 60 * 1000);

        document.cookie = `${SLACK_COOKIE_KEY}=${temporaryToken};expires=${date.toUTCString()};domain=${domain};path=/`;

        setLocation(providerUrl);
      }
    });
  };

  return {
    connectSlackIntegration: onIntegrationConnection,
    loading,
  };
};

export default useConnectSlackIntegration;
