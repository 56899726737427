import LinkNew from "ds/components/LinkNew";

import useStackListAnalytics from "../useStackListAnalytics";
import { CellComponentProps } from "./types";

type StackListItemBlueprintCellProps = CellComponentProps;

const StackListItemBlueprintCell = ({ stack }: StackListItemBlueprintCellProps) => {
  const trackAnalytics = useStackListAnalytics();

  if (!stack.blueprint) {
    return null;
  }

  return (
    <LinkNew
      onPress={() => trackAnalytics("Blueprint link clicked")}
      size="small"
      href={`/blueprint/${stack.blueprint.ulid}`}
      tooltip={`Stack created from blueprint ${stack.blueprint.name}`}
    >
      {stack.blueprint.name}
    </LinkNew>
  );
};

export default StackListItemBlueprintCell;
