import { CSSProperties } from "react";

import { Spacing, SpacingShorthand } from "types/Spacing";

const getSpacingVariable = (spacing: Spacing): string => {
  if (spacing === "0") {
    return "0";
  }

  return `var(--semantic-spacing-${spacing})`;
};

export const getSpacingCSS = (
  cssProperty: string,
  spacing: SpacingShorthand | undefined
): CSSProperties | undefined => {
  if (!spacing) {
    return undefined;
  }

  const spacingValues = spacing.split(" ") as Spacing[];
  const filteredSpacingValues = spacingValues.filter(Boolean);

  if (spacingValues.length !== filteredSpacingValues.length) {
    return undefined;
  }

  if (spacingValues.length === 1) {
    return { [cssProperty]: getSpacingVariable(spacingValues[0]) };
  }

  if (spacingValues.length === 2) {
    const [vertical, horizontal] = spacingValues;

    return { [cssProperty]: `${getSpacingVariable(vertical)} ${getSpacingVariable(horizontal)}` };
  }

  if (spacingValues.length === 3) {
    const [top, horizontal, bottom] = spacingValues;

    return {
      [cssProperty]: `${getSpacingVariable(top)} ${getSpacingVariable(
        horizontal
      )} ${getSpacingVariable(bottom)}`,
    };
  }

  if (spacingValues.length === 4) {
    const [top, right, bottom, left] = spacingValues;

    return {
      [cssProperty]: `${getSpacingVariable(top)} ${getSpacingVariable(right)} ${getSpacingVariable(
        bottom
      )} ${getSpacingVariable(left)}`,
    };
  }

  return undefined;
};

export const getDrawerFormFix = (): { style: CSSProperties } => ({
  style: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
});
