import React from "react";
import cx from "classnames";

import Col from "components/column/Col";
import { InfoCircle } from "components/icons/generated";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";

type WarningBarProps = {
  className?: string;
  topMargin?: boolean;
  children: React.ReactNode;
};

const WarningBar = ({ children, className, topMargin }: WarningBarProps) => (
  <div className={cx(styles.warningBar, { [styles.warningBarTop]: topMargin }, className)}>
    <div className={styles.warningBarText}>{children}</div>
    <Col className={styles.warningBarIcon} auto>
      <Icon src={InfoCircle} />
    </Col>
  </div>
);

export default WarningBar;
