import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { VCS_INTEGRATIONS_LIST_PATH } from "./constants";

const useCloseFullScreenModal = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClose = useCallback(() => {
    // if user navigated to this page directly, go back to the integrations list
    if (location.key === "default") {
      navigate(VCS_INTEGRATIONS_LIST_PATH);
    } else {
      navigate(-1);
    }
  }, [location.key, navigate]);

  return { handleOnClose };
};

export default useCloseFullScreenModal;
