import EmptyState from "ds/components/EmptyState";
import { EmptystateShareColored } from "components/icons/generated";
import Box from "ds/components/Box";

const PersonalSettingsGroupsEmpty = () => {
  return (
    <Box align="center" justify="center" grow="1">
      <EmptyState
        title="You don’t have any Groups yet"
        icon={EmptystateShareColored}
        caption="This is where your group access list will appear once your admin will add you to any of the
        groups in your organization."
      />
    </Box>
  );
};

export default PersonalSettingsGroupsEmpty;
