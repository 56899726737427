import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { Space, Clock } from "components/icons/generated";
import Timestamp from "components/time/Timestamp";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import { ConfigType } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";
import TabWithCounter from "ds/components/Tab/WithCounter";
import TabWithBadge from "ds/components/Tab/WithBadge";
import ContextListItemDropdownDelete from "views/Account/Contexts/ListItemDropdown/Delete";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import { openContextUpdateDrawer } from "views/Account/Contexts/UpdateDrawer";

import { CONTEXT_ANALYTICS_VERSION } from "../constants";
import { ContextHooks } from "../types";
import { ContextContext } from "../Context";
import { showContextDetailsDrawer } from "../DetailsDrawer";

const ContextHeader = () => {
  const { context, contextUrl, canManageContext } = useTypedContext(ContextContext);
  const navigate = useNavigate();

  const {
    attachedStacks,
    hasAttachedStacks,
    id,
    name,
    createdAt,
    spaceDetails,
    config,
    hooks,
    labels,
  } = context;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageContext.Context,
    defaultCallbackTrackProperties: { version: CONTEXT_ANALYTICS_VERSION },
  });

  const variablesCount = config.filter(
    (item) => item.type === ConfigType.EnvironmentVariable
  ).length;

  const mountedFilesCount = config.filter((item) => item.type === ConfigType.FileMount).length;
  const hasHooksDefined = useMemo(() => {
    return Object.keys(hooks).some((item) => {
      const key = item as keyof ContextHooks;
      if (key === "__typename") return;
      const value = hooks[key];

      return value && value.length > 0;
    });
  }, [hooks]);

  const onDeleteSuccess = () => {
    trackSegmentAnalyticsEvent("Delete", {
      labels: labels,
      space: spaceDetails.name,
      name: name,
    });

    navigate("/contexts");
  };

  const showDetailsDrawer = () => {
    showContextDetailsDrawer({ context });
  };

  const showEditDrawer = () => {
    openContextUpdateDrawer({ context, refetchQuery: "GetContext" });
  };

  return (
    <ViewHeader>
      <ViewHeaderWrapper direction="row" justify="between" fullWidth>
        <ViewHeaderWrapper direction="row" align="center">
          <ViewHeaderTitle>{name}</ViewHeaderTitle>
        </ViewHeaderWrapper>

        <ViewHeaderWrapper direction="row" align="end" shrink="0">
          <Button variant="secondary" onClick={showDetailsDrawer}>
            Details
          </Button>

          <DropdownMenuEllipsis tooltip="Context actions" dotsSize="medium">
            <CopyFieldDropdownMenuItem title="Copy context ID" value={id} />
            {canManageContext && (
              <>
                <DropdownMenuItem onAction={showEditDrawer}>Edit</DropdownMenuItem>
                <ContextListItemDropdownDelete
                  hasAttachedEntities={hasAttachedStacks}
                  name={name}
                  id={id}
                  onSuccess={onDeleteSuccess}
                />
              </>
            )}
          </DropdownMenuEllipsis>
        </ViewHeaderWrapper>
      </ViewHeaderWrapper>
      <ViewHeaderScrollCollapse>
        <MetaInfoList>
          {spaceDetails && (
            <MetaInfoListItem
              icon={Space}
              linkText={spaceDetails.name}
              href={`/spaces/${spaceDetails.id}`}
            />
          )}
          {createdAt && (
            <MetaInfoListItem icon={Clock}>
              <Timestamp timestamp={createdAt} />
            </MetaInfoListItem>
          )}
        </MetaInfoList>
      </ViewHeaderScrollCollapse>

      <ViewHeaderNavigation>
        <TabWithCounter end to={contextUrl} count={variablesCount} label="Variables" />
        <TabWithCounter
          to={`${contextUrl}/files`}
          count={mountedFilesCount}
          label="Mounted files"
        />
        <TabWithBadge
          badgeValue={hasHooksDefined ? "Defined" : "None"}
          to={`${contextUrl}/hooks`}
          label="Hooks"
        />
        <TabWithCounter
          to={`${contextUrl}/used-by`}
          count={attachedStacks.length}
          label="Used by"
        />
      </ViewHeaderNavigation>
    </ViewHeader>
  );
};

export default ContextHeader;
