import { useHref, useResolvedPath } from "react-router-dom";

import { isExternalPath } from "./helpers";

/**
 * This hook is used to resolve relative paths to absolute paths in react-aria-components. We should
 * monitor this thread https://github.com/adobe/react-spectrum/issues/5476 to see if we can remove
 * this hook in the future.
 *
 * The hook has to be used in the component usage, and cannot be passed via the global context.
 */
const useAriaHrefProps = (href?: string) => {
  const resolvedHref = useHref(useResolvedPath(href || ""));

  if (isExternalPath(href)) {
    return { href };
  }

  return href ? { href: resolvedHref } : {};
};

export default useAriaHrefProps;
