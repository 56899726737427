import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationSamlCreateArgs, SamlSettings } from "types/generated";

type FetchSAMLCreate = { samlCreate: Pick<SamlSettings, "adminLogin"> };
export const CREATE_SSO_SAML: TypedDocumentNode<FetchSAMLCreate, MutationSamlCreateArgs> = gql`
  mutation SSOCreateSAML($metadata: String!, $dynamic: Boolean!, $nameIDFormat: SAMLNameIDFormat) {
    samlCreate(metadata: $metadata, dynamic: $dynamic, nameIDFormat: $nameIDFormat) {
      adminLogin
    }
  }
`;
