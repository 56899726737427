import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import useLocalStorage from "@rehooks/local-storage";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { AnsibleHost } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import { decodeURIParam } from "utils/urls";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import useURLParams from "hooks/useURLParams";
import PageInfo from "components/PageWrapper/Info";
import SearchInput from "components/SearchInput";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import ConfigManagementEmptyState from "views/Account/ConfigManagement/EmptyState";
import ConfigManagementTreeGrid from "views/Account/ConfigManagement/TreeGrid";
import { LayoutMode } from "views/Account/ConfigManagement/TreeGrid/types";
import SegmentedControl from "ds/components/SegmentedControl";
import { LAYOUT_MODE_OPTIONS } from "views/Account/ConfigManagement/constants";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import { createAnsibleNodesByHost } from "views/Account/ConfigManagement/TreeGrid/utils";

import { GET_RUN_ANSIBLE_HOSTS } from "./gql";
import {
  DEFAULT_LAYOUT_MODE,
  FILTERS_ORDER_SETTINGS_KEY,
  LAYOUT_MODE_LOCAL_STORAGE_KEY,
} from "./constants";
import styles from "./styles.module.css";

type AnsibleRunTasksProps = {
  stackId: string;
  runId: string;
  analyticsPage?: AnalyticsPage;
};

const AnsibleRunTasks = ({ stackId, runId, analyticsPage }: AnsibleRunTasksProps) => {
  const urlParams = useURLParams();
  const searchQuery = decodeURIParam(urlParams.get(URL_SEARCH_KEY));
  const [layoutMode, setLayoutMode] = useLocalStorage<LayoutMode>(
    LAYOUT_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_LAYOUT_MODE
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const { onError } = useTypedContext(FlashContext);
  const { data, loading, error } = useQuery<{ ansibleHosts: AnsibleHost[] }>(
    GET_RUN_ANSIBLE_HOSTS,
    {
      onError,
      variables: { input: { stack: stackId, run: runId } },
    }
  );

  const filteredAnsibleHosts = useMemo(() => {
    const ansibleHosts = data?.ansibleHosts || [];
    const searchValue = searchQuery?.trim();
    const filteredAnsibleHosts = searchValue
      ? ansibleHosts.filter((host) => host.name.includes(searchValue))
      : ansibleHosts;
    return filteredAnsibleHosts;
  }, [data?.ansibleHosts, searchQuery]);

  const nodes = useMemo(
    () =>
      createAnsibleNodesByHost(
        filteredAnsibleHosts,
        layoutMode === LayoutMode.Diagram,
        location.pathname
      ),
    [filteredAnsibleHosts, layoutMode]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.ansibleHosts) {
    // TODO: skeleton
    return (
      <>
        <PageInfo title="Tasks" />
        <PageLoading />
      </>
    );
  }

  const hasNoFilteringResults = searchQuery && !filteredAnsibleHosts.length;
  const isPageEmpty = !searchQuery && !filteredAnsibleHosts.length;
  const hasAnsibleHosts = !!filteredAnsibleHosts?.length;

  return (
    <>
      <PageInfo title="Tasks">
        <SearchInput
          placeholder="Search by host name"
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        />
        <SegmentedControl
          optionClassName={styles.layoutSwitchOption}
          onChange={(value) => {
            trackSegmentAnalyticsEvent?.("View Changed", { type: value });
            setLayoutMode(value);
          }}
          value={layoutMode}
          options={LAYOUT_MODE_OPTIONS}
        />
      </PageInfo>

      {hasNoFilteringResults && (
        <EmptyState
          title="No results"
          caption="Try different filters."
          icon={EmptystateSearchNoResultsColored}
        />
      )}

      {isPageEmpty && <ConfigManagementEmptyState />}

      {hasAnsibleHosts && (
        <ConfigManagementTreeGrid
          chartClassName={styles.chart}
          // TODO [Ansible v2] add lazy loading
          loadMoreItems={async () => undefined}
          layoutMode={layoutMode}
          nodes={nodes}
          analyticsPage={analyticsPage}
        />
      )}
    </>
  );
};

export default AnsibleRunTasks;
