import cx from "classnames";

import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import Icon from "ds/components/Icon";
import { Play } from "components/icons/generated";
import Box from "ds/components/Box";
import Badge from "ds/components/Badge";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageLaunchpad } from "hooks/useAnalytics/pages/launchpad";

import styles from "./styles.module.css";
import { showLaunchPadVideoModal } from "./Modal";

type LaunchPadVideosItemProps = {
  videoId: string;
  title: string;
  backgroundClass: string;
};

const LaunchPadVideosItem = ({ videoId, title, backgroundClass }: LaunchPadVideosItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageLaunchpad.Launchpad,
  });

  const handleClick = () => {
    showLaunchPadVideoModal({ videoId });
    trackSegmentAnalyticsEvent("Video Click", { title });
  };

  return (
    <TileWrapper onClick={handleClick} className={styles.thumbnailWrapper}>
      <TileContent direction="column">
        <TileTitle variant="p-t6">{title}</TileTitle>
        <div className={styles.thumbnail}>
          <div className={cx(styles.thumbnailBg, styles[backgroundClass])} />
          <Badge size="small" state="warningLight" className={styles.thumbnailBadge}>
            <Box gap="small" align="center">
              <Icon src={Play} />
              Video
            </Box>
          </Badge>
        </div>
      </TileContent>
    </TileWrapper>
  );
};

export default LaunchPadVideosItem;
