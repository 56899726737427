import { Controller, useFormContext } from "react-hook-form";
import { useCallback, useEffect, useRef } from "react";

import { TerraformWorkflowToolOptions } from "constants/terraform_workflow_tools";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import Typography from "ds/components/Typography";
import { TerraformWorkflowTool } from "types/generated";
import Box from "ds/components/Box";
import DragDropFileUpload from "ds/components/FileUpload/DragDropFileUpload";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Link from "ds/components/Link";
import { stackValidator } from "utils/formValidators";
import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import { getDocsUrl } from "utils/getDocsUrl";
import FormToggleField from "ds/components/Form/ToggleField";

import VersionInput from "../VersionInput";
import { StackVendorFormFields } from "../../types";
import { getValidateToolVersion } from "./getValidateToolVersion";
import { useFileUpload } from "./useFileUpload";
import useAsyncRangeVersionValidation from "./useAsyncRangeVersionValidation";
import VersionTooltipContent from "../VersionTooltipContent";
import { StackFormContext } from "../../context";
import { useNewStackAnalyticsSegementEvent } from "../../useNewStackAnalyticsSegementEvent";
import { getSwitchTooggleAnalyticsProps, getTooltipAnalyticsProps } from "../../utils";
import useStackCreationAnalyticsVersion from "../../useStackCreationAnalyticsVersion";

type NewStackVendorTerraformProps = {
  terraformVersions?: string[];
  openTofuVersions?: string[];
  reloadVersionsData: () => void;
  reloadLoading: boolean;
};

const NewStackVendorTerraform = ({
  terraformVersions,
  openTofuVersions,
  reloadVersionsData,
  reloadLoading,
}: NewStackVendorTerraformProps) => {
  const analyticsVersion = useStackCreationAnalyticsVersion();

  const { setAsyncValidationLoading } = useTypedContext(StackFormContext);
  const fileUploadRef = useRef<HTMLDivElement>(null);
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const { control, watch, trigger, formState, setValue, resetField } =
    useFormContext<StackVendorFormFields>();

  const handleStateFileChange = useFileUpload();

  const formValues = watch();
  const terraformValues = formValues.terraform;

  const handleWorkflowToolChange = useCallback(
    (value: TerraformWorkflowTool) => {
      if (terraformValues.workflowTool === value) {
        return;
      }

      let version: string;
      if (value === TerraformWorkflowTool.TerraformFoss) {
        version = terraformVersions ? terraformVersions[0] : "";
      } else if (value === TerraformWorkflowTool.OpenTofu) {
        version = openTofuVersions ? openTofuVersions[0] : "";
      } else {
        version = "";
      }

      setValue("terraform.workflowTool", value);
      setValue("terraform.version", {
        value: version,
        type: terraformValues.version.type,
      });

      trigger();

      trackSegmentEvent("Workflow tool", { tool: value, provider: "Terraform / OpenTofu" });
    },
    [
      terraformValues.workflowTool,
      terraformValues.version.type,
      setValue,
      trigger,
      trackSegmentEvent,
      terraformVersions,
      openTofuVersions,
    ]
  );

  // Trigger version validation after change of the workflowTool
  // version is updated just after workflowTool therefore standard validation process with {shouldValidate: true} works incorrectly as it uses outdated data
  useEffect(() => {
    if (formState.touchedFields.terraform?.workflowTool) {
      trigger(`terraform.version`);
    }
  }, [trigger, formState.touchedFields.terraform?.workflowTool]);

  const isVersionInputEnabled = terraformValues.workflowTool !== TerraformWorkflowTool.Custom;
  const isStateFileUploadEnabled = terraformValues.importExistingStateFile;
  const toolVersions =
    terraformValues.workflowTool === TerraformWorkflowTool.OpenTofu
      ? openTofuVersions
      : terraformVersions;
  const vendor =
    terraformValues.workflowTool === TerraformWorkflowTool.OpenTofu ? "OpenTofu" : "Terraform";

  const isManageState = terraformValues.manageState;

  useEffect(() => {
    if (!isManageState) {
      resetField("terraform.externalStateAccessEnabled");
      resetField("terraform.importExistingStateFile");
      resetField("terraform.uploadedStateObjectId");
      resetField("terraform.uploadedStateFile");
    } else {
      resetField("terraform.workspace");
    }
  }, [resetField, isManageState]);

  useEffect(() => {
    if (!isStateFileUploadEnabled) {
      resetField("terraform.uploadedStateObjectId");
      resetField("terraform.uploadedStateFile");
    }
  }, [resetField, isStateFileUploadEnabled]);

  useEffect(() => {
    if (isStateFileUploadEnabled && fileUploadRef.current) {
      fileUploadRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isStateFileUploadEnabled]);

  useAsyncRangeVersionValidation(setAsyncValidationLoading);

  return (
    <Box gap="x-large" direction="column">
      <Controller
        name="terraform.workflowTool"
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            error={fieldState.error?.message}
            noMargin
            label="Workflow tool:"
            {...getTooltipAnalyticsProps("Vendor", "Workflow tool", {
              provider: "Terraform / OpenTofu",
              version: analyticsVersion,
            })}
            tooltipInfo={
              <>
                <TooltipModalTitle>Workflow tool</TooltipModalTitle>
                <TooltipModalBody align="start">
                  The tool used to execute the workflow commands. This can be an open source (FOSS)
                  version of Terraform, OpenTofu or a custom tool. When a custom tool is selected,
                  you must provide a .spacelift/workflow.yml file containing the commands to
                  execute.
                </TooltipModalBody>
              </>
            }
            tooltipInfoVariant="modal"
          >
            {({ ariaInputProps }) => (
              <Select
                value={field.value}
                options={TerraformWorkflowToolOptions}
                onChange={handleWorkflowToolChange}
                error={!!fieldState.error?.message}
                ariaInputProps={ariaInputProps}
                ref={field.ref}
              />
            )}
          </FormField>
        )}
      />
      {isVersionInputEnabled && (
        <Controller
          name="terraform.version"
          control={control}
          rules={{
            validate: getValidateToolVersion(toolVersions),
          }}
          render={({ field, fieldState }) => {
            const version = field.value;

            return (
              <VersionInput
                analyticsProps={{
                  location: "Vendor",
                  name: "Version",
                  provider: "Terraform / OpenTofu",
                  version: analyticsVersion,
                }}
                title={`${vendor} version`}
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Choose version</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      <VersionTooltipContent />
                      <Link
                        href={getDocsUrl("/concepts/stack/stack-settings#terraform-version")}
                        target="_blank"
                      >
                        Learn more
                      </Link>
                    </TooltipModalBody>
                  </>
                }
                reloadLoading={reloadLoading}
                reloadVersionsData={reloadVersionsData}
                errorMessage={fieldState.error?.message}
                type={version?.type}
                version={version?.value}
                supportedVersions={toolVersions}
                onChange={field.onChange}
                ref={field.ref}
              />
            );
          }}
        />
      )}
      <Box gap="large" direction="column">
        <Controller
          name="terraform.useSmartSanitization"
          control={control}
          render={({ field }) => (
            <FormToggleField
              variant="switch"
              onChange={(arg) => {
                field.onChange(arg);
                trigger(`terraform.version`);
              }}
              checked={field.value}
              title="Smart Sanitization (recommended)"
              description={`If set, Spacelift will attempt to sanitize the resources created by terraform using the information provided by terraform as to which fields are "sensitive" or not`}
              {...getSwitchTooggleAnalyticsProps("Vendor", "Manage state", false, {
                provider: "Terraform / OpenTofu",
                version: analyticsVersion,
              })}
            />
          )}
        />

        <Controller
          name="terraform.manageState"
          control={control}
          render={({ field }) => (
            <FormToggleField
              variant="switch"
              onChange={field.onChange}
              checked={field.value}
              title="Manage State (recommended)"
              description="Indicates whether Spacelift should take care of the Terraform state"
              {...getSwitchTooggleAnalyticsProps("Vendor", "Manage state", true, {
                provider: "Terraform / OpenTofu",
                version: analyticsVersion,
              })}
              tooltipInfo={
                <>
                  <TooltipModalTitle>State management</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    <Typography tag="p" variant="p-body3">
                      For those of you who don't want to manage Terraform state, Spacelift offers an
                      optional sophisticated state backend synchronized with the rest of the
                      application to maximize security and convenience.
                    </Typography>
                    <Link href={getDocsUrl("/vendors/terraform/state-management")} target="_blank">
                      Learn more
                    </Link>
                  </TooltipModalBody>
                </>
              }
            />
          )}
        />
        {!isManageState && (
          <Controller
            name="terraform.workspace"
            control={control}
            rules={{ validate: (value) => stackValidator.validateWorkspaceField(value) || true }}
            render={({ field, fieldState }) => (
              <FormField label="Workspace:" error={fieldState.error?.message} isOptional noMargin>
                {({ ariaInputProps }) => (
                  <Input
                    error={!!fieldState.error}
                    type="text"
                    value={field.value}
                    placeholder="Terraform workspace to select"
                    onChange={field.onChange}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            )}
          />
        )}

        {isManageState && (
          <>
            <Controller
              name="terraform.externalStateAccessEnabled"
              control={control}
              render={({ field }) => (
                <FormToggleField
                  variant="switch"
                  onChange={field.onChange}
                  checked={field.value}
                  title="External state access"
                  description="Enables external, read-only access to the state of this Stack"
                  tooltipInfo={
                    <>
                      <TooltipModalTitle>External state access</TooltipModalTitle>
                      <TooltipModalBody align="start">
                        Only administrative stacks or users with write permission to this stack's
                        space can access the state.
                      </TooltipModalBody>
                    </>
                  }
                  {...getSwitchTooggleAnalyticsProps(
                    "Vendor",
                    "External state access enabled",
                    true,
                    {
                      provider: "Terraform / OpenTofu",
                      version: analyticsVersion,
                    }
                  )}
                />
              )}
            />

            <Box direction="column" gap="large">
              <Controller
                name="terraform.importExistingStateFile"
                control={control}
                render={({ field }) => (
                  <FormToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Import existing state file"
                    description="Spacelift will create a state file for you the first time you apply changes"
                    {...getSwitchTooggleAnalyticsProps(
                      "Vendor",
                      "Import existing state file",
                      true,
                      {
                        provider: "Terraform / OpenTofu",
                        version: analyticsVersion,
                      }
                    )}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Import state file</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            This is convenient if you have no existing resources to import. On the
                            other hand, if you're importing an existing Terraform project, you may
                            want to import its state file now to avoid having to import individual
                            resources later.
                          </Typography>
                          <Link
                            href={getDocsUrl(
                              "/vendors/terraform/state-management#importing-existing-state-file-into-your-terraform-stacks"
                            )}
                            target="_blank"
                          >
                            Learn more
                          </Link>
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />

              {isStateFileUploadEnabled && (
                <div ref={fileUploadRef}>
                  <Controller
                    name="terraform.uploadedStateFile"
                    control={control}
                    rules={{ required: "State file is required." }}
                    render={({ fieldState, field }) => (
                      <FormField
                        error={fieldState.error?.message}
                        label="State file upload"
                        noMargin
                      >
                        <DragDropFileUpload
                          caption="Drag and drop state file file here"
                          file={field.value}
                          onChange={handleStateFileChange}
                        />
                      </FormField>
                    )}
                  />
                </div>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default NewStackVendorTerraform;
