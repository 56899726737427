import { useMemo } from "react";
import { noop } from "lodash-es";
import { NetworkStatus, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { VcsProvider } from "types/generated";

import { LaunchPadFeatureSelfHosted } from "./types";
import { LaunchPadsFeatureProgress } from "../types";
import { GET_SELF_HOSTED_ONBOARDING_PROGRESS } from "./gql";

const useSelfHostedOnboardingProgress = (skip?: boolean, flashOnError = true) => {
  const { onError } = useTypedContext(FlashContext);

  const { data, loading, networkStatus, error, refetch } = useQuery(
    GET_SELF_HOSTED_ONBOARDING_PROGRESS,
    {
      variables: {
        searchVcsIntegrationsInput: {
          first: 1,
          orderBy: null,
          predicates: null,
        },
        searchWorkerPoolsInput: {
          first: 1,
          orderBy: null,
          predicates: null,
        },
      },
      onError: flashOnError ? onError : noop,
      skip,
    }
  );

  const isVcsConnected =
    data?.providers.includes(VcsProvider.Github) || !!data?.searchVCSIntegrations?.edges?.length;
  const hasInvitedUsers =
    !!data?.managedUsers && data.managedUsers.filter((user) => user.latestInvitation).length > 0;
  const hasConfiguredSSO = !!data?.hasSSO;
  const hasCreatedWorkerPool = !!data?.searchWorkerPools?.edges?.length;
  const hasConnectedSlack = !!data?.slackIntegration?.teamID;

  const progressByFeature: LaunchPadsFeatureProgress<LaunchPadFeatureSelfHosted> = useMemo(
    () => ({
      [LaunchPadFeatureSelfHosted.ConfigureSSO]: {
        isCompleted: hasConfiguredSSO,
      },
      [LaunchPadFeatureSelfHosted.CreateWorkerPool]: {
        isCompleted: hasCreatedWorkerPool,
      },
      [LaunchPadFeatureSelfHosted.SetupVCSIntegration]: {
        isCompleted: isVcsConnected,
      },
      [LaunchPadFeatureSelfHosted.InviteTeammates]: {
        isCompleted: hasInvitedUsers,
      },
      [LaunchPadFeatureSelfHosted.SetupSlackIntegration]: {
        isCompleted: hasConnectedSlack,
      },
    }),
    [hasConfiguredSSO, hasInvitedUsers, isVcsConnected, hasCreatedWorkerPool, hasConnectedSlack]
  );

  const isOnboardingComplete = Object.values(progressByFeature).every((value) => value.isCompleted);

  return {
    onboardingProgressInitialLoading: loading && !data && networkStatus !== NetworkStatus.refetch,
    progressByFeature,
    isOnboardingComplete,
    error,
    refetch,
    loading,
    networkStatus,
  };
};

export default useSelfHostedOnboardingProgress;
