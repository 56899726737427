import { createContext } from "react";

import { AnalyticsCommonProps } from "hooks/useAnalytics";

import { DashboardColumns, DashboardConfig, DashboardOverviewStaticColumn } from "./types";

type DashboardContextProps = {
  updateTabOverviewColumnConfig: (column: DashboardOverviewStaticColumn) => void;
  updateTabColumnsConfig: (tab: string, columns: DashboardColumns) => void;
  config: DashboardConfig;
  openManageDrawer: (tab?: string, analyticsProps?: AnalyticsCommonProps["analyticsProps"]) => void;
  tabs: { id: string; title: string }[];
  addNewTab: () => void;
  removeTab: (id: string) => void;
  canAddMoreViews: boolean;
  configLoading: boolean;
};

export const DashboardContext = createContext<DashboardContextProps | undefined>(undefined);
DashboardContext.displayName = "DashboardContext";
