import { SpaceAccessLevel } from "types/generated";
import { Book, Edit, Glasses } from "components/icons/generated";

import { SpaceField } from "./types";

export const validateSpaceFields = (fields: SpaceField[], allowEmpty?: boolean) => {
  const hasEmptySubfield =
    allowEmpty &&
    fields.length === 1 &&
    fields.some(
      (field) =>
        (field.space && !field.spaceAccessLevel) || (!field.space && field.spaceAccessLevel)
    );
  const hasEmptySpaceFields =
    (!allowEmpty || (allowEmpty && fields.length > 1)) &&
    fields.some((field) => !field.space || !field.spaceAccessLevel);
  const spaces = fields.map(({ space }) => space);
  const hasUniqueSpaces = spaces.length === new Set(spaces).size;

  return !hasEmptySubfield && !hasEmptySpaceFields && hasUniqueSpaces;
};

export const getOptionIconSrc = (value: SpaceAccessLevel) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (value) {
    case SpaceAccessLevel.Admin:
      return Glasses;
    case SpaceAccessLevel.Write:
      return Edit;
    case SpaceAccessLevel.Read:
      return Book;
    default:
      return undefined;
  }
};
