import { OverlayTriggerStateContext } from "react-aria-components";
import { useCallback, useContext } from "react";

import ButtonNew from "../Button/New";

type DrawerCancelButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
};

const DrawerCancelButton = ({ onClick, disabled }: DrawerCancelButtonProps) => {
  const state = useContext(OverlayTriggerStateContext)!;

  const handleClick = useCallback(() => {
    state.close();
    onClick?.();
  }, [state, onClick]);

  return (
    <ButtonNew variant="secondary" onPress={handleClick} disabled={disabled}>
      Cancel
    </ButtonNew>
  );
};

export default DrawerCancelButton;
