import { MouseEvent, useCallback, useEffect, useMemo, useState, useId } from "react";
import cx from "classnames";
import debounce from "lodash-es/debounce";

import {
  ActiveFilter,
  FilterItem,
  FilterItemOption,
  HierarchyFilterItemOption,
} from "components/Filters/types";
import { ChevronRight } from "components/icons/generated";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import { FiltersContext } from "components/Filters";
import { SearchSuggestionsFieldType } from "types/generated";
import { escapeRegex } from "utils/strings";
import Autocomplete from "ds/components/Autocomplete";
import BaseAction from "ds/components/BaseAction";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import Icon from "ds/components/Icon";

import styles from "../styles.module.css";
import { projectRootFilter } from "../../helpers";
import { ADD_ANOTHER_LABEL_TEXT } from "../constants";
import FilterOpenSectionHeader from "./SectionHeader";
import FilterOpenSectionActions from "./SectionActions";
import FilterOptionsList from "./FilterOptionsList";
import HierarchyFilterOptionsList from "./HierarchyFilterOptionsList";

type FilterOpenSectionProps = {
  isLabelFilter: boolean;
  isLastLabelsFilter: boolean;
  filterName: string;
  filterItem: FilterItem;
  toggleSectionOpen: () => void;
  addAnotherLabelFilter: () => void;
  activeFilter: ActiveFilter | undefined;
  analyticsPage?: AnalyticsPage;
  disabled?: boolean;
};

const FilterOpenSection = ({
  isLabelFilter,
  isLastLabelsFilter,
  filterName,
  filterItem,
  toggleSectionOpen,
  addAnotherLabelFilter,
  activeFilter,
  analyticsPage,
  disabled,
}: FilterOpenSectionProps) => {
  const { setActiveFilter, activeFilters, filtersItemsOptionsMap, filtersLoading } =
    useTypedContext(FiltersContext);
  const titleId = useId();

  const urlParams = useURLParams();
  const searchValue = urlParams.get(URL_SEARCH_KEY);
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const [propertyValues, syncPropertyValues] = useState(
    () => filtersItemsOptionsMap.get(filterItem.filterName) || []
  );

  const [selectedValues, setSelectedValues] = useState<Set<string>>(new Set(activeFilter?.values));
  const [searchInput, setSearchInput] = useState("");

  // Sync filter values for clickable metadata
  useEffect(() => {
    setSelectedValues(new Set(activeFilter?.values));
  }, [activeFilter?.values, setSelectedValues]);

  const filteredPropertyValues = useMemo<FilterItemOption[] | HierarchyFilterItemOption[]>(() => {
    const propertyValuesMap = new Map(
      propertyValues.map((item: FilterItemOption | HierarchyFilterItemOption) => [
        "id" in item ? item.id : item.value,
        item,
      ])
    );

    activeFilter?.values.forEach((value) => {
      if (
        !propertyValuesMap.has(value) &&
        activeFilter.type !== SearchSuggestionsFieldType.Hierarchy
      ) {
        propertyValuesMap.set(value, {
          value,
          count: undefined,
          type: activeFilter.type,
        });
      }
    });

    // sort wildcards to the top of list
    const propertyValuesUniqueByValue = [...propertyValuesMap.values()].sort((a, b) => {
      if (a.type === SearchSuggestionsFieldType.String && (a.value as string).includes("*")) {
        return -1;
      }

      if (b.type === SearchSuggestionsFieldType.String && (b.value as string).includes("*")) {
        return 1;
      }

      return 0;
    });

    if (!searchInput) return propertyValuesUniqueByValue;

    const reg = new RegExp(escapeRegex(searchInput), "i");

    return propertyValuesUniqueByValue.filter((item) => reg.test(item.value.toString())) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyValues, activeFilter?.values, searchInput]);

  const handleApplyFilter = (values: string[]) => {
    setActiveFilter({
      ...filterItem,
      values,
    });
    trackSegmentAnalyticsEvent?.("Filter applied", {
      filterName: filterItem.filterName,
    });
  };

  // TODO: refactor and move to filter context during https://app.clickup.com/t/862jbnajp
  const debouncedApplyFilter = useMemo(
    () => {
      return debounce(handleApplyFilter, 500);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFilters, searchValue]
  );

  // TODO: uncomment when backend optimize "select all" behavior
  // const handleSelectAll = (e: MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation();
  //   const allValues = filteredPropertyValues.map((item) => item.value.toString());
  //   setSelectedValues(new Set(allValues));
  //   debouncedApplyFilter(allValues);
  // };

  const handleResetAll = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setSelectedValues(new Set());
      debouncedApplyFilter([]);
      setSearchInput("");
    },
    [debouncedApplyFilter]
  );

  const handleSelectValue = useCallback(
    (value: string) => {
      if (selectedValues.has(value)) {
        selectedValues.delete(value);
      } else {
        selectedValues.add(value);
      }

      setSelectedValues(new Set(selectedValues));
      debouncedApplyFilter(Array.from(selectedValues));
    },
    [selectedValues, debouncedApplyFilter]
  );

  const handleAddWildcard = useCallback((value: string) => {
    handleSelectValue(value);
    setSearchInput("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isHierarchy = filterItem.type === SearchSuggestionsFieldType.Hierarchy;
  const isSearchable = filterItem.type === SearchSuggestionsFieldType.String || isHierarchy;
  const searchInputIsWildcard = searchInput.includes("*");
  const isProjectRootFilter = projectRootFilter(filterItem.filterName);
  // TODO: uncomment when backend optimize "select all" behavior
  // const allSelected = selectedValues.size === filteredPropertyValues.length;

  const handleAutocompleteEnterKey = useCallback(() => {
    if (searchInputIsWildcard && !isHierarchy) {
      handleAddWildcard(searchInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  useEffect(() => {
    if (!filtersLoading) {
      syncPropertyValues(filtersItemsOptionsMap.get(filterItem.filterName) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersLoading, filtersItemsOptionsMap]);

  return useMemo(() => {
    return (
      <>
        <div
          className={cx(styles.activePanel, {
            [styles.labelFilter]: isLabelFilter,
          })}
        >
          <div className={styles.activePanelHeader}>
            <Box>
              <BaseAction onClick={toggleSectionOpen} justify="start" className={styles.activeItem}>
                <FilterOpenSectionHeader id={titleId} name={filterName} loading={filtersLoading} />
              </BaseAction>
              {!disabled && (
                <FilterOpenSectionActions
                  selectedSize={selectedValues.size}
                  handleResetAll={handleResetAll}
                />
              )}
            </Box>

            {isSearchable && (
              <Box direction="column">
                <Autocomplete
                  className={styles.autocomplete}
                  placeholder={isHierarchy ? "Search" : "Search or create a wildcard"}
                  query={searchInput}
                  onChange={setSearchInput}
                  onEnterKey={handleAutocompleteEnterKey}
                />
              </Box>
            )}
          </div>

          {!isHierarchy && (
            <FilterOptionsList
              titleId={titleId}
              options={filteredPropertyValues}
              selectedValues={selectedValues}
              isProjectRootFilter={isProjectRootFilter}
              handleSelectValue={handleSelectValue}
              handleAddWildcard={handleAddWildcard}
              searchInputIsWildcard={searchInputIsWildcard}
              isSearchable={isSearchable}
              searchInput={searchInput}
              disabled={disabled}
            />
          )}

          {isHierarchy && (
            <HierarchyFilterOptionsList
              titleId={titleId}
              options={filteredPropertyValues as HierarchyFilterItemOption[]}
              handleSelectValue={handleSelectValue}
              selectedValues={selectedValues}
              searchInput={searchInput}
            />
          )}
        </div>

        {isLastLabelsFilter && (
          <BaseAction
            onClick={addAnotherLabelFilter}
            className={cx(styles.item, styles.labelFilter)}
            disabled={selectedValues.size === 0}
          >
            <Icon src={ChevronRight} className={styles.icon} />
            {ADD_ANOTHER_LABEL_TEXT}
          </BaseAction>
        )}
      </>
    );
  }, [
    addAnotherLabelFilter,
    filterName,
    filteredPropertyValues,
    filtersLoading,
    handleAddWildcard,
    handleAutocompleteEnterKey,
    handleResetAll,
    handleSelectValue,
    isHierarchy,
    isLabelFilter,
    isLastLabelsFilter,
    isProjectRootFilter,
    isSearchable,
    searchInput,
    searchInputIsWildcard,
    selectedValues,
    toggleSectionOpen,
    disabled,
    titleId,
  ]);
};

export default FilterOpenSection;
