import Skeleton from "react-loading-skeleton";

import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import MetricCard from "components/MetricCard";
import { User } from "components/icons/generated";

import styles from "./styles.module.css";

const DashboardOverviewColumnUserActivitySkeleton = () => (
  <Box direction="column" className={styles.skeletonWrapper}>
    <MetricCard
      noBorder
      titleIcon={<Icon src={User} />}
      titleColor="primary"
      title="Users"
      value={<Skeleton height={33} width={33} />}
      className={styles.purpleWeakBackground}
    />
    <Box padding="x-large 0">
      <Skeleton height={20} width={110} />
    </Box>
  </Box>
);

export default DashboardOverviewColumnUserActivitySkeleton;
