import { gql, TypedDocumentNode } from "@apollo/client";

import { SamlSettings } from "types/generated";

type FetchSAMLDelete = { samlDelete: Pick<SamlSettings, "adminLogin"> };
export const DELETE_SAML_SSO: TypedDocumentNode<FetchSAMLDelete> = gql`
  mutation SSODeleteSAML {
    samlDelete {
      adminLogin
    }
  }
`;
