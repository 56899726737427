import EmptyState from "ds/components/EmptyState";
import { EmptystateUsersColored } from "components/icons/generated";
import Link from "ds/components/Link";
import { getDocsUrl } from "utils/getDocsUrl";

const UsersEmpty = () => {
  return (
    <EmptyState
      title="You don’t have any collaborators yet"
      icon={EmptystateUsersColored}
      caption={
        <>
          Invite your team to Spacelift and manage their access.
          <br></br>
          <Link size="small" href={getDocsUrl("/concepts/blueprint")} target="_blank">
            Learn more
          </Link>
          {` `}
          about access management.
        </>
      }
    />
  );
};

export default UsersEmpty;
