import { mapDictionaryKey } from "components/Filters/helpers";
import { searchModuleSuggestionsDictionary, ModuleSuggestions } from "constants/module";
import { Module } from "types/generated";

export const getFilterKey = (key: ModuleSuggestions | string) => {
  return mapDictionaryKey(key, searchModuleSuggestionsDictionary);
};

export const getAllItemsForSelectAll = (modules: Module[]) =>
  new Set(modules.map((module) => module.id));
