import React, { createContext } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import DocumentationIconButton from "components/DocumentationIconButton";
import SegmentedControl from "ds/components/SegmentedControl";
import { Diagram, List } from "components/icons/generated";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import SearchInput from "components/SearchInput";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";
import { SpacesLayoutMode } from "./types";
import { FILTERS_ORDER_SETTINGS_KEY } from "./constants";
import { SpacesViewActionsContext } from "./ActionsContext";
import { SpacesViewContext } from "./Context";

type SpacesPageLayoutContextProps = {
  layoutMode: SpacesLayoutMode;
};

export const SpacesPageLayoutContext = createContext<SpacesPageLayoutContextProps | undefined>(
  undefined
);
SpacesPageLayoutContext.displayName = "SpacesPageLayoutContext";

const layoutModeOptions = [
  {
    label: (
      <Box gap="medium" align="center">
        <Icon src={List} />
        <Typography tag="span" variant="p-t7">
          List
        </Typography>
      </Box>
    ),
    value: SpacesLayoutMode.List,
    tooltip: "Switch to list view",
  },
  {
    label: (
      <Box gap="medium" align="center">
        <Icon src={Diagram} />
        <Typography tag="span" variant="p-t7">
          Diagram
        </Typography>
      </Box>
    ),
    value: SpacesLayoutMode.Diagram,
    tooltip: "Switch to diagram view",
  },
];

type SpacesPageLayoutProps = {
  children: React.ReactNode;
  setLayoutMode: (mode: SpacesLayoutMode) => void;
  layoutMode: SpacesLayoutMode;
};

const SpacesPageLayout = ({ children, setLayoutMode, layoutMode }: SpacesPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);

  const { isRootAdmin } = useTypedContext(SpacesViewContext);
  const { onCreate: onSpaceCreate } = useTypedContext(SpacesViewActionsContext);

  useTitle(`Spaces · ${accountName}`);

  return (
    <SpacesPageLayoutContext.Provider value={{ layoutMode }}>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Spaces</ViewHeaderTitle>

        <ViewHeaderWrapper direction="row">
          {layoutMode === SpacesLayoutMode.List && (
            <SearchInput
              placeholder="Search spaces by name or id"
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />
          )}

          <SegmentedControl
            optionClassName={styles.layoutSwitchOption}
            onChange={setLayoutMode}
            value={layoutMode}
            options={layoutModeOptions}
          />

          <DocumentationIconButton
            href={getDocsUrl("/concepts/spaces")}
            tooltipText="Go to spaces documentation"
          />

          {isRootAdmin && (
            <Button onClick={() => onSpaceCreate()} variant="primary">
              Create space
            </Button>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>

      {children}
    </SpacesPageLayoutContext.Provider>
  );
};

export default SpacesPageLayout;
