import { DiffEditor } from "@monaco-editor/react";
import cx from "classnames";
import { useCallback } from "react";

import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import Box from "ds/components/Box";
import ThemedLogs from "components/ThemedLogs";
import ThemedLogsContent from "components/ThemedLogs/Content";
import { Expand } from "components/icons/generated";
import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import Select from "ds/components/Select";
import { SelectOptionBase } from "ds/components/Select/types";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import styles from "./styles.module.css";
import { EDITOR_CONFIG } from "./constants";

type ConfigManagementTreeGridDrawerDiffProps = {
  options?: SelectOptionBase[];
  original: string;
  modified: string;
  toggleFullScreen: (value?: boolean) => void;
  setFileName: (value: string) => void;
  fileName?: string;
  analyticsPage?: AnalyticsPage;
};

const ConfigManagementTreeGridDrawerDiff = ({
  options,
  original,
  modified,
  toggleFullScreen,
  setFileName,
  fileName,
  analyticsPage,
}: ConfigManagementTreeGridDrawerDiffProps) => {
  const { isDarkMode, currentDarkModeSyntaxTheme, currentLightModeSyntaxTheme } =
    useTypedContext(ThemeContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const openFullScreen = useCallback(() => {
    toggleFullScreen(true);
    trackSegmentAnalyticsEvent("Diff fullscreen opened");
  }, [toggleFullScreen, trackSegmentAnalyticsEvent]);

  return options?.length ? (
    <>
      {options.length > 1 && (
        <FormField
          className={styles.label}
          label={
            <Box fullWidth align="center" justify="between">
              File
            </Box>
          }
        >
          <Select options={options} value={fileName} onChange={setFileName} />
        </FormField>
      )}
      <FormField
        className={styles.label}
        labelGrow="1"
        label={
          <Box fullWidth align="center" justify="between">
            Diff
            <ButtonIconNew variant="ghost" icon={Expand} onPress={openFullScreen}>
              Enter full screen mode
            </ButtonIconNew>
          </Box>
        }
      >
        <ThemedLogs className={cx(styles.diffEditor, options.length === 1 && styles.enhanced)}>
          <ThemedLogsContent>
            <Box className={styles.diffContent}>
              <DiffEditor
                original={original}
                modified={modified}
                language="json"
                theme={isDarkMode ? currentDarkModeSyntaxTheme : currentLightModeSyntaxTheme}
                options={{
                  renderSideBySide: false,
                  ...EDITOR_CONFIG,
                }}
              />
            </Box>
          </ThemedLogsContent>
        </ThemedLogs>
      </FormField>
    </>
  ) : (
    <FormFieldViewText label="Difference" value="No difference available" />
  );
};

export default ConfigManagementTreeGridDrawerDiff;
