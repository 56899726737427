import isObject from "lodash-es/isObject";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import useURLParams from "hooks/useURLParams";
import { AVAILABLE_POLICY_TYPES, POLICY_TYPES_FEATURES } from "constants/policy";
import { PolicyType } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { GET_POLICY_NAMES } from "./gql";
import { SpacesContext } from "../SpacesProvider";
import { SubscriptionContext } from "../SubscriptionWrapper";

const areLabelsProperArray = (labels: unknown): labels is Record<"value", string>[] =>
  Array.isArray(labels) &&
  labels.every((label) => isObject(label) && "value" in label && typeof label.value === "string");

export const useDetailsFromUrl = () => {
  const { tierFeatures } = useTypedContext(SubscriptionContext);

  const { manageableSpaces } = useTypedContext(SpacesContext);
  const { onError } = useTypedContext(FlashContext);
  const urlParams = useURLParams();
  const navigate = useNavigate();

  const { loading, data } = useQuery<{ policies: Array<{ name: string }> }>(GET_POLICY_NAMES, {
    onError: (e) => {
      onError(e);
      navigate("/policies");
    },
  });

  const name = urlParams.get("name");
  const labels = urlParams.get("labels");
  const type = urlParams.get("type");
  const space = urlParams.get("space");
  const description = urlParams.get("description");
  const templateId = urlParams.get("templateId");

  let parsedLabels;
  let parsedName;
  let parsedSpace;
  let parsedType;
  let parsedDescription;
  let parsedTemplateId;

  if (templateId) {
    try {
      parsedTemplateId = decodeURIComponent(atob(templateId));
    } catch {
      // templateId is malformed reset them to undefined
      parsedTemplateId = undefined;
    }
  }

  if (labels) {
    try {
      const labelsFromUrl = JSON.parse(decodeURIComponent(atob(labels)));
      if (areLabelsProperArray(labelsFromUrl)) {
        parsedLabels = labelsFromUrl.map((label) => label.value);
      }
    } catch {
      // labels are malformed reset them to undefined
      parsedLabels = undefined;
    }
  }

  if (description) {
    try {
      parsedDescription = decodeURIComponent(atob(description));
    } catch {
      // description is malformed reset it to undefined
      parsedDescription = undefined;
    }
  }

  try {
    if (!name || !type || !space) {
      throw new Error();
    }

    if (!loading && data?.policies) {
      const nameToValidate = decodeURIComponent(atob(name));
      if (!data.policies.map((policy) => policy.name).includes(nameToValidate)) {
        parsedName = nameToValidate;
      } else {
        throw new Error();
      }
    }

    const spaceToValidate = decodeURIComponent(atob(space));

    if (manageableSpaces) {
      if (manageableSpaces.find((space) => space.id === spaceToValidate)) {
        parsedSpace = spaceToValidate;
      } else {
        throw new Error();
      }
    }

    const availableTypes = AVAILABLE_POLICY_TYPES.filter((item) => {
      const billingTierFeature = POLICY_TYPES_FEATURES[item];
      if (!billingTierFeature) {
        return true;
      }

      return tierFeatures.includes(billingTierFeature);
    });

    const typeToValidate = decodeURIComponent(atob(type)) as PolicyType;

    if (availableTypes.includes(typeToValidate)) {
      parsedType = typeToValidate;
    } else {
      throw new Error();
    }
  } catch {
    // qs is malformed redirect to policies
    navigate("/policies");
  }

  return {
    name: parsedName,
    labels: parsedLabels,
    space: parsedSpace,
    description: parsedDescription,
    type: parsedType,
    templateId: parsedTemplateId,
    loading,
    takenPolicyNames: data?.policies.map((policy) => policy.name) || [],
  };
};
