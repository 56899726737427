import { useState, FunctionComponent } from "react";

import { AwsIntegration, AzureIntegration } from "types/generated";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";
import WarningContextProvider from "components/WarningContext/Provider";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import { REFETCH_STACK_AND_STACK_SETTINGS_QUERIES } from "../../constants";
import StackSettingsIntegrationsCloudAttachForm from "./AttachForm";

type StackSettingsIntegrationsCloudDrawerProps = {
  filteredCloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: CLOUD_INTEGRATIONS;
  }>;
  filteredAzureIntegrations?: AzureIntegration[];
  filteredAwsIntegrations?: AwsIntegration[];
  hasData: boolean;
  refetch: () => void;
  refetching: boolean;
  stackId: string;
  refetchQueries?: string[];
  canAttachGcpIntegration?: boolean;
};

const StackSettingsIntegrationsCloudDrawer = createDrawer(
  ({
    filteredAwsIntegrations,
    filteredAzureIntegrations,
    stackId,
    hasData,
    refetch,
    refetching,
    canAttachGcpIntegration,
    filteredCloudConfig,
  }: StackSettingsIntegrationsCloudDrawerProps) => {
    const [cloud, setCloud] = useState<CLOUD_INTEGRATIONS | undefined>(filteredCloudConfig[0].type);

    return (
      <WarningContextProvider>
        <DrawerSimple variant="wide">
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Attach cloud integration" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <StackSettingsIntegrationsCloudAttachForm
              entityId={stackId}
              hasData={hasData}
              refetch={refetch}
              refetching={refetching}
              setCloud={setCloud}
              cloud={cloud}
              cloudConfig={filteredCloudConfig}
              attachableAzureIntegrations={filteredAzureIntegrations}
              attachableAwsIntegrations={filteredAwsIntegrations}
              canAttachGcpIntegration={canAttachGcpIntegration}
              refetchQueries={REFETCH_STACK_AND_STACK_SETTINGS_QUERIES}
            />
            <DrawerFooter sticky>
              <DrawerFooterActions>
                <DrawerCancelButton />
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </DrawerSimple>
      </WarningContextProvider>
    );
  }
);

export const showStackSettingsIntegrationsCloudDrawer = createDrawerTrigger(
  StackSettingsIntegrationsCloudDrawer
);
