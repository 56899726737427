import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { DELETE_MANAGED_USER } from "../../gql";

const useDeleteUser = () => {
  const { onError } = useTypedContext(FlashContext);

  const [deleteManagedUser, { loading }] = useMutation(DELETE_MANAGED_USER, {
    onError,
    refetchQueries: ["GetManagedUsers", "GetAccountSettings"],
  });

  const onDelete = (id: string) => {
    return deleteManagedUser({
      variables: {
        id,
      },
    });
  };

  return { onDelete, loading };
};

export default useDeleteUser;
