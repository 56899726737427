import { createContext } from "react";

import { SelfHostedInstanceInfo } from "./types";

type SelfHostedInstanceContextData = {
  instanceInfo: SelfHostedInstanceInfo | undefined;
  triggerFetchInstanceInfo: (successCallback?: () => void) => void;
};

export const SelfHostedInstanceContext = createContext<SelfHostedInstanceContextData | undefined>(
  undefined
);
SelfHostedInstanceContext.displayName = "SelfHostedInstanceContext";
