import cx from "classnames";
import { useCallback, useMemo } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import { FiltersContext } from "components/Filters";
import { ActiveFilter } from "components/Filters/types";
import { ChevronRight, Cross } from "components/icons/generated";
import BaseAction from "ds/components/BaseAction";
import useTypedContext from "hooks/useTypedContext";
import { pluralize } from "shared/Pluralize";
import Icon from "ds/components/Icon";

import { ADD_ANOTHER_LABEL_TEXT } from "./constants";
import styles from "./styles.module.css";

type FilterActivatedSectionProps = {
  isLabelFilter: boolean;
  isLastLabelsFilter: boolean;
  filterName: string;
  toggleSectionOpen: () => void;
  addAnotherLabelFilter: () => void;
  activeFilter: ActiveFilter;
  disabled?: boolean;
};

const FilterActivatedSection = ({
  isLabelFilter,
  isLastLabelsFilter,
  filterName,
  toggleSectionOpen,
  addAnotherLabelFilter,
  activeFilter,
  disabled,
}: FilterActivatedSectionProps) => {
  const { deleteActiveFilter } = useTypedContext(FiltersContext);

  const handleDeleteFilter = useCallback(() => {
    if (activeFilter) {
      deleteActiveFilter(activeFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);

  // avoid re-rendering on the Context change
  return useMemo(() => {
    return (
      <>
        <div
          className={cx(styles.doneFilter, {
            [styles.labelFilter]: isLabelFilter,
          })}
        >
          {!disabled && (
            <ButtonIcon
              icon={Cross}
              className={styles.deleteFilter}
              onClick={handleDeleteFilter}
              variant="ghost"
            >
              Remove filter
            </ButtonIcon>
          )}

          <BaseAction onClick={toggleSectionOpen} className={cx(styles.item, styles.doneItem)}>
            <span className={styles.property}>
              <Icon src={ChevronRight} className={styles.icon} />
              <span className={styles.text}>{filterName}</span>
            </span>

            <span className={styles.values}>
              {activeFilter.values.length} {pluralize("value", activeFilter.values.length)}
            </span>
          </BaseAction>
        </div>
        {isLastLabelsFilter && (
          <BaseAction
            onClick={addAnotherLabelFilter}
            className={cx(styles.item, styles.labelFilter)}
          >
            <Icon src={ChevronRight} className={styles.icon} />
            {ADD_ANOTHER_LABEL_TEXT}
          </BaseAction>
        )}
      </>
    );
  }, [
    activeFilter.values.length,
    addAnotherLabelFilter,
    filterName,
    handleDeleteFilter,
    isLabelFilter,
    isLastLabelsFilter,
    toggleSectionOpen,
    disabled,
  ]);
};

export default FilterActivatedSection;
