import { isSelfHostedDistribution } from "utils/distribution";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import {
  StackCreationVendor,
  StackCreationWizardNavStep,
  StackCreationWizardStep,
  StackFormFields,
} from "./types";

const isSelfHosted = isSelfHostedDistribution();

export const validateRequired = (fieldTitle: string) => (value?: string) =>
  value?.trim() ? true : `${fieldTitle} field is required.`;

export const validateRequiredURL = (fieldTitle: string) => (value?: string) => {
  try {
    if (value) {
      new URL(value);
    }
  } catch {
    return "Invalid URL";
  }

  return validateRequired(fieldTitle)(value);
};

export const isStepRequired = (step: StackCreationWizardStep, formData: StackFormFields) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (step) {
    case StackCreationWizardStep.Hooks: {
      return formData[StackCreationWizardStep.Vendor].vendor === StackCreationVendor.Pulumi;
    }
    case StackCreationWizardStep.Behaviour: {
      return (
        formData[StackCreationWizardStep.Vendor].vendor === StackCreationVendor.Pulumi ||
        isSelfHosted
      );
    }

    case StackCreationWizardStep.Cloud:
    case StackCreationWizardStep.AttachContext:
    case StackCreationWizardStep.Policies: {
      return false;
    }
    default: {
      return true;
    }
  }
};

export const areAllNextStepsOptional = (
  step: StackCreationWizardStep,
  formData: StackFormFields,
  availableSteps: Array<StackCreationWizardNavStep>
) => {
  const stepIndex = availableSteps.findIndex(({ step: currentStep }) => currentStep === step);
  const nextSteps = availableSteps.slice(stepIndex + 1);

  return !!nextSteps.length && nextSteps.every(({ step }) => !isStepRequired(step, formData));
};

export const isSkipToSummaryVisible = (
  step: StackCreationWizardStep,
  formData: StackFormFields,
  availableSteps: Array<StackCreationWizardNavStep>
) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (step) {
    case StackCreationWizardStep.Vendor:
    case StackCreationWizardStep.Behaviour:
    case StackCreationWizardStep.Hooks:
    case StackCreationWizardStep.Cloud:
    case StackCreationWizardStep.Policies:
    case StackCreationWizardStep.AttachContext: {
      return areAllNextStepsOptional(step, formData, availableSteps);
    }
    default: {
      return false;
    }
  }
};

export const encodeDataToUrl = (data: Record<string, unknown>) => {
  const preparedData = Object.entries(data).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: JSON.stringify(value) }),
    {}
  );
  return new URLSearchParams(preparedData).toString();
};

export const decodeUrlSearchParam = (urlSearchParams: URLSearchParams): Record<string, unknown> => {
  const result: Record<string, unknown> = {};

  for (const [key, value] of urlSearchParams.entries()) {
    try {
      result[key] = JSON.parse(value);
    } catch {
      //do nothing
    }
  }

  return result;
};

export const getTooltipAnalyticsProps = (
  location: string,
  name: string,
  props?: TrackAnalyticsEventProperties
) => {
  return {
    tooltipAnalyticsPage: AnalyticsPageStack.StackNew,
    tooltipAnalyticsTitle: "Tooltip click",
    tooltipAnalyticsProps: { location, name, ...props },
  };
};

export const getSwitchTooggleAnalyticsProps = (
  location: string,
  name: string,
  propagateTooltip?: boolean,
  props?: TrackAnalyticsEventProperties
) => {
  return {
    analyticsPage: AnalyticsPageStack.StackNew,
    analyticsTitle: propagateTooltip ? "Switch Toggle" : undefined,
    tooltipAnalyticsTitle: "Tooltip click",
    analyticsProps: { location, name, ...props },
  };
};
