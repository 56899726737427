import cx from "classnames";
import { Column, ColumnResizer } from "react-aria-components";
import { useState } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { Dots, ChevronUpDown, ChevronDownSmall, ChevronUpSmall } from "components/icons/generated";
import Icon from "ds/components/Icon";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { ColumnConfigValue, ColumnSize } from "./types";
import styles from "./styles.module.css";

type TableColumnProps = {
  value: ColumnConfigValue;
  size?: ColumnSize;
  shouldGrow?: boolean;
  isRowHeader?: boolean;
  hideColumn?: (id: string) => void;
};

const getSortIcon = (sortDirection: "ascending" | "descending" | undefined) => {
  if (sortDirection === "ascending") {
    return ChevronDownSmall;
  }

  if (sortDirection === "descending") {
    return ChevronUpSmall;
  }

  return ChevronUpDown;
};

const TableColumn = ({ value, isRowHeader, hideColumn, size }: TableColumnProps) => {
  const {
    id,
    title,
    sticky,
    resizable,
    icon,
    defaultWidth,
    width,
    noHeader,
    textValue,
    iconOnly,
    ...columnProps
  } = value;

  const [dropdownActive, setDropdownActive] = useState(false);

  const canHide = !columnProps.static && !isRowHeader;

  const a11yValue = title || textValue;

  return (
    <Column
      {...columnProps}
      key={id}
      width={width}
      defaultWidth={size || defaultWidth}
      className={cx(
        styles.column,
        sticky && styles.columnSticky,
        dropdownActive && styles.active,
        columnProps.allowsSorting && styles.sortable,
        canHide && styles.interactive
      )}
      textValue={a11yValue}
      isRowHeader={isRowHeader}
      id={id}
    >
      {({ sortDirection, allowsSorting }) => {
        if (noHeader) {
          return <span className="sr-only">{a11yValue}</span>;
        }

        return (
          <Box gap="small" align="center" justify="between">
            <Box
              align="center"
              gap="small"
              tabIndex={allowsSorting ? 0 : undefined}
              className={styles.columnHeader}
            >
              {icon && <Icon className={styles.columnNameIcon} src={icon} color="secondary" />}
              {iconOnly && <span className="sr-only">{a11yValue}</span>}

              {title && !iconOnly && (
                <Typography
                  className={styles.columnName}
                  color="secondary"
                  tag="span"
                  variant="p-t7"
                >
                  {title}
                </Typography>
              )}
              {allowsSorting && (
                <Icon
                  color="secondary"
                  className={styles.columnCellSortableIcon}
                  src={getSortIcon(sortDirection)}
                />
              )}
            </Box>
            {(canHide || resizable) && (
              <Box align="center">
                {canHide && (
                  <DropdownMenu
                    onOpenChange={setDropdownActive}
                    triggerComponent={
                      <ButtonIconNew
                        className={styles.columnDropdownEllipsis}
                        icon={Dots}
                        variant="ghost"
                      >
                        Column options
                      </ButtonIconNew>
                    }
                  >
                    <DropdownMenuItem onAction={() => (hideColumn?.(id as string), close)}>
                      Hide
                    </DropdownMenuItem>
                  </DropdownMenu>
                )}
                {resizable && <ColumnResizer className={styles.columnResizer} />}
              </Box>
            )}
          </Box>
        );
      }}
    </Column>
  );
};

export default TableColumn;
