import { ReactNode } from "react";

import { SelfHostedInstanceContext } from "./Context";
import useSelfHostedInstanceProvider from "./useInstanceProvider";

type SelfHostedFeatureFlagsProviderProps = {
  children: ReactNode;
};

const SelfHostedInstanceProvider = ({ children }: SelfHostedFeatureFlagsProviderProps) => {
  const { instanceInfo, triggerFetchInstanceInfo } = useSelfHostedInstanceProvider();

  return (
    <SelfHostedInstanceContext.Provider value={{ instanceInfo, triggerFetchInstanceInfo }}>
      {children}
    </SelfHostedInstanceContext.Provider>
  );
};

export default SelfHostedInstanceProvider;
