import { PolicyType } from "types/generated";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import BaseActionButton from "ds/components/BaseAction/Button";

import PolicyTypeBadge from ".";
import styles from "./styles.module.css";

type PolicyTypeBadgeDropdownProps = {
  type: PolicyType;
  className?: string;
  applyFilter: (value: PolicyType) => void;
};

const PolicyTypeBadgeDropdown = ({
  type,
  className,
  applyFilter,
}: PolicyTypeBadgeDropdownProps) => {
  const getHandleOnClick = () => {
    applyFilter(type);
  };

  return (
    <DropdownMenu
      triggerComponent={
        <BaseActionButton className={styles.badgeWrapper}>
          <PolicyTypeBadge className={className} type={type} withDropdown />
        </BaseActionButton>
      }
    >
      <DropdownMenuItem onAction={getHandleOnClick}>Add to filters</DropdownMenuItem>
    </DropdownMenu>
  );
};

export default PolicyTypeBadgeDropdown;
