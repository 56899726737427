import useLocalStorage from "@rehooks/local-storage";
import cx from "classnames";
import Skeleton from "react-loading-skeleton";
import { Transition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import { ApolloError, NetworkStatus } from "@apollo/client";

import { ChevronRight, ConfettiColored, Tick, Rocket } from "components/icons/generated";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import TileWrapper from "ds/components/Tile/Wrapper";
import Typography from "ds/components/Typography";
import EmptyState from "ds/components/EmptyState";
import IconTile from "ds/components/IconTile";
import TileIndicator from "ds/components/Tile/Indicator";
import { EXPLORE_NEXT_FEATURES } from "views/Account/LaunchPad/ExploreNext/constants";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import MissingDataBanner from "components/MissingDataBanner";
import { LaunchPadFeatureMetadata, LaunchPadsFeatureProgress } from "views/Account/LaunchPad/types";

import styles from "./styles.module.css";
import DashboardOverviewColumnOnboardingDropdown from "../OnboardingDropdown";

type DashboardOverviewColumnOnboardingLayoutProps<T extends string> = {
  onboardingProgressInitialLoading: boolean;
  progressByFeature: LaunchPadsFeatureProgress<T>;
  isOnboardingComplete: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
  loading: boolean;
  networkStatus: NetworkStatus;
  features: LaunchPadFeatureMetadata<T>[];
};

const DashboardOverviewColumnOnboardingLayout = <T extends string>({
  onboardingProgressInitialLoading,
  progressByFeature,
  isOnboardingComplete,
  error,
  refetch,
  loading,
  networkStatus,
  features,
}: DashboardOverviewColumnOnboardingLayoutProps<T>) => {
  const [isOnboardingFinishedLocalStorage, setIsOnboardingFinishedLocalStorage] = useLocalStorage(
    "dashboardWidgetOnboardingFinished",
    false
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageDashboard.Dashboard,
  });

  const navigate = useNavigate();

  const setOnboardingFinished = () => {
    if (!isOnboardingFinishedLocalStorage) {
      setIsOnboardingFinishedLocalStorage(true);
    }
  };

  const goToOnboardingFeature = (url: string, title: string) => {
    const itemsLength = features.length;
    const doneItemsLength = features.filter(
      ({ feature }) => progressByFeature[feature].isCompleted
    ).length;

    trackSegmentAnalyticsEvent("Dashboard - Onboarding Checklist Clicked", {
      step: title,
      completed: (doneItemsLength * 100) / itemsLength,
    });
    navigate(url);
  };

  const goToExploreNextFeature = (url: string, title: string) => {
    trackSegmentAnalyticsEvent("Dashboard - Explore Next Clicked", { step: title });
    navigate(url);
  };

  if (error || (loading && networkStatus === NetworkStatus.refetch)) {
    return (
      <TileWrapper
        grow="0"
        noBorder
        grid
        padding="0"
        gridTemplate="1fr"
        className={cx(styles.onboarding, styles.onboardingContinue)}
      >
        <TileContent className={styles.onboardingContent} direction="column">
          <Box direction="column" padding="x-large" gap="large">
            <Box justify="between" align="center" grow="1">
              <Box fullWidth gap="medium">
                <Icon src={Rocket} />
                <TileTitle variant="p-t5">Onboarding</TileTitle>
              </Box>
              <DashboardOverviewColumnOnboardingDropdown />
            </Box>

            <MissingDataBanner
              text="Couldn’t load onboarding data. Please try to refresh or come back later. "
              refreshHandler={refetch}
              refreshLoading={loading}
            />
          </Box>
        </TileContent>
      </TileWrapper>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Transition
        in={!isOnboardingComplete}
        out={isOnboardingComplete}
        onExited={setOnboardingFinished}
        timeout={3000}
      >
        {(state) => (
          <Box direction="column" fullWidth>
            <TileWrapper
              grow="0"
              noBorder
              grid
              padding="0"
              gridTemplate="1fr"
              className={cx(
                styles.onboarding,
                styles.onboardingContinue,
                (state === "exited" || isOnboardingFinishedLocalStorage) && styles.hidden
              )}
            >
              <TileContent className={styles.onboardingContent} direction="column">
                <Box direction="column" padding="x-large" gap="large">
                  <Box justify="between" align="center" grow="1">
                    <Box fullWidth gap="medium">
                      <Icon src={Rocket} />
                      <TileTitle variant="p-t5">Continue your onboarding</TileTitle>
                    </Box>
                    <DashboardOverviewColumnOnboardingDropdown />
                  </Box>

                  {onboardingProgressInitialLoading && (
                    <Skeleton
                      className={styles.onboardingSkeleton}
                      count={4}
                      height={39}
                      width="100%"
                      borderRadius={12}
                    />
                  )}

                  {!onboardingProgressInitialLoading && isOnboardingComplete && (
                    <Box
                      align="center"
                      gap="x-large"
                      padding="x-large"
                      className={cx(styles.onboardingItem, styles.onboardingEmptyItem)}
                    >
                      <EmptyState
                        icon={ConfettiColored}
                        title="Congratulations"
                        caption="You have finished your onboarding setup!"
                      />
                    </Box>
                  )}

                  {!onboardingProgressInitialLoading &&
                    !isOnboardingComplete &&
                    !isOnboardingFinishedLocalStorage && (
                      <Box direction="column" gap="medium">
                        {features.map(({ feature, title, url }) => {
                          const isCompleted = progressByFeature[feature].isCompleted;

                          return (
                            <Box
                              align="center"
                              gap="x-large"
                              padding="medium large"
                              className={cx(styles.onboardingItem, isCompleted && styles.completed)}
                              key={feature}
                              onClick={
                                !isCompleted ? () => goToOnboardingFeature(url, title) : undefined
                              }
                            >
                              <div
                                className={cx(
                                  styles.onboardingItemIcon,
                                  isCompleted && styles.completed
                                )}
                              >
                                <Icon color="on-inversed" src={Tick} />
                              </div>

                              <Typography tag="span" variant="p-t6">
                                {title}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                </Box>
              </TileContent>
            </TileWrapper>
            <TileWrapper
              grow="0"
              noBorder
              grid
              padding="0"
              gridTemplate="1fr"
              className={cx(
                styles.onboarding,
                state !== "exited" && !isOnboardingFinishedLocalStorage && styles.hidden
              )}
            >
              <TileContent className={styles.onboardingContent} direction="column">
                <Box direction="column" padding="x-large" gap="large">
                  <Box justify="between" align="center" grow="1">
                    <Box align="center" fullWidth gap="medium">
                      <Icon src={Rocket} />
                      <TileTitle variant="p-t5">Explore next</TileTitle>
                    </Box>
                    <DashboardOverviewColumnOnboardingDropdown />
                  </Box>

                  <Box direction="column" gap="small">
                    {EXPLORE_NEXT_FEATURES.map((feature, i) => {
                      return (
                        <TileWrapper
                          key={i}
                          className={styles.exploreNextItem}
                          onClick={() => goToExploreNextFeature(feature.to, feature.title)}
                        >
                          <Box justify="between" grow="1">
                            <TileContent direction="row" align="center" justify="between">
                              <Box align="center" gap="medium">
                                <IconTile
                                  icon={feature.icon}
                                  className={feature.iconTileClassName}
                                />
                                <TileTitle variant="p-t6">{feature.title}</TileTitle>
                              </Box>
                              <TileIndicator indicator={<Icon src={ChevronRight} />} />
                            </TileContent>
                          </Box>
                        </TileWrapper>
                      );
                    })}
                  </Box>
                </Box>
              </TileContent>
            </TileWrapper>
          </Box>
        )}
      </Transition>
    </div>
  );
};

export default DashboardOverviewColumnOnboardingLayout;
