import { useState, forwardRef, ReactNode } from "react";
import { Navigate } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Button from "components/button/Button";
import useTaskCreate from "shared/Stack/useTaskCreate";

type PerformTaskButtonProps = {
  children: ReactNode;
  stackId: string;
  command: string;
  className?: string;
  skipInitialization?: boolean;
  disabled?: boolean;
  pill?: boolean;
};

const PerformTaskButton = forwardRef<HTMLButtonElement, PerformTaskButtonProps>(
  function PerformTaskButton(
    {
      children,
      stackId,
      command,
      className,
      skipInitialization = false,
      disabled,
      pill,
    }: PerformTaskButtonProps,
    ref
  ) {
    const [nextUrl, setNextUrl] = useState<string | undefined>(undefined);
    const { reportSuccess } = useTypedContext(FlashContext);

    const [createTask, { loading }] = useTaskCreate();

    const performTask = () =>
      createTask({ stack: stackId, command, skipInitialization }, (data) => {
        if (data?.taskCreate.id) {
          const taskId = data.taskCreate.id;
          reportSuccess({ message: `Task ${taskId} successfully triggered` });
          setNextUrl(`/stack/${stackId}/run/${taskId}`);
        }
      });

    if (nextUrl) {
      return <Navigate to={nextUrl} replace />;
    }

    return (
      <Button
        disabled={disabled}
        loading={loading}
        className={className}
        onClick={performTask}
        pill={pill}
        ref={ref}
      >
        {children}
      </Button>
    );
  }
);

export default PerformTaskButton;
