import camelCase from "lodash-es/camelCase";
import { ChangeEvent, forwardRef, ReactNode } from "react";
import cx from "classnames";

import { Minus, Tick } from "components/icons/generated";
import { FLEX_ALIGN_PREFIX } from "constants/style";
import { FlexAlign } from "types/Flex";
import { withTestId } from "utils/withTestId";

import Box from "../Box";
import Icon from "../Icon";
import Typography from "../Typography";
import styles from "./styles.module.css";
import { getColors } from "../Form/Field/utils";

export type ToggleProps = {
  id?: string;
  color?: "default" | "inversed";
  variant: "checkbox" | "switch";
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  children?: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  align?: FlexAlign;
  indeterminate?: boolean;
  dataTestId?: string;
  name?: string;
  ariaLabel?: string;
  value?: string;
  initialMinHeight?: boolean;
};

/**
 * - Checkbox variant
 *   - Used within forms to indicate boolean state of form fields, requires confirmation by saving the form
 * - Switch variant
 *   - Used to trigger immediate interaction (toggling states)
 *   - Used in forms to hide/show form sections
 */
const Toggle = forwardRef(function Toggle(
  props: ToggleProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const {
    children,
    id,
    className,
    checked,
    onChange,
    disabled,
    align = "center",
    ariaLabel,
    dataTestId,
    color = "default",
    variant,
    indeterminate,
    initialMinHeight,
    ...restProps
  } = props;

  const iconSrc = indeterminate ? Minus : Tick;
  const { primaryTextColor } = getColors(color);
  return (
    <label
      {...withTestId(dataTestId)}
      className={cx(
        styles.toggle,
        styles[camelCase(`${FLEX_ALIGN_PREFIX}-${align}`)],
        styles[camelCase(`color-${color}`)],
        {
          [styles.disabled]: disabled,
          [styles.checked]: checked,
          [styles.initialMinHeight]: initialMinHeight,
        },
        className
      )}
      htmlFor={id}
    >
      <input
        id={id}
        ref={ref}
        className={styles.input}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        aria-label={ariaLabel}
        {...restProps}
      />

      <Box data-handle-id={id} align="center" className={styles.iconWrapper}>
        {variant === "switch" && <span className={styles.switchIcon} />}
        {variant === "checkbox" && (
          <span className={styles.checkboxWrapper}>
            <Icon src={iconSrc} className={styles.checkboxIcon} />
          </span>
        )}
      </Box>

      {typeof children === "string" ? (
        <Typography
          className={styles.label}
          tag="span"
          variant="p-body2"
          color={disabled ? "disabled" : primaryTextColor}
        >
          {children}
        </Typography>
      ) : (
        children
      )}
    </label>
  );
});

export default Toggle;
