import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationSlackAppConfigSetArgs, SlackAppConfig } from "types/generated";

type FetchSelfHostedSlackAppConfigSet = {
  slackAppConfigSet: Pick<SlackAppConfig, "clientId">;
};
export const SELF_HOSTED_SLACK_APP_CONFIG_SET: TypedDocumentNode<
  FetchSelfHostedSlackAppConfigSet,
  MutationSlackAppConfigSetArgs
> = gql`
  mutation SelfHostedSlackAppConfigSet($input: SlackAppConfigInput!) {
    slackAppConfigSet(input: $input) {
      clientId
    }
  }
`;

type FetchGetSlackAppManifest = {
  slackAppManifest: string;
};
export const GET_SLACK_APP_MANIFEST: TypedDocumentNode<FetchGetSlackAppManifest> = gql`
  query GetSlackAppManifest {
    slackAppManifest
  }
`;
