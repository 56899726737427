import { useCallback, useContext } from "react";
import { OverlayTriggerStateContext } from "react-aria-components";

import ButtonIcon from "ds/components/ButtonIcon";
import { Cross } from "components/icons/generated";

import styles from "./styles.module.css";
import Box from "../Box";
import { useIsDismissable } from "./useIsDismissable";
import Heading from "../Typography/Heading";

type ModalHeaderProps = {
  title: string;
  onClose?: () => void;
};

const ModalHeader = ({ title, onClose }: ModalHeaderProps) => {
  const state = useContext(OverlayTriggerStateContext);
  const isDismissable = useIsDismissable();

  const handleClose = useCallback(() => {
    onClose?.();
    state.close();
  }, [onClose, state]);

  return (
    <Box className={styles.header} align="center" justify="between" fullWidth>
      <Heading variant="p-t4" slot="title">
        {title}
      </Heading>
      <ButtonIcon
        icon={Cross}
        onClick={handleClose}
        disabled={!isDismissable}
        className={styles.closeButton}
        variant="ghost"
      >
        Close
      </ButtonIcon>
    </Box>
  );
};

export default ModalHeader;
