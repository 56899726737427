import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import capitalize from "lodash-es/capitalize";
import { useNavigate } from "react-router-dom";

import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { Run } from "types/generated";
import Tooltip from "ds/components/Tooltip";

import { PROMOTE_PROPOSED_RUN } from "./gql";
import { useRunShortcut } from "../../hooks/useRunShortcut";

type PromoteRunButtonProps = {
  runId: string;
  stackId: string;
  promoteBlocker: string | null;
};

const PromoteRunButton = ({ runId, stackId, promoteBlocker }: PromoteRunButtonProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const [runPromote, { data, loading }] = useMutation<{ runPromote: { id: Run["id"] } }>(
    PROMOTE_PROPOSED_RUN,
    {
      variables: {
        stackId,
        runId,
      },
    }
  );

  const promoteRun = useCallback(() => {
    runPromote()
      .then(({ data }) => {
        if (data?.runPromote?.id) {
          reportSuccess({ message: `Run ${data.runPromote.id} successfully promoted` });

          navigate(`/stack/${stackId}/run/${data.runPromote.id}`);
        }
      })
      .catch(onError);
  }, [runPromote, onError, reportSuccess, stackId, navigate]);

  const isLoading = loading || !!data?.runPromote?.id;
  const isDisabled = isLoading || !!promoteBlocker;

  useRunShortcut({
    label: "Promote",
    code: "mod+alt+b",
    callback: promoteRun,
    loading: isLoading,
    disabled: isDisabled,
  });

  return (
    <Tooltip
      on={(props) => (
        <Button
          {...props}
          disabled={isLoading || !!promoteBlocker}
          loading={isLoading}
          onClick={promoteBlocker ? undefined : promoteRun}
          variant="secondary"
          size="small"
        >
          Promote
        </Button>
      )}
    >
      {promoteBlocker
        ? capitalize(promoteBlocker)
        : "Promoting a proposed run is triggering a tracked run for the same Git commit."}
    </Tooltip>
  );
};

export default PromoteRunButton;
