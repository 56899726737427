import DocumentationIconButton from "components/DocumentationIconButton";
import FeedbackButton from "components/FeedbackButton";
import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Tab from "ds/components/Tab";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import { getDocsUrl } from "utils/getDocsUrl";

function PoliciesViewHeader() {
  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle titleSize="p-t3">Policies</ViewHeaderTitle>
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <FeedbackButton id="pendo-policies-feedback" />

            <DocumentationIconButton
              analyticsPage={AnalyticsPagePolicy.PoliciesTemplates}
              analyticsTitle="Docs Opened"
              href={getDocsUrl("/concepts/policy")}
              tooltipText="Go to policies documentation"
            />
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderNavigation>
          <Tab to="/policies" label="Account policies" />
          <Tab to="/policies-templates" label="Templates" />
        </ViewHeaderNavigation>
      </ViewHeader>
    </>
  );
}

export default PoliciesViewHeader;
