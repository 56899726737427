import FormFieldViewText from "components/FormFields/ViewText";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { getBaseUrl } from "utils/getBaseUrl";

const baseUrl = getBaseUrl();

const SettingsSingleSignOnConfigInfoSAML = () => {
  return (
    <>
      <Box direction="column" gap="small">
        <Typography variant="p-t5" tag="h3">
          SAML settings
        </Typography>
        <Typography variant="p-body3" tag="p">
          When setting up Spacelift as a SAML 2.0 application in your identity provider, please use
          the following settings:
        </Typography>
      </Box>
      <FormFieldViewText
        withCopy
        noMargin
        label="Single sign-on URL"
        value={`${baseUrl}/saml/acs`}
      />
      <FormFieldViewText
        withCopy
        noMargin
        label="Entity ID (audience)"
        value={`${baseUrl}/saml/metadata`}
      />

      <Banner variant="info">
        You may want to provide the following optional attributes in the SAML mapping:
        <br />
        <br />
        <b>FirstName:</b> is used to build human-friendly user name;
        <br />
        <b>LastName:</b> is used to build human-friendly user name;
        <br />
        <b>Teams:</b> can be used by policies to determine access to the Spacelift account and/or
        individual Stacks;
      </Banner>
    </>
  );
};

export default SettingsSingleSignOnConfigInfoSAML;
