import { ReactNode } from "react";

import PageWrapper from "components/PageWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { InfoCircle } from "components/icons/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import styles from "./styles.module.css";
import { showIntegrationsInfoDrawer } from "./InfoDrawer";

type GroupsPageLayoutProps = {
  children: ReactNode;
};

const IntegrationsLayout = ({ children }: GroupsPageLayoutProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIntegrations,
  });

  const openInfoDrawer = () => {
    trackSegmentAnalyticsEvent("Info Click");
    showIntegrationsInfoDrawer();
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderWrapper direction="row" align="center" fullWidth>
          <ViewHeaderTitle tag="h2">Integrations</ViewHeaderTitle>
          <ButtonIconNew onPress={openInfoDrawer} icon={InfoCircle} variant="ghost">
            Open info drawer
          </ButtonIconNew>
        </ViewHeaderWrapper>
      </ViewHeader>

      <PageWrapper className={styles.container}>{children}</PageWrapper>
    </>
  );
};

export default IntegrationsLayout;
