import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import Markdown from "components/markdown/Markdown";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

type ModuleVersionMarkedAsBadNoteDrawerProps = {
  note: string | null;
};

const ModuleVersionMarkedAsBadNoteDrawer = createDrawer(
  ({ note }: ModuleVersionMarkedAsBadNoteDrawerProps) => {
    return (
      <DrawerSimple variant="wide">
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Note" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody>
          <Markdown markup={note || ""} />
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showModuleVersionMarkedAsBadNoteDrawer = createDrawerTrigger(
  ModuleVersionMarkedAsBadNoteDrawer
);
