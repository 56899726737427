import { Route, Routes, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import NotFoundPage from "components/error/NotFoundPage";
import FlashContext from "components/FlashMessages/FlashContext";
import { Module } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";

import ModuleVersionReadme from "../Readme";
import ModuleVersionInputs from "../Inputs";
import ModuleVersionOutputs from "../Outputs";
import { GET_VERSION_SUBMODULE } from "./gql";
import ModuleVersionResources from "../Resources";
import ModuleVersionDependencies from "../Dependencies";
import ModuleVersionSecondaryLayout from "../components/SecondaryLayout";
import { VersionContext } from "../Context";

const ModuleVersionSubmodule = () => {
  const { submoduleId } = useParams<{
    submoduleId: string;
  }>();

  const { module, version } = useTypedContext(VersionContext);
  const { onError } = useTypedContext(FlashContext);

  const { data, error, loading } = useQuery<{ module: Module }>(GET_VERSION_SUBMODULE, {
    onError,
    variables: { versionId: version.id, moduleId: module.id },
  });

  const submodules = useMemo(
    () => data?.module?.version?.metadata?.submodules || [],
    [data?.module?.version?.metadata?.submodules]
  );

  const submodule = useMemo(
    () => submodules.find((e) => e.name === submoduleId),
    [submoduleId, submodules]
  );

  const inputs = useMemo(() => submodule?.inputs || [], [submodule?.inputs]);
  const outputs = useMemo(() => submodule?.outputs || [], [submodule?.outputs]);

  const moduleDependencies = useMemo(
    () => submodule?.dependencies || [],
    [submodule?.dependencies]
  );

  const providerDependencies = useMemo(
    () => submodule?.providerDependencies || [],
    [submodule?.providerDependencies]
  );

  const resources = useMemo(() => submodule?.resources || [], [submodule?.resources]);

  const sidebar = useMemo(
    () => [
      {
        to: ".",
        label: "Readme",
        end: true,
      },
      {
        to: "inputs",
        label: "Inputs",
        count: inputs.length,
      },
      {
        to: "outputs",
        label: "Outputs",
        count: outputs.length,
      },
      {
        to: "dependencies",
        label: "Dependencies",
        count: moduleDependencies.length + providerDependencies.length,
      },
      {
        to: "resources",
        label: "Resources",
        count: resources.length || 0,
      },
    ],
    [
      inputs.length,
      outputs.length,
      resources.length,
      moduleDependencies.length,
      providerDependencies.length,
    ]
  );

  const dropdownOptions = useMemo(
    () =>
      submodules.map((e) => ({
        to: `/module/${module.id}/version/${version.id}/submodule/${e.name}`,
        isActive: e.name === submodule?.name,
        label: e.name,
      })),
    [submodules, module.id, version.id, submodule?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module || !submodule) {
    return <NotFoundPage />;
  }

  return (
    <ModuleVersionSecondaryLayout
      entityName={submodule.name}
      title="Submodule overview"
      sidebar={sidebar}
      dropdownLabel={`Submodule: ${submodule.name}`}
      dropdownOptions={dropdownOptions}
      entityTypeLink={`/module/${module.id}/version/${version.id}/submodules`}
      entityTypeLabel="Submodules"
    >
      <Routes>
        <Route index element={<ModuleVersionReadme content={submodule.readme} />} />
        <Route path="inputs" element={<ModuleVersionInputs inputs={inputs} />} />
        <Route path="outputs" element={<ModuleVersionOutputs outputs={outputs} />} />
        <Route path="resources" element={<ModuleVersionResources resources={resources} />} />
        <Route
          path="dependencies"
          element={
            <ModuleVersionDependencies
              providerDependencies={providerDependencies}
              moduleDependencies={moduleDependencies}
            />
          }
        />
      </Routes>
    </ModuleVersionSecondaryLayout>
  );
};

export default ModuleVersionSubmodule;
