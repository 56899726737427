import { LinePath } from "@visx/shape";

import styles from "../styles.module.css";

type TreeChartGroupNodeSummaryRightRoundedBorderProps = {
  startX: number;
  startY: number;
  radius: number;
  height: number;
  width: number;
};

const TreeChartGroupNodeSummaryRightRoundedBorder = ({
  startX,
  startY,
  radius,
  height,
  width,
}: TreeChartGroupNodeSummaryRightRoundedBorderProps) => (
  <LinePath
    className={styles.summaryBorder}
    d={`M ${startX} ${startY} L ${startX + width - radius} ${startY} a${radius},${radius} 0 0 1 ${radius} ${radius} L ${startX + width} ${startY - radius + height} a${radius},${radius} 0 0 1 -${radius} ${radius} L ${startX} ${startY + height}`}
  />
);

export default TreeChartGroupNodeSummaryRightRoundedBorder;
