import { ErrorCircleColored } from "components/icons/generated";
import SystemMessage from "components/SystemMessage";
import useTitle from "hooks/useTitle";
import LinkNew from "ds/components/LinkNew";

const LoginLimitExceededPage = () => {
  useTitle("Login limit exceeded · Spacelift");

  return (
    <SystemMessage
      icon={ErrorCircleColored}
      title="Login denied"
      message={
        <>
          Sorry, looks like number of allowed logins has been exceeded. This could well be an error
          - try <LinkNew href="/login">logging in again</LinkNew> to see if that helps.
        </>
      }
    />
  );
};

export default LoginLimitExceededPage;
