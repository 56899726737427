import { fromUnixTime, differenceInDays } from "date-fns";
import useLocalStorage from "@rehooks/local-storage";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { trackSegmentEvent } from "shared/Analytics";

const LOCAL_STORAGE_KEY = "redirectedToLaunchPad";

export const useLaunchpadRedirect = () => {
  const { viewer } = useTypedContext(AccountContext);
  const [redirectedToLaunchPad, setRedirectedToLaunchPad] =
    useLocalStorage<boolean>(LOCAL_STORAGE_KEY);

  const navigate = useNavigate();

  const shouldRedirectToLaunchpad =
    !redirectedToLaunchPad &&
    viewer.admin &&
    viewer.since &&
    differenceInDays(new Date(), fromUnixTime(viewer.since)) < 1;

  const redirectToLaunchpad = useCallback(() => {
    trackSegmentEvent("Launchpad redirection");
    setRedirectedToLaunchPad(true);
    navigate("/launchpad", { replace: true });
  }, [navigate, setRedirectedToLaunchPad]);

  return {
    shouldRedirectToLaunchpad,
    redirectToLaunchpad,
  };
};
