import ListEntitiesNew from "components/ListEntitiesNew";
import { Policy } from "types/generated";
import Box from "ds/components/Box";

import LoginPoliciesVirtualizedItem from "./VirtualizedItem";
import styles from "./styles.module.css";

type LoginPoliciesListProps = {
  loginPolicies: Policy[];
};

const LoginPoliciesList = ({ loginPolicies }: LoginPoliciesListProps) => {
  return (
    <Box direction="column" grow="1">
      <div className={styles.wrapper}>
        <ListEntitiesNew
          itemCount={loginPolicies.length}
          itemProps={{
            loginPolicies,
          }}
          virtualizedItem={LoginPoliciesVirtualizedItem}
          itemKey={(index) => loginPolicies[index].id}
        />
      </div>
    </Box>
  );
};

export default LoginPoliciesList;
