import { useCallback, useMemo, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

import { Stack } from "types/generated";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import useSelectionStore from "components/Table/useSelectionStore";

import { MINIMAL_SEARCH_STACKS } from "./gql";
import {
  initialSortDirection,
  initialSortOption,
  SELECT_ALL_FILTERS,
  STACKS_SELECT_ALL_OPTIONS,
} from "./constants";

const ITEMS_PER_PAGE = 50;
const ABORT_REASON = "CANCEL REQUEST";
const ERROR_MESSAGE = "Something went wrong. We couldn't load stacks you wanted to select";

const useAllAvailableStacks = () => {
  const { selectIds, selectedAllOption, addSelectedIds, addAvailableIds, setAvailableIds } =
    useSelectionStore();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Record<string, Stack>>({});

  const searchInput = getSearchQuery(searchParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(searchParams, initialSortOption, initialSortDirection),
    [searchParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(searchParams);
    const loadOptionPredicates = selectedAllOption
      ? SELECT_ALL_FILTERS[selectedAllOption as STACKS_SELECT_ALL_OPTIONS]
      : [];

    return [...(predicatesMap?.values() || []), ...loadOptionPredicates];
  }, [searchParams, selectedAllOption]);

  const { reportError } = useTypedContext(FlashContext);

  const filterVariables = useMemo(() => {
    return {
      requestedPage: null,
      fullTextSearch: searchInput,
      predicates,
      ...(sortOptionFields && { orderBy: sortOptionFields }),
    };
  }, [predicates, searchInput, sortOptionFields]);

  const client = useApolloClient();

  const fetchAll = useCallback(() => {
    if (!selectedAllOption) {
      return;
    }

    setLoading(true);

    const abortController = new AbortController();

    const abort = () => {
      if (!abortController.signal.aborted) {
        abortController.abort(ABORT_REASON);
      }
    };

    const fetchStacksPage = (after: string | null) =>
      client
        .query({
          query: MINIMAL_SEARCH_STACKS,
          variables: {
            input: {
              ...filterVariables,
              first: ITEMS_PER_PAGE,
              after,
            },
          },
          fetchPolicy: "cache-first",
          context: {
            fetchOptions: {
              signal: abortController.signal,
            },
          },
        })
        .then(({ data }) => {
          if (!data) {
            setLoading(false);
            throw new Error();
          }

          setResults((results) =>
            data?.searchStacks.edges.reduce((acc, next) => {
              return { ...acc, [next.node.id]: next.node };
            }, results)
          );

          if (!after) {
            // reset ids for first call
            setAvailableIds(selectedAllOption, []);
            selectIds([]);
          }

          const ids = data?.searchStacks.edges.map((item) => item.node.id);

          addAvailableIds(selectedAllOption, ids);
          addSelectedIds(ids);

          if (data?.searchStacks.pageInfo.hasNextPage) {
            fetchStacksPage(data?.searchStacks.pageInfo.endCursor);
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          if (e?.cause !== ABORT_REASON) {
            reportError({
              message: ERROR_MESSAGE,
            });
          }

          setLoading(false);
        });

    fetchStacksPage(null);

    return abort;
  }, [
    client,
    filterVariables,
    selectedAllOption,
    reportError,
    selectIds,
    addSelectedIds,
    addAvailableIds,
    setAvailableIds,
  ]);

  return {
    allAvailableStacks: results,
    loading,
    setAllAvailableStacks: setResults,
    fetchAll,
  };
};

export default useAllAvailableStacks;
