import { memo } from "react";

import DocumentationButton from "components/DocumentationButton";
import EmptyState from "ds/components/EmptyState";
import { EmptystateCalendarClockColored } from "components/icons/generated";
import Box from "ds/components/Box";
import { getDocsUrl } from "utils/getDocsUrl";

import StackSchedulingCreateDropdown from "../CreateDropdown";

const StackSchedulingEmptyCaption = (
  <>
    Create one now by clicking on the Create Schedule button below. Find out more about the
    different schedule types in the documentation.
  </>
);

const StackSchedulingEmpty = () => {
  return (
    <EmptyState
      title="You haven't created any schedules yet"
      caption={StackSchedulingEmptyCaption}
      icon={EmptystateCalendarClockColored}
    >
      <Box gap="large" justify="center" align="center">
        <DocumentationButton
          to={getDocsUrl("/concepts/stack/scheduling.html")}
          label="Documentation"
        />

        <StackSchedulingCreateDropdown componentLocation="Empty state" />
      </Box>
    </EmptyState>
  );
};

export default memo(StackSchedulingEmpty);
