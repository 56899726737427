import { Cross } from "components/icons/generated";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import ButtonNew from "ds/components/Button/New";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import { BulkActionsApiContext } from "../../Context";
import { BulkActionsCloseMethod, BulkActionsVariant } from "../../types";
import styles from "./styles.module.css";
import { BulkActionsFloatingBarHeaderText } from "./Text";

type BulkActionsFloatingBarHeaderProps = {
  selectedItemsCount: number;
  applicableItemsCount?: number;
  analyticsPage?: AnalyticsPage;
  hideClose?: boolean;
  hideDetails?: boolean;
};
const BulkActionsFloatingBarHeader = ({
  selectedItemsCount,
  applicableItemsCount,
  analyticsPage,
  hideClose,
  hideDetails,
}: BulkActionsFloatingBarHeaderProps) => {
  const { closeBulkActions, setBulkActionsVariant } = useTypedContext(BulkActionsApiContext);
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const onSeeDetails = () => {
    setBulkActionsVariant(BulkActionsVariant.Drawer);
    trackSegmentAnalyticsEvent?.("Bulk Actions - Floating Bar - See Details Clicked");
  };

  return (
    <Box className={styles.header} padding="large" justify="between" align="center">
      <BulkActionsFloatingBarHeaderText
        selectedItemsCount={selectedItemsCount}
        applicableItemsCount={applicableItemsCount}
      />
      <Box gap="small">
        {!hideDetails && (
          <ButtonNew size="small" variant="ghostInversed" onPress={onSeeDetails}>
            See details
          </ButtonNew>
        )}

        {!hideClose && (
          <ButtonIconNew
            icon={Cross}
            onPress={() =>
              closeBulkActions(BulkActionsVariant.FloatingBar, BulkActionsCloseMethod.Cross)
            }
            variant="ghostInversed"
          >
            Close and clear
          </ButtonIconNew>
        )}
      </Box>
    </Box>
  );
};

export default BulkActionsFloatingBarHeader;
