import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useModal } from "@ebay/nice-modal-react";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import FormFieldTags from "components/FormFields/Tags";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { Blueprint, BlueprintState } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Space";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";

import { CLONE_BLUEPRINT, CREATE_BLUEPRINT, UPDATE_BLUEPRINT } from "../gql";
import { REFETCH_QUERIES_LIST } from "../constants";

type CreateBlueprintFormFields = {
  name: string;
  description?: string;
  space: string;
  labels: Record<"value", string>[];
};

type CreateBlueprintDrawerProps = {
  blueprint?: Blueprint;
  isCloneMode?: boolean;
};

const getPrimaryButtonText = (isEditMode: boolean, isCloneMode?: boolean): string => {
  if (isEditMode) {
    return "Save";
  }
  if (isCloneMode) {
    return "Clone";
  }

  return "Create blueprint draft";
};

const CreateBlueprintDrawer = createDrawer(
  ({ blueprint, isCloneMode }: CreateBlueprintDrawerProps) => {
    const drawer = useModal();

    const isEditMode = !!blueprint && !isCloneMode;

    const navigate = useNavigate();

    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const blueprintName = isCloneMode ? `${blueprint?.name} (clone)` : blueprint?.name;

    const builderForm = useForm<CreateBlueprintFormFields>({
      defaultValues: {
        name: blueprint ? blueprintName : "",
        description: blueprint?.description || "",
        space: blueprint?.space.id || "",
        labels: blueprint?.labels.map((value) => ({ value })) || [],
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { errors, isValid, isDirty },
    } = builderForm;

    const [createBlueprint] = useMutation<{ blueprintCreate: Blueprint }>(CREATE_BLUEPRINT, {
      refetchQueries: REFETCH_QUERIES_LIST,
    });

    const [updateBlueprint] = useMutation<{ blueprintUpdate: Blueprint }>(UPDATE_BLUEPRINT, {
      onError,
      refetchQueries: REFETCH_QUERIES_LIST,
    });

    const [cloneBlueprint] = useMutation<{ blueprintClone: Blueprint }>(CLONE_BLUEPRINT, {
      onError,
    });

    const onCreateSubmit = (formData: CreateBlueprintFormFields) => {
      createBlueprint({
        variables: {
          input: {
            name: formData.name,
            description: formData.description,
            space: formData.space,
            labels: formData.labels.map((item) => item.value),
            state: BlueprintState.Draft,
            template: "",
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintCreate) {
            reportSuccess({
              message: `Blueprint "${data.blueprintCreate.name}" is successfully created`,
            });

            drawer.hide();
            navigate(`/blueprint/${data.blueprintCreate.id}`);
          }
        })
        .catch(onError);
    };

    const onCloneSubmit = (formData: CreateBlueprintFormFields, blueprintItem: Blueprint) => {
      cloneBlueprint({
        variables: {
          id: blueprintItem.id,
          input: {
            name: formData.name,
            description: formData.description,
            space: formData.space,
            labels: formData.labels.map((item) => item.value),
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintClone) {
            reportSuccess({
              message: `Blueprint "${data.blueprintClone.name}" is successfully created`,
            });

            drawer.hide();

            navigate(`/blueprint/${data.blueprintClone.id}`);
          }
        })
        .catch(onError);
    };

    const onEditSubmit = (formData: CreateBlueprintFormFields, blueprintItem: Blueprint) => {
      updateBlueprint({
        variables: {
          id: blueprintItem.id,
          input: {
            name: formData.name,
            description: formData.description,
            space: formData.space,
            labels: formData.labels.map((item) => item.value),
            state: blueprintItem.state,
            template: blueprintItem.rawTemplate,
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintUpdate) {
            reportSuccess({
              message: `Blueprint "${data.blueprintUpdate.name}" is successfully saved`,
            });

            drawer.hide();
          }
        })
        .catch(onError);
    };

    const onSubmit: SubmitHandler<CreateBlueprintFormFields> = (formData) => {
      if (isEditMode && blueprint?.id) {
        onEditSubmit(formData, blueprint);
      } else if (isCloneMode && blueprint?.id) {
        onCloneSubmit(formData, blueprint);
      } else {
        onCreateSubmit(formData);
      }
    };

    return (
      <DrawerSimple>
        <FormProvider {...builderForm}>
          <DrawerHeader justify="between">
            {!isEditMode && !isCloneMode && <DrawerHeaderTitle title="Create new blueprint" />}
            {isEditMode && <DrawerHeaderTitle title="Edit blueprint" />}
            {isCloneMode && <DrawerHeaderTitle title="Clone blueprint" />}
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            <FormField label="Name" error={errors?.name?.message}>
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter blueprint name here..."
                  error={!!errors?.name}
                  {...register("name", { required: "Name field is required." })}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <FormFieldSpace />

            <FormField label="Description" isOptional>
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Enter blueprint description here..."
                  {...register("description")}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <FormFieldTags label="Labels" tagName="label" name="labels" />

            <DrawerFooter>
              <DrawerFooterActions>
                <DrawerCancelButton />
                <Button
                  variant="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid || (!isCloneMode && !isDirty)}
                >
                  {getPrimaryButtonText(isEditMode, isCloneMode)}
                </Button>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showCreateBlueprintDrawer = createDrawerTrigger(CreateBlueprintDrawer);
