import { createContext, useMemo, useState, ReactNode, useCallback } from "react";

import { SCHEDULING_TYPE, SchedulingItem } from "shared/Stack/Scheduling/types";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";

import { StackSchedulingContextDataProps, StackSchedulingContextApiProps } from "./types";
import { getScheduleDrawerEntityTypePerItem } from "./helpers";
import { showStackSchedulingDrawer } from "./List/Drawer";

export const StackSchedulingContextData = createContext<
  StackSchedulingContextDataProps | undefined
>(undefined);
StackSchedulingContextData.displayName = "StackSchedulingContextData";

export const StackSchedulingContextApi = createContext<StackSchedulingContextApiProps | undefined>(
  undefined
);
StackSchedulingContextApi.displayName = "StackSchedulingContextApi";

type StackSchedulingContextProviderProps = {
  children: ReactNode;
};

const StackSchedulingContextProvider = ({ children }: StackSchedulingContextProviderProps) => {
  const [driftDetectionExist, setDriftDetectionExist] = useState<boolean>(false);
  const { stack } = useTypedContext(StackContext);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
  });

  const handleItemEditing = useCallback(
    (item: SchedulingItem) => {
      const schedulingType = getScheduleDrawerEntityTypePerItem(item);

      showStackSchedulingDrawer({
        drawerEntityType: schedulingType,
        focusedItem: item,
        stackId: stack.id,
      });
      trackSegmentEvent("Schedule Edit Clicked", { type: schedulingType });
    },
    [trackSegmentEvent, stack.id]
  );

  const handleItemCreating = useCallback(
    (type: SCHEDULING_TYPE) => {
      showStackSchedulingDrawer({
        drawerEntityType: type,
        stackId: stack.id,
      });
    },
    [stack.id]
  );

  const apiStore = useMemo(
    () => ({
      onEdit: handleItemEditing,
      onCreate: handleItemCreating,
      setDriftDetectionExist,
    }),
    [handleItemCreating, handleItemEditing]
  );

  const dataStore = useMemo(
    () => ({
      driftDetectionExist,
    }),
    [driftDetectionExist]
  );

  return (
    <StackSchedulingContextData.Provider value={dataStore}>
      <StackSchedulingContextApi.Provider value={apiStore}>
        {children}
      </StackSchedulingContextApi.Provider>
    </StackSchedulingContextData.Provider>
  );
};

export default StackSchedulingContextProvider;
