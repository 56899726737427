import { useMemo } from "react";

import PageInfoHeader from "ds/components/PageInfoHeader";
import ListEntitiesItem from "components/ListEntitiesItem";
import { SearchQueryOrderDirection } from "types/generated";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import useTypedContext from "hooks/useTypedContext";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";

import { VersionContext } from "../Context";

const columns: SortableTableColumn[] = [
  {
    value: "name",
    label: "Name",
  },
];

const ModuleVersionExamples = () => {
  const { version } = useTypedContext(VersionContext);

  const examples = useMemo(() => version?.metadata?.examples || [], [version]);

  return (
    <>
      <PageInfoHeader title="Examples" />
      {examples.length === 0 && (
        <EmptyState
          fullHeight
          icon={EmptystateSearchNoResultsColored}
          caption="This module has no examples"
        />
      )}
      {examples.length > 0 && (
        <SortableTable
          items={examples}
          columns={columns}
          columnOrder="1fr"
          renderItems={(sorted) =>
            sorted.map((item) => (
              <ListEntitiesItem key={item.name}>
                <ListEntitiesItemLink title={item.name} to={`../example/${item.name}`} />
              </ListEntitiesItem>
            ))
          }
          initialSortBy="name"
          initialDirection={SearchQueryOrderDirection.Desc}
        />
      )}
    </>
  );
};

export default ModuleVersionExamples;
