import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import useTypedContext from "hooks/useTypedContext";
import Tab from "ds/components/Tab";
import Typography from "ds/components/Typography";
import DeltaCounts from "components/DeltaCounts";
import Box from "ds/components/Box";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import { ansibleRunHasTasks } from "../../helpers";
import { RunContext } from "../../Context";

type RunHeaderAnsibleChangesNavProps = {
  analyticsPage?: AnalyticsPage;
};

const RunHeaderAnsibleChangesNav = ({ analyticsPage }: RunHeaderAnsibleChangesNavProps) => {
  const { run } = useTypedContext(RunContext);
  const hasTasks = !!run.delta && ansibleRunHasTasks(run.delta);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  return (
    <ViewHeaderNavigation>
      <Tab
        end
        id="History"
        onClick={() => trackSegmentAnalyticsEvent?.("Tab switched", { tab: "History" })}
        label="History"
        to="."
      />

      {!!run.delta && !hasTasks && (
        <Typography variant="p-t6" tag="div" margin="0 medium">
          No tasks
        </Typography>
      )}

      {hasTasks && (
        <Tab
          id="Tasks"
          to="tasks"
          label={
            <Box gap="medium" align="center">
              Tasks
            </Box>
          }
          onClick={() => trackSegmentAnalyticsEvent?.("Tab switched", { tab: "Tasks" })}
          suffixComponent={
            <DeltaCounts isAnsible delta={run?.delta} skipEmpty variant="flexible" size="small" />
          }
        />
      )}
    </ViewHeaderNavigation>
  );
};

export default RunHeaderAnsibleChangesNav;
