import Box from "ds/components/Box";
import { EmptystateCloudColored } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";
import LoadingIndicator from "ds/components/LoadingIndicator";
import KeyValue from "components/KeyValue";
import MissingDataBanner from "components/MissingDataBanner";

import { StackCreationCloud, StackCreationWizardStep } from "../types";
import NewStackSummaryPanel from "./Panel";
import { formatToggleValue } from "./formatToggleValue";
import NewStackAttachCloudDetailsAWSFields from "../AttachCloud/Details/AWSFields";
import NewStackAttachCloudDetailsAzureFields from "../AttachCloud/Details/AzureFields";
import useAttachedCloudIntegration from "../AttachCloud/hooks/useAttachedCloudIntegration";

type NewStackSummaryCloudProps = {
  title: string;
  goToStepDisabled?: boolean;
};

const NewStackSummaryCloud = ({ title, goToStepDisabled }: NewStackSummaryCloudProps) => {
  const { attachedIntegration, attachedIntegrationType, loading, refetching, refetch, hasData } =
    useAttachedCloudIntegration();

  const renderEmptyPlaceholder = () => {
    if (loading) {
      return (
        <Box padding="large" justify="center">
          <LoadingIndicator size="large" loading />
        </Box>
      );
    }

    if (!hasData) {
      return (
        <MissingDataBanner
          text="Couldn't load attached integration, please refresh or come back later"
          refreshHandler={refetch}
          refreshLoading={refetching}
        />
      );
    }

    return (
      <EmptyState
        padding="large"
        icon={EmptystateCloudColored}
        title="Cloud integration not attached"
        caption="Integrate stack with your cloud provider to manage your resources without the need for long-lived static credentials."
      />
    );
  };

  return (
    <NewStackSummaryPanel
      name={title}
      step={StackCreationWizardStep.Cloud}
      goToStepDisabled={goToStepDisabled}
    >
      <Box direction="column">
        {attachedIntegration && (
          <>
            {attachedIntegrationType === StackCreationCloud.AWS && (
              <NewStackAttachCloudDetailsAWSFields integration={attachedIntegration} />
            )}

            {attachedIntegrationType === StackCreationCloud.Azure && (
              <NewStackAttachCloudDetailsAzureFields integration={attachedIntegration} />
            )}
            <KeyValue name="Read">{formatToggleValue(attachedIntegration.read)}</KeyValue>
            <KeyValue name="Write">{formatToggleValue(attachedIntegration.write)}</KeyValue>
          </>
        )}

        {!attachedIntegration && renderEmptyPlaceholder()}
      </Box>
    </NewStackSummaryPanel>
  );
};

export default NewStackSummaryCloud;
