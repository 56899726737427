import { ReactNode } from "react";

import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerFooter from "ds/components/Drawer/Footer";
import DocumentationButton from "components/DocumentationButton";
import { DocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

type LaunchKnowledgeBaseDrawerProps = {
  title: string;
  content: ReactNode;
  docsLink?: DocsUrl;
};

const LaunchPadKnowledgeBaseDrawer = createDrawer(
  ({ title, content, docsLink }: LaunchKnowledgeBaseDrawerProps) => {
    return (
      <DrawerSimple isDismissable variant="wide">
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title={title} />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody className={styles.drawerContent} fullHeight hasStickyFooter>
          {content}
        </DrawerBody>
        {docsLink && (
          <DrawerFooter>
            <DocumentationButton to={docsLink} label="Full documentation" />
          </DrawerFooter>
        )}
      </DrawerSimple>
    );
  }
);

export const showLaunchPadKnowledgeBaseDrawer = createDrawerTrigger(LaunchPadKnowledgeBaseDrawer);
