import { LinePath } from "@visx/shape";

import styles from "../styles.module.css";

type TreeChartGroupNodeSummaryLeftRoundedBorderProps = {
  startX: number;
  startY: number;
  radius: number;
  height: number;
  width: number;
};

const TreeChartGroupNodeSummaryLeftRoundedBorder = ({
  startX,
  startY,
  radius,
  height,
  width,
}: TreeChartGroupNodeSummaryLeftRoundedBorderProps) => (
  <LinePath
    className={styles.summaryBorder}
    d={`M ${startX + width} ${startY + height} L ${startX + radius} ${startY + height} a${radius},${radius} 0 0 1 -${radius} -${radius} L ${startX} ${startY + radius} a${radius},${radius} 0 0 1 ${radius} -${radius} L ${startX + width} ${startY} L ${startX + width} ${startY + height}`}
  />
);

export default TreeChartGroupNodeSummaryLeftRoundedBorder;
