import { useLayoutEffect, useRef } from "react";
import cx from "classnames";

import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import { Policy } from "types/generated";
import Timestamp from "components/time/Timestamp";
import useTypedContext from "hooks/useTypedContext";
import TagsList from "components/TagsList";
import { withTestId } from "utils/withTestId";
import { UserManagementActivationStatus } from "views/Account/Settings/types";
import { SettingsContext } from "views/Account/Settings/Context";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";

import { COLUMN_GAP, COLUMN_ORDER } from "../../constants";
import LoginPolicyManageDropdown from "../../../../LoginPolicyDetails/ManageDropdown";
import { LOGIN_POLICY_LIST_ITEM_TEST_ID } from "./constants";
import styles from "./styles.module.css";

type LoginPoliciesListItemProps = {
  item: Policy;
  setRowHeight?: (size: number) => void;
  editDetailsHandler: () => void;
};

const LoginPoliciesListItem = ({
  item,
  setRowHeight,
  editDetailsHandler,
}: LoginPoliciesListItemProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const isLoginPolicyActive = activationStatus === UserManagementActivationStatus.INACTIVE;

  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useLayoutEffect(handleRowHeight);

  const isActive = useDrawerVisibilityForId(item.id);

  return (
    <ListEntitiesItem
      isActive={isActive}
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      className={cx(!isLoginPolicyActive && styles.item)}
      {...withTestId(LOGIN_POLICY_LIST_ITEM_TEST_ID)}
    >
      <Box direction="column" fullWidth>
        <ListEntitiesItemLink title={item.name} to={`/login-policy/${item.id}`} />
        <TagsList tags={item.labels} onExpand={handleRowHeight} />
      </Box>

      <Box direction="row" align="center">
        {item.createdAt && (
          <MetaInfoListItem>
            {item.createdAt && <Timestamp timestamp={item.createdAt} />}
          </MetaInfoListItem>
        )}
      </Box>
      <Box direction="row" align="center">
        {item.updatedAt && (
          <MetaInfoListItem>
            <Timestamp timestamp={item.updatedAt} />
          </MetaInfoListItem>
        )}
      </Box>
      <Box direction="row" align="center">
        <LoginPolicyManageDropdown
          id={item.id}
          refetchQueriesOnDelete={["GetLoginPolicies"]}
          name={item.name}
          handleLoginPolicyEditDetailsClick={editDetailsHandler}
          isLoginPolicyActive={isLoginPolicyActive}
          analyticsPage={AnalyticsPageOrganization.OrganizationLoginPoliciesList}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default LoginPoliciesListItem;
