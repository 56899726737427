import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SpaceliftLoader from "components/loading/SpaceliftLoader";
import { isSelfHostedDistribution } from "utils/distribution";
import SystemMessage from "components/SystemMessage";

import useInstanceProviderGateRules from "./useInstanceProviderGateRules";

const isSelfHosted = isSelfHostedDistribution();

type SelfHostedInstanceProviderGateProps = {
  children: ReactNode;
};

/**
 * @description SelfHosted only,
 * * used to gate the self-hosted version of the application till data from "/instance_info" is reached.
 * * then based on the data, it will unblock the usual login flow or force redirecting to the admin login page.
 */
const SelfHostedInstanceProviderGate = ({ children }: SelfHostedInstanceProviderGateProps) => {
  const navigate = useNavigate();
  const {
    isSelfHostedInstalled,
    shouldCreateAccount,
    shouldLogin,
    isInstanceInfoLoading,
    isAdminLoginEnabled,
  } = useInstanceProviderGateRules();

  useEffect(() => {
    if (!isSelfHosted || isSelfHostedInstalled || isInstanceInfoLoading) return undefined;

    if (shouldLogin) {
      navigate("/admin-login", { replace: true });
    } else if (shouldCreateAccount) {
      navigate("/create-account", { replace: true });
    }
  }, [isSelfHostedInstalled, navigate, shouldCreateAccount, shouldLogin, isInstanceInfoLoading]);

  if (!isSelfHosted) {
    return <>{children}</>;
  }

  if (!isAdminLoginEnabled && !isInstanceInfoLoading && !isSelfHostedInstalled)
    return (
      <SystemMessage
        title="Admin login disabled"
        message="Admin login is currently disabled. Please enable it to continue with your Spacelift installation"
      />
    );

  return isSelfHostedInstalled ? <>{children}</> : <SpaceliftLoader />;
};

export default SelfHostedInstanceProviderGate;
