import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { StackContext } from "views/Stack/Context";
import { Upload } from "components/icons/generated";
import { AccountContext } from "views/AccountWrapper";
import Tooltip from "ds/components/Tooltip";

import { showStackImportStateModal } from "../ImportStateModal";

const StackStateUploadButton = () => {
  const { stack, canManageStackAndRuns } = useTypedContext(StackContext);
  const { viewer } = useTypedContext(AccountContext);

  const canImportState = stack.lockedBy == viewer.id && canManageStackAndRuns;

  const onUploadClick = () => {
    showStackImportStateModal({ stackId: stack.id });
  };

  return (
    <Tooltip
      active={!canImportState}
      on={(props) => (
        <Button
          {...props}
          disabled={!canImportState}
          onClick={onUploadClick}
          startIcon={Upload}
          variant="secondary"
        >
          Import state
        </Button>
      )}
    >
      {stack.lockedBy
        ? `Stack is locked by another user: ${stack.lockedBy}`
        : "Stack should be locked to import a state"}
    </Tooltip>
  );
};

export default StackStateUploadButton;
