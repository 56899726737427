import { Link } from "react-router-dom";

import FormGroup from "components/FormDefault/Group";
import { AwsIntegration } from "types/generated";
import FormViewer from "components/FormDefault/Viewer";
import AttachedProjects from "components/AttachedProjects";
import { getDuration } from "utils/time";
import TagsList from "components/TagsList";

import styles from "./styles.module.css";

type AwsIntegrationViewFormProps = {
  integration: AwsIntegration;
};

const AwsIntegrationViewForm = ({ integration }: AwsIntegrationViewFormProps) => {
  return (
    <div>
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Name:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{integration.name}</FormViewer>
      </FormGroup>
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Space:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>
          <Link to={`/spaces/${integration.spaceDetails.id}`} className={styles.itemLink}>
            {integration.spaceDetails.name}
          </Link>
        </FormViewer>
      </FormGroup>
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Labels:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>
          <TagsList tags={integration.labels} />
        </FormViewer>
      </FormGroup>
      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Role ARN:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{integration.roleArn}</FormViewer>
      </FormGroup>

      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Duration:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{getDuration(integration.durationSeconds)}</FormViewer>
      </FormGroup>

      <FormGroup
        labelClassName={styles.formLabel}
        labelText="Assume role on worker:"
        labelStyle={{
          muted: true,
        }}
      >
        <FormViewer>{integration.generateCredentialsInWorker ? "Yes" : "No"}</FormViewer>
      </FormGroup>

      {integration.generateCredentialsInWorker && (
        <FormGroup
          labelClassName={styles.formLabel}
          labelText="External ID:"
          labelStyle={{
            muted: true,
          }}
        >
          <FormViewer>{integration.externalId}</FormViewer>
        </FormGroup>
      )}

      <AttachedProjects projects={integration.attachedStacks} />
    </div>
  );
};

export default AwsIntegrationViewForm;
