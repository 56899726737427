import EmptyState from "ds/components/EmptyState";
import { EmptystateHelmetColored } from "components/icons/generated";
import Box from "ds/components/Box";

const LoginPoliciesEmpty = () => {
  return (
    <Box align="center" justify="center" grow="1">
      <EmptyState
        title="You don’t have any login policies yet"
        icon={EmptystateHelmetColored}
        caption="Login policies can allow users to log in to the account, and optionally give them admin
        privileges, too."
      />
    </Box>
  );
};

export default LoginPoliciesEmpty;
