import Box from "ds/components/Box";
import LinkNew from "ds/components/LinkNew";

import useStackListAnalytics from "../useStackListAnalytics";
import { CellComponentProps } from "./types";

type StackListItemNameCellProps = CellComponentProps;

const StackListItemNameCell = ({ stack }: StackListItemNameCellProps) => {
  const trackAnalytics = useStackListAnalytics();

  const stackUrl = `/stack/${stack.id}`;

  return (
    <Box fullWidth>
      <LinkNew
        size="small"
        variant="secondary"
        href={stackUrl}
        onPress={() => trackAnalytics("Name link clicked")}
      >
        {stack.name}
      </LinkNew>
    </Box>
  );
};

export default StackListItemNameCell;
