import { ChangeEvent, ReactNode } from "react";
import { useId } from "react-aria";

import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";
import Toggle, { ToggleProps } from "ds/components/Toggle";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import TooltipInfo, { TooltipInfoVariant } from "ds/components/TooltipInfo";

import { getColors } from "../Field/utils";
import styles from "./styles.module.css";

export type ToggleFieldProps = {
  color?: "default" | "inversed";
  variant: "switch" | "checkbox";
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  title: string;
  description?: string;
  tooltipInfo?: ReactNode;
  tooltipInfoVariant?: TooltipInfoVariant;
  titleSuffix?: ReactNode; // TODO: remove tooltipInfo, use suffix instead
  tooltipAnalyticsTitle?: string;
  disabled?: boolean;
  titleId?: string;
  align?: ToggleProps["align"];
  id?: string;
} & AnalyticsCommonProps;

const FormToggleField = ({
  onChange,
  checked,
  title,
  titleId,
  description,
  titleSuffix,
  tooltipInfo,
  tooltipInfoVariant = "modal",
  tooltipAnalyticsTitle,
  analyticsPage,
  analyticsProps,
  analyticsTitle,
  align = "start",
  variant,
  color = "default",
  disabled,
  id,
}: ToggleFieldProps) => {
  const descriptionId = useId();
  const trackSegmentEvent = useAnalytics({
    page: analyticsPage,
  });

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);

    if (analyticsTitle && analyticsPage) {
      trackSegmentEvent?.(analyticsTitle, { ...analyticsProps, checked: e.target.checked });
    }
  };
  const { primaryIconColor } = getColors(color);
  return (
    <Toggle
      id={id}
      color={color}
      variant={variant}
      onChange={onChangeHandler}
      checked={checked}
      disabled={disabled}
      align={align}
      ariaLabel={title}
      aria-describedby={descriptionId}
    >
      <Box direction="column">
        <Box className={styles.label} gap="small" align="center">
          <Typography tag="p" variant="p-t7" id={titleId}>
            {title}
          </Typography>
          {tooltipInfo && (
            <Box align="center">
              <TooltipInfo
                analyticsPage={analyticsPage}
                analyticsTitle={tooltipAnalyticsTitle}
                analyticsProps={analyticsProps}
                variant={tooltipInfoVariant}
                iconColor={primaryIconColor}
              >
                {tooltipInfo}
              </TooltipInfo>
            </Box>
          )}
          {titleSuffix}
        </Box>

        <Typography id={descriptionId} tag="p" variant="p-body4" color="secondary">
          {description}
        </Typography>
      </Box>
    </Toggle>
  );
};

export default FormToggleField;
