import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { AccountContext } from "views/AccountWrapper";
import { getHostName } from "utils/urls";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import ButtonNew from "ds/components/Button/New";

import styles from "./styles.module.css";
import { ProviderVersionDrawerProps } from "./types";

const code = (source: string, provider: string, version: string) =>
  `
terraform {
  required_providers {
    ${provider} = {
      source = "${source}/${provider}"
      version = "${version}"
    }
  }
}

provider "${provider}" {
  # Configuration options
}
`.trim();

const ProviderVersionDrawer = createDrawer(
  ({ provider, providerVersion }: ProviderVersionDrawerProps) => {
    const { accountName } = useTypedContext(AccountContext);
    const hostname = getHostName();

    const snippet = code(`${hostname}/${accountName}`, provider.id, providerVersion.number);

    const handleCopy = useCopyToClipboard(snippet);

    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="How to use this provider" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <Box>
            <Typography tag="p" variant="p-body2">
              To install this provider, copy and paste this code into your Terraform configuration.
            </Typography>
          </Box>
          <Typography tag="code" variant="s-body3" className={styles.code}>
            <pre>{snippet}</pre>
          </Typography>
          <DrawerFooter>
            <DrawerFooterActions>
              <DrawerCancelButton />
              <ButtonNew variant="secondary" onPress={() => handleCopy()}>
                Copy
              </ButtonNew>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showProviderVersionDrawer = createDrawerTrigger(ProviderVersionDrawer);
