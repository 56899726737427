import { Clock, GitBranch } from "components/icons/generated";
import { VCS_PROVIDERS_ICONS } from "components/icons/ProviderIcons";
import ListBox from "components/listBox/index";
import ListBoxInfoWrapper from "components/listBox/InfoWrapper";
import ListBoxItem from "components/listBox/Item";
import Timestamp from "components/time/Timestamp";
import { StackContext } from "views/Stack/Context";
import UserLink from "components/UserLink";
import useTypedContext from "hooks/useTypedContext";
import { PullRequest, Stack } from "types/generated";
import { generateBranchUrl } from "utils/urls";

type PullRequestElementProps = {
  pr: PullRequest;
  repository: Stack["repository"];
};

const PullRequestElement = (props: PullRequestElementProps) => {
  const { pr, repository } = props;

  const { stack } = useTypedContext(StackContext);
  const pullUrlText = `${stack.namespace}/${stack.repository}/pull/${pr.id}`;

  return (
    <ListBox
      state={{ content: pr.run.state, showTitle: true }}
      headerLink={{ name: pr.title, to: `/stack/${stack.id}/run/${pr.run.id}`, routerLink: true }}
      delta={pr.run.delta}
    >
      <ListBoxInfoWrapper>
        <ListBoxItem
          link={{
            url: pr.url,
            text: pullUrlText,
          }}
          icon={VCS_PROVIDERS_ICONS[stack.provider]}
        />
        <ListBoxItem icon={Clock}>
          Opened <Timestamp timestamp={pr.createdAt} /> by{" "}
          <UserLink login={pr.authorLogin} name="" provider={stack.provider} />
        </ListBoxItem>
        {stack.apiHost && (
          <ListBoxItem
            link={{
              url: generateBranchUrl({
                apiHost: stack.apiHost || "",
                namespace: stack.namespace,
                repository: repository,
                repositoryURL: stack.repositoryURL,
                provider: stack.provider,
                branch: pr.headBranch,
              }),
              text: pr.headBranch,
            }}
            icon={GitBranch}
          />
        )}
      </ListBoxInfoWrapper>
    </ListBox>
  );
};

export default PullRequestElement;
