import useLocalStorage from "@rehooks/local-storage";
import { ChangeEvent } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import FormToggleField from "ds/components/Form/ToggleField";
import { HIDE_IGNORED_RUN_NOTICE_LOCAL_STORAGE_KEY } from "constants/local_storage_keys";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useAnalytics from "hooks/useAnalytics";
import useTierFeature from "views/Account/hooks/useTierFeature";
import { BillingTierFeature } from "types/generated";
import BadgeNextNew from "ds/components/BadgeNext/New";
import { Diamond } from "components/icons/generated";

import useIgnoredRunNoticeDescription from "./useIgnoredRunNoticeDescription";

const NotificationsCustomization = () => {
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Personal Settings · Notifications customization · ${accountName}`);
  const isIgnoredRunsEnabled = useTierFeature(BillingTierFeature.RunIgnoredTriggers);

  const trackSegmentAnalyticsEvent = useAnalytics();

  const [hideIgnoredRunNotice, setHideIgnoredRunNotice] = useLocalStorage(
    HIDE_IGNORED_RUN_NOTICE_LOCAL_STORAGE_KEY,
    false
  );

  const toggleHideIgnoredRunNotice = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = !e.target.checked;
    setHideIgnoredRunNotice(newValue);
    trackSegmentAnalyticsEvent("Display ignored runs warning changed", {
      hidden: newValue,
    });
  };

  const description = useIgnoredRunNoticeDescription();

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle subtitle="Decide what alerts you’d like to see in the app." tag="h2">
          Notifications customization
        </ViewHeaderTitle>
      </ViewHeader>

      <Box direction="column" grow="1" padding="x-large" fullWidth>
        <Box direction="column" gap="medium" fullWidth>
          <Typography variant="p-t6" tag="h3">
            Runs notifications
          </Typography>

          <FormToggleField
            variant="switch"
            onChange={toggleHideIgnoredRunNotice}
            checked={!hideIgnoredRunNotice}
            titleSuffix={
              !isIgnoredRunsEnabled && (
                <div>
                  <BadgeNextNew
                    startIcon={Diamond}
                    variant="purple"
                    type="strong"
                    iconOnly
                    aria-hidden
                  />
                </div>
              )
            }
            disabled={!isIgnoredRunsEnabled}
            title="Ignored runs"
            description={description}
          />
        </Box>
      </Box>
    </>
  );
};

export default NotificationsCustomization;
