import ListEntitiesNew from "components/ListEntitiesNew";
import { SearchQueryOrderDirection, SecurityKey } from "types/generated";
import SortableTable from "components/SortableTable";
import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";

import SecurityKeysListVirtualizedItem from "./VirtualizedItem";
import { COLUMNS, COLUMN_ORDER, COLUMN_ORDER_WITH_OWNER, OWNER_COLUMNS } from "./constants";
import { SecurityKeyView } from "./types";

type SecurityKeysListProps = {
  securityKeys: SecurityKeyView[];
  onDelete: (item: SecurityKey) => void;
  showOwner?: boolean;
};

const SecurityKeysList = ({ securityKeys, onDelete, showOwner }: SecurityKeysListProps) => {
  const columns = showOwner ? OWNER_COLUMNS : COLUMNS;
  const columnOrder = showOwner ? COLUMN_ORDER_WITH_OWNER : COLUMN_ORDER;

  return (
    <SortableTable
      items={securityKeys}
      columns={columns}
      columnOrder={columnOrder}
      renderItems={(sorted) =>
        securityKeys.length ? (
          <ListEntitiesNew
            itemCount={sorted.length}
            itemProps={{
              securityKeys: sorted,
              onDelete,
              showOwner,
            }}
            virtualizedItem={SecurityKeysListVirtualizedItem}
            itemKey={(index) => sorted[index].id}
          />
        ) : (
          <EmptyState title="No results" icon={EmptystateSearchNoResultsColored} />
        )
      }
      initialSortBy="createdAt"
      initialDirection={SearchQueryOrderDirection.Desc}
    />
  );
};

export default SecurityKeysList;
