import { useState } from "react";

import ButtonNew from "ds/components/Button/New";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import { ChevronDown } from "components/icons/generated";
import { BulkActionsApiContext } from "components/BulkActions/Context";
import useTypedContext from "hooks/useTypedContext";
import { BulkActionResultTabs, BulkActionsResult } from "components/BulkActions/types";
import { type BulkActionItemResults } from "components/BulkActions/helpers";
import Button from "ds/components/Button";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { BulkActionResultsStepContinueWith } from "../types";
import { checkHasOnlyCompletedResults, checkHasOnlyFailedResults } from "./helpers";

type BulkActionsDrawerResultsStepFooterContinueWithProps = {
  bulkActionItemResults: BulkActionItemResults;
  onContinueWith: (continueWith: BulkActionResultsStepContinueWith) => void;
  analyticsPage: AnalyticsPage;
};
export const BulkActionsDrawerResultsStepFooterContinueWith = ({
  bulkActionItemResults,
  onContinueWith,
  analyticsPage,
}: BulkActionsDrawerResultsStepFooterContinueWithProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { onContinueWith: contextOnContinueWith } = useTypedContext(BulkActionsApiContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const handleContinueWith = (
    continueWith: BulkActionResultsStepContinueWith,
    method: "dropdownItem" | "button"
  ) => {
    trackSegmentAnalyticsEvent(`Bulk Actions - Drawer - Continue with ${continueWith} clicked`, {
      completedCount: bulkActionItemResults[BulkActionsResult.Completed].size,
      failedCount: bulkActionItemResults[BulkActionsResult.Failed].size,
      skippedCount: bulkActionItemResults[BulkActionsResult.Skipped].size,
      stoppedCount: bulkActionItemResults[BulkActionsResult.Stopped].size,
      method,
    });
    contextOnContinueWith();
    onContinueWith(continueWith);
  };

  const createHandleContinueWithButton =
    (continueWith: BulkActionResultsStepContinueWith) => () => {
      handleContinueWith(continueWith, "button");
    };

  const createHandleContinueWithDropdownItem =
    (continueWith: BulkActionResultsStepContinueWith) => () => {
      handleContinueWith(continueWith, "dropdownItem");
    };

  const hasOnlyCompletedResults = checkHasOnlyCompletedResults(bulkActionItemResults);
  const hasOnlyFailedResults = checkHasOnlyFailedResults(bulkActionItemResults);

  return (
    <>
      {hasOnlyCompletedResults && (
        <Button
          size="medium"
          variant="secondary"
          onClick={createHandleContinueWithButton(BulkActionResultTabs.Completed)}
        >
          New action
        </Button>
      )}
      {hasOnlyFailedResults && (
        <Button
          size="medium"
          variant="secondary"
          onClick={createHandleContinueWithButton(BulkActionResultTabs.Failed)}
        >
          New action
        </Button>
      )}
      {!hasOnlyCompletedResults && !hasOnlyFailedResults && (
        <DropdownMenu
          onOpenChange={setDropdownOpen}
          isOpen={isDropdownOpen}
          triggerComponent={
            <ButtonNew
              onPress={() => {
                trackSegmentAnalyticsEvent("Bulk actions - Drawer - New action dropdown opened");
              }}
              size="medium"
              variant="secondary"
              endIcon={ChevronDown}
            >
              New action
            </ButtonNew>
          }
        >
          <DropdownMenuItem onAction={createHandleContinueWithDropdownItem("all")}>
            With all
          </DropdownMenuItem>
          <DropdownMenuItem
            onAction={createHandleContinueWithDropdownItem(BulkActionResultTabs.Completed)}
          >
            With completed
          </DropdownMenuItem>
          <DropdownMenuItem
            onAction={createHandleContinueWithDropdownItem(BulkActionResultTabs.Failed)}
          >
            With failed
          </DropdownMenuItem>
        </DropdownMenu>
      )}
    </>
  );
};
