import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEqual } from "lodash-es";

import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Box from "ds/components/Box";
import { useToggle } from "hooks/useToggle";
import Tab from "ds/components/Tab";
import { prettify } from "views/shared/run/ResourcesDiff/helpers";
import usePrevious from "hooks/usePrevious";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import { ConfigNode } from "../../types";
import { TASK_SEARCH_KEY } from "../constants";
import { DrawerTabs } from "./types";
import ConfigManagementTreeGridDrawerDetails from "./Details";
import ConfigManagementTreeGridDrawerLogs from "./Logs";
import ConfigManagementTreeGridDrawerLogsFullScreen from "./LogsFullScreen";
import ConfigManagementTreeGridDrawerDiff from "./Diff";
import ConfigManagementTreeGridDrawerDiffFullScreen from "./DiffFullScreen";
import { parseDiff } from "./utils";

type ConfigManagementTreeGridDrawerProps = {
  task?: ConfigNode;
  analyticsPage?: AnalyticsPage;
};

const ConfigManagementTreeGridDrawer = ({
  task,
  analyticsPage,
}: ConfigManagementTreeGridDrawerProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const [logsFullScreen, toggleLogsFullScreen] = useToggle(false);
  const [diffFullScreen, toggleDiffFullScreen] = useToggle(false);
  const [tab, setTab] = useState(DrawerTabs.Details);

  const handleTabChange = useCallback(
    (tab: DrawerTabs) => {
      setTab(tab);
      trackSegmentAnalyticsEvent("Drawer tab switched", { tab });
    },
    [trackSegmentAnalyticsEvent]
  );

  const [diffFileName, setDiffFileName] = useState<string | undefined>();

  const parsedDiff = useMemo(() => parseDiff(task?.diff), [task?.diff]);

  const options = useMemo(
    () => Object.keys(parsedDiff).map((key) => ({ label: key, value: key })),
    [parsedDiff]
  );

  const previousOptions = usePrevious(options);

  useEffect(() => {
    if (!isEqual(previousOptions, options) && diffFileName !== undefined) {
      setDiffFileName(undefined);
    }
  }, [options, previousOptions, diffFileName]);

  const currentDiffFileName = diffFileName || options[0]?.value;
  const original =
    options.length && parsedDiff[currentDiffFileName]
      ? prettify(JSON.stringify(parsedDiff[currentDiffFileName].before))
      : "";
  const modified =
    options.length && parsedDiff[currentDiffFileName]
      ? prettify(JSON.stringify(parsedDiff[currentDiffFileName].after))
      : "";

  const navigate = useNavigate();
  const location = useLocation();
  const newUrlParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    params.delete(TASK_SEARCH_KEY);
    return params;
  }, [location.search]);

  const handleCloseDrawer = () => {
    navigate(`${location.pathname}${newUrlParams ? `?${newUrlParams}` : ""}`);
    toggleLogsFullScreen(false);
    toggleDiffFullScreen(false);
    setTab(DrawerTabs.Details);
  };

  useEffect(() => {
    if (!task && (logsFullScreen || diffFullScreen)) {
      toggleLogsFullScreen(false);
      toggleDiffFullScreen(false);
      setTab(DrawerTabs.Details);
    }
  }, [task, logsFullScreen, diffFullScreen, toggleLogsFullScreen, toggleDiffFullScreen]);

  const logsFileName = `${task?.name}.logs.txt`;
  const isTaskAvailable = !!task;
  return (
    <>
      <Drawer
        visible={isTaskAvailable && !logsFullScreen && !diffFullScreen}
        onOutsideClick={logsFullScreen || diffFullScreen ? undefined : handleCloseDrawer}
      >
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Task details" />
          <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
        </DrawerHeader>
        <DrawerBody direction="column" fullWidth>
          <Box gap="medium">
            <Tab
              label="Details"
              id={DrawerTabs.Details}
              onClick={handleTabChange}
              isActive={tab === DrawerTabs.Details}
            />
            <Tab
              label="Logs"
              id={DrawerTabs.Logs}
              onClick={handleTabChange}
              isActive={tab === DrawerTabs.Logs}
            />
            <Tab
              label="Diff"
              id={DrawerTabs.Diff}
              onClick={handleTabChange}
              isActive={tab === DrawerTabs.Diff}
            />
          </Box>
          {isTaskAvailable && (
            <>
              {tab === DrawerTabs.Details && <ConfigManagementTreeGridDrawerDetails task={task} />}
              {tab === DrawerTabs.Logs && (
                <ConfigManagementTreeGridDrawerLogs
                  analyticsPage={analyticsPage}
                  task={task}
                  toggleFullScreen={toggleLogsFullScreen}
                  fileName={logsFileName}
                />
              )}
              {tab === DrawerTabs.Diff && (
                <ConfigManagementTreeGridDrawerDiff
                  analyticsPage={analyticsPage}
                  options={options}
                  original={original}
                  modified={modified}
                  setFileName={setDiffFileName}
                  fileName={currentDiffFileName}
                  toggleFullScreen={toggleDiffFullScreen}
                />
              )}
            </>
          )}
        </DrawerBody>
      </Drawer>
      {tab === DrawerTabs.Logs && isTaskAvailable && (
        <ConfigManagementTreeGridDrawerLogsFullScreen
          task={task}
          toggleFullScreen={toggleLogsFullScreen}
          fullScreen={logsFullScreen}
          fileName={logsFileName}
          analyticsPage={analyticsPage}
        />
      )}
      {tab === DrawerTabs.Diff && isTaskAvailable && (
        <ConfigManagementTreeGridDrawerDiffFullScreen
          original={original}
          modified={modified}
          toggleFullScreen={toggleDiffFullScreen}
          fullScreen={diffFullScreen}
        />
      )}
    </>
  );
};

export default ConfigManagementTreeGridDrawer;
