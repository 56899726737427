import { memo, useRef, useCallback, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import ListEntitiesItem from "components/ListEntitiesItem";

type ConfigManagementTreeGridListLoaderRowProps = {
  setRowHeight?: (size: number) => void;
};

const ConfigManagementTreeGridListLoaderRow = ({
  setRowHeight,
}: ConfigManagementTreeGridListLoaderRowProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = useCallback(() => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  }, [setRowHeight]);

  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem ref={rowRef}>
      <Skeleton width={128} height={16} borderRadius={4} />
    </ListEntitiesItem>
  );
};

export default memo(ConfigManagementTreeGridListLoaderRow);
