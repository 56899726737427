import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import FormFieldViewText from "components/FormFields/ViewText";
import { SelfHostedLicenseContext } from "views/SelfHosted/LicenseProvider/Context";
import Box from "ds/components/Box";
import LoadingIndicator from "ds/components/LoadingIndicator";
import Feedback from "ds/components/Feedback";
import SelfHostedLicenseWarning from "components/SelfHostedLicenseWarning";

import { useLicensingDates } from "./useLicensingDates";
import AccountDetailsDrawerSelfHostedLicenseExpiryDateText from "./LicenseExpiryDateText";

const AccountDetailsSelfHosted = () => {
  const { viewer } = useTypedContext(AccountContext);
  const { debugInfo, loading, error } = useTypedContext(SelfHostedLicenseContext);

  const { startDate, endDate } = useLicensingDates(debugInfo);

  if (loading) {
    return (
      <Box grow="1" justify="center" align="center">
        <LoadingIndicator size="large" loading />
      </Box>
    );
  }

  if (error) {
    return (
      <Feedback type="banner" variant="danger">
        Something went wrong while fetching the license information. Please try again later.
      </Feedback>
    );
  }

  return (
    <>
      <SelfHostedLicenseWarning type="banner" show2MonthsWarning />
      {debugInfo?.selfHostedVersion && (
        <FormFieldViewText label="Self-hosted version" value={debugInfo.selfHostedVersion} />
      )}
      {debugInfo?.commitSha && (
        <FormFieldViewText label="Build" value={debugInfo.commitSha} withCopy />
      )}
      <FormFieldViewText
        label="Login received from the Identity Provider"
        value={viewer.id}
        withCopy
      />
      <FormFieldViewText label="License start date" value={startDate} />
      <AccountDetailsDrawerSelfHostedLicenseExpiryDateText endDate={endDate} />
    </>
  );
};

export default AccountDetailsSelfHosted;
