import { useMemo } from "react";

import { useToggle } from "hooks/useToggle";
import Tree from "ds/components/Tree";

import RunDependencyBadge from "./RunDependencyBadge";
import HistoryEntryWrapper from "../../components/HistoryEntryWrapper";
import { RunDependenciesEntryEvaluationProps, RunHistoryEntryType } from "../../types";
import { CombinedDependencies, isFinished } from "./utils";
import DependenciesEntryExternalDependency from "./ExternalDependency";
import DependenciesEntryRunDependency from "./RunDependency";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type DependenciesEntryProps = RunDependenciesEntryEvaluationProps & {
  dependencies: CombinedDependencies;
  isSimpleView: boolean;
};

const DependenciesEntry = ({
  dependencies,
  isSimpleView,
  evaluationState,
  evaluationTimestamp,
}: DependenciesEntryProps) => {
  const [isExpanded, toggle] = useToggle(true);

  const jsxList = useMemo(() => {
    let processedList = dependencies;

    if (isSimpleView) {
      processedList = processedList.filter((item) => !isFinished(item.state));
    }

    return processedList.map((item) => {
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      switch (item.__typename) {
        case "RunDependency":
          return <DependenciesEntryRunDependency key={item.id} item={item} />;
        case "RunExternalDependency":
          return <DependenciesEntryExternalDependency key={item.id} item={item} />;
        default:
          return null;
      }
    });
  }, [dependencies, isSimpleView]);

  const label = (
    <>
      <HistoryEntryLabel>
        <strong>Dependencies</strong> evaluated to
      </HistoryEntryLabel>
      <RunDependencyBadge state={evaluationState} />
    </>
  );

  return (
    <HistoryEntryWrapper
      label={label}
      timestamp={evaluationTimestamp}
      state={RunHistoryEntryType.Dependencies}
      isOpen={isExpanded}
      onClick={() => toggle()}
      terminal
    >
      {isExpanded && <Tree margin="0 0 0 medium">{jsxList}</Tree>}
    </HistoryEntryWrapper>
  );
};

export default DependenciesEntry;
