import cx from "classnames";

import {
  CalendarArrow,
  CheckList,
  Eye,
  EyeArrowCircle,
  ExclamationMarkCircle,
  Gear,
  Hourglass,
  Edit,
  Cross,
  File,
  Flash,
  FlagRacing,
  Play,
  Policy,
  Skip,
  StopCircle,
  Tick,
  ThumbUp,
  ThumbDown,
  Trash,
  Pickaxe,
  ArrowsLeftRight,
} from "components/icons/generated";
import { IconComponent } from "types/Icon";
import { BadgeVariants } from "ds/components/BadgeNext/types";
import BadgeNext from "ds/components/BadgeNext";

import styles from "./styles.module.css";
import { RunHistoryEntryType } from "../../types";

type RunHistoryEntryConfig = {
  icon: IconComponent;
  variant?: BadgeVariants;
};

export const STATES: Record<RunHistoryEntryType, RunHistoryEntryConfig> = {
  [RunHistoryEntryType.Comment]: { icon: File },
  [RunHistoryEntryType.Queued]: { icon: Hourglass, variant: "gray" },
  [RunHistoryEntryType.Ready]: { icon: FlagRacing, variant: "gray" },
  [RunHistoryEntryType.Discarded]: { icon: ThumbDown, variant: "red" },
  [RunHistoryEntryType.Preparing]: { icon: CheckList, variant: "blue" },
  [RunHistoryEntryType.Initializing]: { icon: Gear, variant: "blue" },
  [RunHistoryEntryType.Planning]: { icon: Edit, variant: "blue" },
  [RunHistoryEntryType.Unconfirmed]: { icon: Eye, variant: "yellow" },
  [RunHistoryEntryType.ReplanRequested]: { icon: CalendarArrow, variant: "gray" },
  [RunHistoryEntryType.Failed]: { icon: ExclamationMarkCircle, variant: "red" },
  [RunHistoryEntryType.Confirmed]: { icon: ThumbUp, variant: "gray" },
  [RunHistoryEntryType.Applying]: { icon: Play, variant: "blue" },
  [RunHistoryEntryType.Finished]: { icon: Tick, variant: "green" },
  [RunHistoryEntryType.Canceled]: { icon: Cross, variant: "red" },
  [RunHistoryEntryType.Stopped]: { icon: StopCircle, variant: "red" },
  [RunHistoryEntryType.Performing]: { icon: Flash, variant: "blue" },
  [RunHistoryEntryType.Skipped]: { icon: Skip, variant: "gray" },
  [RunHistoryEntryType.Destroying]: { icon: Trash, variant: "blue" },
  [RunHistoryEntryType.PendingReview]: { icon: EyeArrowCircle, variant: "yellow" },
  [RunHistoryEntryType.WorkerPool]: { icon: Pickaxe },
  [RunHistoryEntryType.Dependencies]: { icon: ArrowsLeftRight },
  [RunHistoryEntryType.ApprovalPolicies]: { icon: Policy },
  [RunHistoryEntryType.PlanPolicies]: { icon: Policy },
  [RunHistoryEntryType.TriggerPolicies]: { icon: Policy },
  [RunHistoryEntryType.InitializationPolicies]: { icon: Policy },
  [RunHistoryEntryType.NotificationPolicies]: { icon: Policy },
  [RunHistoryEntryType.PushPolicies]: { icon: Policy },
};

type HistoryEntryStateProps = {
  type: RunHistoryEntryType;
  className?: string;
};

const HistoryEntryState = ({ type, className }: HistoryEntryStateProps) => {
  if (!STATES[type]) {
    return null;
  }

  const elementClass = cx(styles.wrapper, className);

  return (
    <BadgeNext
      variant={STATES[type].variant || "transparent"}
      type="strong"
      className={elementClass}
      startIcon={STATES[type].icon}
      iconOnly
    />
  );
};

export default HistoryEntryState;
