import { MouseEvent, memo, useCallback, useMemo, useRef, useState } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import DropdownWrapper from "ds/components/Dropdown/DropdownWrapper";
import DropdownList from "ds/components/Dropdown/List";
import SelectOption from "ds/components/Select/Option";
import DropdownFooterSelection from "ds/components/Dropdown/Footer/Selection";
import useOutsideClick from "hooks/useOutsideClick";
import { Gear } from "components/icons/generated";
import useTypedContext from "hooks/useTypedContext";
import { type AnalyticsPage } from "hooks/useAnalytics";
import DropdownSection from "ds/components/Dropdown/Section";
import useEscapeKeypress from "hooks/useEscapeKeyPress";

import { ViewCustomizationContext } from "./Context";
import styles from "./styles.module.css";
import useSegmentTracking from "./useSegmentTracking";

type ViewCustomizationDropdownProps = {
  analyticsPage: AnalyticsPage;
};

const ViewCustomizationDropdown = ({ analyticsPage }: ViewCustomizationDropdownProps) => {
  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { viewItems, onChange } = useTypedContext(ViewCustomizationContext);
  useSegmentTracking(isVisible, analyticsPage);

  const handleToggleDropdown = (e: MouseEvent) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
  };

  const closeDropdown = () => {
    setIsVisible(false);
  };

  const handleSelectValue = useCallback(
    (id: string) => {
      onChange(
        viewItems.map((item) => {
          if (item.id !== id) {
            return item;
          }

          return { ...item, selected: !item.selected };
        })
      );
    },
    [onChange, viewItems]
  );

  const handleSelectAll = useCallback(() => {
    onChange(viewItems.map((item) => ({ ...item, selected: true })));
  }, [onChange, viewItems]);

  const handleResetAll = useCallback(() => {
    onChange(viewItems.map((item) => ({ ...item, selected: false })));
  }, [onChange, viewItems]);

  const selectedLength = useMemo(
    () => viewItems.filter((item) => item.selected).length,
    [viewItems]
  );

  useOutsideClick(wrapperRef, closeDropdown);

  useEscapeKeypress(isVisible, closeDropdown);

  return (
    <DropdownWrapper ref={wrapperRef}>
      <ButtonIcon icon={Gear} onClick={handleToggleDropdown} variant="ghost">
        Manage view
      </ButtonIcon>
      <DropdownList active={isVisible} position="bottomLeft" className={styles.dropdownList}>
        <DropdownSection>
          {viewItems.map((item) => (
            <SelectOption
              key={item.id}
              value={item.id}
              label={item.name}
              onChange={handleSelectValue}
              checked={item.selected}
              multiple
              switchToggle
            />
          ))}
        </DropdownSection>

        <DropdownFooterSelection
          selectedLength={selectedLength}
          allSelected={selectedLength === viewItems.length}
          onResetAll={handleResetAll}
          onSelectAll={handleSelectAll}
        />
      </DropdownList>
    </DropdownWrapper>
  );
};

export default memo(ViewCustomizationDropdown);
