import TierInfo from "components/TierInfo";
import { BillingTierFeature } from "types/generated";

type MfaTierInfoProps = {
  hasKeys: boolean;
};

const MfaTierInfo = ({ hasKeys }: MfaTierInfoProps) => {
  if (hasKeys) {
    return (
      <TierInfo
        type="callout"
        variant="danger"
        title="Multi-factor authentication is not supported by your plan"
        feature={BillingTierFeature.Mfa}
      >
        Upgrade to a plan that supports MFA to enable an extra layer of IdP independent protection,
        or disable the feature and remove your keys to avoid being locked out of your account.
      </TierInfo>
    );
  }

  return (
    <TierInfo
      type="callout"
      variant="promo"
      title="Multi-factor authentication"
      feature={BillingTierFeature.Mfa}
    >
      Upgrade your plan to protect your account and sensitive resources with an extra layer of IdP
      independent security, against unauthorized access.
    </TierInfo>
  );
};

export default MfaTierInfo;
