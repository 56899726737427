import { LogoOidc } from "components/icons/generated";
import { OidcSettings } from "types/generated";
import ButtonNew from "ds/components/Button/New";
import Tile from "ds/components/Tile";
import Tooltip from "ds/components/Tooltip";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useAnalytics from "hooks/useAnalytics";
import Box from "ds/components/Box";
import TileTitle from "ds/components/Tile/Title";
import BadgeNextNew from "ds/components/BadgeNext/New";
import setLocation from "shared/setLocation";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { openSingleSignOnDrawer } from "../components/Drawer";
import { showDisableConfirmation } from "./Disable";
import SettingsSingleSignOnCreateOIDC from "./Create";
import SettingsSingleSignOnDetailsOIDC from "./Details";

type SettingsSingleSignOnOIDCProps = {
  integration: OidcSettings | undefined;
  hasManagedUsers: boolean;
  samlAlreadyEnabled: boolean;
};

const SettingsSingleSignOnOIDC = ({
  integration,
  hasManagedUsers,
  samlAlreadyEnabled,
}: SettingsSingleSignOnOIDCProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSSO,
    defaultCallbackTrackProperties: { method: "OIDC" },
  });

  const isIntegrationActive = !!integration?.adminLogin;

  const onPress = () => {
    openSingleSignOnDrawer({
      title: "Set up OIDC",
      children: <SettingsSingleSignOnCreateOIDC />,
      isDismissable: false,
    });
    trackSegmentAnalyticsEvent("Set Up Click");
  };

  const handleDisable = () => {
    showDisableConfirmation({ hasManagedUsers });
    trackSegmentAnalyticsEvent("Disable Click");
  };

  const handleActivate = () => {
    setLocation("/oidc/start");
    trackSegmentAnalyticsEvent("Activate Click");
  };

  const handleShowDetails = () => {
    if (integration) {
      openSingleSignOnDrawer({
        title: "OIDC SSO",
        children: <SettingsSingleSignOnDetailsOIDC integration={integration} />,
        isDismissable: true,
      });
    }
  };

  return (
    <Tile
      icon={LogoOidc}
      title={
        <Box direction="row" gap="medium" align="center">
          <TileTitle>OIDC</TileTitle>
          {integration && isIntegrationActive && (
            <BadgeNextNew variant="green" type="strong" text="Active" />
          )}
        </Box>
      }
      description="Connect your Spacelift account to an OIDC supporting identity provider."
      indicator={
        <Box gap="medium">
          {/* Regular "Set up" flow */}
          {!integration && !samlAlreadyEnabled && (
            <ButtonNew variant="secondary" size="small" onPress={onPress}>
              Set up
            </ButtonNew>
          )}

          {/* Disabled "Set up" button because SAML is already enabled */}
          {!integration && samlAlreadyEnabled && (
            <Tooltip
              active
              on={(props) => (
                <ButtonNew variant="secondary" size="small" disabled {...props}>
                  Set up
                </ButtonNew>
              )}
            >
              SAML integration already present, can't set up OIDC
            </Tooltip>
          )}

          {integration && !isIntegrationActive && (
            <ButtonNew variant="primary" size="small" onPress={handleActivate}>
              Activate
            </ButtonNew>
          )}

          {integration && (
            <DropdownMenuEllipsis tooltip="OIDC actions">
              <DropdownMenuItem onAction={handleShowDetails}>See details</DropdownMenuItem>
              <DropdownMenuItem onAction={handleDisable} danger>
                Unlink
              </DropdownMenuItem>
            </DropdownMenuEllipsis>
          )}
        </Box>
      }
    />
  );
};

export default SettingsSingleSignOnOIDC;
