import { useCallback, useState } from "react";

import { ChevronRight } from "components/icons/generated";
import ControlledInfiniteList from "components/InfiniteList/Controlled";
import ButtonNew from "ds/components/Button/New";
import useAnalytics from "hooks/useAnalytics";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import useModuleVersions from "./useModuleVersions";
import styles from "./styles.module.css";

type VersionDropdownProps = {
  moduleId: string;
  versionNumber: string;
  isLatest: boolean;
};

const VersionDropdown = ({ moduleId, versionNumber, isLatest }: VersionDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { versions, handleLoadMore, hasMore, loading } = useModuleVersions(moduleId);

  const trackSegmentAnalyticsEvent = useAnalytics();

  const handleOpenChange = useCallback(
    (newValue: boolean) => {
      setIsOpen(newValue);

      if (newValue) {
        trackSegmentAnalyticsEvent("Module Registry - Version Switcher Dropdown Opened", {
          count: versions.length,
        });
      }
    },
    [trackSegmentAnalyticsEvent, versions.length]
  );

  const handleVersionClick = useCallback(() => {
    trackSegmentAnalyticsEvent("Module Registry - Version Switcher Dropdown Clicked");
  }, [trackSegmentAnalyticsEvent]);

  return (
    <ControlledInfiniteList onLoadMore={handleLoadMore} hasMore={hasMore} loading={loading}>
      {(onScroll) => (
        <DropdownMenu
          isOpen={isOpen}
          onOpenChange={handleOpenChange}
          triggerComponent={
            <ButtonNew
              variant="secondary"
              active={isOpen}
              disabled={loading}
              endIcon={ChevronRight}
              endIconRotate={isOpen ? "270" : "90"}
            >
              {`Version: ${versionNumber} ${isLatest ? "(latest)" : ""}`}
            </ButtonNew>
          }
          onScroll={onScroll}
          className={styles.valuesList}
        >
          {versions.length === 0 && (
            <DropdownMenuItem isDisabled>No other version</DropdownMenuItem>
          )}
          {versions.length > 0 &&
            versions.map((item) => (
              <DropdownMenuItem
                key={item.id}
                href={`/module/${moduleId}/version/${item.id}`}
                active={item.number === versionNumber}
                onAction={handleVersionClick}
              >
                {item.number}
              </DropdownMenuItem>
            ))}
        </DropdownMenu>
      )}
    </ControlledInfiniteList>
  );
};

export default VersionDropdown;
