import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { Stack } from "types/generated";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";

import StackDetails from "../components/Details";

type StackDetailsDrawerProps = {
  stack: Stack;
  stackUrl: string;
  canManageSpace: boolean;
};

const StackDetailsDrawer = createDrawer(
  ({ stack, stackUrl, canManageSpace }: StackDetailsDrawerProps) => {
    const onOpenFullDescription = useShowFullDescriptionWithBackArrow(stack.description);

    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Stack details" />
          <DrawerCloseIcon />
        </DrawerHeader>

        <DrawerBody gap="x-large">
          <StackDetails
            stack={stack}
            editUrl={`${stackUrl}/settings`}
            onOpenFullDescription={onOpenFullDescription}
            canManageSpace={canManageSpace}
          />
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showStackDetailsDrawer = createDrawerTrigger(StackDetailsDrawer);
