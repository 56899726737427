import { useParams } from "react-router-dom";

import NotFoundPage from "components/error/NotFoundPage";

import VCSIntegrationsManageGitHubEnterprise from "./ManageGitHubEnterprise";
import { VCS_PROVIDERS_URL_KEYS } from "../constants";
import VCSIntegrationsManageGitLab from "./ManageGitLab";
import VCSIntegrationsManageAzureDevOps from "./ManageAzureDevOps";
import VCSIntegrationsManageBitbucketCloud from "./ManageBitbucketCloud";
import VCSIntegrationsManageBitbucketDataCenter from "./ManageBitbucketDataCenter";

const VCSIntegrationsManageRoutes = () => {
  const { provider } = useParams<{ provider: string }>();

  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (provider) {
    case VCS_PROVIDERS_URL_KEYS.AZURE_DEVOPS:
      return <VCSIntegrationsManageAzureDevOps />;
    case VCS_PROVIDERS_URL_KEYS.BITBUCKET_CLOUD:
      return <VCSIntegrationsManageBitbucketCloud />;
    case VCS_PROVIDERS_URL_KEYS.BITBUCKET_DATACENTER:
      return <VCSIntegrationsManageBitbucketDataCenter />;
    case VCS_PROVIDERS_URL_KEYS.GITHUB_ENTERPRISE:
    case VCS_PROVIDERS_URL_KEYS.GITHUB:
      return <VCSIntegrationsManageGitHubEnterprise />;
    case VCS_PROVIDERS_URL_KEYS.GITLAB:
      return <VCSIntegrationsManageGitLab />;
    default:
      return <NotFoundPage />;
  }
};

export default VCSIntegrationsManageRoutes;
