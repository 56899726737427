import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { ScheduledTaskInput } from "types/generated";

import { UPDATE_STACK_SCHEDULING_TASK } from "./gql";

type UseUpdateScheduledTask = {
  stackId: string;
};

const useUpdateScheduledTask = ({ stackId }: UseUpdateScheduledTask) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackScheduledTaskUpdate, { loading }] = useMutation(UPDATE_STACK_SCHEDULING_TASK, {
    refetchQueries: ["GetStackScheduling"],
    awaitRefetchQueries: true,
    onError,
  });

  const updateScheduledTask = useCallback(
    (scheduleId: string, input: ScheduledTaskInput, successCallback?: () => void) => {
      stackScheduledTaskUpdate({
        variables: {
          scheduleId,
          stack: stackId,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackScheduledTaskUpdate) {
            reportSuccess({
              message: `Task schedule was updated successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackId, stackScheduledTaskUpdate]
  );

  return {
    updateScheduledTask,
    loading,
  };
};

export default useUpdateScheduledTask;
