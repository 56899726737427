export type SettingsSingleSignOnOIDCFormFields = {
  clientId: string;
  clientCredentials: string;
  identityProviderHost: string;
  claimMapping: boolean;
};

export const formDefaultValues: SettingsSingleSignOnOIDCFormFields = {
  clientId: "",
  clientCredentials: "",
  identityProviderHost: "",
  claimMapping: false,
};
