import { RefObject } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import Toggle from "ds/components/Toggle";
import { DotsVertical } from "components/icons/generated";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";

type FilterSettingsItemProps = {
  name: string;
  innerRef?: RefObject<HTMLDivElement>;
  visible: boolean;
  onVisibilityChange: () => void;
  hasChosenOptions: boolean;
  isDragging: boolean;
};

const FilterSettingsItem = ({
  name,
  visible,
  onVisibilityChange,
  innerRef,
  hasChosenOptions,
  isDragging,
  ...restProps
}: FilterSettingsItemProps) => {
  return (
    <Box
      ref={innerRef}
      direction="row"
      align="center"
      justify="between"
      className={cx(styles.filterItem, { [styles.isDragging]: isDragging })}
      fullWidth
      {...restProps}
    >
      <Toggle
        variant="checkbox"
        id={`${name}_filter_setting`}
        checked={visible}
        onChange={onVisibilityChange}
        disabled={hasChosenOptions}
        ariaLabel={visible ? `Hide ${name} filter` : `Show ${name} filter`}
      >
        <span className={styles.text}>{name}</span>
      </Toggle>

      <Icon src={DotsVertical} className={styles.icon} />
    </Box>
  );
};

export default FilterSettingsItem;
