import { ReactNode } from "react";

import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

type SettingsSingleSignOnDrawerProps = {
  children: ReactNode;
  title: string;
  isDismissable?: boolean;
};

const SettingsSingleSignOnDrawer = createDrawer(function SingleSignOnSetupDrawer({
  title,
  children,
  isDismissable,
}: SettingsSingleSignOnDrawerProps) {
  return (
    <DrawerSimple isDismissable={isDismissable}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={title} />
        <DrawerCloseIcon />
      </DrawerHeader>

      {children}
    </DrawerSimple>
  );
});

export const openSingleSignOnDrawer = createDrawerTrigger(SettingsSingleSignOnDrawer);
