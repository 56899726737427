import { Commit } from "components/icons/generated";
import { StackSuggestions } from "constants/stack";
import { Stack } from "types/generated";
import { generateBranchUrl } from "utils/urls";
import { getCommmitDigitsCount } from "utils/commit";

import MetaInfoListItem from "./Item";
import styles from "./styles.module.css";

type MetaInfoStackCommitProps = {
  stack: Stack;
  handleApplyFilter?: (keyName: StackSuggestions) => (value: string) => void;
};

const MetaInfoStackCommit = ({ stack, handleApplyFilter }: MetaInfoStackCommitProps) => {
  const {
    apiHost,
    branch,
    namespace,
    provider,
    repository,
    repositoryURL,
    trackedCommit,
    trackedBranchHead,
    trackedCommitSetBy,
  } = stack;

  if (!apiHost) return null;

  if (trackedCommit) {
    const trackedCommitIsOutdated =
      trackedCommit && trackedBranchHead && trackedCommit.hash !== trackedBranchHead.hash;

    return (
      <div className={styles.trackedCommitWrapper}>
        <MetaInfoListItem
          applyFilter={handleApplyFilter && handleApplyFilter(StackSuggestions.Commit)}
          href={trackedCommit.url}
          linkText={trackedCommit.hash.slice(0, getCommmitDigitsCount(provider))}
          icon={Commit}
          tooltip={
            (trackedCommitSetBy || trackedCommitIsOutdated) && (
              <>
                {trackedCommitSetBy && `${trackedCommitSetBy}.`}
                {trackedCommitIsOutdated && (
                  <span className={styles.outdatedMessage}>
                    Stack's tracked commit is not equal to the head of the tracked branch.
                  </span>
                )}
              </>
            )
          }
          target="_blank"
          type="commit"
          tooltipWidthMode="maxWidthSm"
        />

        {trackedCommitIsOutdated && <span className={styles.outdatedIndicator}>&nbsp;</span>}
      </div>
    );
  }

  return (
    <MetaInfoListItem
      href={generateBranchUrl({
        apiHost,
        namespace,
        repository,
        repositoryURL,
        provider,
        branch,
      })}
      linkText="HEAD"
      icon={Commit}
      tooltip={trackedCommitSetBy}
      target="_blank"
    />
  );
};

export default MetaInfoStackCommit;
