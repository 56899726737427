import { useCallback, useState } from "react";
import InfiniteLoader from "react-window-infinite-loader";

import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useErrorHandle from "hooks/useErrorHandle";
import ListEntitiesNew from "components/ListEntitiesNew";
import EmptyState from "ds/components/EmptyState";
import { Policy } from "components/icons/generated";
import { CreatePolicyFields } from "views/Account/PolicyCreateDrawer/types";
import { PolicyTemplate } from "types/generated";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import { SpacesContext } from "views/Account/SpacesProvider";

import { showPolicyCreateDrawer } from "../../PolicyCreateDrawer";
import PoliciesViewHeader from "../ViewHeader";
import PoliciesTemplatesFiltersLayout from "./FiltersLayout";
import PoliciesTemplatesVirtualizedListItem from "./Virtualized";
import { showPoliciesTemplatesTypesDrawer } from "./TypesDrawer";
import { showPoliciesTemplatesInfoDrawer } from "./InfoDrawer";
import useSearchTemplates from "./useSearchTemplates";
import useSearchTemplatesSuggestions from "./useSearchTemplatesSuggestions";
import { ENTITIES_PER_PAGE } from "./constants";
import useTakenPolicyNames from "../useTakenPolicyNames";

const PoliciesTemplates = () => {
  const { accountName } = useTypedContext(AccountContext);

  const [visibleTemplatePreviews, setVisibleTemplatePreviews] = useState<Record<string, boolean>>(
    {}
  );
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [focusedPolicyTemplate, setFocusedPolicyTemplate] = useState<PolicyTemplate | undefined>(
    undefined
  );

  const { hasEntityCreateAccess: canManagePolicies } = useTypedContext(SpacesContext);

  const handleOpenFullDescriptionDrawer = (policyTemplate: PolicyTemplate) => {
    setFocusedPolicyTemplate(policyTemplate);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFocusedPolicyTemplate(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  useTitle(`Policies templates · ${accountName}`);

  const {
    entities,
    error,
    isPageEmpty,
    hasNoFilteringResults,
    loading,
    isItemLoaded,
    loadMoreItems,
  } = useSearchTemplates();

  const {
    filters,
    filtersMap,
    sortOptions,
    loading: filtersLoading,
    refetchActiveSections,
    filteredCount,
  } = useSearchTemplatesSuggestions();

  const takenPolicyNames = useTakenPolicyNames();

  const openCreateDrawer = useCallback(
    (defaultValues: Partial<CreatePolicyFields>, templateId: string) => {
      showPolicyCreateDrawer({
        forceValidationOnOpen: true,
        autoSelectFirstType: false,
        takenPolicyNames,
        forcedType: defaultValues?.type,
        templateId,
        defaultValues,
      });
    },
    [takenPolicyNames]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  return (
    <>
      <PoliciesViewHeader />

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={focusedPolicyTemplate?.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />
      <PoliciesTemplatesFiltersLayout
        openPolicyTypesDrawer={showPoliciesTemplatesTypesDrawer}
        openPolicyInfoDrawer={showPoliciesTemplatesInfoDrawer}
        isPageEmpty={isPageEmpty}
        renderedEntitiesCount={entities.length}
        loadingIndication={loading}
        filters={filters}
        filtersMap={filtersMap}
        sortOptions={sortOptions}
        filtersLoading={filtersLoading}
        refetchActiveSections={refetchActiveSections}
        filteredCount={filteredCount}
      >
        {hasNoFilteringResults && (
          <EmptyState title="No results" icon={Policy} caption="Try different filters." />
        )}
        {!!entities.length && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={entities.length + ENTITIES_PER_PAGE}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={entities.length}
                itemProps={{
                  items: entities,
                  openCreateDrawer,
                  canManagePolicies,
                  setVisibleTemplatePreviews,
                  visibleTemplatePreviews,
                  onShowFullDescription: handleOpenFullDescriptionDrawer,
                }}
                virtualizedItem={PoliciesTemplatesVirtualizedListItem}
                itemKey={(index) => entities[index].id}
                onItemsRendered={onItemsRendered}
              />
            )}
          </InfiniteLoader>
        )}
      </PoliciesTemplatesFiltersLayout>
    </>
  );
};

export default PoliciesTemplates;
