import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import { WebhooksIntegration } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import SecretFieldDetails from "ds/components/SecretFieldDetails";
import FormFieldViewBoolean from "components/FormFields/ViewBoolean";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import Typography from "ds/components/Typography";

type WebhookDetailsDrawerProps = {
  webhook: WebhooksIntegration;
  canManageModule: boolean;
};

const WebhookDetailsDrawer = createDrawer(
  ({ webhook, canManageModule }: WebhookDetailsDrawerProps) => {
    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Details" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <FormFieldViewText label="Endpoint" value={webhook.endpoint} />
          <FormField label="Secret">
            {canManageModule ? (
              <SecretFieldDetails secret={webhook.secret} />
            ) : (
              <Typography tag="span" variant="p-body2">
                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
              </Typography>
            )}
          </FormField>
          <FormFieldViewBoolean label="Enabled" value={webhook.enabled} />
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showWebhookDetailsDrawer = createDrawerTrigger(WebhookDetailsDrawer);
