import { useState } from "react";
import cx from "classnames";

import { Eye } from "components/icons/generated";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";

type PrivateTextProps = {
  text: string;
};

const PrivateText = ({ text }: PrivateTextProps) => {
  const [isVisible, setVisible] = useState(false);
  const hiddenText = text.replace(/./g, "*");
  const iconClassName = cx(styles.icon, { [styles.visible]: isVisible });

  return (
    <div>
      {isVisible ? text : hiddenText}
      <button className={styles.button} onClick={() => setVisible(!isVisible)}>
        <Icon src={Eye} className={iconClassName} />
      </button>
    </div>
  );
};

export default PrivateText;
