import Button from "ds/components/Button";
import { Edit } from "components/icons/generated";
import Tooltip from "ds/components/Tooltip";

import styles from "./styles.module.css";

type CreateFileButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

const CreateFileButton = ({ onClick, disabled }: CreateFileButtonProps) => {
  return (
    <Tooltip
      on={(props) => (
        <Button
          {...props}
          variant="secondary"
          className={styles.button}
          onClick={onClick}
          startIcon={Edit}
          disabled={disabled}
        >
          Create file manually
        </Button>
      )}
      active={disabled}
    >
      Please provide path before creating file
    </Tooltip>
  );
};

export default CreateFileButton;
