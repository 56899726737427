import { Navigate, Route, Routes } from "react-router-dom";

import CloudIntegrations from "./CloudIntegrations";
import Webhooks from "./Webhooks";

const ModuleSettingsIntegrations = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="cloud" replace />} />
      <Route path="cloud" element={<CloudIntegrations />} />
      <Route path="webhooks" element={<Webhooks />} />
    </Routes>
  );
};

export default ModuleSettingsIntegrations;
