import Skeleton from "react-loading-skeleton";

import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import Box from "ds/components/Box";
import IconTile from "ds/components/IconTile";
import { ArrowsLeftRight } from "components/icons/generated";

const MOCK = Array(3).fill(null);

const DashboardWidgetsNextDriftDetectionScheduleSkeleton = () => (
  <Box direction="column" gap="medium" fullWidth>
    {MOCK.map((_, i) => (
      <TileWrapper key={i}>
        <TileContent>
          <Box gap="medium" align="start" fullWidth>
            <IconTile icon={ArrowsLeftRight} />
            <Box direction="column" gap="small">
              <Skeleton count={1} height={38} width={170} />
              <Skeleton count={1} height={20} width={30} />
            </Box>
          </Box>
        </TileContent>
      </TileWrapper>
    ))}
  </Box>
);

export default DashboardWidgetsNextDriftDetectionScheduleSkeleton;
