import { useMutation } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";

import Box from "ds/components/Box";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import ButtonNew from "ds/components/Button/New";

import { ADD_RUN_COMMENT } from "./gql";

type CommentFormProps = {
  stackId: string;
  runId: string;
  runQueryToRefetch: string;
};

const CommentForm = ({ stackId, runId, runQueryToRefetch }: CommentFormProps) => {
  const [comment, setComment] = useState("");

  const { reportSuccess, onError } = useTypedContext(FlashContext);

  const [addRunComment, { loading: isCommentSending }] = useMutation(ADD_RUN_COMMENT, {
    refetchQueries: [runQueryToRefetch],
    variables: { stackId, runId, body: comment },
    awaitRefetchQueries: true,
  });

  const handleSendComment = useCallback(async () => {
    try {
      await addRunComment();

      setComment("");
      reportSuccess({ message: "Comment successfully added" });
    } catch (error) {
      onError(error);
    }
  }, [addRunComment, onError, reportSuccess]);

  const buttonDisabled = useMemo(() => !comment.trim(), [comment]);

  return (
    <Box gap="medium" align="start" fullWidth>
      <Textarea
        minRows={1}
        maxRows={10}
        placeholder="Share your thoughts..."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        disabled={isCommentSending}
      />
      <ButtonNew
        variant="secondary"
        disabled={buttonDisabled}
        onPress={handleSendComment}
        loading={isCommentSending}
      >
        Comment
      </ButtonNew>
    </Box>
  );
};

export default CommentForm;
