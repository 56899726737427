import { useRef, useState, MouseEvent, useId } from "react";
import cx from "classnames";
import useLocalStorage from "@rehooks/local-storage";
import { datadogRum } from "@datadog/browser-rum";

import useTypedContext from "hooks/useTypedContext";
import { ExpandLeft } from "components/icons/generated";
import Box from "ds/components/Box";
import { AnalyticsPage } from "hooks/useAnalytics";
import ButtonIcon from "ds/components/ButtonIcon";

import styles from "./styles.module.css";
import {
  FILTER_DEFAULT_MIN_WIDTH,
  FILTER_EXPANDED_STORAGE_KEY,
  FILTER_WIDTH_STORAGE_KEY,
} from "../constants";
import { FilterMode } from "../types";
import SidebarHeadingContent from "./SidebarHeadingContent";
import SidebarContent from "./SidebarContent";
import { createStorageKey } from "../helpers";
import { FiltersContext } from "..";
import { useSwappableTooltip } from "./useSwappableTooltip";

const HOVER_EFFECT_OPEN_DELAY = 300;
const HOVER_EFFECT_CLOSE_DELAY = 600;

type FiltersSidebarProps = {
  analyticsPage?: AnalyticsPage;
};

const getFiltersTooltipText = (expanded: boolean) => (expanded ? "Hide filters" : "Pin filters");

const FiltersSidebar = ({ analyticsPage }: FiltersSidebarProps) => {
  const groupId = useId();
  const containerRef = useRef<HTMLDivElement>(null);
  const { filterType, filtersRevealedOnHover, setFiltersRevealedOnHover } =
    useTypedContext(FiltersContext);

  const [lastSidebarWidth, setLastSidebarWidth] = useLocalStorage(
    createStorageKey(filterType, FILTER_WIDTH_STORAGE_KEY),
    FILTER_DEFAULT_MIN_WIDTH
  );
  const [filtersExpanded, updateFiltersExpanded] = useLocalStorage(
    createStorageKey(filterType, FILTER_EXPANDED_STORAGE_KEY),
    true
  );

  const [mode, setMode] = useState<FilterMode>(FilterMode.Filters);

  const { tooltipActive, tooltipText, swapText } = useSwappableTooltip({
    initialTooltipText: getFiltersTooltipText(filtersExpanded),
    swapDelay: 300,
  });

  const handleModeChange = (mode: FilterMode) => {
    if (mode === FilterMode.Views) {
      datadogRum.addAction("[Saved Views] Click Views tab");
    }
    setMode(mode);
  };

  const revealSidebarTimeout = useRef<NodeJS.Timeout>();
  const collapseSidebarTimeout = useRef<NodeJS.Timeout>();

  const clearRevealSidebarTimeout = () => {
    if (revealSidebarTimeout.current) {
      clearTimeout(revealSidebarTimeout.current);
    }
  };

  const clearCollapseSidebarTimeout = () => {
    if (collapseSidebarTimeout.current) {
      clearTimeout(collapseSidebarTimeout.current);
    }
  };

  const handleSidebarMouseEnter = () => {
    clearRevealSidebarTimeout();
    clearCollapseSidebarTimeout();
  };

  const handleSidebarContentMouseEnter = () => {
    clearRevealSidebarTimeout();
    clearCollapseSidebarTimeout();

    if (!filtersExpanded) {
      revealSidebarTimeout.current = setTimeout(() => {
        setFiltersRevealedOnHover(true);
      }, HOVER_EFFECT_OPEN_DELAY);
    }
  };

  const handleSidebarMouseLeave = () => {
    clearRevealSidebarTimeout();
    clearCollapseSidebarTimeout();

    if (filtersRevealedOnHover) {
      collapseSidebarTimeout.current = setTimeout(() => {
        setFiltersRevealedOnHover(false);
      }, HOVER_EFFECT_CLOSE_DELAY);
    }
  };

  const toggleFilters = (e: MouseEvent) => {
    e.stopPropagation();

    datadogRum.addAction(`[Filters] ${filtersExpanded ? "Hide" : "Pin"} filters`);

    if (filtersExpanded && containerRef.current) {
      setLastSidebarWidth(containerRef.current.offsetWidth);
    }

    if (filtersExpanded) {
      setFiltersRevealedOnHover(false);
    }
    updateFiltersExpanded(!filtersExpanded);
    swapText(getFiltersTooltipText(!filtersExpanded));
  };

  return (
    <Box
      id={groupId}
      direction="column"
      grow="1"
      ref={containerRef}
      style={
        {
          "--filters-sidebar-collapsed-min-width": `${lastSidebarWidth}px`,
        } as React.CSSProperties
      }
      onMouseLeave={handleSidebarMouseLeave}
      onMouseEnter={handleSidebarMouseEnter}
      className={cx(styles.sidebar, {
        [styles.hide]: !filtersExpanded,
        [styles.revealedOnHover]: filtersRevealedOnHover,
      })}
    >
      <Box direction="row" align="center" justify="between" className={cx(styles.header)}>
        <Box
          direction="column"
          grow="1"
          className={cx(styles.contentToHide, styles.sidebarHeadingWrapper)}
        >
          <SidebarHeadingContent setMode={handleModeChange} mode={mode} />
        </Box>
        {/* This is a valid case, we don't want the peek functionality for keyboard anyway */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span onMouseEnter={clearRevealSidebarTimeout}>
          <ButtonIcon
            className={cx(styles.toggle, {
              [styles.iconRotated]: !filtersExpanded,
            })}
            iconRotate={!filtersExpanded ? "180" : undefined}
            icon={ExpandLeft}
            onClick={toggleFilters}
            disableTooltip={!tooltipActive}
            aria-controls={groupId}
            aria-expanded={filtersExpanded}
            variant="ghost"
          >
            {tooltipText}
          </ButtonIcon>
        </span>
      </Box>

      <Box direction="column" grow="1" onMouseEnter={handleSidebarContentMouseEnter}>
        <Box direction="column" grow="1" className={styles.contentToHide}>
          <SidebarContent mode={mode} analyticsPage={analyticsPage} />
        </Box>
      </Box>
    </Box>
  );
};

export default FiltersSidebar;
