import { BlueprintState } from "types/generated";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import BaseActionButton from "ds/components/BaseAction/Button";

import BlueprintStateBadge from ".";
import styles from "./styles.module.css";

type BlueprintStateBadgeDropdownProps = {
  state: BlueprintState;
  className?: string;
  applyFilter: (value: BlueprintState) => void;
};

const BlueprintStateBadgeDropdown = ({
  state,
  className,
  applyFilter,
}: BlueprintStateBadgeDropdownProps) => {
  const getHandleOnClick = () => {
    applyFilter(state);
  };

  return (
    <DropdownMenu
      triggerComponent={
        <BaseActionButton className={styles.badgeWrapper}>
          <BlueprintStateBadge withDropdown state={state} className={className} />
        </BaseActionButton>
      }
    >
      <DropdownMenuItem onAction={getHandleOnClick}>Add to filters</DropdownMenuItem>
    </DropdownMenu>
  );
};

export default BlueprintStateBadgeDropdown;
