import TooltipInfoFloating from "ds/components/TooltipInfo";
import useTypedContext from "hooks/useTypedContext";
import { LicenseExpirationStatus } from "views/SelfHosted/LicenseProvider/types";
import { SelfHostedLicenseContext } from "views/SelfHosted/LicenseProvider/Context";
import { IconProps } from "ds/components/Icon";
import { AriaOnlyText } from "components/AriaOnlyText";
import Box from "ds/components/Box";
import { showAccountDetailsDrawer } from "views/Account/AccountDetailsDrawer";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

const getLicenseExpirationStatusProps = (
  licenseExpirationStatus?: LicenseExpirationStatus
): { color: IconProps["color"]; tooltip: string } | null => {
  if (licenseExpirationStatus === LicenseExpirationStatus.EXPIRED) {
    return {
      color: "danger",
      tooltip: "The license expired",
    };
  }
  if (licenseExpirationStatus === LicenseExpirationStatus.IN_2_WEEKS) {
    return {
      color: "danger",
      tooltip: "The license will expire soon",
    };
  }
  if (licenseExpirationStatus === LicenseExpirationStatus.IN_2_MONTHS) {
    return {
      color: "warning",
      tooltip: "The license will expire soon",
    };
  }

  return null;
};

const SideNavigationUserDropdownAccountDetailsMenuItem = () => {
  const { licenseExpirationStatus } = useTypedContext(SelfHostedLicenseContext);

  const properties = getLicenseExpirationStatusProps(licenseExpirationStatus);
  if (properties) {
    return (
      <DropdownMenuItem onAction={showAccountDetailsDrawer}>
        <Box gap="small">
          <TooltipInfoFloating standardIconColor={properties.color} widthMode="maxWidthSm">
            {properties.tooltip}
          </TooltipInfoFloating>
          <span>
            Account details<AriaOnlyText>{`. Warning: ${properties.tooltip}`}</AriaOnlyText>
          </span>
        </Box>
      </DropdownMenuItem>
    );
  }

  return <DropdownMenuItem onAction={showAccountDetailsDrawer}>Account details</DropdownMenuItem>;
};

export default SideNavigationUserDropdownAccountDetailsMenuItem;
