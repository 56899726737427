import { useMutation } from "@apollo/client";

import { StarFilled, Star } from "components/icons/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import { Stack } from "types/generated";

import { STAR_SET } from "../gql";
import styles from "./styles.module.css";
import { ANALYTICS } from "../constants";
import { CellComponentProps } from "./types";

type StackListItemFavoriteCellProps = CellComponentProps;

const StackListItemFavoriteCell = ({ stack }: StackListItemFavoriteCellProps) => {
  const { onError } = useTypedContext(FlashContext);

  const [starSet] = useMutation<{ starSet: Stack }>(STAR_SET, {
    refetchQueries: ["SearchStacksNew", "SearchStacksSuggestionsNew"],
  });

  const handleStarStack = (clb?: () => void) => () => {
    starSet({ variables: { id: stack.id, star: !stack.starred } }).catch(onError);
    clb?.();
  };

  return (
    <ButtonIconNew
      {...ANALYTICS}
      analyticsTitle={"Star clicked"}
      analyticsProps={{
        value: stack.starred ? "remove" : "add",
      }}
      className={styles.favoriteButtonIcon}
      icon={stack.starred ? StarFilled : Star}
      onPress={handleStarStack()}
      variant="ghost"
    >
      {stack.starred ? "Remove from favorites" : "Add to favorites"}
    </ButtonIconNew>
  );
};

export default StackListItemFavoriteCell;
