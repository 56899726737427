import DrawerBody from "ds/components/Drawer/Body";
import FormFieldViewText from "components/FormFields/ViewText";
import { SamlSettings } from "types/generated";

import SettingsSingleSignOnConfigInfoSAML from "../components/ConfigInfoSAML";
import { SAML_NAME_ID_FORMATS_DICTIONARY } from "./constants";

type SettingsSingleSignOnDetailsSAMLProps = {
  integration: SamlSettings;
};

const SettingsSingleSignOnDetailsSAML = ({ integration }: SettingsSingleSignOnDetailsSAMLProps) => {
  return (
    <>
      <DrawerBody hasDivider gap="x-large">
        <SettingsSingleSignOnConfigInfoSAML />
      </DrawerBody>
      <DrawerBody hasDivider gap="x-large">
        <FormFieldViewText label="Admin login" value={integration.adminLogin} withCopy noMargin />
        <FormFieldViewText
          label="NameID Format"
          value={SAML_NAME_ID_FORMATS_DICTIONARY[integration.nameIDFormat]}
          withCopy
          noMargin
        />
        {integration.idpMetadata && (
          <FormFieldViewText
            label="IdP metadata"
            value={integration.idpMetadata}
            withCopy
            noMargin
          />
        )}
        {integration.idpMetadataURL && (
          <FormFieldViewText
            label="IdP metadata URL"
            value={integration.idpMetadataURL}
            withCopy
            noMargin
          />
        )}
      </DrawerBody>
    </>
  );
};

export default SettingsSingleSignOnDetailsSAML;
