import { useCallback, useEffect, useState } from "react";
import { SortDescriptor } from "react-aria-components";
import { useSearchParams } from "react-router-dom";

import { SearchQueryOrderDirection } from "types/generated";
import { getSessionStorageKeys } from "components/Filters/helpers";
import { decodeURIParam } from "utils/urls";
import { URL_SORT_DIRECTION, URL_SORT_KEY } from "constants/url_query_keys";

import {
  initialSortDirection,
  initialSortOption,
  FILTERS_ORDER_SETTINGS_KEY,
  SORT_CONFIG,
} from "./constants";
import useStackListAnalytics from "./useStackListAnalytics";

const useSort = (callback?: () => void) => {
  const trackAnalytics = useStackListAnalytics();

  const [searchParams, setSearchParams] = useSearchParams();

  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "symbol",
    direction: "ascending",
  });

  const storage = sessionStorage;

  const { storageUrlSortDirection, storageUrlSortKey } = getSessionStorageKeys(
    FILTERS_ORDER_SETTINGS_KEY
  );

  useEffect(() => {
    const querySortOption = decodeURIParam(searchParams.get(URL_SORT_KEY)) || initialSortOption;
    const querySortDirection = (decodeURIParam(searchParams.get(URL_SORT_DIRECTION)) ||
      initialSortDirection) as SearchQueryOrderDirection;

    const convertedSortConfig = Object.entries(SORT_CONFIG).reduce(
      (prev, [key, value]) => ({ ...prev, [value]: key }),
      {} as Record<string, string>
    );

    const column = querySortOption ? convertedSortConfig[querySortOption] : "";
    const direction = querySortDirection === "ASC" ? "ascending" : "descending";

    if (sortDescriptor.column !== column || sortDescriptor.direction !== direction) {
      setSortDescriptor({
        column,
        direction,
      });
    }
  }, [searchParams, sortDescriptor.column, sortDescriptor.direction]);

  const handleSortOptionChange = useCallback(
    (descriptor: SortDescriptor) => {
      trackAnalytics("Sort by column", { ...descriptor });
      setSortDescriptor(descriptor);

      const column = descriptor.column ? SORT_CONFIG[descriptor.column as string] : "";
      const direction = descriptor.direction === "ascending" ? "ASC" : "DESC";

      searchParams.set(URL_SORT_KEY, encodeURIComponent(column));
      searchParams.set(URL_SORT_DIRECTION, encodeURIComponent(direction));
      storage.setItem(storageUrlSortKey, encodeURIComponent(column));
      storage.setItem(storageUrlSortDirection, encodeURIComponent(direction));

      setSearchParams(searchParams);
      callback?.();
    },
    [
      searchParams,
      trackAnalytics,
      storageUrlSortDirection,
      setSearchParams,
      callback,
      storageUrlSortKey,
      storage,
    ]
  );

  return {
    handleSortOptionChange,
    sortDescriptor,
  };
};

export default useSort;
