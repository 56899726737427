import { useModal, create, show } from "@ebay/nice-modal-react";
import { useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { createDefaultMutationHook } from "hooks/useMutationHandler";
import { ConfirmationModalInput } from "ds/components/ConfirmationModal/Input";

import { DELETE_SAML_SSO } from "./gql";

const useDeleteSAML = createDefaultMutationHook(DELETE_SAML_SSO);

const SettingsSingleSignOnDisableSAML = create(function SettingsSingleSignOnDisableSAML() {
  const modal = useModal();
  const [isValid, setIsValid] = useState(false);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSSO,
    defaultCallbackTrackProperties: { method: "SAML" },
  });

  const { reportSuccess } = useTypedContext(FlashContext);

  const [deleteSAML, { loading, data }] = useDeleteSAML({
    refetchQueries: ["GetSSOSettings"],
  });

  const handleDelete = () => {
    trackSegmentAnalyticsEvent("Confirm disable click");
    deleteSAML({}, (data) => {
      if (data?.samlDelete) {
        reportSuccess({ message: "SAML successfully unlinked" });
        modal.hide();
      }
    });
  };

  return (
    <DeleteConfirmationModal
      title="Unlink SAML SSO"
      onConfirm={handleDelete}
      isLoading={loading}
      isDismissable={!loading && !data?.samlDelete?.adminLogin}
      confirmationButtonLabel="Unlink"
      isConfirmationDisabled={!isValid}
      size="large"
    >
      <ConfirmationModalMessage>
        Are you sure you want to unlink current SAML integration?{" "}
        <strong>SAML managed users will be removed and this action cannot be undone.</strong>
      </ConfirmationModalMessage>

      <ConfirmationModalInput setIsValid={setIsValid} keyWord="unlink" />
    </DeleteConfirmationModal>
  );
});

export const showDisableConfirmation = () => show(SettingsSingleSignOnDisableSAML);
