import cx from "classnames";

import { ArrowBoxColored } from "components/icons/generated";

import EmptyState, { EmptyStateProps } from "../../ds/components/EmptyState";
import styles from "./styles.module.css";

type DropEmptyStateProps = EmptyStateProps & {
  isDragActive: boolean;
  dropCaption: string;
};

const DropEmptyState = ({
  isDragActive,
  className,
  dropCaption,
  caption,
  icon,
  ...restEmptyStateProps
}: DropEmptyStateProps) => (
  <EmptyState
    icon={isDragActive ? ArrowBoxColored : icon}
    caption={isDragActive ? dropCaption : caption}
    className={cx(className, styles.dropEmptyState, isDragActive ? styles.active : undefined)}
    {...restEmptyStateProps}
  />
);

export default DropEmptyState;
