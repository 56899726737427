import { useState } from "react";
import { useModal } from "@ebay/nice-modal-react";

import CodeEditor from "components/CodeEditor";
import Box from "ds/components/Box";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import Typography from "ds/components/Typography";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import ButtonNew from "ds/components/Button/New";
import Banner from "ds/components/Banner";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import styles from "./styles.module.css";

type TriggerWithCustomConfigDrawerProps = {
  onTrigger: (config: string) => void;
};

const TriggerWithCustomConfigDrawer = createDrawer(
  ({ onTrigger }: TriggerWithCustomConfigDrawerProps) => {
    const [config, setConfig] = useState("");

    const drawer = useModal();

    const handleTrigger = () => {
      onTrigger(config);
      drawer.hide();
    };

    const isDirty = config !== "";

    return (
      <DrawerForm isDirty={isDirty}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Runtime config details" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <Banner variant="info" margin="0 0 x-large 0">
            To type runtime config, please use YAML format.
          </Banner>
          <Typography tag="span" variant="p-t7">
            Runtime config
          </Typography>
          <Box direction="column" margin="large 0 0 0" className={styles.codeEditorWrapper}>
            <CodeEditor
              language="yaml"
              body={config}
              onChange={(value?: string) => setConfig(value ?? "")}
              options={{ automaticLayout: true }}
              rounded
            />
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <DrawerFooterActions>
            <DrawerCancelButton />
            <ButtonNew disabled={!config} variant="primary" onPress={handleTrigger}>
              Trigger
            </ButtonNew>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerForm>
    );
  }
);

export const showTriggerWithCustomConfigDrawer = createDrawerTrigger(TriggerWithCustomConfigDrawer);
