import { ChangeEvent, KeyboardEvent, ReactNode, memo, useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import cx from "classnames";
import capitalize from "lodash-es/capitalize";

import FormField from "ds/components/Form/Field";
import Input, { InputProps } from "ds/components/Input";
import Box from "ds/components/Box";
import Tag from "ds/components/Tag";
import Link from "ds/components/Link";
import { pluralize } from "shared/Pluralize";
import { AUTO_ATTACHMENT_KEY, LABEL_FOLDER_KEY } from "constants/labels";
import { TooltipInfoVariant } from "ds/components/TooltipInfo";
import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";
import { TooltipProps } from "ds/components/Tooltip/types";

import styles from "./styles.module.css";
import { isTagWrapped } from "./helpers";

type TFieldValues = Record<string, Record<"value", string>[]>;

type FormFieldTagsProps = {
  name: string;
  tagName: string;
  label: ReactNode;
  isOptional?: boolean;
  tooltipInfo?: string | ReactNode;
  tooltipInfoVariant?: TooltipInfoVariant;
  tooltipPlacement?: TooltipProps["placement"];
  tooltipMode?: TooltipProps["mode"];
  inputSize?: InputProps["size"];
  noMargin?: boolean;
  stopEnterPropagation?: boolean;
  onInputChange?: (value: string) => void;
} & Pick<AnalyticsCommonProps, "analyticsPage" | "analyticsProps">;

const PLURAL_VALUE = 2;

const FormFieldTags = ({
  name,
  label,
  isOptional,
  tagName,
  tooltipInfo,
  tooltipInfoVariant,
  tooltipPlacement,
  inputSize = "regular",
  analyticsPage,
  analyticsProps,
  noMargin,
  onInputChange,
  stopEnterPropagation,
  tooltipMode,
}: FormFieldTagsProps) => {
  const [localError, setLocalError] = useState<undefined | string>(undefined);
  const [isTagsListExpanded, setTagsListExpanded] = useState(false);
  const [isToggleControlVisible, setToggleControlVisibility] = useState(false);
  const [tagFieldValue, setTagFieldValue] = useState("");
  const tagsListRef = useRef<HTMLDivElement>(null);
  const lastTagRef = useRef<HTMLDivElement>(null);

  const trackSegmentEvent = useAnalytics({
    page: analyticsPage,
    defaultCallbackTrackProperties: analyticsProps,
  });

  const {
    formState: { errors },
    getValues,
    register,
  } = useFormContext<TFieldValues>();

  const { fields, append, remove } = useFieldArray<TFieldValues>({
    name,
  });

  const onKeyDown = (e: KeyboardEvent) => {
    if (stopEnterPropagation && e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleCreateTag = (e: KeyboardEvent) => {
    const tags = getValues(name);
    const trimmedTagFieldValue = tagFieldValue.trim();

    setLocalError(undefined);

    /**
     * Tags local validation.
     * the validation is needed here to validate a tag value before adding to the list
     * BUT not to block the form submit
     */
    if (tagFieldValue.length > 0 && !trimmedTagFieldValue) {
      setLocalError(`Empty ${tagName} is not allowed`);
    }

    if (trimmedTagFieldValue === LABEL_FOLDER_KEY) {
      setLocalError(`'${LABEL_FOLDER_KEY}' is a reserved keyword`);
    }

    if (trimmedTagFieldValue === AUTO_ATTACHMENT_KEY) {
      setLocalError(`'${AUTO_ATTACHMENT_KEY}' is a reserved keyword`);
    }

    if (tags.some((item) => item.value === trimmedTagFieldValue)) {
      setLocalError(`${capitalize(tagName)} "${trimmedTagFieldValue}" already added`);
    }

    // set new tag
    if (e.key === "Enter" && tagFieldValue && !errors?.[name] && !localError) {
      append({ value: trimmedTagFieldValue });
      setTagFieldValue("");
      onInputChange?.("");

      if (analyticsPage) {
        trackSegmentEvent?.("Add new label");
      }
    }
  };

  const handleRemoveTag = (index: number) => () => {
    remove(index);
  };

  const toggleTagsList = () => {
    setTagsListExpanded(!isTagsListExpanded);
  };

  const handleUpdateTag = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target.value;
    setTagFieldValue(value);
    onInputChange?.(value);
  };

  useEffect(() => {
    if (tagsListRef.current && lastTagRef.current) {
      if (isTagWrapped(tagsListRef.current, lastTagRef.current)) {
        setToggleControlVisibility(true);
      } else {
        setToggleControlVisibility(false);
        setTagsListExpanded(false);
      }
    }
  }, [fields]);

  return (
    // TODO: add the Box wrapper
    <>
      <FormField
        isOptional={isOptional}
        label={label}
        error={errors?.[name]?.message || localError}
        helperText={tagFieldValue.length > 0 ? "Press Enter to add" : ""}
        tooltipInfo={tooltipInfo}
        tooltipInfoVariant={tooltipInfoVariant}
        tooltipPlacement={tooltipPlacement}
        tooltipMode={tooltipMode}
        noMargin={noMargin}
      >
        {({ ariaInputProps }) => (
          <Input
            size={inputSize}
            placeholder={`Add new ${name}`}
            onKeyUp={handleCreateTag}
            onKeyDown={onKeyDown}
            onChange={handleUpdateTag}
            error={!!errors?.[name]?.message || !!localError}
            value={tagFieldValue}
            {...ariaInputProps}
          />
        )}
      </FormField>
      {fields.length > 0 && (
        <Box direction="column" align="start">
          <Box
            ref={tagsListRef}
            direction="row"
            className={cx(styles.tags, {
              [styles.expanded]: isTagsListExpanded,
            })}
          >
            {fields.map((tag, i) => (
              <Tag
                key={tag.id}
                tag={tag.value}
                className={styles.tag}
                onRemove={handleRemoveTag(i)}
                {...register(`${name}.${i}.value`)}
                ref={i === fields.length - 1 ? lastTagRef : null}
              />
            ))}
          </Box>

          {isToggleControlVisible && fields.length > 2 && (
            <Link onClick={toggleTagsList}>
              {isTagsListExpanded
                ? `Hide ${pluralize(tagName, PLURAL_VALUE)}`
                : `Show all ${pluralize(tagName, PLURAL_VALUE)}`}
            </Link>
          )}
        </Box>
      )}
    </>
  );
};

export default memo(FormFieldTags);
