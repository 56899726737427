import styles from "./styles.module.css";
import DeltaCountsCell from "./Cell";
import { deltaConfig } from "./constants";
import { DeltaType } from "./types";

type DeltaCountsACDBarProps = {
  skipEmpty?: boolean;
  addCount: number;
  changeCount: number;
  deleteCount: number;
};

const DeltaCountsACDBar = ({
  skipEmpty,
  addCount,
  changeCount,
  deleteCount,
}: DeltaCountsACDBarProps) => (
  <div className={styles.group}>
    {(!skipEmpty || addCount > 0) && <DeltaCountsCell {...deltaConfig[DeltaType.ADD](addCount)} />}
    {(!skipEmpty || changeCount > 0) && (
      <DeltaCountsCell {...deltaConfig[DeltaType.CHANGE](changeCount)} />
    )}
    {(!skipEmpty || deleteCount > 0) && (
      <DeltaCountsCell {...deltaConfig[DeltaType.DELETE](deleteCount)} />
    )}
  </div>
);

export default DeltaCountsACDBar;
