import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import {
  BillingTierFeature,
  Blueprint,
  BlueprintParseResult,
  BlueprintState,
} from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import MetaInfoList from "components/MetaInfoList";
import { Space, Clock } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTitle from "hooks/useTitle";
import PageInfo from "components/PageWrapper/Info";
import ViewHeader from "components/ViewHeader";
import Box from "ds/components/Box";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";
import { hasSpaceManageAccess } from "utils/user";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import ButtonNew from "ds/components/Button/New";

import BlueprintStateBadge from "../Blueprints/StateBadge";
import { GET_BLUEPRINT, VALIDATE_BLUEPRINT_TEMPLATE } from "./gql";
import { UPDATE_BLUEPRINT } from "../Blueprints/gql";
import { showBlueprintDetailsDrawer } from "./DetailsDrawer";
import { showPublishConfirmationModal } from "./PublishConfirmationModal";
import { FILTERS_ORDER_SETTINGS_KEY, INITIAL_TEMPLATE } from "../Blueprints/constants";
import styles from "./styles.module.css";
import BlueprintEditor from "./Editor";
import { SpacesContext } from "../SpacesProvider";
import { showDeleteConfirmation } from "../Blueprints/ListItemDropdown/DeleteConfirmation";
import useTierFeature from "../hooks/useTierFeature";
import BlueprintsFeatureGateTooltip from "../Blueprints/FeatureGate/Tooltip";
import BlueprintFeatureGateCallout from "../Blueprints/FeatureGate/Callout";
import { BlueprintActions } from "../Blueprints/FeatureGate/types";
import { showCreateBlueprintDrawer } from "../Blueprints/CreateBlueprintDrawer";
import { showTemplatePreviewDrawer } from "../Blueprints/TemplatePreview";

const BlueprintPage = () => {
  const navigate = useNavigate();
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);

  const { blueprintId } = useParams<{ blueprintId: string }>();

  const [editorBody, setEditorBody] = useState<string>(INITIAL_TEMPLATE);
  const { reportSuccess, onError } = useTypedContext(FlashContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const [errors, setErrors] = useState<string[]>([]);

  const { error, loading, data } = useQuery<{
    blueprint: Blueprint;
    blueprintSchema: string;
  }>(GET_BLUEPRINT, {
    variables: {
      id: blueprintId,
    },
    onError,
    // APOLLO CLIENT UPDATE
    nextFetchPolicy: "cache-first",
  });

  const [validateTemplate] = useMutation<{
    blueprintParseTemplate: BlueprintParseResult;
  }>(VALIDATE_BLUEPRINT_TEMPLATE);

  const editorHasUnsavedChanges = editorBody !== data?.blueprint?.rawTemplate;

  const [updateBlueprint, { loading: saveLoading }] = useMutation<{ blueprintUpdate: Blueprint }>(
    UPDATE_BLUEPRINT,
    {
      onError,
    }
  );

  const handleUpdateBlueprint = (publish?: boolean) => {
    if (data?.blueprint) {
      updateBlueprint({
        variables: {
          id: data.blueprint.id,
          input: {
            name: data.blueprint.name,
            description: data.blueprint.description,
            labels: data.blueprint.labels,
            space: data.blueprint.space.id,
            state: publish ? BlueprintState.Published : data.blueprint.state,
            template: editorBody,
          },
        },
      })
        .then(({ data }) => {
          if (data?.blueprintUpdate?.name) {
            reportSuccess({
              message: `Blueprint "${data.blueprintUpdate.name}" is successfully saved`,
            });
          }
        })
        .catch(onError);
    }
  };

  const handleValidateTemplate = (template: string) => {
    setErrors([]);

    return validateTemplate({
      variables: {
        template,
      },
    })
      .then(({ data }) => {
        setErrors(data?.blueprintParseTemplate?.errors || []);

        return {
          inputs: data?.blueprintParseTemplate?.inputs || [],
          errors: data?.blueprintParseTemplate?.errors || [],
        };
      })
      .catch(onError);
  };

  const handleSaveTemplate = useCallback(async () => {
    await handleUpdateBlueprint();

    handleOpenPreviewDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorBody, data?.blueprint]);

  const handleOpenPreviewDrawer = () => {
    showTemplatePreviewDrawer({
      item: blueprint,
      validate: isPublished ? undefined : () => handleValidateTemplate(editorBody),
      isPublished,
      onEditMetadata: handleOpenEditDrawer,
    });
  };

  const handleEditorChange = (value?: string) => {
    setEditorBody(value || "");
  };

  const handleOpenEditDrawer = () => {
    showCreateBlueprintDrawer({ blueprint: data?.blueprint });
  };

  const handleOpenCloneDrawer = () => {
    showCreateBlueprintDrawer({ blueprint: data?.blueprint, isCloneMode: true });
  };

  const handleOpenDetailsDrawer = () => {
    if (data?.blueprint) {
      showBlueprintDetailsDrawer({
        item: data.blueprint,
        ...(canManageBlueprint && { onEdit: handleOpenEditDrawer }),
      });
    }
  };

  useEffect(() => {
    if (data?.blueprint?.rawTemplate) {
      setEditorBody(data.blueprint.rawTemplate);
      handleValidateTemplate(data.blueprint.rawTemplate);
    } else {
      handleValidateTemplate(editorBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.blueprint?.rawTemplate]);

  useTitle(`Blueprint · ${data?.blueprint?.name}`);

  useBreadcrumbs(
    [
      {
        title: "Blueprints",
        link: getBreadcrumbsBackUrl("/blueprints", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: data?.blueprint?.name || "",
      },
    ],
    [data?.blueprint?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.blueprint) {
    return <PageLoading />;
  }

  if (!data?.blueprint) {
    return <NotFoundPage />;
  }

  const blueprint = data.blueprint;
  const blueprintSchema = data.blueprintSchema;
  const isPublished = blueprint.state === BlueprintState.Published;
  const canManageBlueprint =
    hasSpaceManageAccess(blueprint.space.accessLevel) && !blueprint.deleted;

  const handleDeleteClick = () => {
    showDeleteConfirmation({ name: blueprint.name, id: blueprint.id }).then(() =>
      navigate("/blueprints")
    );
  };

  const onPublishConfirmation = () => {
    showPublishConfirmationModal({
      onPublish: () => handleUpdateBlueprint(true),
    });
  };

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{blueprint.name}</ViewHeaderTitle>
            <BlueprintStateBadge state={blueprint.state} />
          </ViewHeaderWrapper>

          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <ButtonNew variant="secondary" onPress={handleOpenDetailsDrawer}>
              Details
            </ButtonNew>

            {canManageBlueprint && (
              <DropdownMenuEllipsis tooltip="Blueprint actions" dotsSize="medium">
                {isFeatureActive && (
                  <DropdownMenuItem onAction={handleOpenEditDrawer}>Edit metadata</DropdownMenuItem>
                )}

                {isFeatureActive && (
                  <DropdownMenuItem onAction={handleOpenCloneDrawer}>Clone</DropdownMenuItem>
                )}

                <DropdownMenuItem onAction={handleDeleteClick} danger>
                  Delete
                </DropdownMenuItem>
              </DropdownMenuEllipsis>
            )}
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>

        <ViewHeaderScrollCollapse>
          <MetaInfoList>
            <MetaInfoListItem
              icon={Space}
              linkText={blueprint.space.name}
              href={`/spaces/${blueprint.space.id}`}
            />
            <MetaInfoListItem icon={Clock}>
              <Timestamp timestamp={blueprint.updatedAt} />
            </MetaInfoListItem>
          </MetaInfoList>
        </ViewHeaderScrollCollapse>
      </ViewHeader>
      <BlueprintFeatureGateCallout />
      <Box direction="column" grow="1" fullWidth relative>
        <PageInfo title="Template body">
          {!(isPublished && !hasEntityCreateAccess) && (
            <ButtonNew variant="secondary" size="medium" onPress={handleOpenPreviewDrawer}>
              {isPublished ? "Create stack" : "Preview"}
            </ButtonNew>
          )}

          {!isPublished && canManageBlueprint && (
            <>
              <BlueprintsFeatureGateTooltip
                action={BlueprintActions.Edit}
                on={({ isDisabled, ...props }) => (
                  <ButtonNew
                    {...props}
                    variant="secondary"
                    size="medium"
                    onPress={handleSaveTemplate}
                    disabled={saveLoading || isDisabled || !editorHasUnsavedChanges}
                    loading={saveLoading}
                  >
                    Save
                  </ButtonNew>
                )}
              />

              <BlueprintsFeatureGateTooltip
                action={BlueprintActions.Publish}
                on={({ isDisabled, ...props }) => (
                  <ButtonNew
                    {...props}
                    variant="primary"
                    size="medium"
                    onPress={onPublishConfirmation}
                    disabled={isDisabled || errors.length > 0}
                  >
                    Publish
                  </ButtonNew>
                )}
              />
            </>
          )}
        </PageInfo>

        <Box direction="column" grow="1" fullWidth relative className={styles.editorWrapper}>
          <BlueprintEditor
            body={editorBody}
            schema={blueprintSchema}
            onChange={handleEditorChange}
            readOnly={isPublished || blueprint.deleted}
          />
        </Box>
      </Box>
    </>
  );
};

export default BlueprintPage;
