import { NetworkStatus, useQuery } from "@apollo/client";
import { useEffect } from "react";

import Box from "ds/components/Box";
import Link from "ds/components/Link";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import StackStateBadge from "components/StackStateBadge";
import EmptyState from "ds/components/EmptyState";
import { EmptystateStarColored } from "components/icons/generated";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import MissingDataBanner from "components/MissingDataBanner";

import DashboardWidgetsEmptyStateStacks from "../EmptyStateStacks";
import { GetFavoriteStacks } from "./types";
import { GET_FAVORITE_STACKS } from "./gql";
import DashboardWidgetsFavoriteStacksSkeleton from "./Skeleton";
import { Widget } from "../../types";
import usePollInterval from "../../usePollInterval";

const LIMIT = 5;

const DashboardWidgetsFavoriteStacks = () => {
  const pollInterval = usePollInterval();

  const { loading, data, error, refetch, startPolling, stopPolling, networkStatus } =
    useQuery<GetFavoriteStacks>(GET_FAVORITE_STACKS, {
      onError() {
        stopPolling();
      },
    });

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;

  const isLoading = loading && !data?.metrics?.favouriteStacks;

  const favouriteStacks = data?.metrics?.favouriteStacks?.slice(0, LIMIT);

  const isEmpty = !isLoading && !favouriteStacks?.length;

  const hasStacks = !!data?.metrics?.hasStacks;

  if (showErrorBanner) {
    return (
      <MissingDataBanner
        text="Couldn’t load favorite stacks. Please try to refresh or come back later. "
        refreshHandler={refetch}
        refreshLoading={loading}
        fullWidth
      />
    );
  }

  if (isLoading) {
    return <DashboardWidgetsFavoriteStacksSkeleton />;
  }

  return (
    <Box direction="column" fullWidth>
      {!hasStacks && <DashboardWidgetsEmptyStateStacks widget={Widget.FavoriteStacks} />}

      {hasStacks && isEmpty && (
        <TileWrapper>
          <Box padding="small" fullWidth justify="center">
            <EmptyState
              title="You don’t have any favorite stacks yet"
              icon={EmptystateStarColored}
              caption="Navigate to Stacks, select the ones you want to keep better track of and add them to favourites by clicking on the three dots menu and choosing “Add to favorites."
            >
              {/* TODO: [dashboard] userflow trigger */}
              {/* <Box gap="medium">
                <Button variant="primary">Show me how</Button>
              </Box> */}
            </EmptyState>
          </Box>
        </TileWrapper>
      )}

      {!isEmpty && (
        <Box direction="column" gap="medium">
          {favouriteStacks?.map(({ name, slug, state }, i) => (
            <TileWrapper key={i}>
              <TileContent>
                <Box justify="between" align="center" gap="medium">
                  <TextEllipsis tooltip={name}>
                    {(props) => (
                      <Link listLink variant="secondary" to={`/stack/${slug}`}>
                        <Typography {...props} tag="span" variant="p-body2">
                          {name}
                        </Typography>
                      </Link>
                    )}
                  </TextEllipsis>

                  <StackStateBadge state={state} />
                </Box>
              </TileContent>
            </TileWrapper>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DashboardWidgetsFavoriteStacks;
