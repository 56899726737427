import { useModal, create, show } from "@ebay/nice-modal-react";
import { useMutation, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { Module, Version } from "types/generated";

type DeleteConfirmationProps = {
  id: Version["id"];
  moduleId: Module["id"];
  number: Version["number"];
};

const DELETE_VERSION = gql`
  mutation DeleteVersion($moduleId: ID!, $id: ID!) {
    versionDelete(module: $moduleId, id: $id) {
      id
    }
  }
`;

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  moduleId,
  number,
}: DeleteConfirmationProps) {
  const modal = useModal();
  const navigate = useNavigate();

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [deleteVersion, { loading }] = useMutation(DELETE_VERSION, {
    variables: { moduleId, id },
    refetchQueries: ["GetModule", "GetModuleVersionsNext", "GetPaginatedModuleVersions"],
    awaitRefetchQueries: true,
  });

  const handleVersionDelete = () => {
    deleteVersion()
      .then(() => {
        modal.resolve();
        modal.hide();
        reportSuccess({ message: "Version was successfully deleted" });
        navigate(`/module/${moduleId}`, { replace: true });
      })
      .catch(onError);
  };

  return (
    <DeleteConfirmationModal
      title="Delete version"
      name={`version ${number}`}
      onConfirm={handleVersionDelete}
      isLoading={loading}
      isDismissable={!loading}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
