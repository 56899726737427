import ButtonIconNew from "ds/components/ButtonIcon/New";
import { Cross } from "components/icons/generated";

type BulkActionsDrawerItemDismissProps = {
  onDismiss: () => void;
  tooltip?: string;
};

const BulkActionsDrawerItemDismiss = ({
  onDismiss,
  tooltip = "Unselect",
}: BulkActionsDrawerItemDismissProps) => {
  return (
    <ButtonIconNew icon={Cross} onPress={onDismiss} variant="ghostDanger">
      {tooltip}
    </ButtonIconNew>
  );
};

export default BulkActionsDrawerItemDismiss;
