import { ReactNode } from "react";
import { create, show, useModal } from "@ebay/nice-modal-react";

import ConfirmationModal from "ds/components/ConfirmationModal";
import { ConfirmationModalProps } from "ds/components/ConfirmationModal/types";

type MFAConfirmationModalProps = {
  title: string;
  content: ReactNode;
  mainActionText?: string;
  mainActionVariant?: ConfirmationModalProps["confirmationButtonVariant"];
  onConfirm: () => void;
};

const MFAConfirmationModal = create(function MFAConfirmationModal({
  onConfirm,
  title,
  content,
  mainActionText,
  mainActionVariant,
}: MFAConfirmationModalProps) {
  const modal = useModal();

  const handleOnConfirm = () => {
    onConfirm();
    modal.resolve();
    modal.hide();
  };

  return (
    <ConfirmationModal
      title={title}
      confirmationButtonLabel={mainActionText}
      confirmationButtonVariant={mainActionVariant}
      onConfirm={handleOnConfirm}
    >
      {content}
    </ConfirmationModal>
  );
});

export const showMFAConfirmationModal = (props: MFAConfirmationModalProps) =>
  show(MFAConfirmationModal, props);
