import { BillingTier } from "types/generated";

export const TIER_PLANS_LABELS = {
  [BillingTier.Free]: "Free",
  [BillingTier.Cloud]: "Cloud",
  [BillingTier.Enterprise]: "Enterprise SaaS",
  [BillingTier.V3Free]: "Free",
  [BillingTier.V3Cloud]: "Cloud",
  [BillingTier.V4Free]: "Free",
  [BillingTier.V4Starter]: "Starter",
  [BillingTier.V4StarterPlus]: "Starter Plus",
  [BillingTier.V4Business]: "Business",
  [BillingTier.V4Enterprise]: "Enterprise",
};
