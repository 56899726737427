import { js_beautify } from "js-beautify";

export const escapeRegex = (str: string) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");

export const truncate = (str: string, n: number) =>
  str.length > n ? `${str.substr(0, n - 1)}...` : str;

export const capitalizeFirstLetter = (str: string) => str[0].toUpperCase() + str.slice(1);
export const containsLowercaseLettersOnly = (str: string) => /^[a-z]+$/.test(str);

export const beautifyCode = (code: string) =>
  js_beautify(code, {
    indent_size: 2,
  });
