import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Tab from "ds/components/Tab";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import { isSaasDistribution } from "utils/distribution";

import { VCS_INTEGRATIONS_LIST_PATH } from "../../Integrations/constants";

const isSaas = isSaasDistribution();

type VCSPageHeaderProps = {
  children?: ReactNode;
};

const VCSPageHeader = ({ children }: VCSPageHeaderProps) => {
  const { viewer } = useTypedContext(AccountContext);

  return (
    <ViewHeader firstLevel>
      <Box direction="column" gap="medium" fullWidth>
        <Box direction="row" justify="between">
          <ViewHeaderTitle titleSize="p-t3">Source code</ViewHeaderTitle>

          <ViewHeaderWrapper direction="row">{children}</ViewHeaderWrapper>
        </Box>

        <Box gap="medium">
          <Tab to={VCS_INTEGRATIONS_LIST_PATH} id="vcs_integrations" label="Integrations" />
          {/* FYI: the access check is copied from the side navigation, that will be deleted when the MUltiple VCS global release */}
          {viewer.admin && isSaas && (
            <Tab to="/vcs-agent-pools" id="vcs_agent_pools" label="VCS agent pools" />
          )}
        </Box>
      </Box>
    </ViewHeader>
  );
};

export default VCSPageHeader;
