import { Draggable, Droppable } from "react-beautiful-dnd";
import { useCallback } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import DraggableItemHandle from "ds/components/DraggableItem/Handle";
import DraggableItem from "ds/components/DraggableItem";
import { ArrowBoxColored } from "components/icons/generated";
import DropEmptyState from "components/DropEmptyState";

import styles from "./styles.module.css";

type CustomizeViewItemsProps<T extends string> = {
  type: "hidden" | "visible";
  title: string;
  items: T[];
  disabledItems?: T[];
  getItemLabel: (item: T) => string;
  isDropDisabled?: boolean;
  isDragActive?: boolean;
};

const CustomizeViewItems = <T extends string>({
  type,
  title,
  items,
  disabledItems = [],
  getItemLabel,
  isDropDisabled,
  isDragActive,
}: CustomizeViewItemsProps<T>) => {
  const isDisabled = useCallback((item: T) => disabledItems.includes(item), [disabledItems]);

  return (
    <Box
      fullWidth
      direction="column"
      padding={items.length > 0 ? "large large medium large" : "large"}
      className={styles.card}
    >
      <Typography tag="h3" variant="p-t7" color="secondary" transform="uppercase">
        {title}
      </Typography>
      <Droppable droppableId={type} isDropDisabled={isDropDisabled}>
        {(droppableProvided) => (
          <Box
            direction="column"
            ref={droppableProvided.innerRef}
            margin="medium 0 0 0"
            className={isDropDisabled ? styles.dropDisabled : undefined}
            {...droppableProvided.droppableProps}
          >
            {items.map((item, index) => (
              <Draggable
                draggableId={item}
                index={index}
                key={item}
                isDragDisabled={isDisabled(item)}
              >
                {(provided, snapshot) => {
                  return (
                    <DraggableItem
                      className={styles.draggable}
                      ref={provided.innerRef}
                      size="small"
                      margin="0 0 medium 0"
                      dragging={snapshot.isDragging}
                      dropping={snapshot.isDropAnimating}
                      disabled={isDropDisabled || isDisabled(item)}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DraggableItemHandle variant="p-body2">
                        {getItemLabel(item)}
                      </DraggableItemHandle>
                    </DraggableItem>
                  );
                }}
              </Draggable>
            ))}
            {items.length !== 0 && droppableProvided.placeholder}

            {items.length === 0 && (
              <DropEmptyState
                isDragActive={!!isDragActive}
                padding="large"
                icon={ArrowBoxColored}
                caption="Drag & Drop a feature from the section above if you want to hide it"
                dropCaption="You can drop the feature here"
              />
            )}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default CustomizeViewItems;
