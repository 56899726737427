import { type ReactNode, RefObject, useRef } from "react";
import cx from "classnames";

import Box from "ds/components/Box";
import { AriaOnlyText } from "components/AriaOnlyText";

import SlideTransition from "./SlideTransition";
import styles from "./styles.module.css";
import BulkActionsFloatingBarSpacer from "./Spacer";
import { useKeyboardShortcutFocus } from "./useKeyboardShortcutFocus";

type BulkActionsFloatingBarWrapperProps = {
  children: ReactNode;
  isVisible: boolean;
  isFixed?: boolean;
  virtualizedListContainerRef?: RefObject<HTMLElement>;
};

const BulkActionsFloatingBarWrapper = ({
  children,
  isVisible,
  isFixed,
  virtualizedListContainerRef,
}: BulkActionsFloatingBarWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { focusElementRef, sourceElement } = useKeyboardShortcutFocus({
    wrapperElement: ref.current,
  });

  return (
    <>
      <SlideTransition visible={isVisible}>
        <Box
          ref={ref}
          className={cx(styles.wrapper, {
            [styles.fixed]: isFixed,
          })}
          justify="center"
        >
          <AriaOnlyText ref={focusElementRef} tabIndex={-1}>
            {sourceElement ? "Press Control + 2 to move focus back to the previous element" : ""}
          </AriaOnlyText>
          <Box className={styles.panel} direction="column">
            {children}
          </Box>
        </Box>
      </SlideTransition>
      <BulkActionsFloatingBarSpacer
        isVisible={isVisible}
        floatingBlockRef={ref}
        virtualizedListContainerRef={virtualizedListContainerRef}
      />
    </>
  );
};

export default BulkActionsFloatingBarWrapper;
