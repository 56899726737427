import { gql, TypedDocumentNode } from "@apollo/client";

import { OidcSettings } from "types/generated";

type FetchOIDCDelete = { oidcDelete: Pick<OidcSettings, "clientId"> };
export const DELETE_OIDC_SSO: TypedDocumentNode<FetchOIDCDelete> = gql`
  mutation SSODeleteOIDC {
    oidcDelete {
      clientId
    }
  }
`;
