import { ReactNode, useId } from "react";
import cx from "classnames";

import styles from "./styles.module.css";
import Segment from "./Segment";
import Box, { BoxProps } from "../Box";

export type SegmentedControlOption<T extends string> = {
  label: ReactNode;
  value: T;
  disabled?: boolean;
  tooltip?: ReactNode;
};

export type SegmentedControlProps<T extends string> = {
  options: SegmentedControlOption<T>[];
  value: T;
  onChange: (value: T) => void;
  margin?: BoxProps["margin"];
  className?: string;
  optionClassName?: string;
};

function SegmentedControl<T extends string>({
  options,
  value,
  onChange,
  margin,
  className,
  optionClassName,
}: SegmentedControlProps<T>) {
  const controlName = useId();

  return (
    <Box className={cx(styles.wrapper, className)} margin={margin}>
      {options.map((option) => (
        <Segment
          className={optionClassName}
          key={option.value}
          controlName={controlName}
          label={option.label}
          value={option.value}
          selected={option.value === value}
          onChange={onChange}
          disabled={option.disabled}
          tooltip={option.tooltip}
        />
      ))}
    </Box>
  );
}

export default SegmentedControl;
