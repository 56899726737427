import WarningContext from "components/WarningContext";
import ButtonNew, { ButtonProps } from "ds/components/Button/New";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedContext from "hooks/useTypedContext";

import { showNewStackFooterConfirmation } from "./Confirmation";

type NewStackFooterContinueButtonProps = {
  onClickHandler: () => void;
} & ButtonProps;

const NewStackFooterContinueButton = ({
  onClickHandler,
  children,
  ...buttonProps
}: NewStackFooterContinueButtonProps) => {
  const { warning, clearWarning } = useTypedContext(WarningContext);
  const onContinue = () => {
    onClickHandler();

    if (warning) {
      clearWarning();
    }
  };

  const onModalShow = () => {
    showNewStackFooterConfirmation({
      onConfirm: onContinue,
      warning,
      confirmationButtonLabel: children?.toString(),
    });
  };

  return (
    <ButtonNew
      {...buttonProps}
      analyticsPage={AnalyticsPageStack.StackNew}
      onPress={warning ? onModalShow : onContinue}
    >
      {children}
    </ButtonNew>
  );
};

export default NewStackFooterContinueButton;
