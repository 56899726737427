import { useMemo } from "react";
import fromUnixTime from "date-fns/fromUnixTime";
import subDays from "date-fns/subDays";
import getUnixTime from "date-fns/getUnixTime";
import addDays from "date-fns/addDays";
import { useHref } from "react-router-dom";

import DropdownMenuItem from "ds/components/DropdownMenu/Item";

type RunOriginDropdownLinkProps = {
  runId: string;
  runCreatedAt: number;
};

const RunOriginDropdownLink = ({ runId, runCreatedAt }: RunOriginDropdownLinkProps) => {
  const startDate = useMemo(
    () => getUnixTime(subDays(fromUnixTime(runCreatedAt), 3)),
    [runCreatedAt]
  );

  const endDate = useMemo(
    () => getUnixTime(addDays(fromUnixTime(runCreatedAt), 3)),
    [runCreatedAt]
  );

  const dateRangeParam = {
    startDate,
    endDate,
    label: "",
  };
  const dateRange = `${btoa(JSON.stringify(dateRangeParam))}`;
  const path = useHref({
    pathname: `/runs`,
    search: `?dateRange=${dateRange}&zoomTarget=${btoa(JSON.stringify(runId))}`,
  });

  return (
    <DropdownMenuItem
      tooltip="A visualization of the action(s) that have triggered this run. It also shows all of the
    related runs."
      tooltipWidthMode="maxWidthSm"
      tooltipPlacement="left"
      tooltipDelay={0}
      href={path}
    >
      Run origin
    </DropdownMenuItem>
  );
};

export default RunOriginDropdownLink;
