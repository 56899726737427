import { LinkUnlinked } from "components/icons/generated";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";

type VCSDetachedIndicatorProps = {
  className?: string;
};

const VCSDetachedIndicator = ({ className }: VCSDetachedIndicatorProps) => {
  return (
    <Tooltip
      widthMode="maxWidthXSm"
      textAlign="center"
      on={(props) => <Icon {...props} className={className} src={LinkUnlinked} color="danger" />}
    >
      Source code integration has been detached
    </Tooltip>
  );
};

export default VCSDetachedIndicator;
