import EmptyState from "ds/components/EmptyState";
import { EmptystatePickaxeColored } from "components/icons/generated";
import useTypedContext from "hooks/useTypedContext";
import { isSaasDistribution } from "utils/distribution";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";

import { SpacesContext } from "../SpacesProvider";

const isSaas = isSaasDistribution();

const adminEmptyStateTitle = isSaas
  ? "Create your first private worker pool"
  : "Create your first worker pool";

const adminEmptyStateCaption = isSaas ? (
  <>
    There is a public worker pool above, but Spacelift also allows you to create your own private
    worker pools. Find out how to do it in the documentation.
  </>
) : (
  <>
    Before you can create any stacks or modules, you need to create a worker pool.
    <br />
    Find out how to do it in the documentation.
  </>
);

const nonAdminEmptyStateTitle = isSaas ? "No private worker pools found" : "No worker pools found";

const nonAdminEmptyStateCaption = isSaas ? (
  <>
    There are currently no private worker pools that you have access to.
    <br /> If this is not correct, please speak to a Spacelift administrator to grant you the
    correct access or to create a worker pool.
  </>
) : (
  <>
    There are currently no worker pools that you have access to.
    <br /> If this is not correct, please speak to a Spacelift administrator to grant you the
    correct access or to create a worker pool.
  </>
);

const WorkerPoolsEmpty = () => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  return (
    <EmptyState
      title={hasEntityCreateAccess ? adminEmptyStateTitle : nonAdminEmptyStateTitle}
      icon={EmptystatePickaxeColored}
      caption={hasEntityCreateAccess ? adminEmptyStateCaption : nonAdminEmptyStateCaption}
    >
      <DocumentationButton to={getDocsUrl("/concepts/worker-pools")} label="Documentation" />
    </EmptyState>
  );
};

export default WorkerPoolsEmpty;
