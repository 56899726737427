import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";
import BulkActionsSelectAllCheckbox from "components/BulkActionsPatterns/SelectAllCheckbox";
import { makeSortableHeaderOption } from "components/Filters/SortHeader/helpers";
import Icon from "ds/components/Icon";
import { FlagFilled } from "components/icons/generated";
import Tooltip from "ds/components/Tooltip";

import {
  COLUMN_GAP,
  COLUMN_ORDER,
  COLUMN_ORDER_WITHOUT_PRIORITY,
  FilterItemKeys,
  FILTER_ITEMS_DICTIONARY,
} from "../constants";

type WorkerPoolQueuedRunsFiltersLayoutHeader = {
  isPublic?: boolean;
  allSelected: boolean;
  onSelectAll: () => void;
  onResetAll: () => void;
};

const WorkerPoolQueuedRunsFiltersLayoutHeader = ({
  isPublic,
  allSelected,
  onResetAll,
  onSelectAll,
}: WorkerPoolQueuedRunsFiltersLayoutHeader) => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();

  return (
    <FiltersSortHeaderWrapper
      columnOrder={isPublic ? COLUMN_ORDER_WITHOUT_PRIORITY : COLUMN_ORDER}
      columnGap={COLUMN_GAP}
    >
      <FiltersSortHeaderStaticColumn>
        <BulkActionsSelectAllCheckbox
          allSelected={allSelected}
          onResetAll={onResetAll}
          onSelectAll={onSelectAll}
        />
      </FiltersSortHeaderStaticColumn>
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.POSITION, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      >
        <Tooltip on={(props) => <span {...props}>#</span>}>Position</Tooltip>
      </FiltersSortHeaderSortableColumn>
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.STATE, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.TYPE, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.STACK_NAME, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.COMMIT, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
      {!isPublic && (
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(FilterItemKeys.IS_PRIORITIZED, FILTER_ITEMS_DICTIONARY)}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={onItemClick}
          buttonAriaLabel="Priority"
        >
          <Tooltip on={(props) => <Icon src={FlagFilled} color="disabled" {...props} />}>
            Priority
          </Tooltip>
        </FiltersSortHeaderSortableColumn>
      )}
      <FiltersSortHeaderSortableColumn
        option={makeSortableHeaderOption(FilterItemKeys.CREATED_AT, FILTER_ITEMS_DICTIONARY)}
        querySortOption={querySortOption}
        querySortDirection={querySortDirection}
        onClick={onItemClick}
      />
    </FiltersSortHeaderWrapper>
  );
};

export default WorkerPoolQueuedRunsFiltersLayoutHeader;
