import { GitHubAppStatus, Maybe, VcsIntegrationDetails, VcsProvider } from "types/generated";
import { VCS_INTEGRATION_DETAILS_UNION } from "constants/vcs_providers";

import {
  URL_KEY_AZURE_DEVOPS,
  URL_KEY_BITBUCKET_CLOUD,
  URL_KEY_BITBUCKET_DATACENTER,
  URL_KEY_GITHUB,
  URL_KEY_GITHUB_ENTERPRISE,
  URL_KEY_GITLAB,
  VCS_INTEGRATIONS_PATH,
  VCS_PROVIDERS_URL_KEYS,
} from "./constants";

export const getVcsProviderByUrlKey = (urlKey?: string) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (urlKey) {
    case URL_KEY_AZURE_DEVOPS:
      return VcsProvider.AzureDevops;
    case URL_KEY_BITBUCKET_CLOUD:
      return VcsProvider.BitbucketCloud;
    case URL_KEY_BITBUCKET_DATACENTER:
      return VcsProvider.BitbucketDatacenter;
    case URL_KEY_GITLAB:
      return VcsProvider.Gitlab;
    case URL_KEY_GITHUB_ENTERPRISE:
      return VcsProvider.GithubEnterprise;
    case URL_KEY_GITHUB:
      return VcsProvider.Github;
    default:
      return undefined;
  }
};

export const getManageIntegrationURL = (provider: VcsProvider) =>
  `${VCS_INTEGRATIONS_PATH}/manage/${VCS_PROVIDERS_URL_KEYS[provider]}`;

export const getEditIntegrationURL = (provider: VcsProvider, id: string) =>
  `${VCS_INTEGRATIONS_PATH}/edit/${VCS_PROVIDERS_URL_KEYS[provider]}/${id}`;

export const isTestIntegrationConnectionAvailable = (details: Maybe<VcsIntegrationDetails>) => {
  if (
    details?.__typename === VCS_INTEGRATION_DETAILS_UNION.GITHUB_ENTERPRISE_INTEGRATION &&
    details.githubAppStatus === GitHubAppStatus.AppNotFound
  ) {
    return false;
  }

  return true;
};
