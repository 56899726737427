import { parseLicenseDate } from "components/SelfHostedLicenseWarning/utils";
import { DebugInfo } from "types/generated";

export const useLicensingDates = (debugInfo?: DebugInfo) => {
  const validityStartDate = debugInfo?.selfHostedLicence?.validity.begin;
  const validityEndDate = debugInfo?.selfHostedLicence?.validity.end;

  const startDate = parseLicenseDate(validityStartDate ?? undefined) ?? "Not set";

  const endDate = parseLicenseDate(validityEndDate ?? undefined) ?? "Not set";

  return { startDate, endDate };
};
