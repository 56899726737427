import { Link } from "react-router-dom";
import cx from "classnames";

import TextEllipsis from "ds/components/TextEllipsis";

import { BreadcrumbsContextItem } from "./types";
import styles from "./styles.module.css";

type BreadcrumbsItemProps = {
  item: BreadcrumbsContextItem;
};

const BreadcrumbsItem = ({ item }: BreadcrumbsItemProps) => {
  return (
    <TextEllipsis tooltip={item.title} tooltipPlacement="bottom" tooltipWidthMode="maxWidthXl">
      {(tooltipRefProps) => (
        <li
          {...tooltipRefProps}
          className={cx(styles.breadcrumbItem, tooltipRefProps.className, {
            [styles.fullWidth]: item.title.length < 100,
          })}
        >
          {!!item.link && (
            <Link className={styles.link} to={item.link}>
              {item.title}
            </Link>
          )}
          {!item.link && item.title}
        </li>
      )}
    </TextEllipsis>
  );
};

export default BreadcrumbsItem;
