import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { ScheduledTaskInput } from "types/generated";

import { CREATE_STACK_SCHEDULING_TASK } from "./gql";

type UseCreateScheduledTask = {
  stackId: string;
};

const useCreateScheduledTask = ({ stackId }: UseCreateScheduledTask) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackScheduledTaskCreate, { loading }] = useMutation(CREATE_STACK_SCHEDULING_TASK, {
    refetchQueries: ["GetStackScheduling"],
    awaitRefetchQueries: true,
    onError,
  });

  const createScheduledTask = useCallback(
    (input: ScheduledTaskInput, successCallback?: () => void) => {
      stackScheduledTaskCreate({
        variables: {
          stack: stackId,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackScheduledTaskCreate) {
            reportSuccess({
              message: `Task schedule was set up successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackId, stackScheduledTaskCreate]
  );

  return {
    createScheduledTask,
    loading,
  };
};

export default useCreateScheduledTask;
