export const ICON_SIZE = 16;
export const ICON_SPACE = 4;
export const RECT_ITEMS_PER_ROW = 10;
export const RECT_ITEM_SIZE = 24;
export const RECT_ITEM_SPACE = 4;
export const RECT_ITEM_BORDER_RADIUS = 8;
export const MOUSE_LEAVE_TIMEOUT = 200;
export const MIDDLE_GROUP_BORDER_SPACE = 1;
export const MIDDLE_GROUP_BORDER_RADIUS = 9;
export const TEXT_HEIGHT = 20;
export const TEXT_GAP = 5;
export const CONNECTION_SPACE = 10;
export const CONNECTION_DY = 5;
export const SUMMARY_ITEM_HEIGHT = 18;
export const SUMMARY_ITEM_WIDTH = 32;
export const SUMMARY_ITEM_BORDER_RADIUS = 6;
export const SUMMARY_GAP = 8;
