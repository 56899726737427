import { ManagedUserGroup } from "types/generated";
import LoadingIndicator from "ds/components/LoadingIndicator";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";

import useGetUser from "./useGetUser";
import UsersDetailsDrawerContent from "./Content";
import { UserManagementActivationStatus } from "../../types";

type UsersDetailsDrawerProps = {
  userId: string;
  activationStatus: UserManagementActivationStatus;
  managedUserGroups: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
};

const UsersDetailsDrawer = createDrawer(
  ({ userId, activationStatus, managedUserGroups }: UsersDetailsDrawerProps) => {
    const { user, loading, error } = useGetUser({
      id: userId,
    });

    if (error) {
      return null;
    }

    return (
      <DrawerSimple>
        {loading && <LoadingIndicator />}
        {user && (
          <UsersDetailsDrawerContent
            activationStatus={activationStatus}
            managedUserGroups={managedUserGroups}
            user={user}
          />
        )}
      </DrawerSimple>
    );
  }
);

export const showUsersDetailsDrawer = createDrawerTrigger(UsersDetailsDrawer);
