import Box from "ds/components/Box";
import { Datum } from "ds/components/Charts/PieChart/types";
import IconTile from "ds/components/IconTile";
import TileContent from "ds/components/Tile/Content";
import Typography from "ds/components/Typography";
import Counter from "ds/components/Counter";
import { IconComponent } from "types/Icon";
import { ChevronRight } from "components/icons/generated";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";

type DashboardResourcesChartTileContentProps = {
  data: Datum;
  logos: Record<string, { logo: IconComponent; color: string }>;
  hovered?: boolean;
  percentage: number;
};

const DashboardResourcesChartTileContent = ({
  data,
  logos,
  hovered,
  percentage,
}: DashboardResourcesChartTileContentProps) => {
  const vendor = logos[data.id];

  if (!vendor) {
    return null;
  }

  return (
    <TileContent gap="small">
      <Box align="start" gap="medium" justify="between" fullWidth>
        <Box align="center" gap="medium" className={styles.tileContent} fullWidth>
          <IconTile icon={vendor.logo} color="on-solid-primary" backgroundColor={vendor.color} />
          <Box direction="column" gap="small" align="start">
            <Box gap="medium" align="center">
              <Typography align="left" variant="p-t7" tag="span">
                {data.label}
              </Typography>
              <Counter
                variant={hovered ? "inversed" : "default"}
                size="small"
                count={`${percentage}%`}
              />
            </Box>
            <Typography variant="p-body3" tag="span" color="secondary">
              Total: {data.value}
            </Typography>
          </Box>
        </Box>
        <Box padding="small">
          <Icon src={ChevronRight} />
        </Box>
      </Box>
    </TileContent>
  );
};

export default DashboardResourcesChartTileContent;
