import { useRef } from "react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import { Notification } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import Typography from "ds/components/Typography";
import { Clock, Tick, CirclesGroup } from "components/icons/generated";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import NotificationSeverityBadge from "views/Account/Notifications/SeverityBadge";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemTitle from "components/ListEntitiesItem/Title";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";
import { DISMISS_NOTIFICATIONS } from "../gql";
import { iconPerType } from "../helpers";

type StackNotificationItemProps = {
  item: Notification;
};

const StackNotificationItem = (props: StackNotificationItemProps) => {
  const { item } = props;

  const rowRef = useRef<HTMLDivElement>(null);

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [dismissNotifications] = useMutation(DISMISS_NOTIFICATIONS, {
    refetchQueries: ["SearchStackNotifications"],
  });

  const handleDismissNotification = () => {
    dismissNotifications({ variables: { ids: [item.id] } })
      .then(() => reportSuccess({ message: "Notifications successfully dismissed" }))
      .catch(onError);
  };

  const TypeIcon = iconPerType[item.type];

  return (
    <ListEntitiesItem direction="column" ref={rowRef} className={styles.listItem}>
      <div className={styles.entityWrapper}>
        <Icon src={TypeIcon} size="large" color="secondary" />
      </div>

      <Box justify="between" gap="medium">
        <Box align="center" gap="medium">
          {item.dismissed && <Icon src={Tick} color="secondary" />}

          <ListEntitiesItemTitle
            title={item.title}
            titleColor={item.dismissed ? "secondary" : "primary"}
          />

          <NotificationSeverityBadge severity={item.severity} />
        </Box>

        {!item.dismissed && (
          <Box className={styles.actionsRow}>
            <Button onClick={handleDismissNotification} variant="secondary" size="small">
              Dismiss
            </Button>
          </Box>
        )}
      </Box>

      <Box direction="column" gap="medium" margin="medium 0 0">
        <MetaInfoList>
          <MetaInfoListItem icon={CirclesGroup}>{item.groupingKey}</MetaInfoListItem>

          <MetaInfoListItem icon={Clock}>
            <Timestamp timestamp={item.timestamp} />
          </MetaInfoListItem>
        </MetaInfoList>

        <Typography variant="p-body2" tag="div" className={styles.body} color="secondary">
          {item.body}
        </Typography>
      </Box>
    </ListEntitiesItem>
  );
};

export default StackNotificationItem;
