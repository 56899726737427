import ListEntitiesNew from "components/ListEntitiesNew";
import { ManagedUser, ManagedUserGroup } from "types/generated";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import UsersListVirtualizedItem from "./VirtualizedItem";
import { showUsersDetailsDrawer } from "../DetailsDrawer";
import styles from "./styles.module.css";
import { getManagementStrategy } from "../../helpers";
import { SettingsContext } from "../../Context";

type UsersListProps = {
  users: ManagedUser[];
  managedUserGroups: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
};

const UsersList = ({ users, managedUserGroups }: UsersListProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationUsers,
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const handleUserDetailsClick = (user: ManagedUser) => {
    trackSegmentAnalyticsEvent("Access Details Click");
    showUsersDetailsDrawer({ id: user.id, userId: user.id, activationStatus, managedUserGroups });
  };

  return (
    <Box direction="column" grow="1">
      <div className={styles.wrapper}>
        <ListEntitiesNew
          itemCount={users.length}
          itemProps={{
            users,
            handleUserDetailsClick,
          }}
          virtualizedItem={UsersListVirtualizedItem}
          itemKey={(index) => users[index].id}
        />
      </div>
    </Box>
  );
};

export default UsersList;
