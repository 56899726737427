import { OverlayTriggerStateContext } from "react-aria-components";
import { useContext } from "react";

import { Cross } from "components/icons/generated";

import ButtonIconNew from "../ButtonIcon/New";

const DrawerCloseIcon = () => {
  const state = useContext(OverlayTriggerStateContext)!;
  return (
    <ButtonIconNew icon={Cross} onPress={() => state.close()} variant="ghost">
      Close
    </ButtonIconNew>
  );
};

export default DrawerCloseIcon;
