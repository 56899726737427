import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import PageInfo from "components/PageWrapper/Info";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { SearchNotificationsOutput, SearchQueryPredicate } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import InfiniteScroll from "components/scroll/InfiniteScroll";
import { uniqByKey } from "utils/uniq";
import useTitle from "hooks/useTitle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import EmptyState from "ds/components/EmptyState";
import { EmptystateNotificationsColored } from "components/icons/generated";

import { ModuleContext } from "../Context";
import { ITEMS_LIMIT, POLL_INTERVAL } from "./constants";
import { DISMISS_NOTIFICATIONS, SEARCH_NOTIFICATIONS } from "./gql";
import ModuleNotificationItem from "./Item";

const ORDER_BY_OPTION = {
  field: "timestamp",
  direction: "DESC",
};

const ModuleNotifications = () => {
  const { module } = useTypedContext(ModuleContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  useTitle(`Notifications · ${module.name}`);

  useBreadcrumbs([
    {
      title: "Modules",
      link: "/modules",
    },
    {
      title: module.id,
    },
  ]);

  const predicates = useMemo((): SearchQueryPredicate => {
    return {
      field: "target",
      exclude: null,
      constraint: {
        booleanEquals: null,
        enumEquals: null,
        hierarchyNodeValueEquals: null,
        timeInRange: null,
        timeInLast: null,
        stringMatches: [`module/${module.id}`],
      },
    };
  }, [module.id]);

  const {
    error,
    loading,
    data,
    stopPolling,
    fetchMore: fetchMoreNotifications,
  } = useQuery<{
    searchNotifications: SearchNotificationsOutput;
  }>(SEARCH_NOTIFICATIONS, {
    variables: {
      input: {
        first: ITEMS_LIMIT,
        after: null,
        predicates,
        orderBy: ORDER_BY_OPTION,
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [dismissNotifications] = useMutation(DISMISS_NOTIFICATIONS, {
    refetchQueries: ["SearchModuleNotifications"],
  });

  const hasMore = Boolean(
    data?.searchNotifications.pageInfo.endCursor && data?.searchNotifications.pageInfo.hasNextPage
  );

  const hasNotDismissedNotifications = useMemo(() => {
    return data?.searchNotifications?.edges.some(({ node }) => !node.dismissed);
  }, [data?.searchNotifications?.edges]);

  const loadMoreItems = async () => {
    try {
      if (hasMore) {
        await fetchMoreNotifications({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.searchNotifications.edges.length > 0) {
              return {
                searchNotifications: {
                  ...fetchMoreResult.searchNotifications,
                  edges: uniqByKey(
                    [
                      ...(prev.searchNotifications.edges || []),
                      ...fetchMoreResult.searchNotifications.edges,
                    ],
                    "cursor"
                  ),
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: data?.searchNotifications.pageInfo.endCursor,
              predicates,
              orderBy: ORDER_BY_OPTION,
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleDismissAllNotifications = useCallback(() => {
    const ids = data?.searchNotifications?.edges
      .filter((edge) => !edge.node.dismissed)
      .map(({ node }) => node.id);

    if (ids) {
      dismissNotifications({ variables: { ids } })
        .then(() => reportSuccess({ message: "Notifications successfully dismissed" }))
        .catch(onError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.searchNotifications?.edges]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  // Do not show 'loading' when polling in the background.
  if (loading && !data?.searchNotifications) {
    return <PageLoading />;
  }

  if (!data?.searchNotifications) {
    return <NotFoundPage />;
  }

  const notificationsList = data.searchNotifications.edges;
  const hasNotifications = notificationsList.length > 0;

  return (
    <InfiniteScroll onScrollEnd={loadMoreItems} hasMore={hasMore}>
      <PageInfo title="Notifications">
        <Button
          variant="dangerPrimary"
          disabled={!hasNotDismissedNotifications}
          onClick={handleDismissAllNotifications}
        >
          Dismiss all
        </Button>
      </PageInfo>

      {notificationsList.map((edge) => (
        <ModuleNotificationItem key={edge.node.id} item={edge.node} />
      ))}

      {!hasNotifications && (
        <EmptyState
          icon={EmptystateNotificationsColored}
          title="No notifications yet"
          caption="You currently don't have any notifications. We'll inform you if any appear."
        />
      )}
    </InfiniteScroll>
  );
};

export default ModuleNotifications;
