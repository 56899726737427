import Link from "ds/components/LinkNew";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { AuditActor } from "types/generated";

import { AuditTrailEntrySuggestions } from "../../constants";

type AuditTrailListItemCreatedByProps = {
  value: AuditActor;
  applyFilter: (filterName: AuditTrailEntrySuggestions) => (value: string) => void;
};

const AuditTrailListItemCreatedBy = ({ value, applyFilter }: AuditTrailListItemCreatedByProps) => {
  return (
    <>
      {value.run === null && (
        <TextEllipsis tooltip={value.username} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Link
              {...props}
              onPress={() => applyFilter(AuditTrailEntrySuggestions.Username)(value.username)}
            >
              {value.username}
            </Link>
          )}
        </TextEllipsis>
      )}

      {value.run && (
        <Typography tag="span" variant="p-body2">
          Run&nbsp;
          <Link href={`/stack/${value.run.stackId}/run/${value.run.id}`}>{value.run.id}</Link>
        </Typography>
      )}
    </>
  );
};

export default AuditTrailListItemCreatedBy;
