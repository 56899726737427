import { useMemo } from "react";
import { Route, Routes } from "react-router-dom";

import useTitle from "hooks/useTitle";
import { Module, Run, RunType, Stack, EntityChangeType } from "types/generated";
import { isModuleRunType } from "utils/run";
import { AnalyticsPage } from "hooks/useAnalytics";
import { isAnsibleStackVendor } from "utils/stack";
import HideOnScrollProvider from "components/HideOnScroll/HideOnScrollContext";

import RunHeader from "../run/Header/RunHeader";
import { RunContext } from "../run/Context";
import Changes from "../run/RunChangesNext/Changes";
import { runHasChanges, ansibleRunHasTasks } from "../run/helpers";
import RunChanges from "../run/RunChanges";
import AnsibleRunTasks from "./components/AnsibleRunTasks";
import RunHistory from "./routes/History";
import RunChangesLayout from "./components/RunChangesLayout";

type RunProps = {
  run: Run;
  stack: Stack | Module;
  versionId?: string;
  analyticsPage?: AnalyticsPage;
  configurationManagementViewEnabled?: boolean;
};

const RunNext = (props: RunProps) => {
  const { run, stack, versionId, analyticsPage, configurationManagementViewEnabled } = props;

  const isTaskRun = run.type === RunType.Task;
  const isModuleRun = isModuleRunType(run.type);

  const isStackRun = !isTaskRun && !isModuleRun;
  const hasChanges = !!run.delta && runHasChanges(run.delta);
  const hasAnsibleTasks = !!run.delta && ansibleRunHasTasks(run.delta);
  const isStackRunWithChanges = isStackRun && hasChanges;

  const isAnsibleVendor =
    stack.__typename === "Stack" &&
    isAnsibleStackVendor(stack) &&
    configurationManagementViewEnabled;

  const runTitle = useMemo(() => {
    if (!(run && stack)) return "";

    if (isTaskRun) {
      return `${run.command || ""} · ${stack.name}`;
    }

    return `${run.title} · ${stack.name}`;
  }, [run, stack, isTaskRun]);

  useTitle(runTitle);

  return (
    <RunContext.Provider value={{ run, stack, versionId }}>
      <HideOnScrollProvider>
        <RunHeader analyticsPage={analyticsPage} />
        <Routes>
          {!isAnsibleVendor && !isModuleRun && !isTaskRun && (
            <Route path="changes" element={<Changes />} />
          )}
          {isAnsibleVendor && hasAnsibleTasks && (
            <Route
              path="tasks"
              element={
                <AnsibleRunTasks analyticsPage={analyticsPage} stackId={stack.id} runId={run.id} />
              }
            />
          )}

          <Route index element={<RunHistory analyticsPage={analyticsPage} />} />

          {!isAnsibleVendor && isStackRunWithChanges && (
            <Route element={<RunChangesLayout />}>
              {!!run.delta?.addCount && (
                <Route path="changes/add" element={<RunChanges type={EntityChangeType.Add} />} />
              )}
              {!!run.delta?.changeCount && (
                <Route
                  path="changes/change"
                  element={<RunChanges type={EntityChangeType.Change} />}
                />
              )}
              {!!run.delta?.deleteCount && (
                <Route
                  path="changes/delete"
                  element={<RunChanges type={EntityChangeType.Delete} />}
                />
              )}
            </Route>
          )}
        </Routes>
      </HideOnScrollProvider>
    </RunContext.Provider>
  );
};

export default RunNext;
