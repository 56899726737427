import Banner from "ds/components/Banner";
import Toggle from "ds/components/Toggle";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { SpaceAccessLevel } from "types/generated";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { SpacesViewContext } from "../Context";
import styles from "./styles.module.css";

type SpaceDrawerViewProps = {
  handleCloseDrawer: () => void;
};

const SpaceDrawerView = ({ handleCloseDrawer }: SpaceDrawerViewProps) => {
  const { currentSpace, isRootAdmin } = useTypedContext(SpacesViewContext);

  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Space details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody noPadding>
        <div className={styles.viewList}>
          {isRootAdmin && currentSpace && (
            <Banner variant="info">
              This is your {currentSpace?.name} space. It’s not editable.
            </Banner>
          )}

          <FormFieldViewText label="ID" value={currentSpace?.id} withCopy />

          {!isRootAdmin && (
            <FormFieldViewText
              label="Permissions"
              value={
                currentSpace?.accessLevel === SpaceAccessLevel.None
                  ? "No access"
                  : currentSpace?.accessLevel.toLowerCase()
              }
              noTextEllipsis
              textTransform="uppercase-first"
            />
          )}

          <FormFieldViewText label="Name" value={currentSpace?.name} />

          {currentSpace?.description && (
            <FormFieldViewText
              label="Description"
              value={currentSpace.description}
              noTextEllipsis
            />
          )}
        </div>

        {!isRootAdmin ||
          (currentSpace?.id === "legacy" && (
            <div className={styles.viewList}>
              <Typography tag="h5" variant="p-t5" className={styles.title}>
                Inherit entities
              </Typography>

              <Typography tag="p" variant="p-body2" className={styles.info}>
                Should read access and attachable entities be inherited from the parent space.
              </Typography>

              <Toggle
                variant="checkbox"
                id="inheritEntities"
                checked={currentSpace?.inheritEntities}
                disabled
                ariaLabel="Inheritance is enabled and cannot be disabled"
              >
                Inheritance enabled
              </Toggle>
            </div>
          ))}
      </DrawerBody>
    </>
  );
};

export default SpaceDrawerView;
