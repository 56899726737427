import { getSelectOptions } from "./utils";

export const TYPE_OF_USE_OPTIONS = getSelectOptions(["Business", "Personal"]);
export const WORK_OPTIONS = getSelectOptions([
  "Cloud Engineer",
  "DevOps Engineer",
  "Security Architect",
  "Developer",
  "Platform team manager",
  "Other",
]);

export const COMPANY_SIZE_OPTIONS = getSelectOptions([
  "1-100 employees",
  "101-250 employees",
  "251-1000 employees",
  "1001-5000 employees",
  ">5000 employees",
]);
