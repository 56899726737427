import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useModal } from "@ebay/nice-modal-react";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import { ConfigType } from "types/generated";
import ContextConfigFormPathField from "components/ContextConfigForm/PathField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerForm from "ds/components/DrawerNew/Form";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import ButtonNew from "ds/components/Button/New";

import { ContextVariablesFormFields, ContextMountedFilesFormDrawerProps } from "./types";

const ContextMountedFilesFormDrawer = createDrawer(
  ({ handleFileAdd, item }: ContextMountedFilesFormDrawerProps) => {
    const fileForm = useForm<ContextVariablesFormFields>({
      defaultValues: {
        id: item?.id || "",
        description: item?.description || "",
        writeOnly: item?.writeOnly ? item.writeOnly : true,
        type: ConfigType.FileMount,
      },
      mode: "onChange",
    });

    const drawer = useModal();

    const {
      handleSubmit,
      formState: { isValid, isDirty },
    } = fileForm;

    const closeDrawer = () => {
      drawer.resolve();
      drawer.hide();
    };

    const onCreateSubmit = (formData: ContextVariablesFormFields) => {
      const fileData = {
        id: formData.id,
        description: formData.description || "",
        writeOnly: formData.writeOnly,
        type: formData.type,
        isDraft: true,
        createdAt: null,
        value: null,
      };
      handleFileAdd(fileData);
      closeDrawer();
    };

    const onSubmit: SubmitHandler<ContextVariablesFormFields> = (formData) => {
      onCreateSubmit(formData);
    };

    return (
      <DrawerForm isDirty={isDirty}>
        <FormProvider {...fileForm}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle title="Add file" />
            <DrawerCloseIcon />
          </DrawerHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerBody fullHeight>
              <Box margin="0 0 large">
                <ContextConfigFormPathField name="id" />
              </Box>
              <ContextConfigFormIsSecretField name="writeOnly" />
              <ContextConfigFormDescriptionField name="description" />

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <ButtonNew variant="primary" type="submit" disabled={!isValid || !isDirty}>
                    {item ? "Save" : "Continue"}
                  </ButtonNew>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerForm>
    );
  }
);

export const showContextMountedFilesFormDrawer = createDrawerTrigger(ContextMountedFilesFormDrawer);
