import { ReactNode } from "react";

import { Cross } from "components/icons/generated";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";

type WarningBarItemProps = {
  children: ReactNode;
};

const WarningBarItem = ({ children }: WarningBarItemProps) => {
  return (
    <p className={styles.warningItemText}>
      <Icon src={Cross} color="danger" className={styles.warningCrossIcon} noShrink /> {children}
    </p>
  );
};

export default WarningBarItem;
