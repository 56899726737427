import { TerraformWorkflowTool as WorkflowTool } from "types/generated";
import { TERRAFORM_CUSTOM_VENDOR, VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import {
  Gear,
  LogoAnsible,
  LogoCloudformation,
  LogoKubernetes,
  LogoOpentofu,
  LogoPulumi,
  LogoTerraform,
  LogoTerragrunt,
} from "components/icons/generated";
import { getStackVendorInfo } from "utils/vendor";
import Box from "ds/components/Box";

import StackListItemValue from "./Value";
import { CellComponentProps } from "./types";

type StackListItemVendorCellProps = CellComponentProps;

const StackListItemVendorCell = ({ stack }: StackListItemVendorCellProps) => {
  const { vendorConfig, effectiveTerraformVersion } = stack;

  // TF Stacks/Stack view
  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAFORM) {
    let label = effectiveTerraformVersion || vendorConfig.version || "";

    let icon = LogoTerraform;
    if (vendorConfig?.workflowTool === WorkflowTool.OpenTofu) {
      icon = LogoOpentofu;
    }

    if (vendorConfig?.workflowTool === WorkflowTool.Custom) {
      icon = Gear;
      label = TERRAFORM_CUSTOM_VENDOR;
    }

    return <StackListItemValue icon={icon}>{label}</StackListItemValue>;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.TERRAGRUNT) {
    const vendorInfo = getStackVendorInfo(vendorConfig);

    if (!vendorInfo) return <StackListItemValue icon={LogoTerragrunt} />;

    return (
      <Box direction="column" gap="small">
        <StackListItemValue icon={vendorInfo.vendor.icon}>
          {vendorInfo.vendor.text}
        </StackListItemValue>

        {vendorInfo.vendorTool && (
          <StackListItemValue icon={vendorInfo.vendorTool.icon}>
            {vendorInfo.vendorTool.text}
          </StackListItemValue>
        )}
      </Box>
    );
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.PULUMI) {
    return <StackListItemValue tooltip="Pulumi" icon={LogoPulumi} />;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.KUBERNETES) {
    return <StackListItemValue tooltip="Kubernetes" icon={LogoKubernetes} />;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.CLOUD_FORMATION) {
    return <StackListItemValue tooltip="CloudFormation" icon={LogoCloudformation} />;
  }

  if (vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.ANSIBLE) {
    return <StackListItemValue tooltip="Ansible" icon={LogoAnsible} />;
  }

  return null;
};

export default StackListItemVendorCell;
