import { useNavigate } from "react-router-dom";

import { AwsIntegration } from "types/generated";
import Button from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";

import { showDeleteConfirmation } from "../DeleteConfirmation";

type ListItemProps = {
  integration: AwsIntegration;
};

const AwsIntegrationDeleteButton = (props: ListItemProps) => {
  const navigate = useNavigate();

  const { integration } = props;

  const isAttachedToStacks = integration.attachedStacks.length > 0;

  const deleteTitle = isAttachedToStacks
    ? "Cannot delete this integration because it is attached to a stack"
    : "";

  const onClick = () =>
    showDeleteConfirmation({ integration }).then(() => navigate("/cloud-integrations/aws"));

  return (
    <Tooltip
      active={!!deleteTitle}
      on={(props) => (
        <Button
          {...props}
          variant="dangerSecondary"
          onClick={onClick}
          size="small"
          disabled={isAttachedToStacks}
        >
          Delete
        </Button>
      )}
    >
      {deleteTitle}
    </Tooltip>
  );
};

export default AwsIntegrationDeleteButton;
