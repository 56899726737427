import { SpaceAccessLevel } from "types/generated";

export const hasSpaceManageAccess = (accessLevel: SpaceAccessLevel) =>
  accessLevel === SpaceAccessLevel.Admin;

export const hasSpaceManageOrWriteAccess = (accessLevel: SpaceAccessLevel) =>
  accessLevel === SpaceAccessLevel.Write || hasSpaceManageAccess(accessLevel);

export const hasSpaceAccessAtLeast = (
  accessLevel: SpaceAccessLevel,
  requiredLevel: SpaceAccessLevel
) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (requiredLevel) {
    case SpaceAccessLevel.Admin:
      return hasSpaceManageAccess(accessLevel);
    case SpaceAccessLevel.Write:
      return hasSpaceManageOrWriteAccess(accessLevel);
    case SpaceAccessLevel.Read:
      return hasSpaceManageOrWriteAccess(accessLevel) || accessLevel === SpaceAccessLevel.Read;
    default:
      return false;
  }
};
