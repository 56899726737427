import cx from "classnames";
import { Link } from "react-router-dom";

import { Delta, Maybe } from "types/generated";
import Box, { BoxProps } from "ds/components/Box";
import TooltipModal from "ds/components/TooltipModal";
import LinkDS from "ds/components/Link";

import styles from "./styles.module.css";
import DeltaCountsACDBar from "./ACDBar";
import DeltaCountsIMFBar from "./IMFBar";
import DeltaCountsAnsibleBar from "./AnsibleBar";
import DeltaCountsResponsive from "./Responsive";
import { DeltaType } from "./types";

type DeltaCountsProps = {
  delta: Maybe<Delta>;
  className?: string;
  skipEmpty?: boolean;
  variant?: "flexible" | "fixed";
  size?: "small" | "medium";
  direction?: BoxProps["direction"];
  align?: BoxProps["align"];
  link?: string;
  linkAriaLabel?: string;
  compact?: boolean;
  isAnsible?: boolean;
  responsive?: boolean;
};

const DeltaCounts = ({
  delta,
  className,
  skipEmpty,
  variant = "fixed",
  size = "medium",
  align = "center",
  link,
  compact,
  linkAriaLabel,
  isAnsible,
  direction,
  responsive,
}: DeltaCountsProps) => {
  const addCount = delta?.addCount || 0;
  const changeCount = delta?.changeCount || 0;
  const deleteCount = delta?.deleteCount || 0;
  const importCount = delta?.importCount || 0;
  const moveCount = delta?.moveCount || 0;
  const forgetCount = delta?.forgetCount || 0;
  const ignoreCount = delta?.ignoreCount || 0;
  const okCount = delta?.okCount || 0;
  const rescueCount = delta?.rescueCount || 0;
  const skipCount = delta?.skipCount || 0;
  const unreachableCount = delta?.unreachableCount || 0;
  const failedCount = delta?.failedCount || 0;

  const IMFDeltaBar = !isAnsible && (importCount > 0 || moveCount > 0 || forgetCount > 0) && (
    <DeltaCountsIMFBar
      key="IMF"
      importCount={importCount}
      moveCount={moveCount}
      forgetCount={forgetCount}
    />
  );

  const ACDDeltaBar = !isAnsible &&
    (!skipEmpty || addCount > 0 || changeCount > 0 || deleteCount > 0) && (
      <DeltaCountsACDBar
        key="ACD"
        skipEmpty={skipEmpty}
        addCount={addCount}
        changeCount={changeCount}
        deleteCount={deleteCount}
      />
    );

  const AnsibleBar = isAnsible &&
    (!skipEmpty ||
      ignoreCount > 0 ||
      changeCount > 0 ||
      okCount > 0 ||
      rescueCount > 0 ||
      skipCount > 0 ||
      failedCount > 0 ||
      unreachableCount > 0) && (
      <DeltaCountsAnsibleBar
        key="ansible"
        skipEmpty={skipEmpty}
        ignoreCount={ignoreCount}
        changeCount={changeCount}
        okCount={okCount}
        rescueCount={rescueCount}
        skipCount={skipCount}
        unreachableCount={unreachableCount}
        failedCount={failedCount}
      />
    );

  const deltaBars = [IMFDeltaBar, ACDDeltaBar, AnsibleBar].filter(
    (item) => !!item
  ) as JSX.Element[];

  if (responsive) {
    const deltas = [
      { type: DeltaType.ADD, count: addCount },
      { type: DeltaType.CHANGE, count: changeCount },
      { type: DeltaType.DELETE, count: deleteCount },
      { type: DeltaType.IMPORT, count: importCount },
      { type: DeltaType.MOVE, count: moveCount },
      { type: DeltaType.FORGET, count: forgetCount },
      { type: DeltaType.OK, count: okCount, ansible: true },
      { type: DeltaType.CHANGED, count: changeCount, ansible: true },
      { type: DeltaType.UNREACHABLE, count: unreachableCount, ansible: true },
      { type: DeltaType.FAILED, count: failedCount, ansible: true },
      { type: DeltaType.SKIPPED, count: skipCount, ansible: true },
      { type: DeltaType.RESCUED, count: rescueCount, ansible: true },
      { type: DeltaType.IGNORED, count: ignoreCount, ansible: true },
    ];

    const filteredDeltas = deltas.filter(
      (item) => item.count && (isAnsible ? item.ansible : !item.ansible)
    ) as Array<{ type: DeltaType; count: number }>;

    return <DeltaCountsResponsive deltas={filteredDeltas} />;
  }

  const visibleBars = compact ? deltaBars[0] : deltaBars;

  return (
    <Box
      gap="medium"
      align={align}
      direction={direction}
      className={cx(styles[variant], styles[size], className)}
    >
      {link ? (
        <Link to={link} aria-label={linkAriaLabel}>
          <Box gap="medium">{visibleBars}</Box>
        </Link>
      ) : (
        visibleBars
      )}

      {compact && deltaBars.length > 1 && (
        <TooltipModal
          className={styles.tooltipModal}
          disableClose
          on={(props) => <LinkDS {...props}>Show all</LinkDS>}
        >
          <Box gap="medium" align={align} className={cx(styles[variant], styles[size], className)}>
            {deltaBars}
          </Box>
        </TooltipModal>
      )}
    </Box>
  );
};

export default DeltaCounts;
