import { useNavigate } from "react-router-dom";
import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box, { BoxProps } from "ds/components/Box";
import Tab from "ds/components/Tab";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { Gear, Plus } from "components/icons/generated";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";
import Icon from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import FeedbackButton from "components/FeedbackButton";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import { DashboardContext } from "./context";
import useCurrentTab from "./useCurrentTab";
import {
  DASHBOARD_HEADER_PENDO_FEEDBACK_ID,
  DEFAULT_TAB_IDS,
  NEW_TAB_IN_LIST_ID,
} from "./constants";
import styles from "./styles.module.css";
import { showDeleteConfirmation } from "./DeleteConfirmation";

type DashboardViewHeaderProps = Omit<BoxProps, "children"> & { children?: ReactNode };

function DashboardViewHeader({ children, ...rest }: DashboardViewHeaderProps) {
  const navigate = useNavigate();
  const { canAddMoreViews, openManageDrawer, addNewTab, removeTab, configLoading, tabs } =
    useTypedContext(DashboardContext);

  const currentTab = useCurrentTab();

  const handleClick = (path: string) => {
    navigate(path);
  };

  const handleTabDelete = (id: string, title: string) => {
    showDeleteConfirmation({ removeTabCallback: () => removeTab(id), name: title });
  };

  const addNewTabHandler = () => {
    if (canAddMoreViews) {
      addNewTab();
    }
  };

  return (
    <ViewHeader firstLevel>
      <Box align="start" justify="between" fullWidth>
        <Box gap="medium" direction="column" grow="1" fullWidth>
          <Box direction="row" justify="between" shrink="0">
            <ViewHeaderTitle titleSize="p-t3">Dashboard</ViewHeaderTitle>
            <ViewHeaderWrapper direction="row" align="end" shrink="0">
              <FeedbackButton id={DASHBOARD_HEADER_PENDO_FEEDBACK_ID} />
              <ButtonIconNew
                variant="secondary"
                icon={Gear}
                disabled={configLoading}
                size="medium"
                onPress={() => openManageDrawer(undefined, { method: "Header button click" })}
              >
                Manage view
              </ButtonIconNew>
            </ViewHeaderWrapper>
          </Box>
          <Box className={styles.tabsContainerScollable}>
            <Box gap="medium" className={styles.tabsContainer}>
              {tabs.map(({ id, title }) => (
                <div key={id} className={styles.tabWithActionWrapper}>
                  <Tab
                    isActive={currentTab === id}
                    onClick={() => handleClick(`/dashboard?tab=${id}`)}
                    id={id}
                    aria-label={title}
                    className={styles.tabWithAction}
                    label={
                      <TextEllipsis tooltip={title} tooltipWidthMode="maxWidthSm">
                        {(props) => (
                          <Typography {...props} tag="span" variant="p-t7">
                            {title}
                          </Typography>
                        )}
                      </TextEllipsis>
                    }
                  />
                  <DropdownMenuEllipsis
                    className={styles.tabWithActionDropdownTrigger}
                    tooltip="Settings"
                    buttonVariant="ghost"
                  >
                    <DropdownMenuItem onAction={openManageDrawer}>Manage view</DropdownMenuItem>
                    {!DEFAULT_TAB_IDS.includes(id) && (
                      <DropdownMenuItem danger onAction={() => handleTabDelete(id, title)}>
                        Delete
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuEllipsis>
                </div>
              ))}
              <Tab
                id={NEW_TAB_IN_LIST_ID}
                onClick={addNewTabHandler}
                aria-label={canAddMoreViews ? "Add new tab" : "Views limit exceeded"}
                isActive={false}
                label={
                  <Tooltip on={(props) => <Icon {...props} src={Plus} />}>
                    {canAddMoreViews ? "Add new tab" : "Views limit exceeded"}
                  </Tooltip>
                }
                disabled={!canAddMoreViews}
              />
            </Box>
          </Box>
        </Box>
        <Box {...rest}>{children}</Box>
      </Box>
    </ViewHeader>
  );
}

export default DashboardViewHeader;
