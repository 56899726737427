import { Outlet } from "react-router-dom";

import PageInfo from "components/PageWrapper/Info";

import RunPageWrapper from "../RunPageWrapper";

const RunChangesLayout = () => {
  return (
    <>
      <PageInfo title="Run changes" />
      <RunPageWrapper>
        <Outlet />
      </RunPageWrapper>
    </>
  );
};

export default RunChangesLayout;
