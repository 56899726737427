import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import Link from "ds/components/Link";
import { Space } from "components/icons/generated";
import MetaInfoBoolean from "components/MetaInfoList/Boolean";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import SecretFieldDetails from "ds/components/SecretFieldDetails";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { NamedWebhooksIntegration } from "types/generated";

import styles from "./styles.module.css";

type WebhookDetailsDrawerProps = {
  webhook: NamedWebhooksIntegration;
  canManageSpace: boolean;
};

const WebhookDetailsDrawer = createDrawer(
  ({ webhook, canManageSpace }: WebhookDetailsDrawerProps) => {
    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Webhook details" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <FormFieldViewText label="Name" value={webhook.name} />

          <FormField label="Endpoint URL">
            <Typography tag="span" variant="p-body2">
              <Link href={webhook.endpoint} target="_blank">
                {webhook.endpoint}
              </Link>
            </Typography>
          </FormField>

          <FormField label="Space">
            <MetaInfoListItem
              icon={Space}
              linkText={webhook.space.name}
              href={`/spaces/${webhook.space.id}`}
            />
          </FormField>

          <FormField label="Enabled">
            <MetaInfoBoolean
              text={webhook.enabled ? "Enabled" : "Disabled"}
              value={webhook.enabled}
            />
          </FormField>
          {webhook.secret && (
            <FormField label="Secret">
              {canManageSpace ? (
                <SecretFieldDetails secret={webhook.secret} />
              ) : (
                <Typography tag="span" variant="p-body2">
                  &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                </Typography>
              )}
            </FormField>
          )}
          <FormFieldTimestamp timestamp={webhook.createdAt} label="Created at" />
          {webhook.labels.length > 0 && (
            <FormField label="Labels">
              <TagsList tags={webhook.labels} alwaysExpanded />
            </FormField>
          )}
          {!!webhook.secretHeaders.length && (
            <Box direction="column" className={styles.headersWrapper} gap="large">
              <Typography tag="span" variant="p-t5">
                Headers
              </Typography>

              {webhook.secretHeaders.map((key) => (
                <Box __deprecatedGap="1rem" align="center" key={key}>
                  <FormField label="Key" className={styles.headerField}>
                    <Typography tag="span" variant="p-body2">
                      {key}
                    </Typography>
                  </FormField>
                  <FormField label="Value" className={styles.headerField}>
                    <Typography tag="span" variant="p-body2">
                      &bull;&bull;&bull;&bull;&bull;&bull;&bull;
                    </Typography>
                  </FormField>
                </Box>
              ))}
            </Box>
          )}
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showWebhookDetailsDrawer = createDrawerTrigger(WebhookDetailsDrawer);
