import cx from "classnames";
import { useLayoutEffect, useState } from "react";

import Box from "ds/components/Box";
import usePrevious from "hooks/usePrevious";
import LinkNew from "ds/components/LinkNew";
import useElementWidth from "hooks/useElementWidth";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import { ChevronRight } from "components/icons/generated";

import styles from "./styles.module.css";
import ListEntitiesItemDescriptionContent from "./Content";

type ListEntitiesItemDescriptionMultipleLinesProps =
  | {
      description: string;
      onShowFullDescription: (description: string) => void;
      className?: string;
      lines?: number;
      hasChevronLink?: boolean;
    }
  | {
      description: string;
      onShowFullDescription?: never;
      className?: string;
      lines?: number;
      hasChevronLink?: boolean;
    };

const ListEntitiesItemDescriptionMultipleLines = ({
  description,
  onShowFullDescription,
  className,
  lines = 3,
  hasChevronLink,
}: ListEntitiesItemDescriptionMultipleLinesProps) => {
  const [textRef, containerSize] = useElementWidth<HTMLDivElement>();
  const [isTextEllipsis, setIsTextEllipsis] = useState(false);
  const prevWidth = usePrevious(containerSize);

  useLayoutEffect(() => {
    if (prevWidth !== containerSize) {
      const isTextEllipsis =
        (textRef.current?.scrollHeight || 0) >
        (textRef.current?.getBoundingClientRect().height || 0);

      setIsTextEllipsis(isTextEllipsis);
    }
  }, [prevWidth, containerSize, textRef]);

  const handleShowFullDescription = () => {
    onShowFullDescription?.(description);
  };

  return (
    <Box
      grow="1"
      direction={hasChevronLink ? "row" : "column"}
      gap="small"
      align={hasChevronLink ? "center" : "start"}
      className={cx(styles.wrapper, styles.multipleLinesWrapper)}
    >
      <ListEntitiesItemDescriptionContent
        ref={textRef}
        description={description}
        style={{ WebkitLineClamp: lines }}
        className={cx(className, styles.multipleLines)}
        color="primary"
      />

      {isTextEllipsis && hasChevronLink && (
        <ButtonIconNew variant="ghost" icon={ChevronRight} onPress={handleShowFullDescription}>
          Show full description
        </ButtonIconNew>
      )}

      {isTextEllipsis && !hasChevronLink && (
        <LinkNew
          onPress={handleShowFullDescription}
          size="small"
          className={styles.showFullDescriptionLink}
        >
          Show full description
        </LinkNew>
      )}
    </Box>
  );
};

export default ListEntitiesItemDescriptionMultipleLines;
