import { useLayoutEffect, useRef, useState } from "react";
import { throttle } from "lodash-es";

const THROTTLE_TIME = 50;

const useElementWidth = <T extends HTMLElement>(initialValue: number = 0) => {
  const ref = useRef<T | null>(null);
  const [elementWidth, setElementWidth] = useState<number>(initialValue);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const updateWidth = () => {
      if (!ref.current) {
        return;
      }

      setElementWidth(ref.current.clientWidth);
    };

    const resizeObserver = new ResizeObserver(
      throttle(updateWidth, THROTTLE_TIME, { leading: true, trailing: true })
    );

    updateWidth();

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return [ref, elementWidth] as [typeof ref, typeof elementWidth];
};

export default useElementWidth;
