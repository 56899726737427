import { RefObject, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from "@floating-ui/react";
import cx from "classnames";

import { withTextAlign } from "utils/withTextAlign";

import styles from "./styles.module.css";
import { TooltipProps, TooltipReferenceProps } from "./types";

const Tooltip = forwardRef(function Tooltip(props: TooltipProps, ref: React.ForwardedRef<Element>) {
  const {
    on,
    children,
    placement = "top",
    widthMode,
    active: propsActive,
    forceOpen,
    onChildrenChangedCallback,
    delay = 300,
    mode = "portal",
    textAlign = "left",
    triggerClassName,
    disableAriaLabel,
    offset: tooltipOffset = 8,
  } = props;
  const arrowRef = useRef(null);

  const active = "active" in props ? propsActive : true;

  const [isOpen, setIsOpen] = useState(false);

  let open = false;

  if (forceOpen) {
    open = true;
  } else if (active) {
    open = isOpen;
  }

  const { refs, floatingStyles, context } = useFloating<Element>({
    placement,
    open,
    onOpenChange: setIsOpen,
    middleware: [
      offset(tooltipOffset),
      shift({ padding: 8 }),
      flip(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    move: false,
    delay: {
      open: delay,
      close: 100,
    },
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {
    enabled: !disableAriaLabel,
    role: "tooltip",
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  const { isMounted, styles: tooltipStyles } = useTransitionStyles(context, {
    duration: 100,
  });

  useEffect(() => {
    if (refs.domReference) {
      // observe children change
      onChildrenChangedCallback?.(refs.domReference as RefObject<HTMLElement>);
    }
  }, [children, onChildrenChangedCallback, refs.domReference]);

  const mergedRef = useMergeRefs([ref, refs.setReference]);

  const triggerProps = useMemo(() => {
    const props: TooltipReferenceProps = {
      ref: mergedRef!,
    };

    if (triggerClassName) {
      props.className = triggerClassName;
    }

    return props;
  }, [mergedRef, triggerClassName]);

  const trigger = on(getReferenceProps(triggerProps) as TooltipReferenceProps);

  if (!isMounted) {
    return <>{trigger}</>;
  }

  const tooltip = (
    <span
      role="tooltip"
      className={cx(
        styles.floatingTooltip,
        widthMode && styles[widthMode],
        withTextAlign(styles, textAlign)
      )}
      ref={refs.setFloating}
      style={{ ...floatingStyles, ...tooltipStyles }}
      {...getFloatingProps()}
    >
      {children}
      <FloatingArrow ref={arrowRef} context={context} className={styles.floatingArrow} />
    </span>
  );

  if (mode === "parent") {
    return (
      <>
        {trigger}
        {tooltip}
      </>
    );
  }

  return (
    <>
      {trigger}
      {<FloatingPortal id="portal-root">{tooltip}</FloatingPortal>}
    </>
  );
});

export default Tooltip;
