import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import Box from "ds/components/Box";
import PageLoading from "components/loading/PageLoading";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUser, ManagedUserGroup, UserStatus } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import useAnalytics from "hooks/useAnalytics";
import NotFoundPage from "components/error/NotFoundPage";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import UsersInviteWrapper from "./InviteWrapper";
import UsersList from "./UsersList";
import UsersPageLayout from "./PageLayout";
import UsersFiltersLayout from "./FiltersLayout";
import UsersInviteForm from "./InviteForm";
import UsersEmpty from "./Empty";
import { showUsersInfoDrawer } from "./InfoDrawer";
import { GET_MANAGED_USERS } from "./gql";
import { getSortedUsers } from "./helpers";
import { SettingsContext } from "../Context";
import { getManagementStrategy } from "../helpers";
import { showUsersInviteDrawer } from "./InviteDrawer";

type GetManagedUsersGql = {
  managedUsers: ManagedUser[];
  managedUserGroups: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
};

const Users = () => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { accountName } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);

  const { loading, data, error } = useQuery<GetManagedUsersGql>(GET_MANAGED_USERS, {
    onError,
  });

  useTitle(`Organization Settings · Users · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationUsers,
  });

  const activeUsersCount = useMemo(() => {
    if (data?.managedUsers) {
      return data?.managedUsers.filter((item) => item.status === UserStatus.Active).length;
    }

    return 0;
  }, [data?.managedUsers]);

  const shouldShowOnlyList = activeUsersCount > 5;

  const usersCount = data?.managedUsers?.length || 0;

  const memoizedDisplayableUsers = useMemo<ManagedUser[]>(() => {
    if (data?.managedUsers) {
      return getSortedUsers(data.managedUsers);
    }
    return [];
  }, [data?.managedUsers]);

  const openInfoDrawer = () => {
    trackSegmentAnalyticsEvent("Info Click", {
      managementStrategy: getManagementStrategy(activationStatus),
    });
    showUsersInfoDrawer({
      isInviteFormVisible: !shouldShowOnlyList,
    });
  };

  const openInviteDrawer = () => {
    trackSegmentAnalyticsEvent("Invite More Users click", {
      managementStrategy: getManagementStrategy(activationStatus),
      usersCount,
      activeUsersCount,
    });
    showUsersInviteDrawer({
      activationStatus,
      usersCount,
      activeUsersCount,
    });
  };

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.managedUsers) {
    return <PageLoading />;
  }

  if (!data?.managedUsers) {
    return <NotFoundPage />;
  }

  return (
    <UsersPageLayout
      openInviteDrawer={shouldShowOnlyList ? openInviteDrawer : undefined}
      openInfoDrawer={openInfoDrawer}
    >
      {!shouldShowOnlyList && (
        <UsersInviteWrapper>
          <UsersInviteForm activeUsersCount={activeUsersCount} usersCount={usersCount} />
        </UsersInviteWrapper>
      )}

      {memoizedDisplayableUsers.length === 0 && (
        <Box align="center" justify="center" grow="1">
          <UsersEmpty />
        </Box>
      )}

      {memoizedDisplayableUsers.length > 0 && (
        <>
          <UsersFiltersLayout />
          <UsersList users={memoizedDisplayableUsers} managedUserGroups={data.managedUserGroups} />
        </>
      )}
    </UsersPageLayout>
  );
};

export default Users;
