import useTypedContext from "hooks/useTypedContext";
import Feedback from "ds/components/Feedback";
import { SubscriptionContext } from "views/Account/SubscriptionWrapper";
import { BillingTier } from "types/generated";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import { pluralize } from "shared/Pluralize";

import { SettingsContext } from "../Context";
import { UserManagementActivationStatus } from "../types";
import { SeatLimitsNotificationEntity } from "./types";

const SeatLimitsNotificationLoginPolicies = () => {
  const { tier } = useTypedContext(SubscriptionContext);
  const { activationStatus, seats } = useTypedContext(SettingsContext);

  if (activationStatus === UserManagementActivationStatus.ACTIVE) {
    return null;
  }

  const userSeats = seats.user;
  const apiKeySeats = seats.apiKey;

  if (userSeats.limit === -1) {
    return null;
  }

  const isFreeTier = tier === BillingTier.V4Free;
  const isStarterTier = tier === BillingTier.V4Starter;

  if (!isFreeTier && !isStarterTier) {
    return null;
  }

  return (
    <Feedback type="callout" variant="promo" title="Upgrade to unlock more seats">
      {isFreeTier && (
        <>
          Free tier has{" "}
          <strong>
            {userSeats.limit} {pluralize(SeatLimitsNotificationEntity.User, userSeats.limit)} and{" "}
            {apiKeySeats.limit} {pluralize(SeatLimitsNotificationEntity.ApiKey, apiKeySeats.limit)}
          </strong>{" "}
          limit. Upgrade now to unlock more seats and keep the collaboration flowing!
        </>
      )}
      {isStarterTier && (
        <>
          Starter tier has{" "}
          <strong>
            {userSeats.limit} {pluralize(SeatLimitsNotificationEntity.User, userSeats.limit)}
          </strong>{" "}
          limit (including API keys). Update subscription with{" "}
          <strong>Unlimited users add on</strong> or <strong>upgrade to Starter+</strong> to unlock
          more seats and keep the collaboration flowing!
        </>
      )}
      <FeedbackActions>
        <Button variant="contrast" size="small" to="/settings/billing">
          Upgrade
        </Button>
        {isStarterTier && (
          <Button variant="secondary" size="small" to="/settings/billing/subscription">
            Manage subscription
          </Button>
        )}
      </FeedbackActions>
    </Feedback>
  );
};

export default SeatLimitsNotificationLoginPolicies;
