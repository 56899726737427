import DocumentationButton from "components/DocumentationButton";
import {
  EmptystateModulesColored,
  EmptystateSearchNoResultsColored,
} from "components/icons/generated";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import { getDocsUrl } from "utils/getDocsUrl";

type SharedModulesEmptyStateProps = {
  didPerformSearch?: boolean;
};

const SharedModulesEmptyState = ({ didPerformSearch }: SharedModulesEmptyStateProps) => {
  return (
    <Box align="center" justify="center" grow="1">
      {didPerformSearch && (
        <EmptyState
          title="No results"
          icon={EmptystateSearchNoResultsColored}
          caption="Don’t give up! Check the spelling or try something less specific."
        />
      )}
      {!didPerformSearch && (
        <EmptyState title="You don’t have any shared modules yet" icon={EmptystateModulesColored}>
          <DocumentationButton
            to={getDocsUrl("/vendors/terraform/module-registry.html#sharing-modules")}
            label="Documentation"
          />
        </EmptyState>
      )}
    </Box>
  );
};

export default SharedModulesEmptyState;
