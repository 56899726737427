type SkeletonSvgProps = {
  height: number;
  width: number;
  borderRadius?: number;
};

const SkeletonSvg = ({ height, width, borderRadius }: SkeletonSvgProps) => {
  return (
    <svg aria-labelledby="loading-skeleton-aria">
      <title id="loading-skeleton-aria">Loading</title>
      <rect
        height={height}
        width={width}
        rx={borderRadius}
        ry={borderRadius}
        fill="url(#skeletonGradient)"
      />
      <defs>
        <linearGradient id="skeletonGradient">
          <stop offset={0} stopOpacity="1" stopColor="var(--color-default-divider)">
            <animate
              dur="1.5s"
              attributeName="offset"
              values="-2; -2; 0.4; 2"
              keyTimes="0; 0.3; 0.5; 1"
              repeatCount="indefinite"
            />
          </stop>
          <stop
            offset={0.5}
            stopOpacity="1"
            stopColor="var(--semantic-color-surface-app-background)"
          >
            <animate
              dur="1.5s"
              attributeName="offset"
              values="-1; -1; 0.9; 3"
              keyTimes="0; 0.3; 0.5; 1"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset={1} stopOpacity="1" stopColor="var(--color-default-divider)">
            <animate
              dur="1.5s"
              attributeName="offset"
              values="0; 0; 1.4; 4"
              keyTimes="0; 0.3; 0.5; 1"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SkeletonSvg;
