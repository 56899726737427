import { FunctionComponent, useState } from "react";
import { useModal } from "@ebay/nice-modal-react";

import { AwsIntegration, AzureIntegration } from "types/generated";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import AttachCloudForm from "views/Account/NewModule/AttachCloud/Form";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import WarningContextProvider from "components/WarningContext/Provider";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { ModuleCreationCloud } from "views/Account/NewModule/types";

type ModuleSettingsIntegrationsAttachCloudIntegrationsDrawerProps = {
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: ModuleCreationCloud;
  }>;
  filteredAzureIntegrations?: AzureIntegration[];
  filteredAwsIntegrations?: AwsIntegration[];
  hasData: boolean;
  refetch: () => void;
  refetching: boolean;
  moduleId: string;
  onSuccess?: () => void;
  refetchQueries?: string[];
  canAttachGcpIntegration?: boolean;
};

const ModuleSettingsIntegrationsAttachCloudIntegrationsDrawer = createDrawer(
  ({
    moduleId,
    hasData,
    refetch,
    refetching,

    cloudConfig,
    filteredAzureIntegrations,
    filteredAwsIntegrations,
    canAttachGcpIntegration,
  }: ModuleSettingsIntegrationsAttachCloudIntegrationsDrawerProps) => {
    const drawer = useModal();
    const [cloud, setCloud] = useState<ModuleCreationCloud | undefined>();
    const handleClose = () => {
      setCloud(undefined);
      drawer.hide();
    };

    return (
      <>
        <WarningContextProvider>
          <DrawerSimple variant="wide" onClose={handleClose}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Attach cloud integration" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight>
              <AttachCloudForm
                moduleId={moduleId}
                hasData={hasData}
                refetch={refetch}
                refetching={refetching}
                setCloud={setCloud}
                cloud={cloud}
                cloudConfig={cloudConfig}
                attachableAzureIntegrations={filteredAzureIntegrations}
                attachableAwsIntegrations={filteredAwsIntegrations}
                canAttachGcpIntegration={canAttachGcpIntegration}
                onSuccess={handleClose}
                refetchQueries={[
                  "GetAttachedModuleIntegrations",
                  "GetModuleAttachedGcpIntegration",
                ]}
              />

              <DrawerFooter sticky>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </DrawerSimple>
        </WarningContextProvider>
      </>
    );
  }
);

export const showModuleSettingsIntegrationsAttachCloudIntegrationsDrawer = createDrawerTrigger(
  ModuleSettingsIntegrationsAttachCloudIntegrationsDrawer
);
