import { Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import FormField from "ds/components/Form/Field";
import DescriptionDetails from "components/DescriptionDetails";
import { Stack } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import {
  getStackDetailsNameTooltip,
  getStackDetailsSpaceTooltip,
} from "views/Stack/Settings/components/getTooltips";
import FormFieldTimestamp from "components/FormFields/Timestamp";

type StackDetailsProps = {
  editUrl?: string;
  onOpenFullDescription: () => void;
  stack: Stack;
  canManageSpace: boolean;
};

const StackDetails = ({
  editUrl,
  onOpenFullDescription,
  stack,
  canManageSpace,
}: StackDetailsProps) => {
  return (
    <>
      <FormFieldViewText
        label="Name"
        value={stack.name}
        noMargin
        {...getStackDetailsNameTooltip()}
      />

      <FormField label="Space" noMargin {...getStackDetailsSpaceTooltip()}>
        <MetaInfoListItem
          icon={Space}
          linkText={stack.spaceDetails.name}
          href={`/spaces/${stack.spaceDetails.id}`}
        />
      </FormField>

      <DescriptionDetails
        description={stack.description}
        onOpenFullDescription={onOpenFullDescription}
        {...(canManageSpace && { addDescriptionUrl: editUrl })}
        noMargin
      />

      <FormField label="Labels" noMargin>
        <TagsList
          tags={stack.labels}
          {...(canManageSpace && { addTagsUrl: editUrl })}
          alwaysExpanded
          withEmptyPlaceholder
        />
      </FormField>

      {stack.stateSetAt && (
        <FormFieldTimestamp
          timestamp={stack.stateSetAt}
          label="The last change to the stack state"
          noMargin
        />
      )}
    </>
  );
};

export default StackDetails;
