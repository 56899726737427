import { Lock, LogoSlack, Pickaxe, GitBranch, Users } from "components/icons/generated";

import { LaunchPadFeatureSelfHosted } from "./types";
import { LaunchPadFeatureMetadata } from "../types";

export const GET_STARTED_FEATURES: LaunchPadFeatureMetadata<LaunchPadFeatureSelfHosted>[] = [
  {
    feature: LaunchPadFeatureSelfHosted.ConfigureSSO,
    title: "Configure SSO",
    icon: Lock,
    url: "/settings/sso",
    analyticsTitle: "Configure SSO Click",
  },

  {
    feature: LaunchPadFeatureSelfHosted.SetupSlackIntegration,
    title: "Set up Slack integration",
    icon: LogoSlack,
    url: "/settings/integrations",
    analyticsTitle: "Setup Slack Integration Click",
  },
  {
    feature: LaunchPadFeatureSelfHosted.CreateWorkerPool,
    title: "Create worker pool",
    icon: Pickaxe,
    url: "/worker-pools",
    analyticsTitle: "Create Worker Pool Click",
  },
  {
    feature: LaunchPadFeatureSelfHosted.SetupVCSIntegration,
    title: "Set up VCS integration",
    icon: GitBranch,
    url: "/vcs/integrations",
    analyticsTitle: "Setup VCS Integration Click",
  },
  {
    feature: LaunchPadFeatureSelfHosted.InviteTeammates,
    title: "Invite teammates",
    icon: Users,
    url: "/settings/users",
    analyticsTitle: "Invite User Click",
  },
];
