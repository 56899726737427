import { ConfigNode } from "../types";
import { RECT_ITEM_SIZE, RECT_ITEM_SPACE, RECT_ITEMS_PER_ROW } from "./contstants";

export const getNodeHeight = (config: ConfigNode<string>) => {
  if ("group" in config) {
    return (
      Math.ceil((config.group as Array<unknown>).length / RECT_ITEMS_PER_ROW) *
        (RECT_ITEM_SIZE + RECT_ITEM_SPACE) +
      60
    );
  }

  return 60;
};
