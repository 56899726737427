import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUser, ManagedUserInviteInput } from "types/generated";

import { INVITE_MANAGED_USER } from "../gql";

const useInviteUser = () => {
  const { onError } = useTypedContext(FlashContext);

  const [inviteManagedUser, { loading }] = useMutation<{ managedUserInvite: ManagedUser }>(
    INVITE_MANAGED_USER,
    {
      onError,
      refetchQueries: ["GetManagedUsers", "GetAccountSettings"],
    }
  );

  const onCreate = (formData: ManagedUserInviteInput) => {
    return inviteManagedUser({
      variables: {
        input: formData,
      },
    });
  };

  return { onCreate, loading };
};

export default useInviteUser;
