import { ChevronDown } from "components/icons/generated";
import BadgeNext from "ds/components/BadgeNext";

import { STACK_STATES } from "./constants";
import { StackStateBadgeProps } from "./types";

/**
 * @deprecated Use DS StackStateBadgeNew instead
 */
const StackStateBadge = ({
  state,
  className,
  withDropdown,
  onClick,
  ariaProps,
}: StackStateBadgeProps) => {
  if (!STACK_STATES[state]) {
    return null;
  }

  return (
    <BadgeNext
      startIcon={STACK_STATES[state].icon}
      variant={STACK_STATES[state].variant}
      type="strong"
      className={className}
      text={STACK_STATES[state].text}
      endIcon={withDropdown ? ChevronDown : undefined}
      onClick={onClick}
      {...ariaProps}
    />
  );
};

export default StackStateBadge;
