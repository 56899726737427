import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import { ApiKey, AuthorizationScheme } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import NotFoundPage from "components/error/NotFoundPage";
import { showApiKeysDetailsDrawer } from "components/ApiKeys/DetailsDrawer";
import ApiKeysSortHeader from "components/ApiKeys/SortHeader";
import ApiKeysEmptyList from "components/ApiKeys/EmptyList";
import ApiKeysList from "components/ApiKeys/List";
import { showApiKeysCreateDrawer } from "components/ApiKeys/CreateDrawer";
import { UserManagementActivationStatus } from "views/Account/Settings/types";
import { showApiKeysInfoDrawer } from "components/ApiKeys/InfoDrawer";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";

import PersonalSettingsApiKeysPageLayout from "./components/PageLayout";
import { GET_API_KEYS } from "./gql";

type PersonalSettingsApiKeysProps = {
  authorizationScheme: string;
};

const REFETCH_QUERIES = ["GetApiKeys"];

const PersonalSettingsApiKeys = ({ authorizationScheme }: PersonalSettingsApiKeysProps) => {
  const { data, loading, error } = useQuery(GET_API_KEYS, {
    fetchPolicy: "no-cache",
  });

  const activationStatus =
    authorizationScheme === AuthorizationScheme.ManagedUsers
      ? UserManagementActivationStatus.ACTIVE
      : UserManagementActivationStatus.INACTIVE;

  const handleOpenInfoDrawer = () => {
    showApiKeysInfoDrawer({
      analyticsPage: AnalyticsPagePersonal.PersonalApiKeys,
    });
  };

  const handleApiKeyOpenDetails = (apiKey: ApiKey) => {
    showApiKeysDetailsDrawer({
      id: apiKey.id,
      apiKey,
      activationStatus,
    });
  };

  const handleCreateApiKeyDrawer = () => {
    showApiKeysCreateDrawer({
      activationStatus,
      refetchQueries: REFETCH_QUERIES,
      analyticsPage: AnalyticsPagePersonal.PersonalApiKeys,
    });
  };

  const apiKeys = useMemo(() => data?.viewer?.apiKeys || [], [data?.viewer?.apiKeys]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.viewer?.apiKeys) {
    return (
      <PersonalSettingsApiKeysPageLayout>
        <PageLayoutSkeleton />
      </PersonalSettingsApiKeysPageLayout>
    );
  }

  if (!data?.viewer?.apiKeys) {
    return <NotFoundPage />;
  }

  return (
    <PersonalSettingsApiKeysPageLayout
      onOpenInfoDrawer={handleOpenInfoDrawer}
      onOpenCreateApiKeyDrawer={handleCreateApiKeyDrawer}
      seats={data.seats.apiKey}
    >
      {apiKeys.length > 0 && (
        <>
          <ApiKeysSortHeader />
          <ApiKeysList
            apiKeys={apiKeys}
            handleApiKeyOpenDetails={handleApiKeyOpenDetails}
            refetchQueriesOnDelete={REFETCH_QUERIES}
          />
        </>
      )}
      {apiKeys.length === 0 && <ApiKeysEmptyList />}
    </PersonalSettingsApiKeysPageLayout>
  );
};

export default PersonalSettingsApiKeys;
