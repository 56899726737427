import Box from "ds/components/Box";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import { VcsIntegrationDetails, VcsProvider } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import FormFieldViewText from "components/FormFields/ViewText";
import DescriptionDetails from "components/DescriptionDetails";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import VCSProviderIcon from "../../components/ProviderIcon";
import {
  BUILT_IN_GITHUB_INTEGRATION_DESCRIPTION,
  BUILT_IN_GITHUB_INTEGRATION_NAME,
} from "./constants";
import { getIntegrationDetails } from "../../components/Details/helpers";

type VCSIntegrationsBuiltInGitHubDetailsDrawerProps = {
  details: VcsIntegrationDetails | undefined;
  onClose?: () => void;
};

const VCSIntegrationsBuiltInGitHubDetailsDrawer = createDrawer(
  ({ details, onClose }: VCSIntegrationsBuiltInGitHubDetailsDrawerProps) => {
    return (
      <DrawerSimple onClose={onClose}>
        {details && (
          <>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Source code details" />
              <DrawerCloseIcon />
            </DrawerHeader>

            <DrawerBody>
              <FormField label="Provider">
                <Box gap="medium">
                  <VCSProviderIcon provider={VcsProvider.Github} />

                  <Typography tag="span" variant="p-body2">
                    {HUMANIZE_PROVIDER[VcsProvider.Github]}
                  </Typography>
                </Box>
              </FormField>

              <FormFieldViewText
                label="Integration name"
                value={BUILT_IN_GITHUB_INTEGRATION_NAME}
              />

              {getIntegrationDetails(details)}

              <DescriptionDetails description={BUILT_IN_GITHUB_INTEGRATION_DESCRIPTION} />
            </DrawerBody>
          </>
        )}
      </DrawerSimple>
    );
  }
);

export const showVCSIntegrationsBuiltInGitHubDetailsDrawer = createDrawerTrigger(
  VCSIntegrationsBuiltInGitHubDetailsDrawer
);
