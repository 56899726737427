import Box from "ds/components/Box";
import { isRunReviewable } from "shared/Run/useReviewRun/accessValidation";
import {
  ExclamationMarkCircleFilled,
  ExclamationMarkTriangleFilled,
} from "components/icons/generated";
import Typography from "ds/components/Typography";
import useElementWidth from "hooks/useElementWidth";

import StackListItemIssuesCellItem from "./Item";
import { CellComponentProps } from "../types";

const CELL_COMPACT_WIDTH = 115;

type StackListItemIssuesCellProps = CellComponentProps;

const StackListItemIssuesCell = ({ stack }: StackListItemIssuesCellProps) => {
  const stackRunNeedsApproval = isRunReviewable(stack.blocker);

  const [containerRef, containerWidth] = useElementWidth<HTMLDivElement>();

  const isCompact = containerWidth < CELL_COMPACT_WIDTH;

  return (
    <Box ref={containerRef} direction={isCompact ? "row" : "column"} gap="medium" align="start">
      {stack.isDrifted && (
        <StackListItemIssuesCellItem
          iconColor="danger"
          icon={ExclamationMarkCircleFilled}
          hideText={isCompact}
        >
          Drift detected
        </StackListItemIssuesCellItem>
      )}

      {stackRunNeedsApproval && (
        <StackListItemIssuesCellItem
          iconColor="warning"
          icon={ExclamationMarkTriangleFilled}
          hideText={isCompact}
        >
          Needs approval
        </StackListItemIssuesCellItem>
      )}

      {!stack.isDrifted && !stackRunNeedsApproval && (
        <Typography tag="span" variant="p-body3">
          -
        </Typography>
      )}
    </Box>
  );
};

export default StackListItemIssuesCell;
