import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import cx from "classnames";

import styles from "./styles.module.css";

type FormNavLinkProps = {
  to: NavLinkProps["to"];
  children: React.ReactNode;
  isActive: boolean;
};

const FormNavLink = ({ children, to, isActive }: FormNavLinkProps) => (
  <NavLink className={cx(styles.navLink, { [styles.active]: isActive })} to={to}>
    {children}
  </NavLink>
);

export default FormNavLink;
