import { useMutation } from "@apollo/client";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import useTypedFlags from "hooks/useTypedFlags";
import { Maybe, OidcSettings } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";
import SettingItem from "components/SettingItem";
import SettingItemLink from "components/SettingItem/SettingItemLink";
import SettingItemActions from "components/SettingItem/SettingItemActions";
import Button from "components/button/Button";
import SettingItemContent from "components/SettingItem/SettingItemContent";
import setLocation from "shared/setLocation";
import useAnalytics from "hooks/useAnalytics";
import Tooltip from "ds/components/Tooltip";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import FlashContext from "components/FlashMessages/FlashContext";
import FormGroup from "components/FormDefault/Group";
import TooltipInfo from "ds/components/TooltipInfo";
import { OIDCUseSlackIDFromIdpTooltip } from "components/TooltipSnippets";

import IdentityProviderConfiguration from "./IdentityProviderConfiguration";
import OIDCSettingsForm from "./OIDCSettingsForm";
import styles from "./styles.module.css";
import ViewConfiguration from "./ViewConfiguration";
import { showDisableConfirmation } from "./DisableConfirmation";
import { UPDATE_OIDC } from "./gql";
import { SLACK_MEMBER_ID_CLAIM_MAPPING, SLACK_MEMBER_ID_CLAIM_MAPPING_EMPTY } from "./constants";
import { shouldUseSlackMemberIDFromIdp } from "./utils";

type OIDCPaneProps = {
  integration: Maybe<OidcSettings>;
  disabled: boolean;
  hasManagedUsers?: boolean;
};

const SETUP_PATH = "oidc";

const OIDCPane = ({ integration, disabled, hasManagedUsers }: OIDCPaneProps) => {
  const { useSlackMemberIdFromIdpForUserProfile } = useTypedFlags();
  const { accountName } = useTypedContext(AccountContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const location = useLocation();
  const navigate = useNavigate();

  const isSetupMode = location.pathname.includes(SETUP_PATH) && !disabled;

  const shouldUseSlackMemberID = shouldUseSlackMemberIDFromIdp(integration?.claimMapping);

  const [oidcUpdate] = useMutation(UPDATE_OIDC, {
    variables: {
      input: {
        claimMapping: shouldUseSlackMemberID
          ? SLACK_MEMBER_ID_CLAIM_MAPPING_EMPTY
          : SLACK_MEMBER_ID_CLAIM_MAPPING,
      },
    },
    onError,
    onCompleted: () => reportSuccess({ message: "OIDC settings updated" }),
    refetchQueries: ["GetSSOSettings"],
    awaitRefetchQueries: true,
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSSO,
    defaultCallbackTrackProperties: { method: "OIDC" },
  });

  const handleSetUp = () => {
    trackSegmentAnalyticsEvent("Set Up Click");

    navigate(isSetupMode ? "./" : SETUP_PATH);
  };

  const handleDisable = () => {
    trackSegmentAnalyticsEvent("Disable Click");
    showDisableConfirmation({ hasManagedUsers });
  };

  const handleActivate = () => {
    trackSegmentAnalyticsEvent("Activate Click");
    setLocation("/oidc/start");
  };

  const handleToggleUseSlackMemberIDFromIdp = () => {
    oidcUpdate();
  };

  return (
    <SettingItem>
      <SettingItemLink
        className={styles.topPanel}
        to={SETUP_PATH}
        title="OIDC Settings"
        description=""
        disabled={disabled}
      >
        <SettingItemActions>
          {!integration && !isSetupMode && (
            <Tooltip
              on={(props) => (
                // it enables tooltip for disabled button
                <span {...props}>
                  <Button disabled={disabled} type="button" onClick={handleSetUp} full pill>
                    Set up
                  </Button>
                </span>
              )}
              active={disabled}
            >
              SAML integration already present, can't set up OIDC
            </Tooltip>
          )}

          {integration?.adminLogin === null && (
            <Button type="button" onClick={handleActivate} pill>
              Activate
            </Button>
          )}

          {integration && (
            <Button type="button" onClick={handleDisable} pill danger>
              Disable
            </Button>
          )}
        </SettingItemActions>
      </SettingItemLink>

      <IdentityProviderConfiguration />

      {useSlackMemberIdFromIdpForUserProfile && !!integration && !isSetupMode && (
        <>
          <hr className={styles.divider} />
          <FormGroup
            labelText="Use Provided Slack Member ID:"
            labelIcon={
              <TooltipInfo variant="modal" widthMode="maxWidthSm" iconClassName={styles.infoIcon}>
                <OIDCUseSlackIDFromIdpTooltip />
              </TooltipInfo>
            }
            className={styles.oidcPaneFormGroup}
            labelClassName={styles.oidcPaneFormInfoLabel}
            type="checkbox"
            checked={shouldUseSlackMemberID}
            id="useSlackMemberIDFromIdp"
            onChange={handleToggleUseSlackMemberIDFromIdp}
          />
        </>
      )}

      <Routes>
        {!disabled && (
          <Route
            path={SETUP_PATH}
            element={
              <SettingItemContent title={`OIDC Settings · ${accountName}`}>
                {!!integration && (
                  <ViewConfiguration integration={integration} onFormClose={handleSetUp} />
                )}
                {!integration && <OIDCSettingsForm onFormClose={handleSetUp} />}
              </SettingItemContent>
            }
          />
        )}
      </Routes>
    </SettingItem>
  );
};

export default OIDCPane;
