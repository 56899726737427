import Typography from "ds/components/Typography";
import { Loading } from "components/icons";
import { ChevronDown } from "components/icons/generated";
import Icon from "ds/components/Icon";

import styles from "../styles.module.css";

type FilterOpenSectionHeaderProps = {
  id: string;
  name: string;
  loading: boolean;
};

const FilterOpenSectionHeader = ({ name, loading, id }: FilterOpenSectionHeaderProps) => {
  return (
    <Typography tag="span" variant="p-t6" className={styles.title}>
      <Icon src={ChevronDown} className={styles.icon} aria-hidden />
      <span id={id} className={styles.text}>
        {name}
      </span>

      {loading && (
        <div className={styles.loader}>
          <Loading />
        </div>
      )}
    </Typography>
  );
};

export default FilterOpenSectionHeader;
