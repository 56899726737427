import { useFormContext } from "react-hook-form";
import { useCallback, useState } from "react";

import CardWrapper from "components/CardWrapper";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import ButtonIcon from "ds/components/ButtonIcon";
import Drawer from "ds/components/Drawer";
import { Expand } from "components/icons/generated";
import ContextConfigFormPathField from "components/ContextConfigForm/PathField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import ContextConfigFormFileUploadField from "components/ContextConfigForm/FileUploadField";
import ContextConfigFormContentField from "components/ContextConfigForm/ContentField";

import { ContextAttachedMountedFile, ContextFormFields } from "../types";
import ContentDrawer from "./ContentDrawer";
import styles from "./styles.module.css";

type ContextMountedFilesEditFormProps = {
  index: number;
  onSave: (item: ContextAttachedMountedFile) => void;
};

const ContextMountedFilesEditForm = ({ index, onSave }: ContextMountedFilesEditFormProps) => {
  const {
    watch,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<ContextFormFields>();
  const content = watch(`attachedMountedFiles.${index}.content`);
  const [isContentDrawerVisible, setIsContentDrawerVisible] = useState(false);

  const handleCloseDrawer = useCallback(() => setIsContentDrawerVisible(false), []);
  const handleOpenDrawer = useCallback(() => setIsContentDrawerVisible(true), []);

  const onSubmit = useCallback(
    (formData: ContextFormFields) => onSave(formData["attachedMountedFiles"][index]),
    [onSave, index]
  );

  return (
    <>
      <CardWrapper variant="filled" direction="column">
        <Box margin="0 0 large">
          <ContextConfigFormPathField name={`attachedMountedFiles.${index}.path`} />
        </Box>

        <ContextConfigFormIsSecretField name={`attachedMountedFiles.${index}.isSecret`} />

        <ContextConfigFormDescriptionField name={`attachedMountedFiles.${index}.description`} />

        <Box margin="large 0 0" grid gridTemplate="1fr" className={styles.fileWrapper} fullWidth>
          {content !== null && (
            <ButtonIcon
              icon={Expand}
              onClick={handleOpenDrawer}
              size="small"
              className={styles.expandIcon}
              variant="secondary"
            >
              Expand editor
            </ButtonIcon>
          )}

          {content === null && (
            <ContextConfigFormFileUploadField
              name={`attachedMountedFiles.${index}.file`}
              required
            />
          )}

          {content !== null && (
            <ContextConfigFormContentField name={`attachedMountedFiles.${index}.content`} />
          )}
        </Box>

        <Box justify="end" align="center" fullWidth margin="large 0 0">
          <Button variant="contrast" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
            Save
          </Button>
        </Box>
      </CardWrapper>
      <Drawer position="fixedRight" visible={isContentDrawerVisible} isResizable>
        {isContentDrawerVisible && (
          <ContentDrawer
            onDrawerClose={handleCloseDrawer}
            name={`attachedMountedFiles.${index}.content`}
            onSave={handleSubmit(onSubmit)}
            isEditing
          />
        )}
      </Drawer>
    </>
  );
};

export default ContextMountedFilesEditForm;
