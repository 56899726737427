import DescriptionDetails from "components/DescriptionDetails";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import { Space } from "components/icons/generated";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import { VcsIntegration } from "types/generated";
import Box from "ds/components/Box";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import FormFieldViewText from "components/FormFields/ViewText";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";

import { getIntegrationDetails } from "./helpers";
import VCSProviderIcon from "../ProviderIcon";
import { getEditIntegrationURL } from "../../helpers";

type VCSIntegrationDetailsProps = {
  item: VcsIntegration | null;
  canEdit: boolean;
  onClose?: () => void;
};

const VCSIntegrationDetailsDrawer = createDrawer(
  ({ item, canEdit, onClose }: VCSIntegrationDetailsProps) => {
    const onOpenFullDescription = useShowFullDescriptionWithBackArrow(item?.description || "");

    return (
      <DrawerSimple onClose={onClose}>
        {item && (
          <>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Source code details" />
              <DrawerCloseIcon />
            </DrawerHeader>

            <DrawerBody>
              <FormField label="Provider">
                <Box gap="medium">
                  <VCSProviderIcon provider={item.provider} />

                  <Typography tag="span" variant="p-body2">
                    {HUMANIZE_PROVIDER[item.provider]}
                  </Typography>
                </Box>
              </FormField>

              <FormFieldViewText label="Integration name" value={item.name} />

              {!item.isDefault && (
                <FormField label="Space">
                  <MetaInfoListItem
                    icon={Space}
                    linkText={item.space.name}
                    href={`/spaces/${item.space.id}`}
                  />
                </FormField>
              )}

              {item.details && getIntegrationDetails(item.details)}

              <FormField label="Labels">
                <TagsList
                  tags={item.labels}
                  {...(canEdit && { addTagsUrl: getEditIntegrationURL(item.provider, item.id) })}
                  alwaysExpanded
                  withEmptyPlaceholder
                />
              </FormField>

              <DescriptionDetails
                description={item.description}
                onOpenFullDescription={onOpenFullDescription}
                {...(canEdit && {
                  addDescriptionUrl: getEditIntegrationURL(item.provider, item.id),
                })}
              />
            </DrawerBody>
          </>
        )}
      </DrawerSimple>
    );
  }
);

export const showVCSIntegrationDetailsDrawer = createDrawerTrigger(VCSIntegrationDetailsDrawer);
