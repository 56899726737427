import { ReactNode } from "react";

import BadgeNext from "ds/components/BadgeNext";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import Link from "ds/components/Link";
import Tooltip from "ds/components/Tooltip";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import ButtonNew from "ds/components/Button/New";

import { UserManagementActivationStatus } from "../types";
import UserManagementWarningCalloutContent from "../UserManagementWarningCallout/Content";
import { showManageSlackAccessCreateDrawer } from "./CreateDrawer";
import { MANAGE_SLACK_ACCESS_INFO_KEY, MANAGE_SLACK_ACCESS_PAGE_NAME } from "./constants";

type ManageSlackAccessPageLayoutProps = {
  activationStatus: UserManagementActivationStatus;
  children: ReactNode;
};

const ManageSlackAccessPageLayout = ({
  activationStatus,
  children,
}: ManageSlackAccessPageLayoutProps) => {
  useBreadcrumbs([
    {
      title: "Organization settings",
      link: "/settings",
    },
    {
      title: "Integrations",
      link: "/settings/integrations",
    },
    {
      title: MANAGE_SLACK_ACCESS_PAGE_NAME,
    },
  ]);

  const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row" justify="between" fullWidth>
          <ViewHeaderWrapper direction="row" align="center">
            <ViewHeaderTitle>{MANAGE_SLACK_ACCESS_PAGE_NAME}</ViewHeaderTitle>
            {isUserManagementActive ? (
              <BadgeNext variant="green" type="strong" text="ACTIVE" />
            ) : (
              <Tooltip
                on={(props) => (
                  <BadgeNext variant="gray" type="strong" text="INACTIVE" {...props} />
                )}
              >
                Preview mode. Activate in management strategy
              </Tooltip>
            )}
          </ViewHeaderWrapper>
          <ViewHeaderWrapper direction="row" align="end" shrink="0">
            <ButtonNew
              onPress={() => showManageSlackAccessCreateDrawer({ activationStatus })}
              variant="primary"
            >
              Grant access
            </ButtonNew>
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>
      </ViewHeader>

      {isUserManagementActive ? (
        <Callout variant="info" storageKey={MANAGE_SLACK_ACCESS_INFO_KEY}>
          To assign Slack access to <strong>users</strong> navigate to user access details.
          <FeedbackActions>
            <Link size="small" to="/settings/users">
              Go to Users
            </Link>
          </FeedbackActions>
        </Callout>
      ) : (
        <UserManagementWarningCalloutContent />
      )}

      {children}
    </>
  );
};

export default ManageSlackAccessPageLayout;
