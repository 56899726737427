import cx from "classnames";

import { CircleCrossed, File } from "components/icons/generated";
import Icon from "ds/components/Icon";

import styles from "../styles.module.css";

const ShowWriteOnlyFile = () => (
  <span className={cx(styles.variableDownload, styles.variableDownloadDisabled)}>
    <Icon src={File} />
    <span className={styles.variableIcon}>
      <Icon src={CircleCrossed} />
    </span>
  </span>
);

export default ShowWriteOnlyFile;
