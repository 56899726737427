import { useCallback, useEffect, useState } from "react";
import InfiniteLoader from "react-window-infinite-loader";

import useTitle from "hooks/useTitle";
import { Policy } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useErrorHandle from "hooks/useErrorHandle";
import ListEntitiesNew from "components/ListEntitiesNew";
import EmptyState from "ds/components/EmptyState";
import { EmptystateHelmetColored } from "components/icons/generated";
import Button from "ds/components/Button";
import { showPolicyEditDrawer } from "views/Account/PolicyEditDrawer";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import useURLParams from "hooks/useURLParams";
import { SpacesContext } from "views/Account/SpacesProvider";
import { hasSpaceManageOrWriteAccess } from "utils/user";

import { showPolicyCreateDrawer } from "../../PolicyCreateDrawer";
import PoliciesViewHeader from "../ViewHeader";
import PoliciesListFiltersLayout from "./FiltersLayout";
import PoliciesListVirtualizedItem from "./VirtualizedItem";
import useSearchPoliciesSuggestions from "./useSearchPoliciesSuggestions";
import useSearchPolicies from "./useSearchPolicies";
import { ENTITIES_PER_PAGE, REFETCH_QUERIES_ON_DELETE } from "./constants";
import useTakenPolicyNames from "../useTakenPolicyNames";

const PoliciesList = () => {
  const { hasEntityCreateAccess: canManagePolicies } = useTypedContext(SpacesContext);
  const { accountName } = useTypedContext(AccountContext);
  const urlParams = useURLParams();

  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [focusedPolicy, setFocusedPolicy] = useState<Policy | undefined>(undefined);

  const handleCloseUpdateDrawer = () => {
    setFocusedPolicy(undefined);
  };

  const handleOpenUpdateDrawer = (policy: Policy) => {
    const canManagePolicy = hasSpaceManageOrWriteAccess(policy.spaceDetails.accessLevel);

    if (canManagePolicy) {
      setFocusedPolicy(policy);
      showPolicyEditDrawer({
        analyticsPage: AnalyticsPagePolicy.PoliciesList,
        takenPolicyNames: takenPolicyNames,
        policy,
        onCloseDrawer: handleCloseUpdateDrawer,
        refetchQueries: REFETCH_QUERIES_ON_DELETE,
      });
    }
  };

  const handleOpenFullDescriptionDrawer = (policy: Policy) => {
    setFocusedPolicy(policy);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFocusedPolicy(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  useTitle(`Policies · ${accountName}`);

  const {
    entities,
    error,
    isPageEmpty,
    hasNoFilteringResults,
    loading,
    isItemLoaded,
    loadMoreItems,
  } = useSearchPolicies();

  const {
    filters,
    filtersMap,
    sortOptions,
    loading: filtersLoading,
    refetchActiveSections,
    filteredCount,
  } = useSearchPoliciesSuggestions();

  const takenPolicyNames = useTakenPolicyNames();

  const openCreateDrawer = useCallback(
    (omitQuerySearchCheck?: boolean) => {
      showPolicyCreateDrawer({
        takenPolicyNames,
        omitQuerySearchCheck,
      });
    },
    [takenPolicyNames]
  );

  useEffect(() => {
    if (urlParams.has("create-new")) {
      openCreateDrawer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  return (
    <>
      <PoliciesViewHeader />

      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={focusedPolicy?.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />
      <PoliciesListFiltersLayout
        openCreateDrawer={openCreateDrawer}
        isPageEmpty={isPageEmpty}
        renderedEntitiesCount={entities.length}
        loadingIndication={loading}
        filters={filters}
        filtersMap={filtersMap}
        sortOptions={sortOptions}
        filtersLoading={filtersLoading}
        refetchActiveSections={refetchActiveSections}
        filteredCount={filteredCount}
        canManagePolicies={canManagePolicies}
      >
        {isPageEmpty && (
          <EmptyState
            title="You don’t have any policies yet"
            icon={EmptystateHelmetColored}
            caption={`Policies are declarative rules evaluated at various decision points within Spacelift.
            Create new policy from scratch using button above or start with one of our templates.`}
          >
            {canManagePolicies && (
              <Button variant="secondary" to="/policies-templates">
                Explore templates
              </Button>
            )}
          </EmptyState>
        )}
        {hasNoFilteringResults && (
          <EmptyState
            title="No results"
            icon={EmptystateHelmetColored}
            caption="Try different filters."
          />
        )}
        {!!entities.length && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={entities.length + ENTITIES_PER_PAGE}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={entities.length}
                itemProps={{
                  items: entities,
                  focusedPolicyId: focusedPolicy?.id,
                  onEdit: handleOpenUpdateDrawer,
                  canManagePolicies,
                  onShowFullDescription: handleOpenFullDescriptionDrawer,
                }}
                virtualizedItem={PoliciesListVirtualizedItem}
                itemKey={(index) => entities[index].id}
                onItemsRendered={onItemsRendered}
              />
            )}
          </InfiniteLoader>
        )}
      </PoliciesListFiltersLayout>
    </>
  );
};

export default PoliciesList;
