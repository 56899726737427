import { differenceInDays, fromUnixTime } from "date-fns";

import { DebugInfo } from "types/generated";

import { LicenseExpirationStatus } from "./types";

export const getLicenseRemainingDaysFromDebugInfo = (
  debugInfo: DebugInfo | undefined
): number | undefined => {
  const validityEndDate = debugInfo?.selfHostedLicence?.validity.end;
  if (!validityEndDate) {
    return undefined;
  }
  try {
    const remainingDays = differenceInDays(
      fromUnixTime(debugInfo?.selfHostedLicence?.validity?.end ?? 0),
      new Date()
    );

    return Number.isNaN(remainingDays) ? undefined : remainingDays;
  } catch {
    return undefined;
  }
};

export const getLicenseExpirationStatus = (remainingDays?: number): LicenseExpirationStatus => {
  if (remainingDays === undefined) {
    return LicenseExpirationStatus.VALID;
  }
  if (remainingDays <= 0) {
    return LicenseExpirationStatus.EXPIRED;
  }
  if (remainingDays <= 14) {
    return LicenseExpirationStatus.IN_2_WEEKS;
  }
  if (remainingDays <= 60) {
    return LicenseExpirationStatus.IN_2_MONTHS;
  }
  return LicenseExpirationStatus.VALID;
};
