import { useParams } from "react-router-dom";

import NotFoundPage from "components/error/NotFoundPage";

import VCSIntegrationsManageGitHubEnterpriseEdit from "./ManageGitHubEnterprise/Edit";
import VCSIntegrationsManageGitHubEdit from "./ManageGitHub/Edit";
import { VCS_PROVIDERS_URL_KEYS } from "../constants";
import VCSIntegrationsManageAzureDevOpsEdit from "./ManageAzureDevOps/Edit";
import VCSIntegrationsManageGitLabEdit from "./ManageGitLab/Edit";
import VCSIntegrationsManageBitbucketCloudEdit from "./ManageBitbucketCloud/Edit";
import VCSIntegrationsManageBitbucketDataCenterEdit from "./ManageBitbucketDataCenter/Edit";

const VCSEditIntegrations = () => {
  const { provider, id } = useParams<{ provider: string; id: string }>();

  if (!id) {
    return <NotFoundPage />;
  }

  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (provider) {
    case VCS_PROVIDERS_URL_KEYS.AZURE_DEVOPS:
      return <VCSIntegrationsManageAzureDevOpsEdit id={id} />;
    case VCS_PROVIDERS_URL_KEYS.BITBUCKET_CLOUD:
      return <VCSIntegrationsManageBitbucketCloudEdit id={id} />;
    case VCS_PROVIDERS_URL_KEYS.BITBUCKET_DATACENTER:
      return <VCSIntegrationsManageBitbucketDataCenterEdit id={id} />;
    case VCS_PROVIDERS_URL_KEYS.GITHUB_ENTERPRISE:
      return <VCSIntegrationsManageGitHubEnterpriseEdit id={id} />;
    case VCS_PROVIDERS_URL_KEYS.GITHUB:
      return <VCSIntegrationsManageGitHubEdit />;
    case VCS_PROVIDERS_URL_KEYS.GITLAB:
      return <VCSIntegrationsManageGitLabEdit id={id} />;
    default:
      return <NotFoundPage />;
  }
};

export default VCSEditIntegrations;
