import { useModal } from "@ebay/nice-modal-react";

import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";

import EnvironmentPreview from "..";
import styles from "./styles.module.css";

type EnvironmentPreviewDrawerProps = {
  stackId: string;
  onCloseDrawer: () => void;
};

const EnvironmentPreviewDrawer = createDrawer(
  ({ stackId, onCloseDrawer }: EnvironmentPreviewDrawerProps) => {
    const drawer = useModal();

    const handleClose = () => {
      onCloseDrawer();
      drawer.resolve();
    };
    return (
      <DrawerSimple variant="wide" onClose={handleClose}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Environment preview" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody hasStickyFooter>
          <EnvironmentPreview stackId={stackId} className={styles.bodyContent} />
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showEnvironmentPreviewDrawer = createDrawerTrigger(EnvironmentPreviewDrawer);
