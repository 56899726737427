import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

const LabelsInfoDrawer = createDrawer(() => (
  <DrawerSimple>
    <DrawerHeader justify="between">
      <DrawerHeaderTitle title="Add Filtering & Magic labels" />
      <DrawerCloseIcon />
    </DrawerHeader>
    <DrawerBody className={styles.documentationBody}>
      <Typography tag="div" variant="p-body2">
        Labels are useful for searching and grouping things, but also work extremely well with
        policies.
        <br />
        <br />
        <b>List of the most useful labels:</b>
        <ul>
          <li>
            <b>infracost</b> → Enables Infracost on your stack
          </li>
          <li>
            <b>feature:add_plan_pr_comment</b> → Enables Pull Request Plan Commenting
          </li>
          <li>
            <b>feature:disable_resource_sanitization</b> → Disables resource sanitization
          </li>
          <li>
            <b>terragrunt</b> → Old way of using Terragrunt from the Terraform backend
          </li>
          <li>
            <b>ghenv:Name</b> → GitHub Deployment environment (defaults to the stack name)
          </li>
          <li>
            <b>ghenv: - </b> → Disables the creation of GitHub deployment environments
          </li>
          <li>
            <b>autoattach:label</b> → Used for policies and contexts to autoattach them to all
            stacks containing that label
          </li>
        </ul>
      </Typography>
    </DrawerBody>
    {/* <DrawerFooter>
    <Button
      // TODO: update docs
      href={getDocsUrl()}
      variant="secondary"
      size="medium"
      target="_blank"
      startIcon={Documentation}
      analyticsTitle="Full documentation Click"
      className={styles.documentationButton}
    >
      Open documentation
    </Button>
  </DrawerFooter> */}
  </DrawerSimple>
));

export const showLabelsInfoDrawer = createDrawerTrigger(LabelsInfoDrawer);
