import { ReactNode } from "react";

import Tab from "ds/components/Tab";

import ModuleSettingsPageLayout from "../../../components/PageLayout";

type ModuleSettingsIntegrationsPageLayoutProps = {
  children: ReactNode;
  actions?: ReactNode;
};

const ModuleSettingsIntegrationsPageLayout = ({
  children,
  actions,
}: ModuleSettingsIntegrationsPageLayoutProps) => {
  return (
    <ModuleSettingsPageLayout
      title="Integrations"
      description="Integrate module with your cloud provider or a webhook."
      navigation={
        <>
          <Tab to="../cloud" label="Cloud integrations" />
          <Tab to="../webhooks" label="Webhooks" />
        </>
      }
      actions={actions}
    >
      {children}
    </ModuleSettingsPageLayout>
  );
};

export default ModuleSettingsIntegrationsPageLayout;
