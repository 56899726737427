import { useEffect, useMemo, useRef, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import InfiniteLoader from "react-window-infinite-loader";

import useTypedContext from "hooks/useTypedContext";
import { Blueprint, SearchBlueprintsOutput } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import FlashContext from "components/FlashMessages/FlashContext";
import { uniqByKey } from "utils/uniq";
import useErrorHandle from "hooks/useErrorHandle";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import NotFoundPage from "components/error/NotFoundPage";
import ListEntitiesNew from "components/ListEntitiesNew";
import { SavedFilterView } from "components/Filters/types";

import BlueprintsEmpty from "./Empty";
import { initialSortDirection, initialSortOption, ITEMS_LIMIT, POLL_INTERVAL } from "./constants";
import BlueprintsPageLayout from "./PageLayout";
import BlueprintVirtualizedListItem from "./ListItem/Virtualized";
import { SEARCH_BLUEPRINTS } from "./gql";
import FiltersLayout from "./FiltersLayout";
import useBlueprintsCheck from "./useBlueprintsCheck";
import BlueprintFeatureGateCallout from "./FeatureGate/Callout";
import { showCreateBlueprintDrawer } from "./CreateBlueprintDrawer";
import { showTemplatePreviewDrawer } from "./TemplatePreview";

const Blueprints = () => {
  const cachedBlueprintEdges = useRef<Blueprint[]>([]);
  const { onError } = useTypedContext(FlashContext);
  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const isRefetching = useRef(false);

  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, initialSortOption, initialSortDirection),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  // Disable reloading when variables has changed to not remount entire sidebar
  const sortOptionFieldsRef = useRef(sortOptionFields);

  const { checking, showUpSell } = useBlueprintsCheck();

  const skipQuery = checking || showUpSell;

  const { error, loading, data, stopPolling, fetchMore, refetch, networkStatus } = useQuery<{
    searchBlueprints: SearchBlueprintsOutput;
  }>(SEARCH_BLUEPRINTS, {
    variables: {
      input: {
        first: ITEMS_LIMIT,
        after: null,
        ...(sortOptionFieldsRef.current && { orderBy: sortOptionFieldsRef.current }),
      },
    },

    onError,
    skip: skipQuery,
    pollInterval: skipQuery ? 0 : POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const memoizedBlueprints = useMemo(() => {
    const sourceEdges = data?.searchBlueprints?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedBlueprintEdges.current : sourceEdges;

    if (!loading) {
      cachedBlueprintEdges.current = sourceEdges;
    }

    return edges;
  }, [data?.searchBlueprints?.edges, loading]);

  const blueprintsQueryRefetch = async () => {
    try {
      isRefetching.current = true;
      await refetch({
        input: {
          first: ITEMS_LIMIT,
          after: null,
          fullTextSearch: searchInput,
          predicates,
          ...(sortOptionFields && { orderBy: sortOptionFields }),
        },
      });
    } catch (e) {
      onError(e);
    } finally {
      isRefetching.current = false;
    }
  };

  const loadMoreItems = async () => {
    try {
      if (
        data?.searchBlueprints.pageInfo.endCursor &&
        data?.searchBlueprints.pageInfo.hasNextPage
      ) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (fetchMoreResult && fetchMoreResult.searchBlueprints.edges.length > 0) {
              return {
                searchBlueprints: {
                  ...fetchMoreResult.searchBlueprints,
                  edges: uniqByKey(
                    [
                      ...(prev.searchBlueprints.edges || []),
                      ...fetchMoreResult.searchBlueprints.edges,
                    ],
                    "cursor"
                  ),
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: data.searchBlueprints.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleOpenCreateBlueprintDrawer = () => {
    showCreateBlueprintDrawer();
  };

  const handleOpenCreateStackDrawer = (blueprint: Blueprint) => {
    showTemplatePreviewDrawer({
      id: blueprint.id,
      item: blueprint,
      isPublished: true,
      onEditMetadata: handleOpenEditMetadataDrawer,
    });
  };

  const handleOpenEditMetadataDrawer = (blueprint: Blueprint) => {
    showCreateBlueprintDrawer({ id: blueprint.id, blueprint });
  };

  const handleOpenCloneBlueprintDrawer = (blueprint: Blueprint) => {
    showCreateBlueprintDrawer({ id: blueprint.id, blueprint, isCloneMode: true });
  };

  const isItemLoaded = (value: number) => value < memoizedBlueprints.length;

  // filtering, refetch query with url params
  useEffect(() => {
    void blueprintsQueryRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, predicates, sortOptionFields]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  if (checking) {
    return (
      <BlueprintsPageLayout hideActions>
        <PageLayoutSkeleton />
      </BlueprintsPageLayout>
    );
  }

  if (
    !isRefetching.current &&
    networkStatus === NetworkStatus.loading &&
    loading &&
    !data?.searchBlueprints
  ) {
    return (
      <BlueprintsPageLayout>
        <PageLayoutSkeleton />
      </BlueprintsPageLayout>
    );
  }

  if (
    !isRefetching.current &&
    networkStatus !== NetworkStatus.refetch &&
    !loading &&
    !data?.searchBlueprints &&
    !skipQuery
  ) {
    return <NotFoundPage />;
  }

  const isEmptyPage = data && !memoizedBlueprints.length;
  const hasNoResultsWithFiltering = searchInput.length > 0 || predicates.length > 0;

  return (
    <BlueprintsPageLayout onCreate={handleOpenCreateBlueprintDrawer} showUpSell={showUpSell}>
      <BlueprintFeatureGateCallout />
      <FiltersLayout
        predicates={predicates}
        hasNoResults={!memoizedBlueprints.length}
        currentSavedView={currentSavedView}
        setCurrentSavedView={setCurrentSavedView}
      >
        {isEmptyPage && (
          <BlueprintsEmpty
            hasNoResults={hasNoResultsWithFiltering}
            onCreate={handleOpenCreateBlueprintDrawer}
          />
        )}

        {data && memoizedBlueprints.length > 0 && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={memoizedBlueprints.length + ITEMS_LIMIT}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={memoizedBlueprints.length}
                itemProps={{
                  items: memoizedBlueprints,
                  onCreateStack: handleOpenCreateStackDrawer,
                  onEditMetadata: handleOpenEditMetadataDrawer,
                  onCloneBlueprint: handleOpenCloneBlueprintDrawer,
                }}
                virtualizedItem={BlueprintVirtualizedListItem}
                itemKey={(index) => memoizedBlueprints[index].id}
                onItemsRendered={onItemsRendered}
              />
            )}
          </InfiniteLoader>
        )}
      </FiltersLayout>
    </BlueprintsPageLayout>
  );
};

export default Blueprints;
