import { ReactNode } from "react";
import { RouterProvider } from "react-aria-components";
import { useNavigate, useHref as useRouterHref } from "react-router-dom";

import { isExternalPath } from "./helpers";

type AriaRouterProviderProps = {
  children: ReactNode;
};

const useHref = (href: string) => {
  const routerLink = useRouterHref(href);

  if (isExternalPath(href)) {
    return href;
  }

  return routerLink;
};

// This provider is needed to allow react aria components support client side routing.
// React Aria components such as Link, Menu, Tabs, Table, and many others support rendering elements as links that perform navigation when the user interacts with them.
const AriaRouterProvider = ({ children }: AriaRouterProviderProps) => {
  const navigate = useNavigate();

  return (
    <RouterProvider navigate={navigate} useHref={useHref}>
      {children}
    </RouterProvider>
  );
};

export default AriaRouterProvider;
