import camelCase from "lodash-es/camelCase";
import cx from "classnames";
import { Link } from "react-router-dom";

import { capitalizeFirstLetter } from "utils/strings";
import {
  CalendarArrow,
  CheckList,
  Circle,
  Clock,
  Cross,
  Edit,
  Eye,
  EyeArrowCircle,
  ExclamationMarkCircle,
  ExclamationMarkTriangle,
  Gear,
  Hourglass,
  Cross as CrossNew,
  InfoCircle,
  File,
  Flash,
  FlagRacing,
  Play,
  Policy,
  Skip,
  StopCircle,
  Tick,
  ThumbUp,
  ThumbDown,
  Trash,
} from "components/icons/generated";

import styles from "./styles.module.css";

export const STATE_ICONS: Record<string, JSX.Element> = {
  APPLYING: <Play className={styles.icon} />,
  BUSY: <Clock className={styles.icon} />,
  CONFIRMED: <ThumbUp className={styles.icon} />,
  CANCELED: <CrossNew className={styles.icon} />,
  SKIPPED: <Skip className={styles.icon} />,
  DESTROYING: <Trash className={styles.icon} />,
  DISCARDED: <ThumbDown className={styles.icon} />,
  DRAINED: <Cross className={styles.icon} />,
  DRAINING: <Clock className={styles.icon} />,
  FAILED: <ExclamationMarkCircle className={styles.icon} />,
  FINISHED: <Tick className={styles.icon} />,
  IDLE: <Tick className={styles.icon} />,
  INITIALIZING: <Gear className={styles.icon} />,
  "NO CHANGES": <Circle className={styles.icon} />,
  NONE: <Circle className={styles.icon} />,
  PERFORMING: <Flash className={styles.icon} />,
  PLANNING: <Edit className={styles.icon} />,
  PREPARING: <CheckList className={styles.icon} />,
  PREPARING_APPLY: <CheckList className={styles.icon} />,
  QUEUED: <Hourglass className={styles.icon} />,
  STOPPED: <StopCircle className={styles.icon} />,
  UNCONFIRMED: <Eye className={styles.icon} />,
  COMMENT: <File className={cx(styles.icon, styles.iconFlip)} />,
  WARNING: <ExclamationMarkTriangle className={cx(styles.icon, styles.iconFlip)} />,
  INFO: <InfoCircle className={styles.icon} />,
  POLICY: <Policy className={cx(styles.icon, styles.iconPolicy)} />,
  APPROVE: <ThumbUp className={cx(styles.icon, styles.iconReview)} />,
  REJECT: <ThumbDown className={cx(styles.icon, styles.iconReview)} />,
  REPLAN_REQUESTED: <CalendarArrow className={styles.icon} />,
  PREPARING_REPLAN: <CheckList className={styles.icon} />,
  READY: <FlagRacing className={styles.icon} />,
  PENDING_REVIEW: <EyeArrowCircle className={styles.icon} />,
};

export const prepareName = (type: string) => {
  switch (type) {
    case "PREPARING_APPLY":
    case "PREPARING_REPLAN":
      return "PREPARING";
    case "REPLAN_REQUESTED":
      return "REPLAN REQUESTED";
    case "PENDING_REVIEW":
      return "PENDING REVIEW";
    default:
      return type;
  }
};

type StateProps = {
  text?: string;
  type?: string;
  full?: boolean;
  link?: string;
  compact?: boolean;
  className?: string;
  isModuleVersion?: boolean;
};

const State = ({ text, type, full, link, compact, className, isModuleVersion }: StateProps) => {
  if (!type || type === null) {
    return null;
  }

  const elementClass = cx(
    styles.state,
    {
      [styles[camelCase(type)]]: !isModuleVersion,
      [styles[`moduleVersion${capitalizeFirstLetter(camelCase(type))}`]]: isModuleVersion,
      [styles.rectangular]: text,
      [styles.compact]: compact,
      [styles.link]: !!link && !text && !full,
    },
    className
  );

  const details = (
    <>
      {STATE_ICONS[type]}
      {full && <span className={styles.text}>{prepareName(type)}</span>}
      {text && <span className={styles.version}>{text}</span>}
    </>
  );

  if (link) {
    return (
      <Link to={link} className={elementClass}>
        {details}
      </Link>
    );
  }

  return <span className={elementClass}>{details}</span>;
};

export default State;
