import { gql } from "@apollo/client";

export const stackVendorConfigFragment = gql`
  fragment stackVendorConfig on Stack {
    vendorConfig {
      __typename
      ... on StackConfigVendorPulumi {
        loginURL
        stackName
      }
      ... on StackConfigVendorTerraform {
        version
        workspace
        useSmartSanitization
        externalStateAccessEnabled
        workflowTool
      }
      ... on StackConfigVendorCloudFormation {
        stackName
        entryTemplateFile
        templateBucket
        region
      }
      ... on StackConfigVendorKubernetes {
        namespace
        kubectlVersion
        kubernetesWorkflowTool
      }
      ... on StackConfigVendorAnsible {
        playbook
      }
      ... on StackConfigVendorTerragrunt {
        terraformVersion
        terragruntVersion
        tool
        effectiveVersions {
          effectiveTerragruntVersion
          effectiveTerraformVersion
        }
        useRunAll
        useSmartSanitization
      }
    }
  }
`;

export const moduleEditingDetails = gql`
  fragment moduleEditingDetails on Module {
    id
    administrative
    apiHost
    ownerSubdomain
    moduleSource
    isDisabled
    canWrite
    attachedContexts {
      id
      contextId
      contextName
      priority
      isAutoattached
    }
    attachedPolicies {
      id
      policyBody
      policyId
      policyName
      policyType
      isAutoattached
    }
    integrations {
      aws {
        activated
        assumedRoleArn
        assumeRoleLink
        assumeRolePolicyStatement
        externalID
        generateCredentialsInWorker
      }
      awsV2 {
        id
        read
        write
        durationSeconds
        externalId
        generateCredentialsInWorker
        integrationId
        name
        roleArn
      }
      azure {
        id
        read
        write
        defaultSubscriptionId
        subscriptionId
        integrationId
        integrationName
      }
      gcp {
        activated
        serviceAccountEmail
        tokenScopes
      }
      slack {
        activated
        channelNames
      }
      webhooks {
        id
        deleted
        enabled
        endpoint
        secret
      }
    }
    branch
    current {
      id
    }
    description
    githubActionDeploy
    labels
    name
    namespace
    projectRoot
    protectFromDeletion
    localPreviewEnabled
    provider
    repository
    repositoryURL
    runnerImage
    sharedAccounts
    spaceDetails {
      id
      name
      accessLevel
    }
    terraformProvider
    vcsIntegration {
      id
      name
      provider
    }
    workerPool {
      id
      name
    }
    workflowTool
  }
`;

export const stackEditingDetails = gql`
  fragment stackEditingDetails on Stack {
    id
    additionalProjectGlobs
    administrative
    afterApply
    afterDestroy
    afterInit
    afterPerform
    afterPlan
    attachedContexts {
      id
      contextId
      contextName
      priority
    }
    attachedPolicies {
      id
      policyBody
      policyId
      policyName
      policyType
      isAutoattached
    }
    autodeploy
    autoretry
    afterRun
    beforeApply
    beforeDestroy
    beforeInit
    beforePerform
    beforePlan
    branch
    description
    effectiveTerraformVersion
    enableSensitiveOutputUpload
    entityCount
    githubActionDeploy
    integrations {
      awsV2 {
        id
        read
        write
        durationSeconds
        externalId
        generateCredentialsInWorker
        integrationId
        legacy
        name
        roleArn
      }
      azure {
        id
        read
        write
        defaultSubscriptionId
        subscriptionId
        integrationId
        integrationName
      }
      gcp {
        activated
        serviceAccountEmail
        tokenScopes
      }
      slack {
        activated
        channelNames
      }
      webhooks {
        id
        deleted
        enabled
        endpoint
        secret
      }
      driftDetection {
        ignoreState
        reconcile
        timezone
        schedule
        nextSchedule
        deleted
      }
    }
    labels
    localPreviewEnabled
    enableWellKnownSecretMasking
    managesStateFile
    name
    namespace
    projectRoot
    protectFromDeletion
    provider
    repository
    repositoryURL
    runnerImage
    scheduledDeletes {
      id
      stack
      shouldDeleteResources
      timestampSchedule
      nextSchedule
    }
    scheduledTasks {
      id
      stack
      command
      timezone
      cronSchedule
      timestampSchedule
      nextSchedule
    }
    spaceDetails {
      id
      name
      accessLevel
    }
    state
    ...stackVendorConfig
    vcsIntegration {
      id
      name
      provider
    }
    workerPool {
      id
      name
    }
  }
  ${stackVendorConfigFragment}
`;

export const UPDATE_MODULE = gql`
  mutation UpdateModule($moduleId: ID!, $input: ModuleUpdateV2Input!) {
    moduleUpdateV2(id: $moduleId, input: $input) {
      ...moduleEditingDetails
    }
  }
  ${moduleEditingDetails}
`;

// TODO: use the UPDATE_STACK from `src/shared/Stack/gql.ts`
export const UPDATE_STACK = gql`
  mutation UpdateStackLegacy($stackId: ID!, $input: StackInput!) {
    stackUpdate(id: $stackId, input: $input) {
      ...stackEditingDetails
    }
  }
  ${stackEditingDetails}
`;
