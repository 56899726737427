import { Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import { Blueprint } from "types/generated";
import DescriptionDetails from "components/DescriptionDetails";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";
import DrawerSimple from "ds/components/DrawerNew/Simple";

type BlueprintDetailsProps = {
  item: Blueprint;
  onEdit?: () => void;
};

const BlueprintDetailsDrawer = createDrawer(({ item, onEdit }: BlueprintDetailsProps) => {
  const onOpenFullDescription = useShowFullDescriptionWithBackArrow(item.description);

  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Blueprint details" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody>
        <FormFieldViewText label="Name" value={item.name} />

        <FormField label="Space">
          <MetaInfoListItem
            icon={Space}
            linkText={item.space.name}
            href={`/spaces/${item.space.id}`}
          />
        </FormField>

        <DescriptionDetails
          description={item.description}
          onAddDescription={onEdit}
          onOpenFullDescription={onOpenFullDescription}
        />

        <FormFieldTimestamp label="Last updated" timestamp={item.updatedAt} />

        {item.labels.length > 0 && (
          <FormField label="Labels">
            <TagsList tags={item.labels} alwaysExpanded />
          </FormField>
        )}
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showBlueprintDetailsDrawer = createDrawerTrigger(BlueprintDetailsDrawer);
