import cx from "classnames";
import { mergeRefs } from "@react-aria/utils";
import { forwardRef } from "react";

import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";
import { transformDeltaCount } from "./helpers";
import { DeltaType } from "./types";

type DeltaCountsCellProps = {
  count: number;
  icon?: IconComponent;
  label: string;
  type: DeltaType;
  single?: boolean;
};

const DeltaCountsCell = forwardRef(function DeltaCountsCell(
  { count, label, icon, type, single }: DeltaCountsCellProps,
  forwardedRef: React.ForwardedRef<HTMLSpanElement>
) {
  return (
    <Tooltip
      on={({ className, ref, ...props }) => (
        <span
          {...props}
          ref={mergeRefs(ref, forwardedRef)}
          className={cx(
            styles.cell,
            styles[type],
            count > 0 && styles.colored,
            !icon && styles.noIcon,
            single && styles.single,
            className
          )}
        >
          {icon && <Icon src={icon} className={styles.icon} />}
          <span aria-hidden>{transformDeltaCount(count)}</span>
        </span>
      )}
    >
      {label}
    </Tooltip>
  );
});

export default DeltaCountsCell;
