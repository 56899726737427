import { fromUnixTime } from "date-fns";

import { formatDateTimeByLocale } from "utils/date";

export const parseLicenseDate = (date?: number) => {
  if (!date) return undefined;

  return formatDateTimeByLocale({
    date: fromUnixTime(date),
    format: "dateReadableLong",
    renderTimezone: "UTC",
  });
};
