import { FocusScope } from "react-aria";
import { useState } from "react";
import { DiffEditor } from "@monaco-editor/react";

import ThemedLogs from "components/ThemedLogs";
import ThemedLogsContent from "components/ThemedLogs/Content";
import FullScreenModalWrapper from "ds/components/FullScreenModal/Wrapper";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Toggle from "ds/components/Toggle";
import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import { Cross } from "components/icons/generated";

import styles from "./styles.module.css";
import { EDITOR_CONFIG } from "./constants";

type ConfigManagementTreeGridDrawerDiffFullScreenProps = {
  fullScreen: boolean;
  toggleFullScreen: (value?: boolean) => void;
  original: string;
  modified: string;
};

const ConfigManagementTreeGridDrawerDiffFullScreen = ({
  fullScreen,
  toggleFullScreen,
  modified,
  original,
}: ConfigManagementTreeGridDrawerDiffFullScreenProps) => {
  const { isDarkMode, currentDarkModeSyntaxTheme, currentLightModeSyntaxTheme } =
    useTypedContext(ThemeContext);
  const [sideBySide, setSideBySide] = useState(true);
  const handleSideBySideChange = () => setSideBySide((currentValue) => !currentValue);

  return (
    <FocusScope contain={fullScreen}>
      {fullScreen && original && modified && (
        <FullScreenModalWrapper>
          <Box
            direction="row"
            justify="between"
            padding="large"
            align="center"
            className={styles.fullScreenHeader}
          >
            <Box grow="1">
              <Typography variant="p-t4" tag="h1">
                Task details difference
              </Typography>
            </Box>

            <Box justify="end" align="center" grow="1" gap="large">
              <Toggle
                variant="switch"
                id="sidebyside"
                checked={sideBySide}
                onChange={handleSideBySideChange}
                ariaLabel={
                  sideBySide
                    ? "Show the differences in a single editor"
                    : "Show the differences in two side-by-side editors"
                }
              >
                Show side by side
              </Toggle>
              <ButtonIconNew variant="ghost" icon={Cross} onPress={() => toggleFullScreen(false)}>
                Close
              </ButtonIconNew>
            </Box>
          </Box>

          <ThemedLogs className={styles.fullScreen}>
            <ThemedLogsContent>
              <DiffEditor
                original={original}
                modified={modified}
                language="json"
                theme={isDarkMode ? currentDarkModeSyntaxTheme : currentLightModeSyntaxTheme}
                options={{
                  renderSideBySide: sideBySide,
                  ...EDITOR_CONFIG,
                }}
              />
            </ThemedLogsContent>
          </ThemedLogs>
        </FullScreenModalWrapper>
      )}
    </FocusScope>
  );
};

export default ConfigManagementTreeGridDrawerDiffFullScreen;
