import { useCallback, useEffect, useState } from "react";

import { isSelfHostedDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { SelfHostedInstanceInfo } from "./types";

const isSelfHosted = isSelfHostedDistribution();

const useSelfHostedInstanceProvider = () => {
  const [hasError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instanceInfo, setInstanceInfo] = useState<SelfHostedInstanceInfo | undefined>(undefined);

  const { reportError } = useTypedContext(FlashContext);

  const triggerFetchInstanceInfo = useCallback(
    (successCallback?: () => void) => {
      setIsLoading(true);

      fetch("/instance_info", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json();

            if (data) {
              setInstanceInfo({
                hasAccountBeenCreated: data.has_account_been_created,
                isAdminLoginEnabled: data.is_admin_login_enabled,
              });

              successCallback?.();
            }
          } else {
            const responseMessage = await response.text();

            setError(true);
            reportError({
              title: "Something went wrong",
              message: responseMessage || "Failed to fetch instance info",
            });
          }
        })
        .catch(() => {
          setError(true);
          reportError({ title: "Something went wrong", message: "Failed to fetch instance info" });
        })
        .finally(() => setIsLoading(false));
    },
    [reportError]
  );

  useEffect(() => {
    if (!instanceInfo && !isLoading && isSelfHosted && !hasError) {
      triggerFetchInstanceInfo();
    }
  }, [triggerFetchInstanceInfo, instanceInfo, isLoading, hasError]);

  return {
    instanceInfo,
    triggerFetchInstanceInfo,
  };
};

export default useSelfHostedInstanceProvider;
