import { ReactNode, createContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { Space, SpaceAccessLevel } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useURLParams from "hooks/useURLParams";
import { AccountContext } from "views/AccountWrapper";

import { SpacesDrawerMode } from "./types";
import { SpacesContext } from "../SpacesProvider";
import { CREATE_NEW_URL_PARAM, PARENT_ID_URL_PARAM } from "./constants";

type SpacesViewContextProps = {
  currentSpace?: Space;
  isDrawerVisible: boolean;
  drawerMode: SpacesDrawerMode;
  isRootAdmin: boolean;
};

export const SpacesViewContext = createContext<SpacesViewContextProps | undefined>(undefined);
SpacesViewContext.displayName = "SpacesViewContext";

type SpacesViewContextProviderProps = {
  children: ReactNode;
};

export const SpacesViewContextProvider = ({ children }: SpacesViewContextProviderProps) => {
  const { spaces } = useTypedContext(SpacesContext);
  const { spaceId } = useParams<{ spaceId?: string }>();
  const { viewer } = useTypedContext(AccountContext);

  const urlParams = useURLParams();
  const parentId = urlParams.get(PARENT_ID_URL_PARAM);
  const isCreateDrawer = urlParams.has(CREATE_NEW_URL_PARAM);

  const drawerMode = isCreateDrawer ? SpacesDrawerMode.Create : SpacesDrawerMode.Edit;

  const currentSpace = useMemo(
    () => spaces.find((space) => space.id === spaceId),
    [spaceId, spaces]
  );

  const createFromParent = useMemo(
    () => spaces.find((space) => space.id === parentId),
    [parentId, spaces]
  );

  const isDrawerVisible = isCreateDrawer || !!currentSpace;

  const rootSpace = useMemo(() => spaces?.find((space) => space.id === "root"), [spaces]);

  const isRootAdmin = viewer.admin || rootSpace?.accessLevel === SpaceAccessLevel.Admin;

  return (
    <SpacesViewContext.Provider
      value={{
        currentSpace: createFromParent || currentSpace,
        isDrawerVisible,
        drawerMode,
        isRootAdmin,
      }}
    >
      {children}
    </SpacesViewContext.Provider>
  );
};
