import { useModal, create, show } from "@ebay/nice-modal-react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import { createDefaultMutationHook } from "hooks/useMutationHandler";

import { SLACK_APP_CONFIG_DELETE } from "./gql";

const useSlackAppConfigDelete = createDefaultMutationHook(SLACK_APP_CONFIG_DELETE);

const SettingsIntegrationsSlackDeleteSelfHosted = create(
  function SettingsIntegrationsSlackDeleteSelfHosted() {
    const modal = useModal();

    const { reportSuccess } = useTypedContext(FlashContext);

    const [slackAppConfigDelete, { loading, data }] = useSlackAppConfigDelete({
      refetchQueries: ["GetAccountIntegrations"],
    });

    const handleDelete = () => {
      slackAppConfigDelete({}, (data) => {
        if (data?.slackAppConfigDelete) {
          reportSuccess({ message: "Slack configuration successfully deleted" });
          modal.hide();
        }
      });
    };

    return (
      <DeleteConfirmationModal
        title="Delete Slack configuration"
        onConfirm={handleDelete}
        isLoading={loading}
        isDismissable={!loading && !data?.slackAppConfigDelete?.clientId}
        confirmationButtonLabel="Delete"
        size="large"
      >
        <ConfirmationModalMessage>
          Are you sure you want to delete current Slack configuration?
        </ConfirmationModalMessage>
      </DeleteConfirmationModal>
    );
  }
);

export const showDeletingConfirmation = () => show(SettingsIntegrationsSlackDeleteSelfHosted);
