import { Navigate, Route, Routes } from "react-router-dom";
import { useQuery } from "@apollo/client";

import useTypedFlags from "hooks/useTypedFlags";
import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import PageWrapper from "components/PageWrapper";
import PageLayoutSkeleton from "components/PageLayoutSkeleton";
import SubSidebar from "components/SubSidebar";
import SubSidebarSection from "components/SubSidebar/Section";
import SubSidebarSectionItem from "components/SubSidebar/SectionItem";
import SubSidebarSectionTitle from "components/SubSidebar/SectionTitle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import BoxNew from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";

import styles from "./styles.module.css";
import { GET_PERSONAL_SETTINGS } from "./gql";
import ThemeAppearance from "./ThemeAppearance";
import MFASettings from "./MFASettings";
import PersonalSettingsSpaces from "./Spaces";
import PersonalSettingsGroups from "./Groups";
import { PersonalAccessTabs } from "../Settings/types";
import CustomizeSidebar from "./CustomizeSidebar";
import PersonalSettingsApiKeys from "./ApiKeys";
import { SpacesContext } from "../SpacesProvider";
import AccessibilitySettings from "./Accessibility";
import NotificationsCustomization from "./NotificationsCustomization";

const PersonalSettings = () => {
  const { enableApiKeyCreationForNonRootAdminsFrontend, vcsEventsShowIgnoredRuns } =
    useTypedFlags();
  const { onError } = useTypedContext(FlashContext);
  const { hasManageableSpaces } = useTypedContext(SpacesContext);

  const { data, loading } = useQuery(GET_PERSONAL_SETTINGS, {
    onError,
    variables: {
      skipApiKeysManagedByNonRootAdminsField: !enableApiKeyCreationForNonRootAdminsFrontend,
    },
  });

  if (loading && !data?.authorizationScheme) {
    return <PageLayoutSkeleton />;
  }

  const shouldShowApiKeys =
    enableApiKeyCreationForNonRootAdminsFrontend &&
    data?.apiKeysManagedByNonRootAdmins &&
    hasManageableSpaces;

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper direction="row">
          <ViewHeaderTitle titleSize="p-t3">Personal settings</ViewHeaderTitle>
        </ViewHeaderWrapper>
      </ViewHeader>
      <BoxNew className={styles.content}>
        <SubSidebar>
          <SubSidebarSection>
            <SubSidebarSectionTitle>ACCESS</SubSidebarSectionTitle>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPagePersonal.Personal}
              analyticsTitle="Switch View"
              analyticsProps={{ tab: PersonalAccessTabs.SPACES }}
              to="spaces"
            >
              Spaces
            </SubSidebarSectionItem>
            <SubSidebarSectionItem
              analyticsPage={AnalyticsPagePersonal.Personal}
              analyticsTitle="Switch View"
              analyticsProps={{ tab: PersonalAccessTabs.IDP_GROUPS }}
              to="groups"
            >
              IdP groups
            </SubSidebarSectionItem>
            {shouldShowApiKeys && (
              <SubSidebarSectionItem
                analyticsPage={AnalyticsPagePersonal.Personal}
                analyticsTitle="Switch View"
                analyticsProps={{ tab: PersonalAccessTabs.API_KEYS }}
                to="api-keys"
              >
                API keys
              </SubSidebarSectionItem>
            )}
          </SubSidebarSection>

          <SubSidebarSection>
            <SubSidebarSectionTitle>AUTHENTICATION</SubSidebarSectionTitle>
            <SubSidebarSectionItem to="mfa">Multi-factor authentication</SubSidebarSectionItem>
          </SubSidebarSection>

          <SubSidebarSection>
            <SubSidebarSectionTitle>PERSONALIZATION</SubSidebarSectionTitle>
            <SubSidebarSectionItem to="theme-appearance">Theme appearance</SubSidebarSectionItem>
            <SubSidebarSectionItem to="sidebar-customization">
              Sidebar customization
            </SubSidebarSectionItem>
            {vcsEventsShowIgnoredRuns && (
              <SubSidebarSectionItem to="notifications-customization">
                Notifications customization
              </SubSidebarSectionItem>
            )}
            <SubSidebarSectionItem to="accessibility">Accessibility</SubSidebarSectionItem>
          </SubSidebarSection>
        </SubSidebar>

        <PageWrapper className={styles.wrapper}>
          <Routes>
            <Route index element={<Navigate to="spaces" replace />} />
            <Route path="theme-appearance" element={<ThemeAppearance />} />
            <Route path="sidebar-customization" element={<CustomizeSidebar />} />
            {!vcsEventsShowIgnoredRuns && (
              <Route path="notifications-customization" element={<Navigate to=".." replace />} />
            )}
            <Route path="notifications-customization" element={<NotificationsCustomization />} />
            <Route path="accessibility" element={<AccessibilitySettings />} />
            <Route
              path="spaces"
              element={<PersonalSettingsSpaces authorizationScheme={data?.authorizationScheme} />}
            />
            <Route
              path="groups"
              element={<PersonalSettingsGroups authorizationScheme={data?.authorizationScheme} />}
            />
            {shouldShowApiKeys && (
              <Route
                path="api-keys"
                element={
                  <PersonalSettingsApiKeys authorizationScheme={data?.authorizationScheme} />
                }
              />
            )}
            <Route path="mfa" element={<MFASettings />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </PageWrapper>
      </BoxNew>
    </>
  );
};

export default PersonalSettings;
