import Box from "ds/components/Box";
import { EmptystateCloudColored } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";
import LoadingIndicator from "ds/components/LoadingIndicator";
import KeyValue from "components/KeyValue";
import MissingDataBanner from "components/MissingDataBanner";

import { ModuleCreationCloud, ModuleCreationWizardStep } from "../types";
import NewModuleSummaryPanel from "./Panel";
import { formatToggleValue } from "./formatToggleValue";
import NewModuleAttachCloudDetailsAWSFields from "../AttachCloud/Details/AWSFields";
import NewModuleAttachCloudDetailsAzureFields from "../AttachCloud/Details/AzureFields";
import useAttachedCloudIntegration from "../AttachCloud/hooks/useAttachedCloudIntegration";

type NewModuleSummaryCloudProps = {
  title: string;
  goToStepDisabled?: boolean;
  createdModuleId: string;
};

const NewModuleSummaryCloud = ({
  title,
  goToStepDisabled,
  createdModuleId,
}: NewModuleSummaryCloudProps) => {
  const { attachedIntegration, attachedIntegrationType, loading, refetching, refetch, hasData } =
    useAttachedCloudIntegration(createdModuleId);

  const renderEmptyPlaceholder = () => {
    if (loading) {
      return (
        <Box padding="large" justify="center">
          <LoadingIndicator size="large" loading />
        </Box>
      );
    }

    if (!hasData) {
      return (
        <MissingDataBanner
          text="Couldn't load attached integration, please refresh or come back later"
          refreshHandler={refetch}
          refreshLoading={refetching}
        />
      );
    }

    return (
      <EmptyState
        padding="large"
        icon={EmptystateCloudColored}
        title="Cloud integration not attached"
        caption="Integrate module with your cloud provider to manage your resources without the need for long-lived static credentials."
      />
    );
  };

  return (
    <NewModuleSummaryPanel
      name={title}
      step={ModuleCreationWizardStep.Cloud}
      goToStepDisabled={goToStepDisabled}
    >
      <Box direction="column">
        {attachedIntegration && (
          <>
            {attachedIntegrationType === ModuleCreationCloud.AWS && (
              <NewModuleAttachCloudDetailsAWSFields integration={attachedIntegration} />
            )}

            {attachedIntegrationType === ModuleCreationCloud.Azure && (
              <NewModuleAttachCloudDetailsAzureFields integration={attachedIntegration} />
            )}
            <KeyValue name="Read">{formatToggleValue(attachedIntegration.read)}</KeyValue>
            <KeyValue name="Write">{formatToggleValue(attachedIntegration.write)}</KeyValue>
          </>
        )}

        {!attachedIntegration && renderEmptyPlaceholder()}
      </Box>
    </NewModuleSummaryPanel>
  );
};

export default NewModuleSummaryCloud;
