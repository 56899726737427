import { gql, TypedDocumentNode } from "@apollo/client";

import {
  ManagedUserInvitation,
  Maybe,
  SlackIntegration,
  VcsIntegrationsConnection,
  VcsProvider,
  WorkerPoolConnection,
} from "types/generated";

type FetchGetSelfHostedOnboardingProgressData = {
  providers: Array<VcsProvider>;
  searchVCSIntegrations: {
    edges: Array<{ cursor: VcsIntegrationsConnection["cursor"] }>;
  };
  searchWorkerPools: {
    edges: Array<{ cursor: WorkerPoolConnection["cursor"] }>;
  };
  managedUsers: Array<{ latestInvitation: ManagedUserInvitation["sentAt"] }>;
  hasSSO: boolean;
  slackIntegration: Maybe<Pick<SlackIntegration, "teamID">>;
};
export const GET_SELF_HOSTED_ONBOARDING_PROGRESS: TypedDocumentNode<FetchGetSelfHostedOnboardingProgressData> = gql`
  query GetSelfHostedOnboardingProgress(
    $searchVcsIntegrationsInput: SearchInput!
    $searchWorkerPoolsInput: SearchInput!
  ) {
    providers
    searchVCSIntegrations(input: $searchVcsIntegrationsInput) {
      edges {
        cursor
      }
    }
    searchWorkerPools(input: $searchWorkerPoolsInput) {
      edges {
        cursor
      }
    }
    managedUsers {
      latestInvitation {
        sentAt
      }
    }
    hasSSO
    slackIntegration {
      teamID
    }
  }
`;
