import { useModal } from "@ebay/nice-modal-react";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { ManagedUserGroupIntegration } from "types/generated";
import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import useTypedContext from "hooks/useTypedContext";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Spaces";
import TabPanel from "ds/components/TabPanel";
import TabWithCounter from "ds/components/Tab/WithCounter";
import Banner from "ds/components/Banner";
import FeedbackActions from "ds/components/Feedback/Actions";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import ButtonNew from "ds/components/Button/New";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { getDrawerFormFix } from "utils/css";

import { UserManagementActivationStatus } from "../../types";
import { UPDATE_GROUP_INTEGRATION_DRAWER_TEST_ID } from "./constants";
import { GroupsIntegrationsDrawerTabs, UpdateGroupIntegrationFields } from "./types";
import useUpdateGroup from "./useUpdateGroupIntegration";
import styles from "./styles.module.css";

type GroupsIntegrationsDetailsDrawerProps = {
  currentIntegration: ManagedUserGroupIntegration;
  activationStatus: UserManagementActivationStatus;
};

const ManageSlackAccessDetailsDrawer = createDrawer(
  ({ currentIntegration, activationStatus }: GroupsIntegrationsDetailsDrawerProps) => {
    const { onError, reportSuccess } = useTypedContext(FlashContext);
    const [currentTab, setCurrentTab] = useState("spaces");
    const drawer = useModal();

    const groupIntegrationUpdateForm = useForm<UpdateGroupIntegrationFields>({
      defaultValues: {
        spaces:
          currentIntegration.accessRules?.length > 0
            ? currentIntegration.accessRules
            : [{ space: undefined, spaceAccessLevel: undefined }],
      },
      mode: "onChange",
    });

    const {
      handleSubmit,
      formState: { isValid, isDirty },
    } = groupIntegrationUpdateForm;

    const { onUpdate } = useUpdateGroup();

    const onSubmit: SubmitHandler<UpdateGroupIntegrationFields> = (formData) => {
      const input = {
        id: currentIntegration.id,
        integrationType: currentIntegration.integrationType,
        integrationName: currentIntegration.integrationName,
        accessRules: formData.spaces,
        slackChannelID: currentIntegration.slackChannelID || "",
      };

      onUpdate(input)
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: `Slack access was updated`,
            });

            drawer.hide();
          }
        })
        .catch(onError);
    };

    return (
      <DrawerSimple dataTestId={UPDATE_GROUP_INTEGRATION_DRAWER_TEST_ID}>
        <FormProvider {...groupIntegrationUpdateForm}>
          <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Access details" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight hasStickyFooter>
              {activationStatus === UserManagementActivationStatus.INACTIVE && (
                <Banner variant="warning" title="User management is inactive">
                  Access rules will not take effect until you activate user management.
                  <FeedbackActions>
                    <Button
                      to="/settings/management-strategy/"
                      analyticsPage={AnalyticsPageOrganization.OrganizationSlackAccess}
                      analyticsTitle="Callout Strategy Click"
                      variant="contrast"
                      size="small"
                    >
                      Choose strategy
                    </Button>
                  </FeedbackActions>
                </Banner>
              )}

              <FormFieldViewText label="Name" value={currentIntegration.integrationName} />

              <FormFieldViewText
                label="Slack channel ID"
                value={currentIntegration.slackChannelID}
                withCopy
              />

              <Box direction="row" align="center" className={styles.tabs}>
                <TabWithCounter
                  onClick={setCurrentTab}
                  isActive={currentTab === GroupsIntegrationsDrawerTabs.Spaces}
                  id={GroupsIntegrationsDrawerTabs.Spaces}
                  label="Spaces"
                  count={currentIntegration.accessRules?.length}
                />
              </Box>

              <TabPanel
                isActive={currentTab === GroupsIntegrationsDrawerTabs.Spaces}
                id={GroupsIntegrationsDrawerTabs.Spaces}
              >
                <div className={styles.listWrapper}>
                  <FormFieldSpace />
                </div>
              </TabPanel>

              <DrawerFooter sticky>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <ButtonNew variant="primary" type="submit" disabled={!isValid || !isDirty}>
                    Save changes
                  </ButtonNew>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showManageSlackAccessDetailsDrawer = createDrawerTrigger(
  ManageSlackAccessDetailsDrawer
);
