import { ReactNode } from "react";

import Button from "ds/components/Button";
import { File } from "components/icons/generated";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerHeader from "ds/components/Drawer/Header";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import styles from "./styles.module.css";
import { StackCreationWizardStep } from "../types";
import useStackCreationAnalyticsVersion from "../useStackCreationAnalyticsVersion";

type NewStackFooterDocumentationDrawerProps = {
  documentationBody: ReactNode;
  documentationTitle: string;
  documentationLink?: string;
  analyticsDocsLocation?: string;
  currentStep: StackCreationWizardStep;
};

const NewStackFooterDocumentationDrawer = createDrawer(
  ({
    documentationTitle,
    documentationBody,
    documentationLink,
    analyticsDocsLocation,
    currentStep,
  }: NewStackFooterDocumentationDrawerProps) => {
    const analyticsVersion = useStackCreationAnalyticsVersion();

    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title={documentationTitle} />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody className={styles.documentationBody}>{documentationBody}</DrawerBody>
        <DrawerFooter>
          <Button
            analyticsPage={AnalyticsPageStack.StackNew}
            analyticsTitle="Full documentation click"
            analyticsProps={{
              location: analyticsDocsLocation
                ? `${currentStep} ${analyticsDocsLocation}`
                : currentStep,
              version: analyticsVersion,
            }}
            href={documentationLink}
            variant="secondary"
            size="medium"
            target="_blank"
            startIcon={File}
            className={styles.documentationButton}
          >
            Open documentation
          </Button>
        </DrawerFooter>
      </DrawerSimple>
    );
  }
);

export const showNewStackFooterDocumentationDrawer = createDrawerTrigger(
  NewStackFooterDocumentationDrawer
);
