import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationOidcCreateArgs, OidcSettings } from "types/generated";

type FetchOIDCCreate = { oidcCreate: Pick<OidcSettings, "adminLogin"> };
export const CREATE_SSO_OIDC: TypedDocumentNode<FetchOIDCCreate, MutationOidcCreateArgs> = gql`
  mutation SSOCreateOIDC(
    $clientId: String!
    $clientCredentials: String!
    $identityProviderHost: String!
    $claimMapping: ClaimMappingInput
  ) {
    oidcCreate(
      clientId: $clientId
      clientCredentials: $clientCredentials
      identityProviderHost: $identityProviderHost
      claimMapping: $claimMapping
    ) {
      adminLogin
    }
  }
`;
