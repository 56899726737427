import { ReactNode, useCallback, useId } from "react";

import { withTestId } from "utils/withTestId";

import Box from "../Box";
import Typography from "../Typography";
import styles from "./styles.module.css";
import { SEGMENT_TEST_ID } from "./constants";
import Tooltip from "../Tooltip";

type SegmentedControlSegmentProps<T extends string> = {
  controlName: string;
  label: ReactNode;
  value: T;
  selected?: boolean;
  disabled?: boolean;
  tooltip?: ReactNode;
  onChange: (value: T) => void;
  className?: string;
};

function SegmentedControlSegment<T extends string>({
  controlName,
  label,
  value,
  selected,
  disabled,
  tooltip,
  onChange,
  className,
}: SegmentedControlSegmentProps<T>) {
  const id = useId();

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value as T);
    },
    [onChange]
  );

  return (
    <Box grow="1" className={className}>
      <Tooltip
        active={!!tooltip}
        widthMode="maxWidthXl"
        textAlign="center"
        on={({ ref }) => (
          <>
            <input
              id={id}
              type="radio"
              name={controlName}
              value={value}
              className={styles.input}
              checked={selected}
              disabled={disabled}
              onChange={handleOnChange}
            />

            <Typography
              ref={ref}
              tag="label"
              variant="p-t7"
              htmlFor={id}
              className={styles.label}
              {...withTestId(SEGMENT_TEST_ID)}
            >
              {label}
            </Typography>
          </>
        )}
      >
        {tooltip}
      </Tooltip>
    </Box>
  );
}

export default SegmentedControlSegment;
