import { useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import ButtonNew from "ds/components/Button/New";

const StackSettingsIntegrationsCloudAttachFormActions = () => {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext();

  return (
    <Box justify="end">
      <ButtonNew
        type="submit"
        variant="primary"
        loading={isSubmitting}
        disabled={isSubmitting || !isValid}
      >
        Attach
      </ButtonNew>
    </Box>
  );
};

export default StackSettingsIntegrationsCloudAttachFormActions;
