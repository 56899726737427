import { NetworkStatus, useQuery } from "@apollo/client";
import { fromUnixTime } from "date-fns";
import { useEffect } from "react";

import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import EmptyState from "ds/components/EmptyState";
import { ArrowsLeftRight, EmptystateCalendarClockColored, Stack } from "components/icons/generated";
import DocumentationButton from "components/DocumentationButton";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TileContent from "ds/components/Tile/Content";
import Typography from "ds/components/Typography";
import IconTile from "ds/components/IconTile";
import { getDocsUrl } from "utils/getDocsUrl";
import { formatDateTimeByLocale } from "utils/date";
import Link from "ds/components/Link";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import MissingDataBanner from "components/MissingDataBanner";

import DashboardWidgetsNextDriftDetectionScheduleSkeleton from "./Skeleton";
import { GET_UPCOMING_DRIFT_DETECTION_SCHEDULES } from "./gql";
import { GetUpcomingDriftDetectionSchedulesGql } from "./types";
import styles from "./styles.module.css";
import usePollInterval from "../../usePollInterval";

const DashboardWidgetsNextDriftDetectionSchedule = () => {
  const pollInterval = usePollInterval();

  const { data, loading, error, refetch, startPolling, stopPolling, networkStatus } =
    useQuery<GetUpcomingDriftDetectionSchedulesGql>(GET_UPCOMING_DRIFT_DETECTION_SCHEDULES, {
      onError() {
        stopPolling();
      },
    });

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  if (error || networkStatus === NetworkStatus.refetch) {
    return (
      <MissingDataBanner
        text="Couldn’t load drift detection schedule. Please try to refresh or come back later."
        refreshHandler={refetch}
        refreshLoading={loading}
      />
    );
  }

  const isLoading = loading && !data?.metrics?.upcomingDriftDetectionSchedules;

  if (isLoading) {
    return <DashboardWidgetsNextDriftDetectionScheduleSkeleton />;
  }

  const schedules = data?.metrics?.upcomingDriftDetectionSchedules?.filter(
    ({ driftDetetionIntegration }) => !!driftDetetionIntegration.nextSchedule
  );
  const hasNoSchedule = !schedules?.length;

  return (
    <Box direction="column" fullWidth>
      {hasNoSchedule && (
        <TileWrapper>
          <Box padding="small" fullWidth justify="center">
            <EmptyState
              title="You haven't created any schedules yet"
              icon={EmptystateCalendarClockColored}
              caption="Navigate to Stack view / Scheduling to create your first schedule."
            >
              <Box gap="medium">
                <DocumentationButton to={getDocsUrl("/concepts/stack/drift-detection.html")} />
                {/* TODO: [dashboard] userflow trigger */}
                {/* <Button variant="primary">Show me how</Button> */}
              </Box>
            </EmptyState>
          </Box>
        </TileWrapper>
      )}

      {!hasNoSchedule && (
        <Box direction="column" gap="medium">
          {schedules.map(({ driftDetetionIntegration, stackTile }, i) => (
            <TileWrapper key={i}>
              <TileContent gap="small">
                <Box gap="large" align="start" fullWidth>
                  <IconTile icon={ArrowsLeftRight} />
                  <Box direction="column" gap="small" align="start" className={styles.content}>
                    <Link
                      analyticsTitle="Next Drift Detection Schedule Widget - Schedule Clicked"
                      analyticsPage={AnalyticsPageDashboard.Dashboard}
                      variant="secondary"
                      to={`/stack/${stackTile.slug}/scheduling`}
                    >
                      <Typography variant="p-t6" tag="span">
                        {formatDateTimeByLocale({
                          date: fromUnixTime(driftDetetionIntegration.nextSchedule!),
                          format: "dateTimeShortNoZone",
                          renderTimezone: driftDetetionIntegration.timezone,
                        })}
                      </Typography>
                    </Link>

                    <MetaInfoListItem
                      fullWidth
                      analyticsTitle="Next Drift Detection Schedule Widget - Stack Clicked"
                      analyticsPage={AnalyticsPageDashboard.Dashboard}
                      to={`/stack/${stackTile.slug}`}
                      linkText={stackTile.name}
                      icon={Stack}
                    />
                  </Box>
                </Box>
              </TileContent>
            </TileWrapper>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DashboardWidgetsNextDriftDetectionSchedule;
