import { gql, TypedDocumentNode } from "@apollo/client";

import { ManagedUser, Maybe, OidcSettings, SamlSettings } from "types/generated";

type FetchSSOSettingsQuery = {
  hasSSO: boolean;
  managedUsers: Array<Pick<ManagedUser, "id">>;
  samlSettings: Maybe<SamlSettings>;
  oidcSettings: Maybe<OidcSettings>;
};
export const GET_SSO_SETTINGS: TypedDocumentNode<FetchSSOSettingsQuery> = gql`
  query GetSSOSettings {
    hasSSO
    managedUsers {
      id
    }
    samlSettings {
      adminLogin
      idpMetadata
      idpMetadataURL
      nameIDFormat
    }
    oidcSettings {
      adminLogin
      clientId
      identityProviderHost
      claimMapping {
        entries {
          name
          value
        }
      }
    }
  }
`;
