import { Hourglass } from "components/icons/generated";
import Timestamp from "components/time/Timestamp";
import ListBox from "components/listBox/index";
import ListBoxInfoWrapper from "components/listBox/InfoWrapper";
import ListBoxItem from "components/listBox/Item";
import { VcsProvider } from "types/generated";
import { getCommmitDigitsCount } from "utils/commit";

type TaskElementProps = {
  id: string;
  canStart: boolean;
  command: string;
  commit: {
    hash: string;
    url: string;
  };
  createdAt: number;
  stackId: string;
  state: string;
  triggeredBy: string;
  provider: VcsProvider;
};

const TaskElement = (props: TaskElementProps) => {
  const { stackId, command, provider } = props;

  return (
    <ListBox
      headerLink={{
        name: command,
        to: `/stack/${stackId}/run/${props.id}`,
        routerLink: true,
        renderAsCode: true,
      }}
      performAgain={props.canStart ? { stackId, command } : undefined}
      state={{ content: props.state, showTitle: true }}
    >
      <ListBoxInfoWrapper>
        <ListBoxItem icon={Hourglass}>
          Started: <Timestamp timestamp={props.createdAt} /> by {props.triggeredBy}
        </ListBoxItem>
        <ListBoxItem
          link={{
            url: props.commit.url,
            text: props.commit.hash.slice(0, getCommmitDigitsCount(provider)),
          }}
        />
      </ListBoxInfoWrapper>
    </ListBox>
  );
};

export default TaskElement;
