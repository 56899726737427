import Box from "ds/components/Box";
import CodeEditor from "components/CodeEditor";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import styles from "./styles.module.css";

type NewModuleAttachedPolicyPreviewDrawerProps = {
  policyBody: string;
  onClose: () => void;
};

const NewModuleAttachedPolicyPreviewDrawer = createDrawer(
  ({ policyBody, onClose }: NewModuleAttachedPolicyPreviewDrawerProps) => {
    return (
      <DrawerSimple variant="wide" onClose={onClose}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Policy preview" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <Box className={styles.codeEditorInDrawerWrapper}>
            <CodeEditor body={policyBody} language="rego" readOnly />
          </Box>
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showNewModuleAttachedPolicyPreviewDrawer = createDrawerTrigger(
  NewModuleAttachedPolicyPreviewDrawer
);
