import { useState } from "react";
import { useMutation } from "@apollo/client";

import Box from "ds/components/Box";
import Button from "ds/components/Button";
import CardWrapper from "components/CardWrapper";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import Banner from "ds/components/Banner";
import MissingDataBanner from "components/MissingDataBanner";
import { PolicyStackAttachment, PolicyType } from "types/generated";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import TextLink from "components/DocumentationSnippets/TextLink";
import EmptyState from "ds/components/EmptyState";
import DocumentationButton from "components/DocumentationButton";
import { EmptystateHelmetColored } from "components/icons/generated";
import FormLoading from "components/form/components/loading";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FlashContext from "components/FlashMessages/FlashContext";
import { useObserveForWarning } from "components/WarningContext/useObserveForWarning";
import { getDocsUrl } from "utils/getDocsUrl";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import { StackFormContext } from "../context";
import { usePolicyTypeOptions } from "./hooks/usePolicyTypeOptions";
import NewStackAutoAttachedPolicies from "./AutoAttachedPolicies";
import NewStackManuallyAttachedPolicies from "./ManuallyAttachedPolicies";
import { useAttachedPolicies } from "./hooks/useAttachedPolicies";
import Documentation from "./Documentation";
import NewStackFooter from "../Footer";
import { ATTACH_POLICY } from "./gql";
import useErrorHandlerNewStack from "../useErrorHandlerNewStack";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";

const NewStackAttachPolicies = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const { createdStackId } = useTypedContext(StackFormContext);

  const [policy, setPolicy] = useState("");
  const [policyType, setPolicyType] = useState<PolicyType | undefined>();

  useObserveForWarning(
    !!policy,
    <>
      You have not attached the <strong>selected policy</strong>. Do you want to continue without
      attaching it?
    </>
  );

  const {
    allPolicies,
    attachablePolicies,
    attachedPolicies,
    autoAttachedPolicies,
    manuallyAttachablePolicyTypes,
    loading,
    hasData,
    refetching,
    refetch,
    attachedError,
    attachableError,
  } = useAttachedPolicies();

  const attachablePolicyOptions = attachablePolicies.filter((policy) => policy.type === policyType);
  const policyTypeOptions = usePolicyTypeOptions(manuallyAttachablePolicyTypes);

  const [attachPolicy, { loading: isAttachingPolicy }] = useMutation<{
    policyAttach: PolicyStackAttachment;
  }>(ATTACH_POLICY, {
    refetchQueries: ["GetAttachedStackPolicies"],
    awaitRefetchQueries: true,
  });

  const handleAttach = () => {
    attachPolicy({ variables: { stackId: createdStackId, policyId: policy } })
      .then(({ data }) => {
        if (data?.policyAttach?.id) {
          setPolicy("");
          reportSuccess({ message: "Policy was attached" });
          trackSegmentEvent("Policy attached");
        }
      })
      .catch(onError);

    if (attachablePolicyOptions.length === 1) {
      setPolicyType(undefined);
    }
  };

  useErrorHandlerNewStack(attachedError);
  useErrorHandlerNewStack(attachableError);

  if (loading) {
    return <FormLoading />;
  }

  const hasNoPolicies = hasData && !allPolicies.length && !attachablePolicies.length;

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h1" variant="p-t4" align="center">
          Attach policies (optional)
        </Typography>
        {hasNoPolicies ? (
          <Box direction="column" gap="large" margin="large 0 0 0">
            <Banner variant="info">
              You can only attach policies from the current space and parent spaces that you inherit
              from.
            </Banner>
            <CardWrapper variant="filled" direction="column">
              <EmptyState
                padding="large"
                icon={EmptystateHelmetColored}
                title="You do not have any policies yet"
                caption={
                  <>
                    Policies are used to control various decision points throughout your workflow
                    from how many approvals you need for a run, what happens when a PR is open, what
                    resources you can create, where you receive notifications and others. This gives
                    you full flexibility on owning your workflow and ensuring governance and
                    compliance through the integration & deployment lifecycles. <br /> Read more in
                    the Documentation.
                  </>
                }
              >
                <DocumentationButton to={getDocsUrl("/concepts/policy")} label="Documentation" />
              </EmptyState>
            </CardWrapper>
          </Box>
        ) : (
          <>
            <Typography
              tag="p"
              variant="p-body2"
              align="center"
              color="secondary"
              margin="small 0 large 0"
            >
              Policies only affect stacks they’re attached to
            </Typography>

            <Banner variant="info">
              You can only attach policies from the current space and parent spaces that you inherit
              from.
            </Banner>

            {!hasData && (
              <Box margin="large 0 0 0" direction="column">
                <MissingDataBanner
                  text="Couldn't load policies. Please try to refresh or come back later."
                  refreshHandler={refetch}
                  refreshLoading={refetching}
                />
              </Box>
            )}

            <CardWrapper variant="filled" direction="column" margin="large 0">
              <FormField
                label="Policy type"
                tooltipInfoVariant="modal"
                tooltipInfo={
                  <>
                    <TooltipModalTitle>Policy type</TooltipModalTitle>
                    <TooltipModalBody align="start">
                      <Typography tag="p" variant="p-body3">
                        Spacelift uses an open-source project called{" "}
                        <TextLink href="https://www.openpolicyagent.org/">
                          <Typography tag="span" variant="p-body3">
                            Open Policy Agent
                          </Typography>
                        </TextLink>{" "}
                        and its rule language,{" "}
                        <TextLink href="https://www.openpolicyagent.org/docs/latest/policy-language/">
                          <Typography tag="span" variant="p-body3">
                            Rego
                          </Typography>
                        </TextLink>
                        , to execute user-defined pieces of code we call Policies at various
                        decision points. Policies come in different flavors that we call types, with
                        each type being executed at a different decision point.
                      </Typography>
                      <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/policy")} />
                    </TooltipModalBody>
                  </>
                }
              >
                {({ ariaInputProps }) => (
                  <Select
                    autocomplete
                    value={policyType}
                    options={policyTypeOptions}
                    onChange={setPolicyType}
                    loading={loading}
                    ariaInputProps={ariaInputProps}
                  />
                )}
              </FormField>
              <FormField label="Select policy">
                {({ ariaInputProps }) => (
                  <Select
                    autocomplete
                    value={policy}
                    options={attachablePolicyOptions}
                    onChange={setPolicy}
                    loading={loading}
                    disabled={!policyType}
                    ariaInputProps={ariaInputProps}
                  />
                )}
              </FormField>
              <Box justify="end" margin="large 0 0">
                <Button
                  variant="contrast"
                  onClick={handleAttach}
                  disabled={attachablePolicies.length === 0 || !policy || isAttachingPolicy}
                  loading={isAttachingPolicy}
                >
                  Attach
                </Button>
              </Box>
            </CardWrapper>

            <Box gap="large" direction="column">
              <NewStackManuallyAttachedPolicies items={attachedPolicies} />
              <NewStackAutoAttachedPolicies items={autoAttachedPolicies} />
            </Box>
          </>
        )}
      </FullScreenModalBody>
      <NewStackFooter
        loading={loading}
        documentationLink={getDocsUrl("/concepts/policy#attaching-policies")}
        documentationTitle="Attach policy"
        documentationBody={<Documentation />}
      />
    </>
  );
};

export default NewStackAttachPolicies;
