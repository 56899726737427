import Typography from "ds/components/Typography";
import TextLink from "components/DocumentationSnippets/TextLink";
import { getDocsUrl } from "utils/getDocsUrl";

import { StackCreationVendor } from "../types";

type NewStackVendorDocumentationProps = {
  vendor: StackCreationVendor;
  children: (props: { body: JSX.Element; link: string }) => JSX.Element;
};

const NewStackVendorDocumentation = ({ vendor, children }: NewStackVendorDocumentationProps) => {
  switch (vendor) {
    case StackCreationVendor.Terraform: {
      return children({
        link: getDocsUrl("/vendors/terraform"),
        body: (
          <>
            <Typography tag="p" variant="p-t7" margin="0 0 small 0">
              Terraform
            </Typography>
            <Typography tag="p" variant="p-body2">
              When selecting Terraform, you can choose which version of Terraform to start with - we
              support Terraform 0.12.0 and above. You don't need to dwell on this decision since you
              can change the version later - Spacelift supports full{" "}
              <TextLink href={getDocsUrl("/vendors/terraform/version-management")}>
                Terraform version management
              </TextLink>{" "}
              allowing you to even preview the impact of upgrading to a newer version. The next
              decisions involves your Terraform state. First, whether you want us to provide a
              Terraform state backend for your state. We do offer that as a convenience feature,
              though Spacelift works just fine with any remote backend, like Amazon S3.
              <br />
              <br />
              If you choose not to use our state backend, feel free to proceed. If you do want us to
              manage your state, you have an option to import an existing state file from your
              previous backend. This is only relevant if you're migrating an existing Terraform
              project to Spacelift. If you have no state yet and Spacelift will be creating
              resources from scratch, this step is unnecessary.
              <br />
              <br />
              In addition to these options, we also offer{" "}
              <TextLink href={getDocsUrl("/vendors/terraform/external-state-access")}>
                external state access
              </TextLink>{" "}
              for read-only purposes, this is available for administrative stacks or users with
              write permission to this Stack's space.
            </Typography>
          </>
        ),
      });
    }
    case StackCreationVendor.Pulumi: {
      return children({
        link: getDocsUrl("/vendors/pulumi"),
        body: (
          <>
            <Typography tag="p" variant="p-t7" margin="0 0 small 0">
              Pulumi
            </Typography>
            <Typography tag="p" variant="p-body2">
              When creating a Pulumi stack, you will need to provide two things. First, the login
              URL to your Pulumi state backend, as currently we don't provide one like we do for
              Terraform, so you will need to bring your own.
              <br />
              Second, you need to specify the name of the Pulumi stack. This is separate from the
              name of the Spacelift stack, which you will specify in the{" "}
              <TextLink href={getDocsUrl("/concepts/stack/creating-a-stack#define-behavior")}>
                next step
              </TextLink>
              . That said, nothing prevents you from keeping them in sync.
            </Typography>
          </>
        ),
      });
    }
    case StackCreationVendor.CloudFormation: {
      return children({
        link: getDocsUrl("/vendors/cloudformation"),
        body: (
          <>
            <Typography tag="p" variant="p-t7" margin="0 0 small 0">
              CloudFormation
            </Typography>
            <Typography tag="p" variant="p-body2">
              If you're using CloudFormation with Spacelift, there are a few pieces of information
              you'll need to provide. First, you'll need to specify the region where your
              CloudFormation stack will be located. <br /> Additionally, you'll need to provide the
              name of the corresponding CloudFormation stack for this Spacelift stack. This will
              help us keep track of the different resources in your infrastructure. <br />
              You'll also need to provide the path to the template file in your repository that
              describes the root CloudFormation stack and finally you'll need to specify the S3
              bucket where your processed CloudFormation templates will be stored. This will enable
              us to manage your CloudFormation state and ensure that all changes are properly
              applied.
            </Typography>
          </>
        ),
      });
    }
    case StackCreationVendor.Kubernetes: {
      return children({
        link: getDocsUrl("/vendors/kubernetes"),
        body: (
          <>
            <Typography tag="p" variant="p-t7" margin="0 0 small 0">
              Kubernetes
            </Typography>
            <Typography tag="p" variant="p-body2">
              When you create a Kubernetes stack in Spacelift, you have the option to specify the
              namespace of the Kubernetes cluster that you want to run commands on. You can leave
              this empty for multi-namespace stacks.
              <br />
              You can also provide the version of kubectl that you want the worker to download. This
              is useful if you need to work with a specific version of kubectl for compatibility or
              testing purposes. The worker will download the specified version of kubectl at
              runtime, ensuring that the correct version is available for executing commands on the
              cluster.
            </Typography>
          </>
        ),
      });
    }
    case StackCreationVendor.Terragrunt: {
      return children({
        link: getDocsUrl("/vendors/terragrunt"),
        body: (
          <>
            <Typography tag="p" variant="p-t7" margin="0 0 small 0">
              Terragrunt
            </Typography>
            <Typography tag="p" variant="p-body2">
              Creating a Terragrunt stack in Spacelift, gives you the option to specify the
              Terraform and Terragrunt versions you want to use.
              <br />
              <br />
              You also have the possibility of enabling the run-all feature of Terragrunt, which is
              useful in scenarios where organizations rely on this in their current process and are
              unable to do a full migration yet.
              <br />
              <br />
              Support is currently in Beta.
            </Typography>
          </>
        ),
      });
    }
    case StackCreationVendor.Ansible: {
      return children({
        link: getDocsUrl("/vendors/ansible"),
        body: (
          <>
            <Typography tag="p" variant="p-t7" margin="0 0 small 0">
              Ansible
            </Typography>
            <Typography tag="p" variant="p-body2">
              When you create an Ansible stack in Spacelift, you have the option to select the
              playbook file you want to use. You can define policies for your stack as you would do
              for any other stack.
            </Typography>
          </>
        ),
      });
    }
    default: {
      return null;
    }
  }
};

export default NewStackVendorDocumentation;
