import CollapsibleList from "components/CollapsibleList";
import EmptyState from "ds/components/EmptyState";
import { EmptystateLinkColored } from "components/icons/generated";

import NewContextAttachedProject from "../AttachedProject";
import { AttachedProject } from "../AttachedProject/types";

const emptyPlaceholder = (
  <EmptyState
    padding="large 0"
    icon={EmptystateLinkColored}
    title="Context is not attached to any items"
    caption="Use projects list to attach context to any of stacks or modules."
  />
);

type NewContextManuallyAttachedProjectsProps = {
  items: AttachedProject[];
  onDetach: (id: string) => void;
};

const NewContextManuallyAttachedProjects = ({
  items,
  onDetach,
}: NewContextManuallyAttachedProjectsProps) => {
  return (
    <CollapsibleList
      title="Manually attached"
      count={items.length}
      emptyPlaceholder={emptyPlaceholder}
      ariaLevel={2}
    >
      {items.map((item) => (
        <NewContextAttachedProject key={item.id} item={item} onDetach={onDetach} />
      ))}
    </CollapsibleList>
  );
};

export default NewContextManuallyAttachedProjects;
