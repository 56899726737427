import { memo, useEffect, useRef, useState } from "react";
import cx from "classnames";

import ButtonIcon from "ds/components/ButtonIcon";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import FlashContext from "components/FlashMessages/FlashContext";
import {
  Clock,
  CircleCrossed,
  Lock,
  Space,
  StarFilled,
  GitBranch,
  Edit,
} from "components/icons/generated";
import Box from "ds/components/Box";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoVendor from "components/MetaInfoList/Vendor";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { generateBranchUrl, generateRepoUrlText } from "utils/urls";
import { VCS_PROVIDERS_ICONS } from "components/icons/ProviderIcons";
import Timestamp from "components/time/Timestamp";
import DeltaCounts from "components/DeltaCounts";
import Toggle from "ds/components/Toggle";
import Tooltip from "ds/components/Tooltip";
import { projectRootTooltipContent } from "components/TooltipSnippets";
import MetaInfoProjectRoot from "components/MetaInfoList/ProjectRoot";
import { truncate } from "utils/strings";
import Icon from "ds/components/Icon";
import { StackSuggestions, searchStackSuggestionsDictionary } from "constants/stack";
import MetaInfoStackCommit from "components/MetaInfoList/StackCommit";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ViewCustomizationItem from "components/ViewCustomization/Item";
import MetaInfoBlueprint from "components/MetaInfoList/Blueprint";
import VCSDetachedIndicator from "components/VCSDetachedIndicator";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import ListEntitiesItem from "components/ListEntitiesItem";
import useApplyFilter from "components/Filters/useApplyFilter";
import Typography from "ds/components/Typography";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useSetStackFavorite from "shared/Stack/useSetStackFavorite";
import useAnalytics from "hooks/useAnalytics";
import useLockStack from "shared/Stack/useLockStack";
import { canLockStack } from "shared/Stack/useLockStack/accessValidation";
import { canUnlockStack } from "shared/Stack/useUnlockStack/accessValidation";
import { canManageStacksAndRuns } from "shared/Stack/utils";
import { getVendorName } from "utils/vendor";
import useUnlockStack from "shared/Stack/useUnlockStack";
import { isRunReviewable } from "shared/Run/useReviewRun/accessValidation";
import useTriggerStack from "shared/Stack/useTriggerStack";
import { canTriggerStackStrict } from "shared/Stack/useTriggerStack/accessValidation";
import TagsListFilterable from "components/TagsList/Filterable";
import { isAnsibleStackVendor } from "utils/stack";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";

import DropdownBadge from "./DropdownBadge";
import { STACK_LIST_VIEW_ITEM_ID } from "../constants";
import styles from "./styles.module.css";

const REFETCH_QUERIES = ["SearchStacks", "SearchStacksSuggestions"];

export type StackListItemProps = {
  stack: Stack;
  setRowHeight?: (size: number) => void;
  onCheckItem: (stackId: string) => unknown;
  onShowFullDescription: (stack: Stack) => unknown;
  checked: boolean;
};

const StackListItem = (props: StackListItemProps) => {
  const { stack, setRowHeight, onCheckItem, onShowFullDescription, checked } = props;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksList,
  });

  const [isTriggerDropdownVisible, setTriggerDropdownVisibility] = useState(false);
  const { reportSuccess } = useTypedContext(FlashContext);

  const { applySpaceFilter, applyFilter, applyLabelFilter } = useApplyFilter<StackSuggestions>({
    searchSuggestionsDictionary: searchStackSuggestionsDictionary,
  });

  const rowRef = useRef<HTMLDivElement>(null);

  const stackUrl = `/stack/${stack.id}`;

  const { viewer } = useTypedContext(AccountContext);

  const { setStackFavorite, loading: setFavoriteLoading } = useSetStackFavorite({
    refetchQueries: REFETCH_QUERIES,
    awaitRefetchQueries: true,
    fetchPolicy: "no-cache",
  });

  const [stackLock, { loading: lockStackLoading }] = useLockStack({
    refetchQueries: REFETCH_QUERIES,
  });

  const [stackUnlock, { loading: unlockStackLoading }] = useUnlockStack({
    refetchQueries: REFETCH_QUERIES,
  });

  const [runTrigger] = useTriggerStack({
    refetchQueries: REFETCH_QUERIES,
    fetchPolicy: "no-cache",
  });

  const canManageStackAndRuns = canManageStacksAndRuns(stack, viewer);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleCheckItem = () => {
    onCheckItem(stack.id);
    trackSegmentAnalyticsEvent(!checked ? "Stack Selected" : "Stack Deselected");
  };

  const handleStarStack = () => {
    setStackFavorite({ id: stack.id, star: !stack.starred }, () => {
      trackSegmentAnalyticsEvent(
        stack.starred ? "Remove stack from favorites" : "Add stack to favorites"
      );
    });
  };

  const handleLockStack = () => {
    stackLock({ id: stack.id, note: null }, (data) => {
      if (data?.stackLock) {
        reportSuccess({ message: "Stack successfully locked" });
        trackSegmentAnalyticsEvent("Stack Locked");
      }
    });
  };

  const handleUnlockStack = () => {
    stackUnlock({ id: stack.id }, (data) => {
      if (data?.stackUnlock) {
        reportSuccess({ message: "Stack successfully unlocked" });
        trackSegmentAnalyticsEvent("Stack Unlocked");
      }
    });
  };

  const handleTriggerStack = () => {
    runTrigger({ stack: stack.id, runtimeConfig: null }, (data) => {
      if (data?.runTrigger.id) {
        trackSegmentAnalyticsEvent("Run triggered", {
          stackVendor: getVendorName(stack.vendorConfig?.__typename),
          customRuntimeConfig: false,
        });
        reportSuccess({ message: "Run successfully triggered" });
      }
    });
  };

  const handleShowFullDescription = () => {
    onShowFullDescription(stack);
  };

  const stackRunUrl = stack.stateSetBy ? `/stack/${stack.id}/run/${stack.stateSetBy}` : undefined;

  const canLock = canLockStack(stack);
  const canUnlock = canUnlockStack(stack, viewer);
  const canTrigger = canTriggerStackStrict(stack, viewer);

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      ref={rowRef}
      isActive={checked}
      className={cx(styles.listItem, isTriggerDropdownVisible && styles.active)}
      direction="column"
    >
      <Toggle
        variant="checkbox"
        id={stack.id}
        onChange={handleCheckItem}
        checked={checked}
        className={styles.tickbox}
        ariaLabel={checked ? `Unselect ${stack.name} stack` : `Select ${stack.name} stack`}
      />
      <Box direction="row" justify="between">
        <Box direction="row" align="center" gap="large">
          <ListEntitiesItemLink to={stackUrl} title={stack.name} titleVariant="p-t5" />

          {stack.vcsDetached && <VCSDetachedIndicator />}

          {!!stack.starred && (
            <Icon
              src={StarFilled}
              color="warning"
              noShrink
              aria-label={`Stack ${stack.name} is added to favorites`}
            />
          )}
          {stack.isDisabled && (
            <Tooltip on={(props) => <Icon {...props} src={CircleCrossed} />}>
              Stack is disabled
            </Tooltip>
          )}
          {!!stack.lockedBy && (
            <Tooltip
              on={(props) => (
                <Icon {...props} src={Lock} aria-label={`Stack ${stack.name} is locked`} />
              )}
              active={!!stack.lockedBy}
            >
              {stack.lockedBy === viewer?.id ? "You" : stack.lockedBy} locked this stack
              {stack.lockedAt && (
                <>
                  {" "}
                  <Timestamp timestamp={stack.lockedAt} />
                </>
              )}
              {stack.lockNote && <>: {truncate(stack.lockNote, 150)}</>}
            </Tooltip>
          )}

          <DropdownBadge
            state={stack.state}
            link={stackRunUrl}
            applyFilter={applyFilter(StackSuggestions.State)}
          />
          {isRunReviewable(stack.blocker) && (
            <Typography
              className={styles.needsApproval}
              tag="span"
              variant="p-body2"
              color="warning"
              margin="0 0 0 medium"
            >
              Needs approval
            </Typography>
          )}
        </Box>

        <Box className={styles.actionsRow} direction="row" justify="end" shrink="0">
          {canManageStackAndRuns && (
            <ButtonIcon
              to={`${stackUrl}/settings`}
              variant="contrast"
              size="small"
              icon={Edit}
              analyticsPage={AnalyticsPageStack.StacksList}
              analyticsTitle="Settings Clicked"
            >
              Settings
            </ButtonIcon>
          )}

          <DropdownMenuEllipsis tooltip="Stack actions" onOpenChange={setTriggerDropdownVisibility}>
            {canTrigger && (
              <DropdownMenuItem
                onAction={handleTriggerStack}
                tooltip="Trigger action is allowed for non-blocked stacks only."
                isTooltipActive={!!stack.blocker}
                isDisabled={!!stack.blocker}
              >
                Trigger run
              </DropdownMenuItem>
            )}
            {canLock && (
              <DropdownMenuItem loading={lockStackLoading} onAction={handleLockStack}>
                Lock stack
              </DropdownMenuItem>
            )}
            {canUnlock && (
              <DropdownMenuItem loading={unlockStackLoading} onAction={handleUnlockStack}>
                Unlock stack
              </DropdownMenuItem>
            )}
            <CopyFieldDropdownMenuItem title="Copy ID" value={stack.id} />
            <DropdownMenuItem
              onAction={handleStarStack}
              loading={setFavoriteLoading}
              isDisabled={setFavoriteLoading}
            >
              {stack.starred ? "Remove from favorites" : "Add to favorites"}
            </DropdownMenuItem>
          </DropdownMenuEllipsis>
        </Box>
      </Box>

      <Box direction="row" justify="between">
        <Box direction="column" fullWidth>
          <ViewCustomizationItem
            id={STACK_LIST_VIEW_ITEM_ID.DESCRIPTION}
            onVisibilityChange={handleRowHeight}
          >
            {stack.description && (
              <Box direction="row" align="start">
                <ListEntitiesItemDescription
                  className={styles.stackDescription}
                  description={stack.description}
                  onShowFullDescription={handleShowFullDescription}
                />
              </Box>
            )}
          </ViewCustomizationItem>

          <MetaInfoList className={styles.metaList}>
            <ViewCustomizationItem
              id={STACK_LIST_VIEW_ITEM_ID.SPACE}
              onVisibilityChange={handleRowHeight}
            >
              {stack.spaceDetails && (
                <MetaInfoListItem
                  applyFilter={() =>
                    applySpaceFilter(StackSuggestions.Space)(stack.spaceDetails.id)
                  }
                  icon={Space}
                  linkText={stack.spaceDetails.name}
                  href={`/spaces/${stack.spaceDetails.id}`}
                  type="space"
                />
              )}
            </ViewCustomizationItem>

            <ViewCustomizationItem
              id={STACK_LIST_VIEW_ITEM_ID.BLUEPRINT}
              onVisibilityChange={handleRowHeight}
            >
              {stack.blueprint && <MetaInfoBlueprint blueprint={stack.blueprint} />}
            </ViewCustomizationItem>

            <ViewCustomizationItem
              id={STACK_LIST_VIEW_ITEM_ID.VENDOR}
              onVisibilityChange={handleRowHeight}
            >
              <MetaInfoVendor
                vendorConfig={stack.vendorConfig}
                effectiveTerraformVersion={stack.effectiveTerraformVersion}
              />
            </ViewCustomizationItem>

            <ViewCustomizationItem
              id={STACK_LIST_VIEW_ITEM_ID.REPOSITORY}
              onVisibilityChange={handleRowHeight}
            >
              {stack.apiHost && (
                <MetaInfoListItem
                  applyFilter={applyFilter(StackSuggestions.Repository)}
                  linkText={generateRepoUrlText({
                    namespace: stack.namespace,
                    repository: stack.repository,
                  })}
                  href={generateBranchUrl({
                    apiHost: stack.apiHost,
                    branch: stack.branch,
                    namespace: stack.namespace,
                    repository: stack.repository,
                    repositoryURL: stack.repositoryURL,
                    provider: stack.provider,
                    projectRoot: stack.projectRoot,
                  })}
                  icon={VCS_PROVIDERS_ICONS[stack.provider]}
                  target="_blank"
                  type="repository"
                />
              )}
            </ViewCustomizationItem>

            <ViewCustomizationItem
              id={STACK_LIST_VIEW_ITEM_ID.PROJECT_ROOT}
              onVisibilityChange={handleRowHeight}
            >
              {stack.projectRoot && (
                <MetaInfoProjectRoot
                  projectRoot={stack.projectRoot}
                  tooltip={projectRootTooltipContent}
                  handleApplyFilter={applyFilter(StackSuggestions.ProjectRoot)}
                />
              )}
            </ViewCustomizationItem>

            <ViewCustomizationItem
              id={STACK_LIST_VIEW_ITEM_ID.BRANCH}
              onVisibilityChange={handleRowHeight}
            >
              {stack.apiHost && (
                <MetaInfoListItem
                  applyFilter={applyFilter(StackSuggestions.Branch)}
                  linkText={stack.branch}
                  href={generateBranchUrl({
                    apiHost: stack.apiHost,
                    namespace: stack.namespace,
                    repository: stack.repository,
                    repositoryURL: stack.repositoryURL,
                    provider: stack.provider,
                    branch: stack.branch,
                  })}
                  icon={GitBranch}
                  target="_blank"
                  type={"branch"}
                />
              )}
            </ViewCustomizationItem>

            <ViewCustomizationItem
              id={STACK_LIST_VIEW_ITEM_ID.COMMIT}
              onVisibilityChange={handleRowHeight}
            >
              <MetaInfoStackCommit stack={stack} handleApplyFilter={applyFilter} />
            </ViewCustomizationItem>

            <ViewCustomizationItem
              id={STACK_LIST_VIEW_ITEM_ID.UPDATED_AT}
              onVisibilityChange={handleRowHeight}
            >
              {stack.stateSetAt && (
                <MetaInfoListItem icon={Clock}>
                  <Timestamp timestamp={stack.stateSetAt} />
                </MetaInfoListItem>
              )}
            </ViewCustomizationItem>

            <ViewCustomizationItem
              id={STACK_LIST_VIEW_ITEM_ID.ADMINISTRATIVE}
              onVisibilityChange={handleRowHeight}
            >
              {stack.administrative && (
                <MetaInfoListItem
                  applyFilter={() => applyFilter(StackSuggestions.Administrative)("true")}
                >
                  Administrative
                </MetaInfoListItem>
              )}
            </ViewCustomizationItem>
          </MetaInfoList>

          <ViewCustomizationItem
            id={STACK_LIST_VIEW_ITEM_ID.TAGS}
            onVisibilityChange={handleRowHeight}
          >
            <TagsListFilterable
              tags={stack.labels}
              applyLabelFilter={applyLabelFilter(StackSuggestions.Label)}
              applyFolderFilter={applyFilter(StackSuggestions.Folder)}
              onExpand={handleRowHeight}
              className={styles.tagsList}
            />
          </ViewCustomizationItem>
        </Box>

        <Box direction="column" align="end" justify="end" shrink="0" className={styles.delta}>
          <ViewCustomizationItem
            id={STACK_LIST_VIEW_ITEM_ID.DELTA_COUNTS}
            onVisibilityChange={handleRowHeight}
          >
            {stack.delta && (
              <DeltaCounts delta={stack.delta} isAnsible={isAnsibleStackVendor(stack)} />
            )}
          </ViewCustomizationItem>
        </Box>
      </Box>
    </ListEntitiesItem>
  );
};

export default memo(StackListItem);
