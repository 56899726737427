import DeltaCountsCell from "./Cell";
import { DeltaType } from "./types";
import styles from "./styles.module.css";
import { deltaConfig } from "./constants";

type DeltaCountsAnsibleBarProps = {
  skipEmpty?: boolean;
  changeCount: number;
  ignoreCount: number;
  okCount: number;
  rescueCount: number;
  skipCount: number;
  unreachableCount: number;
  failedCount: number;
};

const DeltaCountsAnsibleBar = ({
  skipEmpty,
  changeCount,
  ignoreCount,
  okCount,
  rescueCount,
  skipCount,
  unreachableCount,
  failedCount,
}: DeltaCountsAnsibleBarProps) => {
  return (
    <div className={styles.group}>
      {(!skipEmpty || okCount > 0) && <DeltaCountsCell {...deltaConfig[DeltaType.OK](okCount)} />}
      {(!skipEmpty || changeCount > 0) && (
        <DeltaCountsCell {...deltaConfig[DeltaType.CHANGED](changeCount)} />
      )}
      {(!skipEmpty || unreachableCount > 0) && (
        <DeltaCountsCell {...deltaConfig[DeltaType.UNREACHABLE](unreachableCount)} />
      )}
      {(!skipEmpty || failedCount > 0) && (
        <DeltaCountsCell {...deltaConfig[DeltaType.FAILED](failedCount)} />
      )}
      {(!skipEmpty || skipCount > 0) && (
        <DeltaCountsCell {...deltaConfig[DeltaType.SKIPPED](skipCount)} />
      )}
      {(!skipEmpty || rescueCount > 0) && (
        <DeltaCountsCell {...deltaConfig[DeltaType.RESCUED](rescueCount)} />
      )}
      {(!skipEmpty || ignoreCount > 0) && (
        <DeltaCountsCell {...deltaConfig[DeltaType.IGNORED](ignoreCount)} />
      )}
    </div>
  );
};

export default DeltaCountsAnsibleBar;
