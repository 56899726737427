import { SecurityKeyView } from "components/MFA/SecurityKeysList/types";
import { SecurityKey } from "types/generated";

export const mapSecurityKeysToView = (
  securityKeys: SecurityKey[] = [],
  viewerSecurityKeys: SecurityKey[] = [],
  isMfaEnforced: boolean
): SecurityKeyView[] => {
  return securityKeys.map((key) => ({
    ...key,
    canDelete: !isMfaEnforced || !key.currentOwner || viewerSecurityKeys.length > 1,
  }));
};
