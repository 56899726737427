import cx from "classnames";

import { ChevronRight } from "components/icons/generated";
import TextEllipsis from "ds/components/TextEllipsis";
import Box from "ds/components/Box";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import { showFullDescription } from "ds/components/FullDescriptionDrawer";
import useAnalytics from "hooks/useAnalytics";

import styles from "./styles.module.css";
import ListEntitiesItemDescriptionContent from "./Content";

type ListEntitiesItemDescriptionProps =
  | {
      description: string;
      onShowFullDescription: (description: string) => void;
      tooltipMode?: false;
      className?: string;
      id?: never;
    }
  | {
      description: string;
      onShowFullDescription?: never;
      tooltipMode: true;
      className?: string;
      id?: never;
    }
  | {
      description: string;
      onShowFullDescription?: never;
      tooltipMode?: false;
      className?: string;
      id: string;
    };

const ListEntitiesItemDescription = ({
  description,
  onShowFullDescription,
  tooltipMode,
  className,
  id,
}: ListEntitiesItemDescriptionProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics();

  const handleShowFullDescription = () => {
    if (onShowFullDescription) {
      // Remove this callback once all drawers are migrated over to the new drawer
      onShowFullDescription(description);
    } else if (id) {
      trackSegmentAnalyticsEvent("Open full description drawer");
      showFullDescription({
        description,
        id,
      });
    }
  };

  return (
    <TextEllipsis tooltip={description} tooltipWidthMode="maxWidthXl" delay={400}>
      {(props, isActive) => (
        <Box direction="row" gap="medium" className={styles.wrapper} align="center">
          <ListEntitiesItemDescriptionContent
            {...props}
            description={description}
            className={cx(className, props.className)}
            color="primary"
          />

          {!tooltipMode && isActive && (
            <ButtonIconNew variant="ghost" icon={ChevronRight} onPress={handleShowFullDescription}>
              Show full description
            </ButtonIconNew>
          )}
        </Box>
      )}
    </TextEllipsis>
  );
};

export default ListEntitiesItemDescription;
