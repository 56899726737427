import { useState } from "react";

import ButtonNew from "ds/components/Button/New";
import { ChevronDown } from "components/icons/generated";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { DropdownOptions } from "./types";

type ModuleVersionSecondaryLayoutDropdownProps = {
  label: string;
  options: DropdownOptions;
};

const ModuleVersionSecondaryLayoutDropdown = ({
  label: dropdownLabel,
  options: dropdownOptions,
}: ModuleVersionSecondaryLayoutDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      triggerComponent={
        <ButtonNew variant="secondary" active={isOpen} endIcon={ChevronDown}>
          {dropdownLabel}
        </ButtonNew>
      }
    >
      {dropdownOptions.map((option) => (
        <DropdownMenuItem
          key={option.to}
          href={option.to}
          active={option.isActive}
          onAction={() => setIsOpen(false)}
        >
          {option.label}
        </DropdownMenuItem>
      ))}
    </DropdownMenu>
  );
};

export default ModuleVersionSecondaryLayoutDropdown;
