import { useState, useCallback, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import ButtonIcon from "ds/components/ButtonIcon";
import FlashContext from "components/FlashMessages/FlashContext";
import { AwsIntegration } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import { ArrowLeft } from "components/icons/generated";
import { SpacesContext } from "views/Account/SpacesProvider";
import { getDocsUrl } from "utils/getDocsUrl";

import AwsIntegrationForm from "../Form";
import { CREATE_AWS_INTEGRATION } from "../gql";
import EmptyPlaceholder from "../../EmptyPlaceholder";
import { ReactComponent as CloudIntegrationImage } from "../../cloud-integration-image.svg";
import ListItem from "./ListItem";
import styles from "./styles.module.css";

type AwsIntegrationsListProps = {
  integrations: AwsIntegration[];
};

const AwsIntegrationsList = (props: AwsIntegrationsListProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const integrations = useMemo(
    () => [...props.integrations].sort((a, b) => a.name.localeCompare(b.name)),
    [props.integrations]
  );

  const hasIntegrations = integrations.length > 0;

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [createIntegration, { loading: isCreating }] = useMutation<{
    awsIntegrationCreate: AwsIntegration;
  }>(CREATE_AWS_INTEGRATION, {
    refetchQueries: ["GetCloudIntegrations"],
  });

  const loading = isCreating;

  const [showAddForm, setShowAddForm] = useState(false);

  const navigate = useNavigate();

  const handleOnCreate = useCallback(
    (
      name: string,
      roleArn: string,
      externalId: string,
      labels: string[],
      durationSeconds: number,
      generateCredentialsInWorker: boolean,
      space?: string
    ) => {
      createIntegration({
        variables: {
          name,
          roleArn,
          durationSeconds,
          generateCredentialsInWorker,
          externalId,
          labels,
          space,
        },
      })
        .then((result) => {
          setShowAddForm(false);
          reportSuccess({ message: "AWS integration successfully added" });

          if (result.data) {
            navigate(result.data.awsIntegrationCreate.id);
          }
        })
        .catch(onError);
    },
    [createIntegration, onError, reportSuccess, navigate]
  );

  const handleOnCancelAdd = useCallback(() => {
    setShowAddForm(false);
  }, [setShowAddForm]);

  const handleCreate = () => {
    setShowAddForm(true);
  };

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>
          {showAddForm && (
            <ButtonIcon
              icon={ArrowLeft}
              onClick={handleOnCancelAdd}
              className={styles.backButton}
              variant="ghost"
            >
              Go back
            </ButtonIcon>
          )}
          {`${showAddForm ? "Add AWS integration" : "AWS integrations"}`}
        </h3>
        {!showAddForm && hasIntegrations && hasEntityCreateAccess && (
          <Button variant="primary" onClick={() => handleCreate()}>
            Create integration
          </Button>
        )}
      </div>
      {!showAddForm && (
        <>
          {hasIntegrations ? (
            <>
              {integrations.map((integration) => (
                <ListItem key={integration.id} integration={integration} />
              ))}
            </>
          ) : (
            <EmptyPlaceholder
              header="Add your AWS integration"
              icon={CloudIntegrationImage}
              onAdd={hasEntityCreateAccess ? handleCreate : undefined}
              buttonText="Add your first integration"
            >
              Make use of AWS role assumption to generate temporary credentials for runs. Find out
              more{" "}
              <a
                href={getDocsUrl("/integrations/cloud-providers/aws")}
                rel="noopener noreferrer"
                target="_blank"
              >
                here
              </a>
              .
            </EmptyPlaceholder>
          )}
        </>
      )}
      {showAddForm && (
        <AwsIntegrationForm
          onCreate={handleOnCreate}
          onCancel={handleOnCancelAdd}
          loading={loading}
        />
      )}
    </>
  );
};

export default AwsIntegrationsList;
