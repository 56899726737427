import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerFooter from "ds/components/Drawer/Footer";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import DrawerBody from "ds/components/Drawer/Body";
import Typography from "ds/components/Typography";
import LinkNew from "ds/components/LinkNew";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import styles from "./styles.module.css";

const LoginPoliciesInfoDrawer = createDrawer(() => {
  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Login policy" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Typography tag="h6" variant="p-t6">
          Purpose
        </Typography>
        <Typography tag="p" variant="p-body2" className={styles.description}>
          Login policies can allow users to log in to the account, and optionally give them admin
          privileges, too. Unlike all other policy types, login policies are global and can't be
          attached to individual stacks. They take effect immediately once they're created and
          affect all future login attempts.
        </Typography>
        <Typography tag="p" variant="p-body2">
          A login policy can define the following types of boolean rules:
          <ul className={styles.policyRules}>
            <li>
              <Typography tag="span" variant="p-t6">
                allow
              </Typography>{" "}
              - allows the user to log in as a non-admin;
            </li>
            <li>
              <Typography tag="span" variant="p-t6">
                admin
              </Typography>{" "}
              - allows the user to log in as an account-wide admin - note that you don't need to
              explicitly allow admin users;
            </li>
            <li>
              <Typography tag="span" variant="p-t6">
                deny
              </Typography>{" "}
              - denies login attempt, no matter the result of other (allow and admin) rules;
            </li>
            <li>
              <Typography tag="span" variant="p-t6">
                deny_admin
              </Typography>{" "}
              - denies the current user admin access to the stack, no matter the outcome of other
              rules;
            </li>
            <li>
              <Typography tag="span" variant="p-t6">
                space_admin/space_write/space_read
              </Typography>{" "}
              - manages access levels to spaces. More on that in{" "}
              <LinkNew
                href={getDocsUrl("/concepts/spaces/access-control")}
                target="_blank"
                className={styles.textLink}
              >
                Spaces Access Control
              </LinkNew>
              ;
            </li>
          </ul>
        </Typography>

        <Typography tag="span" variant="p-body2">
          If no rules match, the default action will be to deny a login attempt.
        </Typography>
        <DrawerFooter>
          <DocumentationButton
            to={getDocsUrl("/concepts/policy/login-policy.html")}
            analyticsPage={AnalyticsPageOrganization.OrganizationLoginPolicies}
          />
        </DrawerFooter>
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showLoginPoliciesInfoDrawer = createDrawerTrigger(LoginPoliciesInfoDrawer);
