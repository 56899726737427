import { File, ArrowDown } from "components/icons/generated";
import Icon from "ds/components/Icon";

import { createUrl, toBlob } from "../helpers";
import styles from "../styles.module.css";

type DownloadFileProps = {
  fileName: string;
  base64Value: string;
};

const DownloadFile = ({ fileName, base64Value }: DownloadFileProps) => (
  <a className={styles.variableDownload} href={createUrl(toBlob(base64Value))} download={fileName}>
    <Icon src={File} />
    <span className={styles.variableIcon}>
      <Icon src={ArrowDown} />
    </span>
  </a>
);

export default DownloadFile;
