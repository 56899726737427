import { useLayoutEffect, useRef } from "react";

import DeltaCountsCell from "./Cell";
import { deltaConfig } from "./constants";
import { DeltaType } from "./types";

type DeltaCountsSingleDeltaProps = {
  deltaIndex: number;
  setDeltasSizes: React.Dispatch<React.SetStateAction<number[]>>;
  shouldHide: boolean;
  containerSize?: number;
  currentSize?: number;
  type: DeltaType;
  count: number;
};

const DeltaCountsSingleDelta = ({
  deltaIndex,
  setDeltasSizes,
  shouldHide,
  currentSize,
  containerSize,
  type,
  count,
}: DeltaCountsSingleDeltaProps) => {
  const deltaRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (deltaRef.current) {
      const newSize = deltaRef.current?.clientWidth || 0;
      if (newSize !== currentSize) {
        setDeltasSizes((tags) => {
          const newDeltas = [...tags];
          newDeltas[deltaIndex] = newSize;

          return newDeltas;
        });
      }
    }
    // Update when container size changed
  }, [setDeltasSizes, deltaIndex, containerSize, currentSize]);

  if (shouldHide) {
    return null;
  }

  return <DeltaCountsCell ref={deltaRef} {...deltaConfig[type](count)} />;
};

export default DeltaCountsSingleDelta;
