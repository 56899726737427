import { ChangeEvent, ReactNode, useMemo } from "react";

import formStyles from "components/FormDefault/styles.module.css";
import SegmentedControl from "ds/components/SegmentedControl";
import Select from "ds/components/Select";
import CardWrapper from "components/CardWrapper";
import Input from "ds/components/Input";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import TooltipInfo from "ds/components/TooltipInfo";
import MissingDataBanner from "components/MissingDataBanner";

import { VersionInputOption, VERSION_INPUT_TYPE } from "./types";
import { VERSION_INPUT_DICTIONARY } from "./constants";

type FormFieldVersionInputProps = {
  type: VERSION_INPUT_TYPE;
  version: string;
  supportedVersions?: string[];
  onChange(version: VersionInputOption): void;
  errorMessage?: string;
  title: string;
  tooltipInfo: ReactNode;
  reloadVersionsData: () => void;
  reloadLoading: boolean;
};

const FormFieldVersionInput = ({
  title,
  type,
  version,
  supportedVersions,
  onChange,
  errorMessage,
  tooltipInfo,
  reloadVersionsData,
  reloadLoading,
}: FormFieldVersionInputProps) => {
  const defaultPreciseVersion =
    supportedVersions && supportedVersions.length ? supportedVersions[0] : "";
  const supportedVersionOptions = useMemo(
    () =>
      supportedVersions?.map((value) => ({
        value,
        label: value,
      })),
    [supportedVersions]
  );

  const handleTypeChange = (type: VERSION_INPUT_TYPE) => {
    if (type === VERSION_INPUT_TYPE.SPECIFIC) {
      onChange({ value: defaultPreciseVersion, type });
    } else if (type === VERSION_INPUT_TYPE.RANGE) {
      onChange({ value: version, type });
    }
  };

  const handleVersionChange = (value: string) => onChange({ value, type });

  const handleVersionRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const range = event.target.value;
    onChange({
      value: range,
      type: VERSION_INPUT_TYPE.RANGE,
    });
  };

  return (
    <CardWrapper variant="filled" direction="column">
      <Box gap="small" align="center" margin="0 0 x-large">
        <Typography tag="p" variant="p-t5">
          {title}
        </Typography>
        <TooltipInfo variant="modal">{tooltipInfo}</TooltipInfo>
      </Box>

      {supportedVersionOptions === undefined && (
        <MissingDataBanner refreshLoading={reloadLoading} refreshHandler={reloadVersionsData} />
      )}

      {supportedVersionOptions !== undefined && (
        <>
          <SegmentedControl
            value={type}
            onChange={handleTypeChange}
            options={[
              {
                value: VERSION_INPUT_TYPE.SPECIFIC,
                label: VERSION_INPUT_DICTIONARY[VERSION_INPUT_TYPE.SPECIFIC],
              },
              {
                value: VERSION_INPUT_TYPE.RANGE,
                label: VERSION_INPUT_DICTIONARY[VERSION_INPUT_TYPE.RANGE],
              },
            ]}
          />
          <FormField
            error={errorMessage}
            helperText={type === VERSION_INPUT_TYPE.RANGE && "Use SemVer format"}
          >
            {({ ariaInputProps }) => (
              <>
                {type === VERSION_INPUT_TYPE.SPECIFIC && (
                  <Select
                    value={version}
                    options={supportedVersionOptions}
                    onChange={handleVersionChange}
                    autocomplete
                    ariaInputProps={ariaInputProps}
                  />
                )}
                {type === VERSION_INPUT_TYPE.RANGE && (
                  <Input
                    error={!!errorMessage}
                    autoFocus
                    className={formStyles.input}
                    type="text"
                    value={version || ""}
                    placeholder="Set range"
                    onChange={handleVersionRangeChange}
                    {...ariaInputProps}
                  />
                )}
              </>
            )}
          </FormField>
        </>
      )}
    </CardWrapper>
  );
};

export default FormFieldVersionInput;
