import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useTitle from "hooks/useTitle";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import EmptyState from "ds/components/EmptyState";
import { EmptystatePrColored } from "components/icons/generated";

import { StackContext } from "../Context";
import PullRequestElement from "./PullRequestElement";
import { LIST_PRS } from "./gql";
import StackHeader from "../components/Header";
import StackDetachedIntegrationCallout from "../components/DetachedIntegrationCallout";
import { getStacksBackUrl } from "../helpers";

const StackPullRequests = () => {
  const { onError } = useTypedContext(FlashContext);
  const { stack: stackCtx } = useTypedContext(StackContext);

  useTitle(`Pull Requests · ${stackCtx.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stackCtx.name,
    },
  ]);

  const { loading, error, data } = useQuery<{ stack: Stack }>(LIST_PRS, {
    onError,
    variables: { stackId: stackCtx.id },
    skip: stackCtx.vcsDetached,
    // APOLLO CLIENT UPDATE
  });

  const stack = useMemo(
    () => ({
      ...stackCtx,
      ...(data?.stack ? data.stack : {}),
    }),
    [data?.stack, stackCtx]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  // Do not show 'loading' when polling in the background.
  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  // beware the `vcs Detached` is querying only for the stack context
  if (!data?.stack && !stackCtx.vcsDetached) {
    return <NotFoundPage />;
  }

  return (
    <>
      <StackHeader />

      <PageInfo title="Pull requests" />

      <StackDetachedIntegrationCallout />

      <PageWrapper>
        {!stackCtx.vcsDetached &&
          stack.pullRequests?.map((pr) => (
            <PullRequestElement key={pr.id} repository={stack.repository} pr={pr} />
          ))}

        {stackCtx.vcsDetached && (
          <EmptyState
            title="Source code integration was detached from this stack."
            icon={EmptystatePrColored}
          />
        )}

        {!stackCtx.vcsDetached && stack.pullRequests?.length === 0 && (
          <EmptyState
            title="No pull requests yet"
            icon={EmptystatePrColored}
            caption={
              <>
                This is a list of Pull Requests currently open against this stack's tracked branch
                on {HUMANIZE_PROVIDER[stack.provider]}. If something seems missing, it may be
                because the Pull Request was opened before the creation of this stack, or it may be
                targeting a different branch.
              </>
            }
          />
        )}
      </PageWrapper>
    </>
  );
};

export default StackPullRequests;
