import { ReactNode } from "react";

import Typography from "ds/components/Typography";
import { pluralize } from "shared/Pluralize";

const getHeaderText = (itemsCount: number, applicableItemsCount?: number): ReactNode => {
  const applicableCountText =
    applicableItemsCount && itemsCount !== applicableItemsCount ? (
      <span>
        {" "}
        (<strong>{applicableItemsCount}</strong> applicable)
      </span>
    ) : undefined;

  return (
    <span aria-hidden>
      <strong>{itemsCount}</strong> {pluralize("item", itemsCount)} selected
      {applicableCountText}
    </span>
  );
};

const getHeaderAccessibleText = (itemsCount: number, applicableItemsCount?: number): string => {
  if (itemsCount === 0) {
    return "No items selected. Closing bulk actions";
  }

  const applicableCountText =
    applicableItemsCount && itemsCount !== applicableItemsCount
      ? `, selected action is applicable to ${applicableItemsCount} ${pluralize("item", applicableItemsCount)}`
      : "";

  return `${itemsCount} ${pluralize("item", itemsCount)} selected${applicableCountText}. Press Control + 1 to move focus to the bulk actions floating bar.`;
};

type BulkActionsFloatingBarHeaderTextProps = {
  selectedItemsCount: number;
  applicableItemsCount?: number;
};

/**
 * Role status makes sure that once an item is selected, the voiceover will read the new count. Giving the information on how to jump to the bulk actions bar.
 * @param selectedItemsCount
 * @param applicableItemsCount
 * @constructor
 */
export const BulkActionsFloatingBarHeaderText = ({
  selectedItemsCount,
  applicableItemsCount,
}: BulkActionsFloatingBarHeaderTextProps) => {
  return (
    <Typography
      role="alert" // tried to use "status" but it didn't read the first selection
      aria-live="polite"
      tag="p"
      variant="p-body3"
      aria-label={getHeaderAccessibleText(selectedItemsCount, applicableItemsCount)}
    >
      {getHeaderText(selectedItemsCount, applicableItemsCount)}
    </Typography>
  );
};
