import { useEffect, useRef, useState } from "react";

type UseKeyboardShortcutFocusProps = {
  /**
   * If the focus is inside of the element, the shortcut won't move the focus
   */
  wrapperElement?: HTMLElement | null;
};

export const useKeyboardShortcutFocus = ({ wrapperElement }: UseKeyboardShortcutFocusProps) => {
  const focusElementRef = useRef<HTMLDivElement>(null);
  const [sourceElement, setSourceElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "1" && !wrapperElement?.contains(document.activeElement)) {
        setSourceElement(document.activeElement as HTMLElement);
        focusElementRef.current?.focus();
      }
      if (event.ctrlKey && event.key === "2") {
        sourceElement?.focus?.();
        setSourceElement(null);
      }
    };

    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [sourceElement, wrapperElement]);

  return { focusElementRef, sourceElement };
};
