import { MouseEventHandler } from "react";

import { File } from "components/icons/generated";
import ButtonIcon from "ds/components/ButtonIcon";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import { DocsUrl } from "utils/getDocsUrl";

type LinkTypeProps = {
  href: DocsUrl;
  onClick?: never;
};

type ButtonTypePropsType = {
  href?: never;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

type AllTypesProps = LinkTypeProps | ButtonTypePropsType;

type BaseProps = {
  tooltipText: string;
} & AnalyticsCommonProps &
  AllTypesProps;

const DocumentationIconButton = ({
  href,
  onClick,
  tooltipText,
  analyticsProps,
  analyticsPage,
  analyticsTitle,
}: BaseProps) => {
  const hrefProps = href
    ? {
        href,
        target: "_blank",
        rel: "noopener,noreferrer",
      }
    : {};

  return (
    <ButtonIcon
      {...hrefProps}
      onClick={onClick}
      size="medium"
      variant="secondary"
      analyticsPage={analyticsPage}
      analyticsTitle={analyticsTitle}
      analyticsProps={analyticsProps}
      icon={File}
    >
      {tooltipText}
    </ButtonIcon>
  );
};

export default DocumentationIconButton;
