import { Outlet } from "react-router-dom";

import HideOnScrollProvider from "components/HideOnScroll/HideOnScrollContext";
import TierInfo from "components/TierInfo";
import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature } from "types/generated";

import { showModuleDetailsDrawer } from "../DetailsDrawer";
import ModuleHeader from "../Header";
import { ModuleContext } from "../../Context";

const ModuleMainLayout = () => {
  const { module } = useTypedContext(ModuleContext);

  const handleOpenDetailsDrawer = () => {
    showModuleDetailsDrawer({
      module,
    });
  };

  return (
    <HideOnScrollProvider>
      {module.workerPool && (
        <TierInfo type="callout" variant="danger" feature={BillingTierFeature.PrivateWorkers}>
          This module is set up to use a private worker pool, but the current plan does not support
          this functionality. Jobs will not be processed until you upgrade to a plan that supports
          private workers.
        </TierInfo>
      )}
      <ModuleHeader module={module} onDetailsButtonClick={handleOpenDetailsDrawer} />
      <Outlet />
    </HideOnScrollProvider>
  );
};

export default ModuleMainLayout;
