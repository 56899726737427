import { Space, Policy, Puzzle } from "components/icons/generated";

import { LaunchPadExploreNextItemProps } from "./Item";
import styles from "./styles.module.css";

export const EXPLORE_NEXT_FEATURES: LaunchPadExploreNextItemProps[] = [
  {
    title: "Create a space",
    icon: Space,
    iconTileClassName: styles.greenWeakBackground,
    to: "/spaces?create-new",
    analyticsTitle: "Create Space Click",
  },
  {
    title: "Create a context",
    icon: Puzzle,
    iconTileClassName: styles.yellowWeakBackground,
    to: "/new/context",
    analyticsTitle: "Create Context Click",
  },
  {
    title: "Create a policy",
    icon: Policy,
    iconTileClassName: styles.fuchsiaWeakBackground,
    to: "/policies?create-new",
    analyticsTitle: "Create Policy Click",
  },
];
