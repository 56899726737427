import { useMemo } from "react";

import useTypedContext from "hooks/useTypedContext";
import PageInfo from "components/PageWrapper/Info";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import { fuzzySearch } from "utils/fuzzySearch";
import SearchInput from "components/SearchInput";
import SortableTable from "components/SortableTable";
import ListEntitiesItem from "components/ListEntitiesItem";
import { SearchQueryOrderDirection } from "types/generated";
import EmptyState from "ds/components/EmptyState";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { Space, EmptystateSearchNoResultsColored } from "components/icons/generated";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";

import { VersionContext } from "../../Context";
import { COLUMN_ORDER, columns, FILTERS_ORDER_SETTINGS_KEY_VERSION_CONSUMERS } from "./constants";
import ModuleVersionScrollableWrapper from "../../components/ScrollableWrapper";
import ModuleVersionPageIntoTitle from "../../components/PageIntoTitle";

const ModuleVersionConsumers = () => {
  const { version } = useTypedContext(VersionContext);

  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const consumers = useMemo(() => {
    const filteredConsumers = version?.consumers || [];

    if (searchQuery) {
      return fuzzySearch(filteredConsumers, searchQuery.trim(), {
        keys: ["name"],
        scoreThreshold: -1000,
      });
    }

    return filteredConsumers;
  }, [version?.consumers, searchQuery]);

  return (
    <>
      <PageInfo
        title={
          <ModuleVersionPageIntoTitle
            title="Consumers"
            tooltip="Stacks that are using this module."
          />
        }
      >
        <SearchInput
          placeholder="Search by name..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY_VERSION_CONSUMERS}
        />
      </PageInfo>
      <ModuleVersionScrollableWrapper padding="0">
        {consumers.length === 0 && (
          <EmptyState
            padding="x-large"
            icon={EmptystateSearchNoResultsColored}
            caption={
              searchQuery ? "No consumers match your search query" : "This module has no consumers"
            }
            fullHeight
          />
        )}
        {consumers.length > 0 && (
          <SortableTable
            items={consumers}
            columns={columns}
            columnOrder={COLUMN_ORDER}
            stickyHeader
            renderItems={(sorted) =>
              sorted.map((item) => (
                <ListEntitiesItem key={item.id} gap="large" grid gridTemplate={COLUMN_ORDER}>
                  <ListEntitiesItemLink to={`/stack/${item.id}`} title={item.name} />
                  <MetaInfoListItem
                    icon={Space}
                    linkText={item.spaceDetails.name}
                    href={`/spaces/${item.spaceDetails.id}`}
                    type="space"
                  />
                </ListEntitiesItem>
              ))
            }
            initialSortBy="id"
            initialDirection={SearchQueryOrderDirection.Desc}
          />
        )}
      </ModuleVersionScrollableWrapper>
    </>
  );
};

export default ModuleVersionConsumers;
