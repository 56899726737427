import { TypographyColors } from "types/Colors";
import { ButtonProps } from "ds/components/Button";

import { FormFieldColor } from "./types";

type ReturnType = {
  primaryTextColor: TypographyColors;
  primaryIconColor: ButtonProps["variant"];
  secondaryColor: TypographyColors;
  dangerColor: TypographyColors;
};

export const getColors = (color?: FormFieldColor): ReturnType => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (color) {
    case "inversed":
      return {
        primaryTextColor: "on-solid-primary",
        primaryIconColor: "ghostInversed",
        secondaryColor: "on-solid-secondary",
        dangerColor: "danger-inversed",
      };
    case "default":
    default:
      return {
        primaryTextColor: "primary",
        primaryIconColor: "ghost",
        secondaryColor: "secondary",
        dangerColor: "danger",
      };
  }
};
