import { ReactNode } from "react";

import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";

type LaunchPadFAQDrawerProps = {
  title: string;
  content: ReactNode;
};

const LaunchPadFAQDrawer = createDrawer(({ title, content }: LaunchPadFAQDrawerProps) => {
  return (
    <DrawerSimple isDismissable>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title={title} />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody fullHeight hasStickyFooter>
        {content}
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showLaunchPadFAQDrawer = createDrawerTrigger(LaunchPadFAQDrawer);
