import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { DriftDetectionIntegrationInput } from "types/generated";

import { UPDATE_STACK_DRIFT_DETECTION_SCHEDULING } from "./gql";

type UseUpdateDriftDetectionProps = {
  stackId: string;
};

const useUpdateDriftDetection = ({ stackId }: UseUpdateDriftDetectionProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackIntegrationDriftDetectionUpdate, { loading }] = useMutation(
    UPDATE_STACK_DRIFT_DETECTION_SCHEDULING,
    {
      refetchQueries: ["GetStackScheduling"],
      awaitRefetchQueries: true,
      onError,
    }
  );

  const updateDriftDetection = useCallback(
    (input: DriftDetectionIntegrationInput, successCallback?: () => void) => {
      stackIntegrationDriftDetectionUpdate({
        variables: {
          stack: stackId,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackIntegrationDriftDetectionUpdate) {
            reportSuccess({
              message: `Drift Detection schedule was updated successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackId, stackIntegrationDriftDetectionUpdate]
  );

  return {
    updateDriftDetection,
    loading,
  };
};

export default useUpdateDriftDetection;
