import { ReactNode } from "react";

import Drawer, { DrawerProps } from "ds/components/Drawer";
import useTypedContext from "hooks/useTypedContext";

import { BulkActionsDataContext } from "../Context";
import { BulkActionsStep, BulkActionsVariant } from "../types";

type BulkActionsDrawerProps = {
  children: ReactNode;
  isHidden?: boolean;
} & Pick<DrawerProps, "position">;

const BulkActionsDrawer = ({
  children,
  isHidden,
  position = "fixedRight",
}: BulkActionsDrawerProps) => {
  const { isBulkActionsActive, bulkActionsVariant, bulkActionsStep } =
    useTypedContext(BulkActionsDataContext);

  const isDrawerActionsStep =
    bulkActionsVariant === BulkActionsVariant.Drawer && bulkActionsStep !== BulkActionsStep.Results;
  const isDrawerResults = bulkActionsStep === BulkActionsStep.Results;

  const isDrawerVisible =
    !isHidden && isBulkActionsActive && (isDrawerActionsStep || isDrawerResults);

  return (
    <Drawer disablePreventScroll position={position} visible={isDrawerVisible} mountOnEnter>
      {children}
    </Drawer>
  );
};

export default BulkActionsDrawer;
