import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isHotkey } from "is-hotkey";

import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { RunElementsContext } from "../../RunElementsContext";
import { RunShortcuts } from "../../types";
import NavigationBarAction from "./Action";

type NavigationBarProps = {
  next?: string;
  previous?: string;
};

const NavigationBar = ({ next, previous }: NavigationBarProps) => {
  const navigate = useNavigate();
  const { stackId } = useParams<{ stackId: string }>();
  const { shortcuts } = useTypedContext(RunElementsContext);

  const handleNavigate = useCallback(
    (runId: string) => () => navigate(`/stack/${stackId}/run/${runId}`),
    [navigate, stackId]
  );

  const allShortcuts = useMemo(() => {
    const results: RunShortcuts = [];

    if (previous) {
      results.push({
        id: "previous",
        label: "Previous run",
        callback: handleNavigate(previous),
        code: "left",
      });
    }

    if (next) {
      results.push({
        id: "next",
        label: "Next run",
        callback: handleNavigate(next),
        code: "right",
      });
    }

    return results.concat(
      shortcuts.filter((item) => !item.disabled).sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [next, previous, shortcuts, handleNavigate]);

  useEffect(() => {
    const handleShortcut = (e: KeyboardEvent) => {
      const { tagName } = e.target as HTMLElement;
      const isTargetValid = tagName !== "TEXTAREA" && tagName !== "INPUT";

      if (!isTargetValid) {
        return;
      }

      const shortcut = allShortcuts.find((item) => isHotkey(item.code, e));

      if (shortcut && !shortcut.loading) {
        shortcut.callback();
      }
    };

    window.addEventListener("keydown", handleShortcut);
    return () => window.removeEventListener("keydown", handleShortcut);
  }, [next, previous, allShortcuts]);

  if (!allShortcuts.length) {
    // TODO: wrap navigation bar with animation wrapper
    return null;
  }

  return (
    <Box className={styles.wrapper} padding="0 medium" gap="x-large">
      {allShortcuts.map((action) => (
        <NavigationBarAction key={action.id} {...action} />
      ))}
    </Box>
  );
};

export default NavigationBar;
