import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { PolicyStackAttachment } from "types/generated";

import PolicyDetailsUsedByEntity from "./Entity";

type PolicyDetailsUsedByVirtualizedListItemProps = {
  items: PolicyStackAttachment[];
  setRowHeight?: (index: number, size: number) => void;
};

const PolicyDetailsUsedByVirtualizedListItem = (
  props: ListChildComponentProps<
    PolicyDetailsUsedByVirtualizedListItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <PolicyDetailsUsedByEntity
        setRowHeight={handleSetRowHeight}
        key={data.items[index].id}
        attachedStack={data.items[index]}
      />
    </div>
  );
};

export default memo(PolicyDetailsUsedByVirtualizedListItem, areEqual);
