import DrawerSimple from "ds/components/DrawerNew/Simple";
import { type AnalyticsPage } from "hooks/useAnalytics";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import ApiKeysInfoDrawerContent from "./Content";

type ApiKeysInfoDrawerProps = {
  analyticsPage: AnalyticsPage;
};

const ApiKeysInfoDrawer = createDrawer(({ analyticsPage }: ApiKeysInfoDrawerProps) => {
  return (
    <DrawerSimple isDismissable>
      <ApiKeysInfoDrawerContent analyticsPage={analyticsPage} />
    </DrawerSimple>
  );
});

export const showApiKeysInfoDrawer = createDrawerTrigger(ApiKeysInfoDrawer);
