import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { WorkerPool } from "types/generated";

import WorkerPoolListItem from ".";

type WorkerPoolVirtualizedListItemProps = {
  items: WorkerPool[];
  onEdit: (workerPool: WorkerPool) => void;
};

const WorkerPoolVirtualizedListItem = (
  props: ListChildComponentProps<WorkerPoolVirtualizedListItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <WorkerPoolListItem
        key={data.items[index].id}
        item={data.items[index]}
        onEdit={data.onEdit}
        setRowHeight={handleSetRowHeight}
      />
    </div>
  );
};

export default memo(WorkerPoolVirtualizedListItem, areEqual);
