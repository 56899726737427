import { useLayoutEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { ApiKey } from "types/generated";
import { pluralize } from "shared/Pluralize";
import TextEllipsis from "ds/components/TextEllipsis";
import Timestamp from "components/time/Timestamp";
import Tooltip from "ds/components/Tooltip";
import { withTestId } from "utils/withTestId";
import { formatApiKeyType } from "components/ApiKeys/utils";
import CopyFieldIcon from "components/CopyField/Icon";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";

import { COLUMN_GAP, COLUMN_ORDER } from "../../constants";
import ApiKeysListItemDropdown from "./Dropdown";
import { API_KEY_LIST_ITEM_TEST_ID } from "./constants";
import styles from "./styles.module.css";

type ApiKeysListItemProps = {
  item: ApiKey;
  handleApiKeyOpenDetails: (details: ApiKey) => unknown;
  setRowHeight?: (size: number) => void;
  refetchQueriesOnDelete?: string[];
};

const ApiKeysListItem = ({
  item,
  setRowHeight,
  handleApiKeyOpenDetails,
  refetchQueriesOnDelete,
}: ApiKeysListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const isActive = useDrawerVisibilityForId(item.id);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleApiKeyDetailsClick = () => {
    handleApiKeyOpenDetails(item);
  };

  // on every render, update the row height
  useLayoutEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      {...withTestId(API_KEY_LIST_ITEM_TEST_ID)}
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      isActive={isActive}
    >
      {/* TODO: next iteration */}
      {/* <Box direction="row" align="center" className={styles.fixedHeightRow}>
        <BulkActionsListItemTickbox id={item.id} />
      </Box> */}
      <Box direction="row" align="center">
        <TextEllipsis tooltip={item.name} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="span" variant="p-t6">
              {item.name}
            </Typography>
          )}
        </TextEllipsis>
      </Box>
      <Box direction="row" align="center" __deprecatedGap="0.3rem">
        <Typography tag="span" variant="p-body2">
          {formatApiKeyType(item.type)}
        </Typography>
      </Box>
      <Box direction="row" align="center">
        <Tooltip
          on={(props) => (
            <Typography {...props} tag="span" variant="p-body2">
              {item.id.slice(-6)}
            </Typography>
          )}
        >
          {item.id}
        </Tooltip>
        <CopyFieldIcon title="Copy" value={item.id} className={styles.copyField} />
      </Box>

      <Box direction="row" align="center" __deprecatedGap="0.3rem">
        <Typography tag="span" variant="p-t6">
          {item.spaceCount}
        </Typography>
        <Typography tag="span" variant="p-body2">
          {`${pluralize("space", item.spaceCount)}`}
        </Typography>
      </Box>
      <Box direction="row" align="center" __deprecatedGap="0.3rem">
        <Typography tag="span" variant="p-t6">
          {item.teamCount}
        </Typography>
        <Typography tag="span" variant="p-body2">
          {`${pluralize("group", item.teamCount)}`}
        </Typography>
      </Box>
      <Box direction="row" align="center">
        {item.lastUsedAt && (
          <TextEllipsis tooltip={<Timestamp timestamp={item.lastUsedAt} />}>
            {(props) => (
              <Typography {...props} tag="span" variant="p-body2">
                {item.lastUsedAt && <Timestamp timestamp={item.lastUsedAt} />}
              </Typography>
            )}
          </TextEllipsis>
        )}
        {!item.lastUsedAt && (
          <Typography tag="span" variant="p-body2">
            Never used
          </Typography>
        )}
      </Box>
      <Box direction="row" align="center">
        <ApiKeysListItemDropdown
          name={item.name}
          id={item.id}
          refetchQueriesOnDelete={refetchQueriesOnDelete}
          handleApiKeyDetailsClick={handleApiKeyDetailsClick}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default ApiKeysListItem;
