import { ReactNode, useCallback } from "react";

import SubSidebar from "components/SubSidebar";
import SubSidebarSection from "components/SubSidebar/Section";
import Box from "ds/components/Box";
import Counter from "ds/components/Counter";
import SubSidebarSectionItem from "components/SubSidebar/SectionItem";
import useAnalytics from "hooks/useAnalytics";

import styles from "./styles.module.css";
import { SidebarItems } from "./types";

type ModuleVersionSidebarLayoutProps = {
  children: ReactNode;
  sidebar: SidebarItems;
};

const ModuleVersionSidebarLayout = ({ sidebar, children }: ModuleVersionSidebarLayoutProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics();

  const handleSidebarItemClick = useCallback(
    (tab: string) => () => {
      trackSegmentAnalyticsEvent("Module Registry - Tab Switch", {
        tab,
      });
    },
    [trackSegmentAnalyticsEvent]
  );

  return (
    <Box className={styles.content} grow="1">
      <SubSidebar>
        <SubSidebarSection>
          {sidebar.map((item) => (
            <SubSidebarSectionItem
              key={item.to}
              to={item.to}
              end={item.end}
              onClick={handleSidebarItemClick(item.label)}
            >
              {item.label}
              {item.count !== undefined && (
                <Counter variant="inversed" size="small" count={item.count} />
              )}
            </SubSidebarSectionItem>
          ))}
        </SubSidebarSection>
      </SubSidebar>

      <Box direction="column" fullWidth zeroMinWidth>
        {children}
      </Box>
    </Box>
  );
};

export default ModuleVersionSidebarLayout;
