import { memo, useEffect, useRef } from "react";

import Timestamp from "components/time/Timestamp";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { WebhookDelivery } from "types/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";

import { COLUMNS_SIZE } from "../constants";
import WebhookDeliveriesStatus from "./Status";

type WebhookListItemProps = {
  item: WebhookDelivery;
  setRowHeight?: (size: number) => void;
};

const WebhookDeliveryListItem = (props: WebhookListItemProps) => {
  const { item, setRowHeight } = props;

  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMNS_SIZE}
      ref={rowRef}
    >
      <Box direction="column" align="start" justify="center">
        <ListEntitiesItemLink to={`delivery/${item.id}`} title={item.id} />
      </Box>
      <Box direction="row" align="center">
        <WebhookDeliveriesStatus status={item.status} />
      </Box>
      <Box direction="row" align="center">
        <Typography tag="span" variant="p-body2">
          <Timestamp timestamp={item.timestamp} />
        </Typography>
      </Box>
    </ListEntitiesItem>
  );
};

export default memo(WebhookDeliveryListItem);
