import { gql, TypedDocumentNode } from "@apollo/client";

import { Maybe, SlackAppConfig } from "types/generated";

type FetchSlackAppConfigDelete = { slackAppConfigDelete: Maybe<Pick<SlackAppConfig, "clientId">> };
export const SLACK_APP_CONFIG_DELETE: TypedDocumentNode<FetchSlackAppConfigDelete> = gql`
  mutation SlackAppConfigDelete {
    slackAppConfigDelete {
      clientId
    }
  }
`;
