import { LinePath } from "@visx/shape";

import styles from "../styles.module.css";

type TreeChartGroupNodeSummaryRoundedBorderProps = {
  startX: number;
  startY: number;
  radius: number;
  height: number;
  width: number;
};

const TreeChartGroupNodeSummaryRoundedBorder = ({
  startX,
  startY,
  radius,
  height,
  width,
}: TreeChartGroupNodeSummaryRoundedBorderProps) => (
  <LinePath
    className={styles.summaryBorder}
    d={`M ${startX + width - radius} ${startY + height} L ${startX + radius} ${startY + height} a${radius},${radius} 0 0 1 -${radius} -${radius} L ${startX} ${startY + radius} a${radius},${radius} 0 0 1 ${radius} -${radius} L ${startX + width - radius} ${startY} a${radius},${radius} 0 0 1 ${radius} ${radius} L ${startX + width} ${startY + height - radius} a${radius},${radius} 0 0 1 -${radius} ${radius}`}
  />
);

export default TreeChartGroupNodeSummaryRoundedBorder;
