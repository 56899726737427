import ButtonIconNew from "ds/components/ButtonIcon/New";
import { IconComponent } from "types/Icon";
import Icon, { IconProps } from "ds/components/Icon";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

type StackListItemIssuesCellItemProps = {
  children: string;
  icon: IconComponent;
  iconColor: IconProps["color"];
  hideText?: boolean;
};

const StackListItemIssuesCellItem = ({
  children,
  icon,
  iconColor,
  hideText,
}: StackListItemIssuesCellItemProps) => {
  if (hideText) {
    return (
      <ButtonIconNew variant="ghost" iconColor={iconColor} icon={icon}>
        {children}
      </ButtonIconNew>
    );
  }

  return (
    <Box gap="medium" align="center" className={styles.wrapper}>
      <Icon src={icon} color={iconColor} noShrink />
      <Typography tag="span" variant="p-body3" className={styles.text}>
        {children}
      </Typography>
    </Box>
  );
};

export default StackListItemIssuesCellItem;
