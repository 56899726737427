import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationOidcUpdateArgs, OidcSettings } from "types/generated";

type FetchOIDCUpdate = { oidcUpdate: Pick<OidcSettings, "claimMapping"> };
export const UPDATE_OIDC_SSO: TypedDocumentNode<FetchOIDCUpdate, MutationOidcUpdateArgs> = gql`
  mutation SSOUpdateOIDC($input: OIDCUpdateInput!) {
    oidcUpdate(input: $input) {
      claimMapping {
        entries {
          name
          value
        }
      }
    }
  }
`;
