import { memo, useCallback } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { TreeNode } from "utils/tree";
import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";

import ConfigManagementTreeGridListRow from "./Row";
import ConfigManagementTreeGridListLoaderRow from "./LoaderRow";
import { ConfigNode } from "../../types";

type ConfigManagementTreeGridListVirtualizedItemProps = {
  items: TreeNode<ConfigNode>[];
  activeId?: string;
  toggleKey: (key: string, hasChildrenToLoad?: boolean) => () => void;
  expandedKeys: Set<string>;
  selectedRef: React.RefObject<HTMLDivElement>;
};

const ConfigManagementTreeGridListVirtualizedItem = (
  props: ListChildComponentProps<
    ConfigManagementTreeGridListVirtualizedItemProps & ListEntitiesBaseItemProps
  >
) => {
  const { index, style, data } = props;
  const { toggleKey, setRowHeight } = data;

  const node: TreeNode<ConfigNode> | undefined = data.items[index];

  const handleSetRowHeight = useCallback(
    (size: number) => setRowHeight(index, size),
    [setRowHeight, index]
  );

  const onToggle = useCallback(
    () => node && toggleKey(node.id, node.item.hasChildrenToLoad)(),
    [toggleKey, node]
  );

  const isNotLoaded = index > data.items.length - 1;

  if (isNotLoaded) {
    return (
      <div style={style}>
        <ConfigManagementTreeGridListLoaderRow setRowHeight={handleSetRowHeight} />
      </div>
    );
  }

  return (
    <div style={style}>
      <ConfigManagementTreeGridListRow
        key={node.id}
        node={node}
        isActive={data.activeId === node.id}
        hasChildrenToLoad={node.item.hasChildrenToLoad}
        setRowHeight={handleSetRowHeight}
        isExpanded={data.expandedKeys.has(node.id)}
        onToggle={onToggle}
        innerRef={data.activeId === node.id ? data.selectedRef : undefined}
        description={node.item.description}
      />
    </div>
  );
};

export default memo(ConfigManagementTreeGridListVirtualizedItem, areEqual);
