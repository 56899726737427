import { KubernetesWorkflowTool, TerraformWorkflowTool, TerragruntTool } from "types/generated";
import { KubernetesWorkflowToolOptions } from "constants/kubernetes_workflow_tools";
import { TerraformWorkflowToolOptions } from "constants/terraform_workflow_tools";
import { File } from "components/icons/generated";
import Icon from "ds/components/Icon";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import { TERRAGRUNT_TOOL_OPTIONS } from "constants/terragrunt_tools";
import KeyValue from "components/KeyValue";

import { formatToggleValue } from "./formatToggleValue";
import { StackCreationVendor, StackVendorFormFields } from "../types";

const getTerraformWorkflowToolLabel = (workflowTool?: TerraformWorkflowTool) => {
  const option = TerraformWorkflowToolOptions.find(({ value }) => value === workflowTool);

  if (option) {
    return option.label;
  }

  return "";
};

const getTerragruntWorkflowToolLabel = (workflowTool?: TerragruntTool) => {
  const option = TERRAGRUNT_TOOL_OPTIONS.find(({ value }) => value === workflowTool);

  if (option) {
    return option.label;
  }

  return "";
};

const getKubernetesWorkflowToolLabel = (workflowTool?: KubernetesWorkflowTool) => {
  const option = KubernetesWorkflowToolOptions.find(({ value }) => value === workflowTool);

  if (option) {
    return option.label;
  }

  return "";
};

type NewStackSummaryVendorProps = {
  data: StackVendorFormFields;
};

const NewStackSummaryVendor = ({ data }: NewStackSummaryVendorProps) => {
  let workflowToolLabel;

  if (data.vendor === StackCreationVendor.Terraform) {
    workflowToolLabel = getTerraformWorkflowToolLabel(data.terraform.workflowTool);
  } else if (data.vendor === StackCreationVendor.Terragrunt) {
    workflowToolLabel = getTerragruntWorkflowToolLabel(data.terragrunt.tool);
  } else if (data.vendor === StackCreationVendor.Kubernetes) {
    workflowToolLabel = getKubernetesWorkflowToolLabel(data.kubernetes.kubernetesWorkflowTool);
  }

  return (
    <>
      <KeyValue name="Type">{data.vendor}</KeyValue>

      {data.vendor === StackCreationVendor.Terraform && (
        <>
          <KeyValue name="Workflow tool">{workflowToolLabel}</KeyValue>

          {data.terraform.workflowTool !== TerraformWorkflowTool.Custom && (
            <KeyValue name={`${workflowToolLabel} version`}>
              {data.terraform.version.value}
            </KeyValue>
          )}

          <KeyValue name="Smart sanitization">
            {formatToggleValue(data.terraform.useSmartSanitization)}
          </KeyValue>
          <KeyValue name="Manage state">{formatToggleValue(data.terraform.manageState)}</KeyValue>

          {!data.terraform.manageState && (
            <KeyValue name="Workspace">{data.terraform.workspace}</KeyValue>
          )}

          {data.terraform.manageState && (
            <>
              <KeyValue name="Import existing state file">
                {formatToggleValue(data.terraform.importExistingStateFile)}
              </KeyValue>

              {data.terraform.importExistingStateFile && (
                <KeyValue name="State file">
                  <Box direction="row" align="center" gap="medium">
                    <Icon src={File} noShrink />
                    <TextEllipsis
                      tooltip={data.terraform.uploadedStateFile?.name}
                      tooltipWidthMode="maxWidthXl"
                    >
                      {(props) => (
                        <Typography {...props} variant="p-body2" tag="span">
                          {data.terraform.uploadedStateFile?.name}
                        </Typography>
                      )}
                    </TextEllipsis>
                  </Box>
                </KeyValue>
              )}

              <KeyValue name="External state access">
                {formatToggleValue(data.terraform.externalStateAccessEnabled)}
              </KeyValue>
            </>
          )}
        </>
      )}

      {data.vendor === StackCreationVendor.Terragrunt && (
        <>
          <KeyValue name="Terragrunt version">{data.terragrunt.terragruntVersion.value}</KeyValue>
          <KeyValue name="Tool">{workflowToolLabel}</KeyValue>
          <KeyValue name={`${workflowToolLabel} version`}>
            {data.terragrunt.workflowVersion.value}
          </KeyValue>
          <KeyValue name="Smart sanitization">
            {formatToggleValue(data.terragrunt.useSmartSanitization)}
          </KeyValue>
          <KeyValue name="Use Run All">{formatToggleValue(data.terragrunt.useRunAll)}</KeyValue>
        </>
      )}

      {data.vendor === StackCreationVendor.Pulumi && (
        <>
          <KeyValue name="Login url">{data.pulumi.loginURL}</KeyValue>
          <KeyValue name="Stack url">{data.pulumi.stackName}</KeyValue>
        </>
      )}

      {data.vendor === StackCreationVendor.CloudFormation && (
        <>
          <KeyValue name="Entry template file">{data.cloudFormation.entryTemplateFile}</KeyValue>
          <KeyValue name="Region">{data.cloudFormation.region}</KeyValue>
          <KeyValue name="Stack name">{data.cloudFormation.stackName}</KeyValue>
          <KeyValue name="Template bucket">{data.cloudFormation.templateBucket}</KeyValue>
        </>
      )}

      {data.vendor === StackCreationVendor.Kubernetes && (
        <>
          <KeyValue name="Workflow tool">{workflowToolLabel}</KeyValue>

          <KeyValue name="Version">{data.kubernetes.kubectlVersion}</KeyValue>

          <KeyValue name="Namespace">{data.kubernetes.namespace}</KeyValue>
        </>
      )}

      {data.vendor === StackCreationVendor.Ansible && (
        <KeyValue name="Playbook">{data.ansible.playbook}</KeyValue>
      )}
    </>
  );
};

export default NewStackSummaryVendor;
