import {
  ApolloClient,
  from,
  InMemoryCache,
  StoreObject,
  defaultDataIdFromObject,
  ServerError,
  createHttpLink,
  NormalizedCacheObject,
  ApolloLink,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError as onErrorApollo } from "@apollo/client/link/error";

import { Module } from "types/generated";
import { isProductionEnvironment } from "utils/env";

import TYPE_POLICIES from "./typePolicies";

type ApolloCacheGetterObject = {
  ownerSubdomain?: string;
} & Readonly<StoreObject>;

const isProductionEnv = isProductionEnvironment();

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const retryIf = (error: ServerError) => {
  const doNotRetryCodes = [500, 400];
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

export const initApolloClient = (errorCallback?: (error: string) => void) => {
  if (apolloClient) {
    return apolloClient;
  }

  const errorLink = onErrorApollo(({ graphQLErrors }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message }) => {
        if (message.startsWith("Cannot query field")) {
          errorCallback?.(message);
        }
      });
  });

  const authLink = createHttpLink({
    uri: "/graphql",
    credentials: "same-origin",
    headers: {
      "X-Spacelift-Service": "true",
      "Spacelift-Client-Type": "ui",
    },
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 100,
      max: 2000,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf,
    },
  });

  const namedGraphqlLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      uri: `/graphql?${operation.operationName}`,
    }));
    return forward ? forward(operation) : null;
  });

  const additiveLink = from([
    ...(isProductionEnv ? [] : [namedGraphqlLink]),
    errorLink,
    retryLink,
    authLink,
  ]);

  apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: {
        StackConfigVendor: [
          "StackConfigVendorCloudFormation",
          "StackConfigVendorPulumi",
          "StackConfigVendorTerraform",
          "StackConfigVendorKubernetes",
        ],
      },
      dataIdFromObject: (object: ApolloCacheGetterObject) => {
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        switch (object.__typename) {
          case "Module":
            // eslint-disable-next-line no-case-declarations
            const currentModule = object as Module;
            return `${object.__typename}:${currentModule.id}${currentModule.ownerSubdomain}`; // module can have same id when shared
          default:
            return defaultDataIdFromObject(object); // fall back to default handling
        }
      },
      typePolicies: TYPE_POLICIES,
    }),
    link: additiveLink,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
      },
    },
  });

  return apolloClient;
};

export const getApolloClient = () => {
  return apolloClient;
};
