import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import PageWrapper from "components/PageWrapper";
import DocumentationIconButton from "components/DocumentationIconButton";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import { getDocsUrl } from "utils/getDocsUrl";

import { AccountRunsData } from "./types";
import Runs from "./Wrapper";
import { GET_ACCOUNT_RUNS } from "./gql";
import { getDefaultDateRange } from "./helpers";

const RunsWrapper = () => {
  const [dateRange, setDateRange] = useState(getDefaultDateRange);
  const { onError } = useTypedContext(FlashContext);
  const { search } = useLocation();
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Runs · ${accountName}`);

  const { error, loading, data, refetch } = useQuery<AccountRunsData>(GET_ACCOUNT_RUNS, {
    onError,
    variables: {
      start: `${dateRange.startDate.unix()}`,
      end: `${dateRange.endDate.unix()}`,
    },
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.stacks) {
    return <PageLoading />;
  }

  return (
    <>
      <ViewHeader>
        <ViewHeaderTitle titleSize="p-t3">Account Runs</ViewHeaderTitle>
        <ViewHeaderWrapper direction="row">
          <DocumentationIconButton
            href={getDocsUrl("/concepts/run")}
            tooltipText="Go to runs documentation"
          />
        </ViewHeaderWrapper>
      </ViewHeader>

      <PageWrapper>
        <Runs
          data={data || {}}
          search={search}
          dateRange={dateRange}
          setDateRange={setDateRange}
          refetchData={refetch}
          isAccountWide
        />
      </PageWrapper>
    </>
  );
};

export default RunsWrapper;
