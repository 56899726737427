import { Cross, Tick } from "components/icons/generated";
import Icon from "ds/components/Icon";

type SwitchIconProps = {
  checked: boolean;
};

const NewModuleAttachCloudDetailsSwitchIcon = ({ checked }: SwitchIconProps) =>
  checked ? (
    <Icon src={Tick} size="large" color="success" />
  ) : (
    <Icon src={Cross} size="large" color="danger" />
  );

export default NewModuleAttachCloudDetailsSwitchIcon;
