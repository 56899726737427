import useTierFeature from "views/Account/hooks/useTierFeature";
import { BillingTierFeature, SamlSettings } from "types/generated";
import { Diamond, LogoSaml } from "components/icons/generated";
import ButtonNew from "ds/components/Button/New";
import Tile from "ds/components/Tile";
import Tooltip from "ds/components/Tooltip";
import Box from "ds/components/Box";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import useAnalytics from "hooks/useAnalytics";
import setLocation from "shared/setLocation";
import TileTitle from "ds/components/Tile/Title";
import BadgeNextNew from "ds/components/BadgeNext/New";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";

import { openSingleSignOnDrawer } from "../components/Drawer";
import SettingsSingleSignOnCreateSAML from "./Create";
import { showDisableConfirmation } from "./Disable";
import SettingsSingleSignOnDetailsSAML from "./Details";

type SettingsSingleSignOnSAMLProps = {
  integration: SamlSettings | undefined;
  oidcAlreadyEnabled: boolean;
};

const SettingsSingleSignOnSAML = ({
  integration,
  oidcAlreadyEnabled,
}: SettingsSingleSignOnSAMLProps) => {
  const isTierContainSAML = useTierFeature(BillingTierFeature.CustomSsoSaml);
  const isIntegrationActive = !!integration?.adminLogin;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSAML,
    defaultCallbackTrackProperties: {
      method: "SAML",
    },
  });

  const onPress = () => {
    openSingleSignOnDrawer({
      title: "Set up SAML SSO",
      children: <SettingsSingleSignOnCreateSAML />,
      isDismissable: false,
    });
  };

  const handleActivate = () => {
    setLocation("/saml/start");
    trackSegmentAnalyticsEvent("Activate Click");
  };

  const handleDisable = () => {
    showDisableConfirmation();
    trackSegmentAnalyticsEvent("Disable Click");
  };

  const handleShowDetails = () => {
    if (integration) {
      openSingleSignOnDrawer({
        title: "SAML SSO",
        children: <SettingsSingleSignOnDetailsSAML integration={integration} />,
      });
    }
  };

  return (
    <Tile
      icon={LogoSaml}
      indicator={
        <Box gap="medium">
          {/* Regular "Set up" flow */}
          {isTierContainSAML && !integration && !oidcAlreadyEnabled && (
            <ButtonNew variant="secondary" size="small" onPress={onPress}>
              Set up
            </ButtonNew>
          )}

          {/* Disabled "Set up" flow because the SAML is no available in tier */}
          {!isTierContainSAML && (
            <Tooltip
              active
              on={(props) => (
                <ButtonNew variant="secondary" size="small" disabled {...props}>
                  Set up
                </ButtonNew>
              )}
            >
              Upgrade account to use SAML SSO 2.0
            </Tooltip>
          )}

          {/* Disabled "Set up" button because OIDC is already enabled */}
          {isTierContainSAML && !integration && oidcAlreadyEnabled && (
            <Tooltip
              active
              on={(props) => (
                <ButtonNew variant="secondary" size="small" disabled {...props}>
                  Set up
                </ButtonNew>
              )}
            >
              OIDC integration already present, can't set up SAML
            </Tooltip>
          )}

          {isTierContainSAML && integration && !isIntegrationActive && (
            <ButtonNew variant="primary" size="small" onPress={handleActivate}>
              Activate
            </ButtonNew>
          )}

          {isTierContainSAML && integration && (
            <DropdownMenuEllipsis tooltip="SAML actions">
              <DropdownMenuItem onAction={handleShowDetails}>See details</DropdownMenuItem>
              <DropdownMenuItem onAction={handleDisable} danger>
                Unlink
              </DropdownMenuItem>
            </DropdownMenuEllipsis>
          )}
        </Box>
      }
      title={
        <Box direction="row" gap="medium" align="center">
          <TileTitle>SAML</TileTitle>
          {!isTierContainSAML && (
            <BadgeNextNew variant="purple" type="strong" startIcon={Diamond} iconOnly />
          )}
          {isTierContainSAML && integration && isIntegrationActive && (
            <BadgeNextNew variant="green" type="strong" text="Active" />
          )}
        </Box>
      }
      description="Connect your Spacelift account to a SAML 2.0 supporting identity provider."
    />
  );
};

export default SettingsSingleSignOnSAML;
