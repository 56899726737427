import { MouseEvent, useCallback, useEffect } from "react";
import { useModal } from "@ebay/nice-modal-react";

import { StackDependency, StackDependencyDetail } from "types/generated";
import DependencyItem from "views/Stack/components/DependencyItem";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";

import { showDependenciesDeleteConfirmationModal } from "../DeleteConfirmationModal";
import { OutputReferencesDrawer, showOutputReferencesDrawer } from "../OutputReferencesDrawer";

type DependenciesColumnItemProps = {
  dependency: StackDependency;
  stackIdSourceOfOutputs: string;
  itemStack: StackDependencyDetail;
  relatedStack: StackDependencyDetail;
  onDelete?: (dependencyId: string) => void;
  isOutputReferencesDisallowed?: boolean;
  disallowedVendorName?: string;
};

const DependenciesColumnItem = ({
  dependency,
  itemStack,
  stackIdSourceOfOutputs,
  relatedStack,
  onDelete,
  isOutputReferencesDisallowed,
  disallowedVendorName,
}: DependenciesColumnItemProps) => {
  const isFocused = useDrawerVisibilityForId(dependency.id);

  const drawer = useModal(OutputReferencesDrawer);

  const handleDeleteDependency = useCallback(() => {
    onDelete?.(dependency.id);
    if (isFocused) {
      drawer.hide();
    }
  }, [dependency.id, drawer, isFocused, onDelete]);

  const handleDeleteDependencyConfirmation = (e: MouseEvent) => {
    e.stopPropagation();

    showDependenciesDeleteConfirmationModal({
      onConfirm: handleDeleteDependency,
      itemStack,
      relatedStack,
    });
  };

  const handleOutputReferencesEdit = () => {
    showOutputReferencesDrawer({
      id: dependency.id,
      stackDependency: dependency,
      stackIdSourceOfOutputs,
    });
  };

  useEffect(() => {
    if (isFocused) {
      showOutputReferencesDrawer({
        id: dependency.id,
        stackDependency: dependency,
        stackIdSourceOfOutputs,
      });
    }
    // Re-render drawer content when dependency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency, stackIdSourceOfOutputs]);

  return (
    <DependencyItem
      item={itemStack}
      onDelete={onDelete ? handleDeleteDependencyConfirmation : undefined}
      onOutputReferencesEdit={handleOutputReferencesEdit}
      referenceCount={dependency.referenceCount}
      isFocused={isFocused}
      isOutputReferencesDisallowed={isOutputReferencesDisallowed}
      disallowedVendorName={disallowedVendorName}
    />
  );
};

export default DependenciesColumnItem;
