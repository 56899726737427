import { ReactNode, useEffect, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import useTypedContext from "hooks/useTypedContext";
import { isSaasDistribution } from "utils/distribution";
import { AccountContext } from "views/AccountWrapper";

import ProductSignUpForm from "./Form";
import useFirstUserSignUp from "./useFirstUserSignUp";
import { useLaunchpadRedirect } from "./useLaunchpadRedirect";
import { PSU_FORM_NAME } from "./constants";

const isSaas = isSaasDistribution();

type ProductSignUpWrapperProps = {
  children: ReactNode;
};

const ProductSignUpWrapper = ({ children }: ProductSignUpWrapperProps) => {
  const { genericFormsList, viewer } = useTypedContext(AccountContext);

  const shouldCompletePSUForm = useMemo(
    () => viewer.isFirstUser && genericFormsList.some((form) => form.name === PSU_FORM_NAME),
    [genericFormsList, viewer.isFirstUser]
  );

  const { shouldRedirectToLaunchpad, redirectToLaunchpad } = useLaunchpadRedirect();

  useFirstUserSignUp();

  useEffect(() => {
    if (!shouldCompletePSUForm && shouldRedirectToLaunchpad) {
      redirectToLaunchpad();
    }
  }, [genericFormsList, redirectToLaunchpad, shouldCompletePSUForm, shouldRedirectToLaunchpad]);

  if (!isSaas) {
    return children;
  }

  return (
    <Routes>
      <Route
        path="welcome"
        element={shouldCompletePSUForm ? <ProductSignUpForm /> : <Navigate to="/" replace />}
      />
      <Route
        path="*"
        element={shouldCompletePSUForm ? <Navigate to="welcome" replace /> : children}
      />
    </Routes>
  );
};

export default ProductSignUpWrapper;
