import { addDays, differenceInDays, fromUnixTime, getUnixTime, isSameDay, subDays } from "date-fns";
import { useEffect, useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";

import { EmptystateChartColored } from "components/icons/generated";
import { Datum } from "ds/components/Charts/BarChart/Chart";
import MissingDataBanner from "components/MissingDataBanner";

import DashboardWidgetsEmptyStateRuns from "../EmptyStateRuns";
import { GET_AVERAGE_RUN_DURATION } from "./gql";
import { Widget } from "../../types";
import DashboardWidgetsRunDuration from "../RunDuration";
import { GetAverageRunDuration } from "./types";
import usePollInterval from "../../usePollInterval";

const DATA_RANGE_DAYS = 30;

const DashboardWidgetsAverageRunDuration = () => {
  const startTime = useRef(subDays(new Date(), DATA_RANGE_DAYS));
  const endTime = useRef(new Date());
  const pollInterval = usePollInterval();
  const { loading, data, error, refetch, startPolling, stopPolling, networkStatus } =
    useQuery<GetAverageRunDuration>(GET_AVERAGE_RUN_DURATION, {
      variables: {
        startTime: getUnixTime(startTime.current),
        endTime: getUnixTime(endTime.current),
      },
      onError() {
        stopPolling();
      },
    });

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
    }
  }, [error, startPolling, pollInterval]);

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;

  const isLoading = loading && !data?.metricsRange;

  const averageRunDuration = showErrorBanner
    ? 0
    : (data?.metricsRange?.averageRunDuration?.[0]?.value ?? 0);

  const hasRuns = !!data?.metricsRange?.hasRuns;

  let emptyState;

  if (!hasRuns) {
    emptyState = (
      <DashboardWidgetsEmptyStateRuns
        title="There's no data to show yet"
        caption="Trigger your first run so we could display average run duration here."
        icon={EmptystateChartColored}
        widget={Widget.AverageRunDuration}
        showDocs
      />
    );
  }

  let errorBanner;

  if (showErrorBanner) {
    emptyState = undefined;
    errorBanner = (
      <MissingDataBanner
        text="Couldn’t load runs data. Please try to refresh or come back later."
        refreshHandler={refetch}
        refreshLoading={loading}
      />
    );
  }

  const chartData = useMemo(() => {
    const startDate = startTime.current;
    const endDate = endTime.current;
    // Create array of timestamps starting from startTime to endTime
    const timestamps = Array.from({ length: differenceInDays(endDate, startDate) }, (_, i) =>
      addDays(startDate, i + 1)
    );

    return timestamps
      .map((date) => {
        const foundValue = data?.metricsRange?.averageRunDurationRange?.find((item) =>
          isSameDay(fromUnixTime(item.timestamp), date)
        )?.value;

        const value = foundValue || 0;

        return {
          // TODO: BE return value in seconds but our chart has minutes
          value: value / 60,
          timestamp: getUnixTime(date),
        };
      })
      .sort((a, b) => a.timestamp - b.timestamp) as Datum[];
  }, [data?.metricsRange?.averageRunDurationRange]);

  return (
    <DashboardWidgetsRunDuration
      cardTitle="30d average"
      tooltipHeader="Average:"
      data={chartData}
      duration={averageRunDuration}
      isLoading={isLoading}
      emptyState={emptyState}
      errorBanner={errorBanner}
    />
  );
};

export default DashboardWidgetsAverageRunDuration;
