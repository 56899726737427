import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import { VcsProvider } from "types/generated";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import InstallationLink from "./InstallationLink";
import { SOURCE_CODE_FIELDS_TOOLTIPS } from "../constants";
import { SourceCodeFields, SourceCodeProjects } from "../types";

type RepositoryTooltipProps = {
  vcsProvider: VcsProvider;
  whitelistable?: boolean;
  projectType: SourceCodeProjects;
};

const RepositoryGitHub = ({
  whitelistable,
  projectType,
}: {
  whitelistable?: boolean;
  projectType: SourceCodeProjects;
}) => (
  <>
    <TooltipModalTitle>Repository</TooltipModalTitle>
    <TooltipModalBody align="start">
      GitHub {SOURCE_CODE_FIELDS_TOOLTIPS[SourceCodeFields.Repository][projectType].text}
      {whitelistable && (
        <>
          <span>
            If your repository is not on this list, you may need to explicitly whitelist it in your{" "}
            <InstallationLink>GitHub installation</InstallationLink> configuration.
          </span>
          Note that we only list up to 100 repositories. If you have more, you can still type the
          full name in this field.
        </>
      )}
      <ReadMoreDocsLink
        docsUrl={SOURCE_CODE_FIELDS_TOOLTIPS[SourceCodeFields.Repository][projectType].link}
      />
    </TooltipModalBody>
  </>
);

const Repository = ({
  provider,
  whitelistable,
  projectType,
}: {
  provider: string;
  whitelistable?: boolean;
  projectType: SourceCodeProjects;
}) => (
  <>
    <TooltipModalTitle>Repository</TooltipModalTitle>
    <TooltipModalBody align="start">
      {provider} {SOURCE_CODE_FIELDS_TOOLTIPS[SourceCodeFields.Repository][projectType].text}{" "}
      {whitelistable && (
        <>
          If you don't see your repository in the list, make sure that your access token can access
          it.
          <br />
          Note that we only list up to 100 repositories. If you have more, you can still type the
          full name in this field.
        </>
      )}
      <ReadMoreDocsLink
        docsUrl={SOURCE_CODE_FIELDS_TOOLTIPS[SourceCodeFields.Repository][projectType].link}
      />
    </TooltipModalBody>
  </>
);

const RepositoryTooltip = ({ vcsProvider, whitelistable, projectType }: RepositoryTooltipProps) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (vcsProvider) {
    case VcsProvider.Github:
      return <RepositoryGitHub whitelistable={whitelistable} projectType={projectType} />;
    default:
      return (
        <Repository
          provider={HUMANIZE_PROVIDER[vcsProvider]}
          whitelistable={whitelistable}
          projectType={projectType}
        />
      );
  }
};

export default RepositoryTooltip;
