import { Trash, Plus, Minus, Tilde, Download, ArrowsLeftRight } from "components/icons/generated";

import { DeltaType } from "./types";

export const deltaConfig = {
  [DeltaType.ADD]: (count: number) => ({
    type: DeltaType.ADD,
    count,
    label: `Added resources: ${count}`,
    icon: Plus,
  }),
  [DeltaType.CHANGE]: (count: number) => ({
    type: DeltaType.CHANGE,
    count,
    label: `Changed resources: ${count}`,
    icon: Tilde,
  }),
  [DeltaType.DELETE]: (count: number) => ({
    type: DeltaType.DELETE,
    count,
    label: `Deleted resources: ${count}`,
    icon: Minus,
  }),
  [DeltaType.IMPORT]: (count: number) => ({
    type: DeltaType.IMPORT,
    count,
    label: `Imported resources: ${count}`,
    icon: Download,
  }),
  [DeltaType.MOVE]: (count: number) => ({
    type: DeltaType.MOVE,
    count,
    label: `Moved resources: ${count}`,
    icon: ArrowsLeftRight,
  }),
  [DeltaType.FORGET]: (count: number) => ({
    type: DeltaType.FORGET,
    count,
    label: `Forgotten resources: ${count}`,
    icon: Trash,
  }),
  [DeltaType.OK]: (count: number) => ({
    type: DeltaType.OK,
    count,
    label: `Ok tasks: ${count}`,
  }),
  [DeltaType.CHANGED]: (count: number) => ({
    type: DeltaType.CHANGED,
    count,
    label: `Changed tasks: ${count}`,
  }),
  [DeltaType.UNREACHABLE]: (count: number) => ({
    type: DeltaType.UNREACHABLE,
    count,
    label: `Unreachable tasks: ${count}`,
  }),
  [DeltaType.FAILED]: (count: number) => ({
    type: DeltaType.FAILED,
    count,
    label: `Failed tasks: ${count}`,
  }),
  [DeltaType.SKIPPED]: (count: number) => ({
    type: DeltaType.SKIPPED,
    count,
    label: `Skipped tasks: ${count}`,
  }),
  [DeltaType.RESCUED]: (count: number) => ({
    type: DeltaType.RESCUED,
    count,
    label: `Rescued tasks: ${count}`,
  }),
  [DeltaType.IGNORED]: (count: number) => ({
    type: DeltaType.IGNORED,
    count,
    label: `Ignored tasks: ${count}`,
  }),
};
