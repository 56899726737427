import { useEffect, useRef } from "react";

import CollapsiblePanel from "components/CollapsiblePanel";
import Box from "ds/components/Box";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import ButtonIcon from "ds/components/ButtonIcon";
import Button from "ds/components/Button";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CodeEditor from "components/CodeEditor";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import { Copy } from "components/icons/generated";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { PolicyTemplate } from "types/generated";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import useAnalytics from "hooks/useAnalytics";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import useApplyFilter from "components/Filters/useApplyFilter";
import {
  POLICY_TYPES_FEATURES,
  PolicyTemplateSuggestions,
  searchPolicyTemplateSuggestionsDictionary,
} from "constants/policy";
import PolicyTypeBadgeDropdown from "components/PolicyTypeBadge/Dropdown";
import TagsListFilterable from "components/TagsList/Filterable";
import { SubscriptionContext } from "views/Account/SubscriptionWrapper";
import useTypedContext from "hooks/useTypedContext";

import { COLUMN_GAP, COLUMN_ORDER } from "./constants";
import styles from "./styles.module.css";

type PoliciesTemplatesListItemProps = {
  setRowHeight?: (size: number) => void;
  openCreateDrawer: () => void;
  canManagePolicies: boolean;
  isCollapsed: boolean;
  onToggle: () => void;
  onShowFullDescription: () => void;
} & Pick<PolicyTemplate, "body" | "description" | "labels" | "type" | "name">;

const PoliciesTemplatesListItem = ({
  name,
  type,
  body,
  labels,
  description,
  setRowHeight,
  openCreateDrawer,
  canManagePolicies,
  isCollapsed,
  onToggle,
  onShowFullDescription,
}: PoliciesTemplatesListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const { tierFeatures } = useTypedContext(SubscriptionContext);

  const requiredTierFeature = POLICY_TYPES_FEATURES[type];

  const isDisabledPolicy = requiredTierFeature && !tierFeatures.includes(requiredTierFeature);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPagePolicy.PoliciesTemplates,
  });

  const { applyFilter, applyLabelFilter } = useApplyFilter<PolicyTemplateSuggestions>({
    searchSuggestionsDictionary: searchPolicyTemplateSuggestionsDictionary,
  });

  // on every render, update the row height
  useEffect(handleRowHeight);

  const importTemplate = () => {
    openCreateDrawer();
    trackSegmentEvent("Template imported");
  };

  const copyToClipboard = useCopyToClipboard(body);

  return (
    <Box className={styles.entity} ref={rowRef}>
      <CollapsiblePanel
        className={styles.collapsibleItem}
        isCollapsed={isCollapsed}
        onToggle={onToggle}
      >
        <CollapsiblePanelHeader gap="medium" grow="1">
          <Box align="center" gap="large" fullWidth grow="1">
            <Box
              fullWidth
              direction="row"
              align="center"
              justify="between"
              padding="large"
              grid
              gridTemplate={COLUMN_ORDER}
              gap={`0 ${COLUMN_GAP}`}
            >
              <Box gap="large" align="center">
                <CollapsiblePanelToggleTrigger>
                  <CollapsiblePanelToggleIndicator />
                </CollapsiblePanelToggleTrigger>

                <Box direction="column" className={styles.entityTitleWrapper}>
                  <CollapsiblePanelToggleTrigger>
                    <TextEllipsis tooltip={name} tooltipWidthMode="maxWidthSm">
                      {(props) => (
                        <Typography {...props} variant="p-t6" tag="h3">
                          {name}
                        </Typography>
                      )}
                    </TextEllipsis>
                  </CollapsiblePanelToggleTrigger>

                  {description && (
                    <Box direction="row" align="start" fullWidth>
                      <ListEntitiesItemDescription
                        description={description}
                        onShowFullDescription={onShowFullDescription}
                      />
                    </Box>
                  )}
                  <TagsListFilterable
                    applyLabelFilter={applyLabelFilter(PolicyTemplateSuggestions.Label)}
                    tags={labels}
                    onExpand={handleRowHeight}
                    className={styles.tagsList}
                  />
                </Box>
              </Box>

              <Box>
                <PolicyTypeBadgeDropdown
                  type={type}
                  applyFilter={applyFilter(PolicyTemplateSuggestions.Type)}
                />
              </Box>
              {canManagePolicies && !isDisabledPolicy && (
                <Button variant="secondary" onClick={importTemplate}>
                  Import
                </Button>
              )}
            </Box>
          </Box>
        </CollapsiblePanelHeader>

        <CollapsiblePanelContent>
          <Box margin="0 large large large" className={styles.codeWrapper}>
            <ButtonIcon
              size="medium"
              variant="secondary"
              icon={Copy}
              onClick={copyToClipboard}
              className={styles.codeEditorCopyButton}
            >
              Copy
            </ButtonIcon>
            {/* Improves accessibility - without the condition the element contains focusable descendents for aria-hidden="true" */}
            {!isCollapsed && (
              <CodeEditor
                options={{
                  scrollbar: {
                    verticalScrollbarSize: 4,
                    horizontalScrollbarSize: 4,
                    alwaysConsumeMouseWheel: true,
                  },
                }}
                body={body}
                readOnly
                language="rego"
              />
            )}
          </Box>
        </CollapsiblePanelContent>
      </CollapsiblePanel>
    </Box>
  );
};

export default PoliciesTemplatesListItem;
