import { Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import { WorkerPool } from "types/generated";
import DescriptionDetails from "components/DescriptionDetails";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";

type PrivateWorkerPoolDetailsProps = {
  workerPool: WorkerPool;
};

const PrivateWorkerPoolDetailsDrawer = createDrawer(
  ({ workerPool }: PrivateWorkerPoolDetailsProps) => {
    const onOpenFullDescription = useShowFullDescriptionWithBackArrow(workerPool.description);
    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Worker pool details" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody>
          <FormFieldViewText label="ID" value={workerPool.id} withCopy />
          <FormFieldViewText label="Name" value={workerPool.name} />

          <FormField label="Space">
            <MetaInfoListItem
              icon={Space}
              linkText={workerPool.spaceDetails.name}
              href={`/spaces/${workerPool.spaceDetails.id}`}
            />
          </FormField>

          <DescriptionDetails
            description={workerPool.description}
            onOpenFullDescription={onOpenFullDescription}
          />

          <FormFieldTimestamp label="Last updated" timestamp={workerPool.updatedAt} />

          {workerPool.labels.length > 0 && (
            <FormField label="Labels">
              <TagsList tags={workerPool.labels} alwaysExpanded />
            </FormField>
          )}
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showPrivateWorkerPoolDetailsDrawer = createDrawerTrigger(
  PrivateWorkerPoolDetailsDrawer
);
