import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationCompleteGenericFormArgs } from "types/generated";

import { PSU_FORM_NAME } from "../constants";

export const SUBMIT_PSU_FORM: TypedDocumentNode<
  boolean,
  Pick<MutationCompleteGenericFormArgs, "data">
> = gql`
  mutation SubmitPSUForm($data: String!) {
    completeGenericForm(name: "${PSU_FORM_NAME}", data: $data)
  }
`;
