import { File } from "components/icons/generated";
import Button from "ds/components/Button";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import { DocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

type DocumentationButtonProps = {
  to: DocsUrl;
  label?: string;
} & AnalyticsCommonProps;

const DocumentationButton = ({
  to,
  analyticsProps,
  analyticsPage,
  analyticsTitle,
  label = "Full documentation",
}: DocumentationButtonProps) => {
  return (
    <Button
      href={to}
      variant="secondary"
      size="medium"
      target="_blank"
      startIcon={File}
      className={styles.documentationButton}
      analyticsTitle={analyticsTitle || `${label} Click`}
      analyticsProps={analyticsProps}
      analyticsPage={analyticsPage}
    >
      {label}
    </Button>
  );
};

export default DocumentationButton;
