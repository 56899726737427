import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useModal } from "@ebay/nice-modal-react";

import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import FormField from "ds/components/Form/Field";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import ButtonNew from "ds/components/Button/New";
import Textarea from "ds/components/Textarea";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useAnalytics from "hooks/useAnalytics";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import { UPDATE_VERSION_DESCRIPTION } from "../../gql";

type ModuleVersionAddDescriptionDrawerProps = {
  description: string | null;
  moduleId: string;
  versionId: string;
};

type FormValues = {
  description: string | null;
};

const ModuleVersionAddDescriptionDrawer = createDrawer(
  ({ description, moduleId, versionId }: ModuleVersionAddDescriptionDrawerProps) => {
    const drawer = useModal();

    const trackSegmentAnalyticsEvent = useAnalytics();

    const handleCancel = () => {
      trackSegmentAnalyticsEvent("Module Registry - Edit Description Canceled");
    };

    const { onError, reportSuccess } = useTypedContext(FlashContext);

    const [updateVersionDescription] = useMutation(UPDATE_VERSION_DESCRIPTION, {
      refetchQueries: ["GetVersionNext", "GetModuleVersionsNext"],
      awaitRefetchQueries: true,
    });

    const builderForm = useForm<FormValues>({
      defaultValues: {
        description,
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      reset,
      formState: { isValid, isDirty, isSubmitSuccessful, isSubmitting },
    } = builderForm;

    const onSubmit = (values: FormValues) => {
      return updateVersionDescription({
        variables: {
          id: versionId,
          module: moduleId,
          description: values.description,
        },
      })
        .then(() => {
          reportSuccess({ message: "Description successfully saved" });
          trackSegmentAnalyticsEvent("Module Registry - Version Description Edited");
          drawer.hide();
        })
        .catch(onError);
    };

    useEffect(() => {
      if (isSubmitSuccessful) {
        reset({
          description,
        });
      }
    }, [isSubmitSuccessful, reset, description]);

    return (
      <DrawerSimple onClose={handleCancel}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Edit description" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <FormProvider {...builderForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerBody>
              <FormField label="Description" isOptional>
                {({ ariaInputProps }) => (
                  <Textarea
                    placeholder="Enter description here..."
                    maxRows={15}
                    {...register("description")}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton />
                  <ButtonNew
                    variant="primary"
                    type="submit"
                    disabled={!isValid || !isDirty || isSubmitting}
                    loading={isSubmitting}
                  >
                    Save
                  </ButtonNew>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showModuleVersionAddDescriptionDrawer = createDrawerTrigger(
  ModuleVersionAddDescriptionDrawer
);
