import { ApolloError } from "@apollo/client";
import { createContext } from "react";

import { DebugInfo } from "types/generated";

import { LicenseExpirationStatus } from "./types";

export type SelfHostedLicenseContextData = {
  debugInfo?: DebugInfo;
  licenseExpirationStatus?: LicenseExpirationStatus;
  remainingDays?: number;
  loading?: boolean;
  error?: ApolloError;
  refetch?: () => void;
};

export const SelfHostedLicenseContext = createContext<SelfHostedLicenseContextData | undefined>(
  undefined
);

SelfHostedLicenseContext.displayName = "SelfHostedLicenseContext";
