import { useCallback, useState } from "react";
import { isEqual } from "lodash-es";
import { useNavigate } from "react-router-dom";

import { AccountContext } from "views/AccountWrapper";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import FullScreenModal from "ds/components/FullScreenModal";
import NotFoundPage from "components/error/NotFoundPage";
import WarningContextProvider from "components/WarningContext/Provider";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";

import { SpacesContext } from "../SpacesProvider";
import { InternalFormData, ModuleCreationWizardStep, ModuleFormFields } from "./types";
import { ModuleFormContext } from "./context";
import NewModuleStepper from "./Stepper";
import { availableSteps, INITIAL_FORM_DATA } from "./constants";
import NewModuleVcs from "./Vcs";
import NewModuleDetails from "./Details";
import NewModuleIntermediateConfirmation from "./IntermediateConfirmation";
import NewModuleShare from "./Share";
import NewModuleBehavior from "./Behavior";
import NewModuleAttachCloud from "./AttachCloud";
import NewModuleAttachPolicies from "./AttachPolicies";
import NewModuleAttachContext from "./AttachContext";
import NewModuleSummary from "./Summary";

type NewModuleProps = {
  initialStep?: ModuleCreationWizardStep;
  initialModuleId?: string;
};

const NewModule = ({
  initialStep = ModuleCreationWizardStep.Vcs,
  initialModuleId = "",
}: NewModuleProps) => {
  const navigate = useNavigate();
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  const [AccountOrAuthErrorContent, setAccountOrAuthErrorContent] = useState<
    JSX.Element | undefined
  >();

  const { accountName } = useTypedContext(AccountContext);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [asyncValidationLoading, setAsyncValidationLoading] = useState(false);
  const [stepperNavDisabled, setStepperNavDisabled] = useState(false);
  const [isIntermediateStepActive, setIsIntermediateStepActive] = useState(false);
  const [createdModuleId, setCreatedModuleId] = useState(initialModuleId);

  const currentStepIndex =
    currentStep === ModuleCreationWizardStep.Summary
      ? availableSteps.length
      : availableSteps.findIndex((step) => step.step === currentStep);

  const [internalData, setInternalFormData] = useState<InternalFormData>({});

  const initialValues = INITIAL_FORM_DATA;

  const [formData, setFormData] = useState<ModuleFormFields>(initialValues);

  const goToSummary = useCallback(() => {
    setCurrentStep(ModuleCreationWizardStep.Summary);
  }, []);

  const goToNextStep = useCallback(() => {
    const nextStep =
      availableSteps.find((_step, index) => index > currentStepIndex)?.step ??
      ModuleCreationWizardStep.Summary;

    setCurrentStep(nextStep);
  }, [currentStepIndex]);

  const goToPreviousStep = useCallback(() => {
    const previousStep =
      availableSteps
        .filter((step) => step.step !== ModuleCreationWizardStep.Summary)
        .findLast((_step, index) => index < currentStepIndex)?.step ??
      ModuleCreationWizardStep.Details;

    setCurrentStep(previousStep);
  }, [currentStepIndex]);

  const updateStepData = useCallback(
    (step: ModuleCreationWizardStep, data: ModuleFormFields[ModuleCreationWizardStep]) => {
      const newFormData = { ...formData, [step]: data };
      setFormData(newFormData);
      return newFormData;
    },
    [formData]
  );

  useTitle(`New module · ${accountName}`);

  const isInitialDataChanged = !isEqual(formData, initialValues);

  const modalCloseClickHandler = useCallback(async () => {
    if (isInitialDataChanged) {
      await showSimpleLeaveConfirmation({
        title: "Do you want to leave module creation process?",
        message: createdModuleId
          ? "Some of your changes may not be saved."
          : "Your changes will not be saved.",
      });
    }

    if (createdModuleId) {
      navigate(`/module/${createdModuleId}`, {
        replace: true,
      });
    } else {
      navigate("/modules");
    }
  }, [isInitialDataChanged, createdModuleId, navigate]);

  if (AccountOrAuthErrorContent) {
    return AccountOrAuthErrorContent;
  }

  if (!hasEntityCreateAccess) {
    return <NotFoundPage />;
  }

  return (
    <ModuleFormContext.Provider
      value={{
        availableSteps,
        internalData,
        setInternalFormData,
        goToSummary,
        currentStep,
        currentStepIndex,
        setCurrentStep,
        formData,
        setFormData,
        updateStepData,
        goToPreviousStep,
        goToNextStep,
        asyncValidationLoading,
        setAsyncValidationLoading,
        stepperNavDisabled,
        setStepperNavDisabled,
        isIntermediateStepActive,
        setIsIntermediateStepActive,
        setCreatedModuleId,
        createdModuleId,
        setAccountOrAuthErrorContent,
      }}
    >
      <FullScreenModal title="Create module" onClose={modalCloseClickHandler}>
        <WarningContextProvider>
          <Box grow="1" fullWidth scrollable>
            <NewModuleStepper />
            <Box fullWidth direction="column" relative>
              {isIntermediateStepActive && <NewModuleIntermediateConfirmation />}
              {!isIntermediateStepActive && (
                <>
                  {currentStep === ModuleCreationWizardStep.Vcs && <NewModuleVcs />}
                  {currentStep === ModuleCreationWizardStep.Details && <NewModuleDetails />}
                  {currentStep === ModuleCreationWizardStep.Share && <NewModuleShare />}
                  {currentStep === ModuleCreationWizardStep.Behavior && <NewModuleBehavior />}
                  {currentStep === ModuleCreationWizardStep.Cloud && <NewModuleAttachCloud />}
                  {currentStep === ModuleCreationWizardStep.Policies && <NewModuleAttachPolicies />}
                  {currentStep === ModuleCreationWizardStep.Contexts && <NewModuleAttachContext />}
                  {currentStep === ModuleCreationWizardStep.Summary && <NewModuleSummary />}
                </>
              )}
            </Box>
          </Box>
        </WarningContextProvider>
      </FullScreenModal>
    </ModuleFormContext.Provider>
  );
};

export default NewModule;
