import { gql, TypedDocumentNode } from "@apollo/client";

import { DebugInfo } from "types/generated";

type FetchDebugInfoData = { debugInfo: DebugInfo };

export const GET_DEBUG_INFO: TypedDocumentNode<FetchDebugInfoData> = gql`
  query GetDebugInfo {
    debugInfo {
      commitSha
      selfHostedVersion
      selfHostedLicence {
        validity {
          begin
          end
        }
      }
    }
  }
`;
