import { useMemo } from "react";

import { SeatLimit } from "types/generated";

const useSeatLimit = (seatLimit?: SeatLimit) => {
  return useMemo(() => {
    if (!seatLimit) {
      return {
        limit: -1,
        inUse: 0,
        isUnlimited: true,
        isLimitReached: false,
        isOneSeatLeft: false,
      };
    }

    return {
      limit: seatLimit.limit,
      inUse: seatLimit.inUse,
      isUnlimited: seatLimit.limit === -1,
      isLimitReached: seatLimit.limit !== -1 && seatLimit.inUse >= seatLimit.limit,
      isOneSeatLeft: seatLimit.limit !== -1 && seatLimit.inUse === seatLimit.limit - 1,
    };
  }, [seatLimit]);
};

export default useSeatLimit;
