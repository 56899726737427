import { Gear, User, Commit } from "components/icons/generated";
import Icon from "ds/components/Icon";

type RunTriggerCommitProps = {
  triggeredBy?: string;
  className?: string;
};

const RunTriggerCommit = ({ triggeredBy, className }: RunTriggerCommitProps) => {
  let iconSrc = User;
  if (!triggeredBy) {
    iconSrc = Commit;
  }

  if (triggeredBy?.startsWith("policy/")) {
    iconSrc = Gear;
  }

  if (triggeredBy?.startsWith("reconciliation/")) {
    iconSrc = Gear;
  }

  return <Icon src={iconSrc} className={className} />;
};

export default RunTriggerCommit;
