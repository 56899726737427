import { To } from "react-router-dom";

import Link from "ds/components/Link";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography, { TypographyProps } from "ds/components/Typography";
import { TooltipProps } from "ds/components/Tooltip/types";
import SearchHighlight from "components/SearchHighlight";

import { DEFAULT_TITLE_VARIANT } from "./constants";

type ListEntitiesItemLinkWithHref = {
  href: string;
  to?: never;
};

type ListEntitiesItemLinkWithTo = {
  href?: never;
  to: To;
};

type ListEntitiesItemLinkUrlProps = ListEntitiesItemLinkWithHref | ListEntitiesItemLinkWithTo;

type ListEntitiesItemLinkProps = ListEntitiesItemLinkUrlProps & {
  title: string;
  titleTag?: TypographyProps["tag"];
  titleVariant?: TypographyProps["variant"];
  titleColor?: TypographyProps["color"];
  tooltip?: string;
  tooltipWidthMode?: TooltipProps["widthMode"];
  searchQuery?: string;
  replaceLocation?: boolean;
  onClick?: () => void;
};

const ListEntitiesItemLink = ({
  title,
  to,
  href,
  titleTag = "span",
  titleVariant = DEFAULT_TITLE_VARIANT,
  titleColor,
  tooltip = title,
  tooltipWidthMode = "maxWidthSm",
  searchQuery,
  replaceLocation,
  onClick,
}: ListEntitiesItemLinkProps) => {
  return (
    <TextEllipsis tooltip={tooltip} tooltipWidthMode={tooltipWidthMode}>
      {(props) => (
        <Link
          to={to}
          href={href}
          align="center"
          justify="start"
          listLink
          replace={replaceLocation}
          onClick={onClick}
        >
          <Typography {...props} variant={titleVariant} tag={titleTag} color={titleColor}>
            {searchQuery ? <SearchHighlight fullText={title} searchQuery={searchQuery} /> : title}
          </Typography>
        </Link>
      )}
    </TextEllipsis>
  );
};

export default ListEntitiesItemLink;
