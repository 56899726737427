import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FormFieldTags from "components/FormFields/Tags";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import ButtonNew from "ds/components/Button/New";
import { useFormValidations } from "hooks";
import { validatePolicyName } from "utils/formValidators";
import { getDrawerFormFix } from "utils/css";

import { CreatePolicyFields } from "./types";
import { ADD_LOGIN_POLICY_DRAWER_TEST_ID } from "./constants";
import { getManagementStrategy } from "../../helpers";
import { UserManagementActivationStatus } from "../../types";

type LoginPoliciesCreateDrawerProps = {
  activationStatus: UserManagementActivationStatus;
  takenPolicyNames: string[];
};

const LoginPoliciesCreateDrawer = createDrawer(
  ({ activationStatus, takenPolicyNames }: LoginPoliciesCreateDrawerProps) => {
    const navigate = useNavigate();

    const trackSegmentAnalyticsEvent = useAnalytics({
      page: AnalyticsPageOrganization.OrganizationLoginPoliciesList,
      defaultCallbackTrackProperties: {
        managementStrategy: getManagementStrategy(activationStatus),
      },
    });

    const createPolicyForm = useForm<CreatePolicyFields>({
      defaultValues: {
        name: "",
        labels: [],
      },
      mode: "onChange",
    });

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = createPolicyForm;
    const runFormValidations = useFormValidations(createPolicyForm);

    const cancelHandler = () => {
      trackSegmentAnalyticsEvent("Create Cancel");
    };

    const redirectToCreateView: SubmitHandler<CreatePolicyFields> = (formData) => {
      trackSegmentAnalyticsEvent("Create Continue");

      const labelsQuery =
        formData.labels && `&labels=${btoa(encodeURIComponent(JSON.stringify(formData.labels)))}`;

      navigate(`/new/login-policy?name=${btoa(encodeURIComponent(formData.name))}${labelsQuery}`);
    };

    return (
      <DrawerSimple dataTestId={ADD_LOGIN_POLICY_DRAWER_TEST_ID}>
        <FormProvider {...createPolicyForm}>
          <form
            onSubmit={handleSubmit(redirectToCreateView, runFormValidations)}
            {...getDrawerFormFix()}
          >
            <DrawerHeader justify="between">
              <DrawerHeaderTitle title="Create policy" />
              <DrawerCloseIcon />
            </DrawerHeader>
            <DrawerBody fullHeight>
              <FormField label="Name" error={errors?.name?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Name of your new policy"
                    error={!!errors?.name}
                    {...register("name", {
                      required: "Name field is required.",
                      setValueAs: (value) => value.trim(),
                      validate: validatePolicyName(takenPolicyNames),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>

              <FormFieldTags
                stopEnterPropagation
                name="labels"
                tagName="label"
                label="Labels"
                isOptional
              />

              <DrawerFooter>
                <DrawerFooterActions>
                  <DrawerCancelButton onClick={cancelHandler} />
                  <ButtonNew variant="primary" type="submit">
                    Continue
                  </ButtonNew>
                </DrawerFooterActions>
              </DrawerFooter>
            </DrawerBody>
          </form>
        </FormProvider>
      </DrawerSimple>
    );
  }
);

export const showLoginPoliciesCreateDrawer = createDrawerTrigger(LoginPoliciesCreateDrawer);
