import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { Space, Incognito, Network } from "components/icons/generated";
import TagsList from "components/TagsList";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import ListEntitiesItem from "components/ListEntitiesItem";
import { useDrawerVisibilityForId } from "ds/components/DrawerNew/useDrawerVisibilityForId";

import ProviderListItemDropdown from "../ProviderListItemDropdown";
import { ProviderListItemProps } from "./types";
import { COLUMN_ORDER_PROVIDERS } from "../constants";

function ProviderListItem({ item, onEdit }: ProviderListItemProps) {
  const isDrawerVisible = useDrawerVisibilityForId(item.id);

  return (
    <ListEntitiesItem
      grid
      gridTemplate={COLUMN_ORDER_PROVIDERS}
      gap="0 large"
      isActive={isDrawerVisible}
    >
      <Box direction="column" justify="center" __deprecatedGap="0.2rem">
        <Box align="center" __deprecatedGap="1.2rem">
          <ListEntitiesItemLink title={item.id} to={`/provider/${item.id}`} />

          <Tooltip
            on={(props) => <Icon {...props} src={item.public ? Network : Incognito} noShrink />}
          >
            {item.public ? "Public provider" : "Private provider"}
          </Tooltip>
        </Box>

        {item.description && (
          <ListEntitiesItemDescription id={item.id} description={item.description} />
        )}

        <TagsList tags={item.labels} key={item.labels.join()} />
      </Box>

      {item.space && (
        <MetaInfoListItem
          icon={Space}
          linkText={item.spaceDetails.name}
          href={`/spaces/${item.space}`}
        />
      )}

      <Box align="center">
        <Typography tag="div" variant="p-body2">
          {item.latestVersionNumber || "-"}
        </Typography>
      </Box>

      <Box align="center">
        <ProviderListItemDropdown item={item} onEdit={onEdit} />
      </Box>
    </ListEntitiesItem>
  );
}

export default ProviderListItem;
