import cx from "classnames";
import { ReactNode } from "react";

import { ExclamationMarkTriangleFilled, Lock } from "components/icons/generated";
import Timestamp from "components/time/Timestamp";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { Maybe } from "types/generated";

import styles from "./styles.module.css";
import Icon from "../Icon";

type NoteProps = {
  content?: Maybe<string>;
  title: string;
  customTitle?: ReactNode;
  createdBy: Maybe<string>;
  createdAt?: Maybe<number>;
  viewer: string;
  variant: "disabled" | "danger";
};

const icons = {
  disabled: Lock,
  danger: ExclamationMarkTriangleFilled,
};

const Note = ({
  createdAt,
  createdBy,
  content,
  viewer,
  variant,
  title,
  customTitle,
}: NoteProps) => {
  const IconComponent = icons[variant];

  return (
    <div className={cx(styles.wrapper, styles[variant])}>
      <Box
        direction="row"
        align="center"
        justify="between"
        className={cx(styles.header, styles[variant])}
      >
        {customTitle ? (
          customTitle
        ) : (
          <Typography tag="div" variant="p-body3">
            {title} {`by ${createdBy === viewer ? "yourself" : createdBy}`}
            {createdAt && (
              <>
                {" "}
                <Timestamp timestamp={createdAt} />
              </>
            )}
            .
          </Typography>
        )}

        <div className={cx(styles.iconWrapper, styles[variant])}>
          <Icon src={IconComponent} />
        </div>
      </Box>
      {content && (
        <Box direction="column" justify="center" className={cx(styles.content, styles[variant])}>
          <Typography tag="p" variant="p-body3" className={styles.contentHeader}>
            Note:
          </Typography>
          <Typography tag="p" variant="p-body3">
            {content}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default Note;
