import { Controller, useForm } from "react-hook-form";
import { ChangeEvent } from "react";

import DrawerBody from "ds/components/Drawer/Body";
import FormFieldViewText from "components/FormFields/ViewText";
import { OidcSettings } from "types/generated";
import useTypedFlags from "hooks/useTypedFlags";
import { createDefaultMutationHook } from "hooks/useMutationHandler";
import FormToggleField from "ds/components/Form/ToggleField";
import { OIDCUseSlackIDFromIdpTooltip } from "components/TooltipSnippets";
import { SLACK_MEMBER_ID_CLAIM_MAPPING_EMPTY } from "views/Account/Settings/OIDCPane/constants";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import SettingsSingleSignOnConfigInfoOIDC from "../../components/ConfigInfoOIDC";
import { UPDATE_OIDC_SSO } from "./gql";
import { shouldUseSlackMemberIDFromIdp } from "../helpers";
import { SLACK_MEMBER_ID_CLAIM_MAPPING } from "../constants";

const useUpdateOIDC = createDefaultMutationHook(UPDATE_OIDC_SSO);
const TooltipSlackMemberIDForOIDC = OIDCUseSlackIDFromIdpTooltip();

type SettingsSingleSignOnDetailsFormFieldsOIDC = {
  claimMapping: boolean;
};

type SettingsSingleSignOnDetailsOIDCProps = {
  integration: OidcSettings;
};

const SettingsSingleSignOnDetailsOIDC = ({ integration }: SettingsSingleSignOnDetailsOIDCProps) => {
  const { useSlackMemberIdFromIdpForUserProfile } = useTypedFlags();
  const { reportSuccess } = useTypedContext(FlashContext);

  const [oidcUpdate] = useUpdateOIDC({ refetchQueries: ["GetSSOSettings"] });

  const { control } = useForm<SettingsSingleSignOnDetailsFormFieldsOIDC>({
    mode: "onChange",
    defaultValues: {
      claimMapping: shouldUseSlackMemberIDFromIdp(integration.claimMapping),
    },
  });

  const handleChange =
    (onChange: (value: boolean) => void) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked);

      oidcUpdate(
        {
          input: {
            claimMapping: e.target.checked
              ? SLACK_MEMBER_ID_CLAIM_MAPPING
              : SLACK_MEMBER_ID_CLAIM_MAPPING_EMPTY,
          },
        },
        (data) => {
          if (data?.oidcUpdate) {
            reportSuccess({ message: "OIDC settings updated" });
          }
        }
      );
    };

  return (
    <>
      <DrawerBody hasDivider gap="x-large">
        <SettingsSingleSignOnConfigInfoOIDC />
      </DrawerBody>
      <DrawerBody hasDivider gap="x-large">
        <FormFieldViewText label="Admin login" value={integration.adminLogin} withCopy noMargin />
        <FormFieldViewText label="Client ID" value={integration.clientId} withCopy noMargin />
        <FormFieldViewText
          label="Provider URL"
          value={integration.identityProviderHost}
          withCopy
          noMargin
        />

        {useSlackMemberIdFromIdpForUserProfile && (
          <Controller
            name="claimMapping"
            control={control}
            render={({ field }) => (
              <FormToggleField
                title="Use provided Slack Member ID"
                tooltipInfo={TooltipSlackMemberIDForOIDC}
                variant="switch"
                onChange={handleChange(field.onChange)}
                checked={field.value}
              />
            )}
          />
        )}
      </DrawerBody>
    </>
  );
};

export default SettingsSingleSignOnDetailsOIDC;
