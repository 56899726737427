import TileWrapper from "ds/components/Tile/Wrapper";
import EmptyState from "ds/components/EmptyState";
import { EmptystateDataFlowColored } from "components/icons/generated";

const DashboardResourcesChartEmptyNoResources = () => {
  return (
    <TileWrapper padding="x-large" fullWidth justify="center">
      <EmptyState title="You don’t have any resources yet" icon={EmptystateDataFlowColored} />
    </TileWrapper>
  );
};

export default DashboardResourcesChartEmptyNoResources;
