import { gql, TypedDocumentNode } from "@apollo/client";

import { OAuthRedirect } from "types/generated";

type FetchSlackOauthRedirect = {
  slackOauthRedirect: Pick<OAuthRedirect, "providerUrl" | "temporaryToken">;
};
export const SLACK_OAUTH_REDIRECT: TypedDocumentNode<FetchSlackOauthRedirect> = gql`
  mutation SlackOauthRedirect {
    slackOauthRedirect {
      providerUrl
      temporaryToken
    }
  }
`;
