import DrawerSimple from "ds/components/DrawerNew/Simple";
import { ApiKey } from "types/generated";
import { UserManagementActivationStatus } from "views/Account/Settings/types";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";

import ApiKeysDetailsDrawerContent from "./Content";

type ApiKeysDetailsDrawerProps = {
  apiKey?: ApiKey;
  activationStatus: UserManagementActivationStatus;
};

const ApiKeysDetailsDrawer = createDrawer(
  ({ apiKey, activationStatus }: ApiKeysDetailsDrawerProps) => {
    return (
      <DrawerSimple isDismissable>
        {apiKey && (
          <ApiKeysDetailsDrawerContent apiKey={apiKey} activationStatus={activationStatus} />
        )}
      </DrawerSimple>
    );
  }
);

export const showApiKeysDetailsDrawer = createDrawerTrigger(ApiKeysDetailsDrawer);
