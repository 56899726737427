import { isSelfHostedDistribution } from "utils/distribution";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import { ModuleCreationWizardNavStep, ModuleCreationWizardStep } from "./types";

const isSelfHosted = isSelfHostedDistribution();

export const isStepRequired = (step: ModuleCreationWizardStep) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (step) {
    case ModuleCreationWizardStep.Behavior: {
      return isSelfHosted;
    }
    case ModuleCreationWizardStep.Share:
    case ModuleCreationWizardStep.Cloud:
    case ModuleCreationWizardStep.Contexts:
    case ModuleCreationWizardStep.Policies:
    case ModuleCreationWizardStep.Summary: {
      return false;
    }
    default: {
      return true;
    }
  }
};

export const areAllNextStepsOptional = (
  step: ModuleCreationWizardStep,
  availableSteps: Array<ModuleCreationWizardNavStep>
) => {
  const stepIndex = availableSteps.findIndex(({ step: currentStep }) => currentStep === step);
  const nextSteps = availableSteps.slice(stepIndex + 1);

  return !!nextSteps.length && nextSteps.every(({ step }) => !isStepRequired(step));
};

export const isSkipToSummaryVisible = (
  step: ModuleCreationWizardStep,
  availableSteps: Array<ModuleCreationWizardNavStep>
) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (step) {
    case ModuleCreationWizardStep.Share:
    case ModuleCreationWizardStep.Behavior:
    case ModuleCreationWizardStep.Cloud:
    case ModuleCreationWizardStep.Policies:
    case ModuleCreationWizardStep.Contexts: {
      return areAllNextStepsOptional(step, availableSteps);
    }
    default: {
      return false;
    }
  }
};

export const getTooltipAnalyticsProps = (
  location: string,
  name: string,
  props?: TrackAnalyticsEventProperties
) => {
  return {
    tooltipAnalyticsPage: AnalyticsPageModule.ModuleNew,
    tooltipAnalyticsTitle: "Tooltip click",
    tooltipAnalyticsProps: { location, name, ...props },
  };
};

export const getSwitchToggleAnalyticsProps = (
  location: string,
  name: string,
  propagateTooltip?: boolean,
  props?: TrackAnalyticsEventProperties
) => {
  return {
    analyticsPage: AnalyticsPageModule.ModuleNew,
    analyticsTitle: propagateTooltip ? "Switch Toggle" : undefined,
    tooltipAnalyticsTitle: "Tooltip click",
    analyticsProps: { location, name, ...props },
  };
};
