import Timestamp from "components/time/Timestamp";

import StackListItemValue from "./Value";
import { CellComponentProps } from "./types";

type StackListItemUpdatedAtCellProps = CellComponentProps;

const StackListItemUpdatedAtCell = ({ stack }: StackListItemUpdatedAtCellProps) => {
  if (!stack.stateSetAt) {
    return null;
  }

  return (
    <StackListItemValue>
      <Timestamp timestamp={stack.stateSetAt!} />
    </StackListItemValue>
  );
};

export default StackListItemUpdatedAtCell;
