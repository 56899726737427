import { Link, NavLink } from "react-router-dom";

import Box from "components/box/Box";
import { AwsIntegration, SpaceAccessLevel } from "types/generated";
import { pluralize } from "shared/Pluralize";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";
import { getDuration } from "utils/time";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import styles from "./styles.module.css";
import { showDeleteConfirmation } from "../DeleteConfirmation";

type ListItemProps = {
  integration: AwsIntegration;
};

const ListItem = (props: ListItemProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const { integration } = props;

  const canManageItem =
    integration.spaceDetails.accessLevel === SpaceAccessLevel.Admin ||
    integration.spaceDetails.accessLevel === SpaceAccessLevel.Write;

  const attachedStackCount = integration.attachedStacks.length;

  const onDeleteClick = () => {
    showDeleteConfirmation({ integration });
  };

  const hasAttachedEntities = attachedStackCount > 0;
  const hasManageAccess = viewer.admin || canManageItem;

  return (
    <Box className={styles.item}>
      <div className={styles.itemDetails}>
        <div className={styles.itemContent}>
          <NavLink to={integration.id} className={styles.itemName}>
            <span>{integration.name}</span>
          </NavLink>

          <div className={styles.itemInfo}>
            <div>Role ARN: {integration.roleArn}</div>
            <div>Duration: {getDuration(integration.durationSeconds)}</div>
            {integration.externalId && <div>External ID: {integration.externalId}</div>}
            {integration.spaceDetails && (
              <div>
                Space:{` `}
                <Link to={`/spaces/${integration.spaceDetails.id}`} className={styles.itemLink}>
                  {integration.spaceDetails.name}
                </Link>
              </div>
            )}
            <div>
              Used by {attachedStackCount} {pluralize("stack", attachedStackCount)}
            </div>
          </div>
        </div>

        <div className={styles.itemButtons}>
          <DropdownMenuEllipsis tooltip="Integration actions" dotsSize="medium">
            <DropdownMenuItem href={integration.id}>Go to details</DropdownMenuItem>
            <CopyFieldDropdownMenuItem title="Copy ID" value={integration.id} />
            {hasManageAccess && (
              <>
                <DropdownMenuItem href={`${integration.id}/edit`}>Edit</DropdownMenuItem>
                <DropdownMenuItem
                  tooltip="Cannot delete this integration because it is attached to a stack"
                  isTooltipActive={hasAttachedEntities}
                  onAction={onDeleteClick}
                  danger
                  isDisabled={hasAttachedEntities}
                >
                  Delete
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuEllipsis>
        </div>
      </div>
    </Box>
  );
};

export default ListItem;
