import { ChangeEvent, forwardRef, ReactNode, useMemo } from "react";

import formStyles from "components/FormDefault/styles.module.css";
import SegmentedControl from "ds/components/SegmentedControl";
import Select from "ds/components/Select";
import CardWrapper from "components/CardWrapper";
import Input from "ds/components/Input";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import TooltipInfo from "ds/components/TooltipInfo";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import MissingDataBanner from "components/MissingDataBanner";

import { StackCreationVersion, StackCreationVersionType } from "../types";
import styles from "./styles.module.css";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";

type VersionInputProps = {
  type: StackCreationVersionType;
  version: string;
  supportedVersions?: string[];
  onChange(version: StackCreationVersion): void;
  errorMessage?: string;
  title: string;
  tooltipInfo: ReactNode;
  reloadVersionsData: () => void;
  reloadLoading: boolean;
  analyticsProps: TrackAnalyticsEventProperties;
};

const VersionInput = forwardRef(function VersionInput(
  {
    title,
    type,
    version,
    supportedVersions,
    onChange,
    errorMessage,
    tooltipInfo,
    reloadVersionsData,
    reloadLoading,
    analyticsProps,
  }: VersionInputProps,
  externalRef: React.Ref<HTMLInputElement>
) {
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();

  const defaultPreciseVersion =
    supportedVersions && supportedVersions.length ? supportedVersions[0] : "";
  const supportedVersionOptions = useMemo(
    () =>
      supportedVersions?.map((value) => ({
        value,
        label: value,
      })),
    [supportedVersions]
  );

  const handleTypeChange = (type: StackCreationVersionType) => {
    if (type === StackCreationVersionType.Specific) {
      onChange({ value: defaultPreciseVersion, type });
    } else if (type === StackCreationVersionType.Range) {
      onChange({ value: version, type });
    }

    trackSegmentEvent?.("Version specific", {
      ...analyticsProps,
      specific: type === StackCreationVersionType.Specific,
    });
  };

  const handleVersionChange = (value: string) => onChange({ value, type });

  const handleVersionRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const range = event.target.value;
    onChange({
      value: range,
      type: StackCreationVersionType.Range,
    });
  };

  return (
    <CardWrapper variant="filled" direction="column">
      <Box gap="small" align="center" className={styles.versionTitleWrapper}>
        <Typography tag="p" variant="p-t5">
          {title}
        </Typography>
        <TooltipInfo
          analyticsPage={AnalyticsPageStack.StackNew}
          analyticsProps={analyticsProps}
          analyticsTitle="Tooltip click"
          variant="modal"
        >
          {tooltipInfo}
        </TooltipInfo>
      </Box>

      {supportedVersionOptions === undefined && (
        <MissingDataBanner refreshLoading={reloadLoading} refreshHandler={reloadVersionsData} />
      )}

      {supportedVersionOptions !== undefined && (
        <>
          <SegmentedControl
            value={type}
            onChange={handleTypeChange}
            options={[
              { value: StackCreationVersionType.Specific, label: "Specific" },
              { value: StackCreationVersionType.Range, label: "Range" },
            ]}
          />
          <FormField
            error={errorMessage}
            helperText={type === StackCreationVersionType.Range && "Use SemVer format"}
          >
            {({ ariaInputProps }) => (
              <>
                {type === StackCreationVersionType.Specific && (
                  <Select
                    value={version}
                    options={supportedVersionOptions}
                    onChange={handleVersionChange}
                    ariaInputProps={ariaInputProps}
                  />
                )}
                {type === StackCreationVersionType.Range && (
                  <Input
                    error={!!errorMessage}
                    autoFocus
                    className={formStyles.input}
                    type="text"
                    value={version || ""}
                    placeholder="Set range"
                    onChange={handleVersionRangeChange}
                    {...ariaInputProps}
                    ref={externalRef}
                  />
                )}
              </>
            )}
          </FormField>
        </>
      )}
    </CardWrapper>
  );
});

export default VersionInput;
