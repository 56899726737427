import { CloudArrows, External, Gear, Policy, Puzzle } from "components/icons/generated";

import { ModuleCreationWizardStep } from "../types";

export const config = [
  {
    title: "Share module",
    description: "Share the module between Spacelift accounts",
    action: {
      title: "Share module",
      step: ModuleCreationWizardStep.Share,
    },
    icon: External,
  },
  {
    title: "Define module behavior",
    description: "Define additional module settings",
    action: {
      title: "Define behavior",
      step: ModuleCreationWizardStep.Behavior,
    },
    icon: Gear,
  },

  {
    title: "Attach cloud",
    description: "Attach cloud integration to your module",
    action: {
      title: "Attach cloud",
      step: ModuleCreationWizardStep.Cloud,
    },
    icon: CloudArrows,
  },
  {
    title: "Attach policy",
    description: "Attach set of rules to your module",
    action: {
      title: "Attach policy",
      step: ModuleCreationWizardStep.Policies,
    },
    icon: Policy,
  },
  {
    title: "Attach context",
    description: "Attach bundle of configuration elements to your module",
    action: {
      title: "Attach context",
      step: ModuleCreationWizardStep.Contexts,
    },
    icon: Puzzle,
  },
];
