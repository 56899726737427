import DocumentationButton from "components/DocumentationButton";
import EmptyState from "ds/components/EmptyState";
import { EmptystateVcsColored } from "components/icons/generated";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import { SpacesContext } from "views/Account/SpacesProvider";

import VCSIntegrationsSetupDropdown from "./SetupDropdown";
import { VCS_INTEGRATIONS_DOCUMENTATION_LINK } from "./constants";

const VCSIntegrationsEmpty = () => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  return (
    <EmptyState title="Integrate with your source code provider" icon={EmptystateVcsColored}>
      <Box gap="large" justify="center" align="center">
        <DocumentationButton
          to={VCS_INTEGRATIONS_DOCUMENTATION_LINK}
          label="Documentation"
          analyticsPage={AnalyticsPageVCS.VCSIntegrations}
          analyticsProps={{ location: "empty-state" }}
        />

        {hasEntityCreateAccess && (
          <VCSIntegrationsSetupDropdown analyticsLocation="on-page-empty-state" />
        )}
      </Box>
    </EmptyState>
  );
};

export default VCSIntegrationsEmpty;
