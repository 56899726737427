import WarningContext from "components/WarningContext";
import { ArrowRight } from "components/icons/generated";
import Button, { ButtonProps } from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { showNewContextFooterContinueConfirmation } from "./ContinueConfirmation";

type NewContextFooterContinueButtonProps = {
  onClickHandler: () => void;
  analyticsTitle: ButtonProps["analyticsTitle"];
  analyticsPage: ButtonProps["analyticsPage"];
  analyticsProps: ButtonProps["analyticsProps"];
  variant: ButtonProps["variant"];
  disabled: ButtonProps["disabled"];
};

const NewContextFooterContinueButton = ({
  onClickHandler,
  ...buttonProps
}: NewContextFooterContinueButtonProps) => {
  const { warning, clearWarning } = useTypedContext(WarningContext);
  const onContinue = () => {
    onClickHandler();

    if (warning) {
      clearWarning();
    }
  };

  const onModalShow = () => {
    showNewContextFooterContinueConfirmation({
      onConfirm: onContinue,
      warning,
      confirmationButtonLabel: "Continue",
    });
  };

  return (
    <Button
      {...buttonProps}
      className={styles.buttonWithIcon}
      onClick={warning ? onModalShow : onContinue}
      endIcon={ArrowRight}
    >
      Continue
    </Button>
  );
};

export default NewContextFooterContinueButton;
