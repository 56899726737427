import { projectRootTooltipContent } from "components/TooltipSnippets";

import StackListItemValue from "./Value";
import { CellComponentProps } from "./types";

type StackListItemProjectRootCellProps = CellComponentProps;

const StackListItemProjectRootCell = ({ stack }: StackListItemProjectRootCellProps) => {
  if (!stack.projectRoot) {
    return null;
  }

  return (
    <StackListItemValue tooltip={projectRootTooltipContent}>{stack.projectRoot}</StackListItemValue>
  );
};

export default StackListItemProjectRootCell;
