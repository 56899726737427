import removeMd from "remove-markdown";

import Callout from "ds/components/Callout";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Box from "ds/components/Box";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import { ChevronRight } from "components/icons/generated";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";

import { VersionContext } from "../../Context";
import styles from "./styles.module.css";

type ModuleVersionMarkedAsBadCalloutProps = {
  onShowMore: () => void;
};

const ModuleVersionMarkedAsBadCallout = ({ onShowMore }: ModuleVersionMarkedAsBadCalloutProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { version } = useTypedContext(VersionContext);

  const { yankedBy, yankNote } = version;

  const hasNote = !!yankNote;
  const yankedByViewer = yankedBy === viewer.id;

  return (
    <Callout variant="danger" title="Version marked as bad">
      This version cannot be used because it has been marked bad by{" "}
      {yankedByViewer ? "yourself" : yankedBy}
      {hasNote && ` with a note`}.
      {hasNote && (
        <Box
          surfaceColor="primary"
          className={styles.note}
          padding="medium"
          margin="medium 0 0"
          gap="small"
          align="center"
        >
          <TextEllipsis tooltip={yankNote} tooltipWidthMode="maxWidthXl">
            {(props, isActive) => (
              <>
                <Typography {...props} tag="div" variant="p-body3">
                  {removeMd(yankNote)}
                </Typography>

                {isActive && (
                  <ButtonIconNew variant="ghost" icon={ChevronRight} onPress={onShowMore}>
                    Show full note
                  </ButtonIconNew>
                )}
              </>
            )}
          </TextEllipsis>
        </Box>
      )}
    </Callout>
  );
};

export default ModuleVersionMarkedAsBadCallout;
