import Typography from "ds/components/Typography";
import { getDocsUrl } from "utils/getDocsUrl";

import { StackCreationCloud } from "../types";

type NewStackCloudDocumentationProps = {
  cloud?: StackCreationCloud;
  children: (props: { body: JSX.Element; link?: string }) => JSX.Element;
};

// TODO: [Stack creation redesign] update azure docs and links
const NewStackCloudDocumentation = ({ cloud, children }: NewStackCloudDocumentationProps) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (cloud) {
    case StackCreationCloud.AWS: {
      return children({
        link: getDocsUrl("/integrations/cloud-providers/aws"),
        body: (
          <>
            <Typography tag="p" variant="p-body2">
              Select the AWS option, choose your integration, and select whether it should be used
              for read, write or both read and write phases:
            </Typography>
            <Typography tag="p" variant="p-t6" margin="large 0 small 0">
              Read vs Write
            </Typography>
            <Typography tag="p" variant="p-body2">
              You can attach an AWS integration as read, write or read-write, and you can attach at
              most two integrations to any single stack. Read indicates that this integration will
              be used during read phases of runs (for example, plans), and Write indicates that this
              integration will be used during write phases of runs (for example, applies).
            </Typography>
            <Typography tag="p" variant="p-t6" margin="large 0 small 0">
              Role Assumption Verification
            </Typography>
            <Typography tag="p" variant="p-body2">
              If the Cloud Integration has the "Assume Role on Worker" setting disabled, Spacelift
              will verify the role assumption as soon as you click the attach button. If role
              assumption succeeds, it will try to assume the role without the unique external ID,
              and this time it expects to fail. If Spacelift fails the latter check, we consider the
              integration is safely configured.
            </Typography>
          </>
        ),
      });
    }
    case StackCreationCloud.Azure: {
      return children({
        link: getDocsUrl(/* @ignore-checking-sh-docs */ "/integrations/cloud-providers/azure"),
        body: (
          <>
            <Typography tag="p" variant="p-body2">
              Spacelift supports dynamic credentials with Azure. When an Azure integration is
              created, an associated Azure AD Application is created within Azure. A client secret
              is automatically created and it rotates roughly once every 24 hours. The secret is
              stored securely, encrypted using AWS Key Management Service. You have the ability to
              use the integrations for read, write or read and write operations.
            </Typography>
          </>
        ),
      });
    }
    default: {
      return children({
        link: getDocsUrl("/integrations/cloud-providers"),
        body: (
          <>
            <Typography tag="p" variant="p-body2">
              Cloud integrations allow Spacelift to manage your resources without the need for
              long-lived static credentials. When using infrastructure-as-code automation tools such
              as Terraform, AWS CloudFormation, or Pulumi, these tools typically require credentials
              to execute. Usually, these are very powerful credentials, administrative credentials,
              sometimes. And these can do a lot of damage. Typically, you'd provide those
              credentials statically - think AWS credentials, GCP service keys, etc. This is
              dangerous, and against security best practices.
              <br />
              <br />
              That's why Spacelift integrates with identity management systems from major cloud
              providers to dynamically generate short-lived access tokens that can be used to
              configure their corresponding Terraform providers. <br />
              Currently, AWS, Azure and GCP are natively supported. A generic OpenID Connect
              integration is also available to work with any compatible service provider.
            </Typography>
          </>
        ),
      });
    }
  }
};

export default NewStackCloudDocumentation;
