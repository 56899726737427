import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { DriftDetectionIntegrationInput } from "types/generated";

import { CREATE_STACK_DRIFT_DETECTION_SCHEDULING } from "./gql";

type UseCreateDriftDetectionProps = {
  stackId: string;
};

const useCreateDriftDetection = ({ stackId }: UseCreateDriftDetectionProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackIntegrationDriftDetectionCreate, { loading }] = useMutation(
    CREATE_STACK_DRIFT_DETECTION_SCHEDULING,
    {
      refetchQueries: ["GetStackScheduling"],
      awaitRefetchQueries: true,
      onError,
    }
  );

  const createDriftDetection = useCallback(
    (input: DriftDetectionIntegrationInput, successCallback?: () => void) => {
      stackIntegrationDriftDetectionCreate({
        variables: {
          stack: stackId,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackIntegrationDriftDetectionCreate) {
            reportSuccess({
              message: `Drift Detection schedule was set up successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackId, stackIntegrationDriftDetectionCreate]
  );

  return {
    createDriftDetection,
    loading,
  };
};

export default useCreateDriftDetection;
