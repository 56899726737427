import { gql } from "@apollo/client";

export const GET_STACK_SCHEDULING = gql`
  query GetStackScheduling($stack: ID!) {
    stack(id: $stack) {
      state
      blocked
      integrations {
        driftDetection {
          ignoreState
          reconcile
          timezone
          schedule
          nextSchedule
          deleted
        }
      }
      scheduledDeletes {
        id
        stack
        shouldDeleteResources
        timestampSchedule
        nextSchedule
      }
      scheduledTasks {
        id
        stack
        command
        timezone
        cronSchedule
        timestampSchedule
        nextSchedule
      }
      scheduledRuns {
        id
        name
        cronSchedule
        nextSchedule
        timestampSchedule
        timezone
        customRuntimeConfig {
          yaml
        }
      }
      workerPool {
        id
      }
    }
  }
`;
