import { CloudArrows, User, Stack, GitBranch } from "components/icons/generated";

import { LaunchPadFeatureSaaS } from "./types";
import { LaunchPadFeatureMetadata } from "../types";

export const GET_STARTED_FEATURES: LaunchPadFeatureMetadata<LaunchPadFeatureSaaS>[] = [
  {
    feature: LaunchPadFeatureSaaS.IntegrateVCS,
    title: "Integrate Source code",
    icon: GitBranch,
    url: "/vcs/integrations",
    analyticsTitle: "Integrate VCS Click",
  },
  {
    feature: LaunchPadFeatureSaaS.IntegrateCloud,
    title: "Integrate your cloud account",
    icon: CloudArrows,
    url: "/cloud-integrations/aws",
    analyticsTitle: "Integrate Cloud Click",
  },
  {
    feature: LaunchPadFeatureSaaS.CreateStack,
    title: "Create first stack",
    icon: Stack,
    url: "/new/stack",
    analyticsTitle: "Create Stack Click",
  },
  {
    feature: LaunchPadFeatureSaaS.InviteTeammates,
    title: "Invite teammates",
    icon: User,
    url: "/settings/users",
    analyticsTitle: "Invite User Click",
  },
];
