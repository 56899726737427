import { useQuery } from "@apollo/client";
import { ReactNode, useMemo } from "react";

import { isSaasDistribution } from "utils/distribution";

import { SelfHostedLicenseContext, SelfHostedLicenseContextData } from "./Context";
import { GET_DEBUG_INFO } from "./gql";
import { getLicenseExpirationStatus, getLicenseRemainingDaysFromDebugInfo } from "./helpers";

const isSaas = isSaasDistribution();

type SelfHostedLicenseProviderProps = {
  children: ReactNode;
};

const SelfHostedLicenseProvider = ({ children }: SelfHostedLicenseProviderProps) => {
  const { loading, error, data, refetch } = useQuery(GET_DEBUG_INFO, {
    skip: isSaas,
    fetchPolicy: "network-only",
  });

  const contextValue: SelfHostedLicenseContextData = useMemo(() => {
    if (isSaas) {
      return {};
    }
    const remainingDays = getLicenseRemainingDaysFromDebugInfo(data?.debugInfo);

    return {
      debugInfo: data?.debugInfo,
      licenseExpirationStatus: getLicenseExpirationStatus(remainingDays),
      remainingDays,
      loading,
      error: error,
      refetch,
    };
  }, [loading, error, data, refetch]);

  return (
    <SelfHostedLicenseContext.Provider value={contextValue}>
      {children}
    </SelfHostedLicenseContext.Provider>
  );
};

export default SelfHostedLicenseProvider;
