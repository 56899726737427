import ReactMarkdown, { Components } from "react-markdown";
import gfm from "remark-gfm";
import { Suspense, lazy } from "react";
import cx from "classnames";

import styles from "./styles.module.css";

const SyntaxHighlighter = lazy(() => import("./SyntaxHighlighter"));

const renderers: Components = {
  code({ children, className }) {
    const match = /language-(\w+)/.exec(className || "");
    const code = (
      <code className={className} role="code">
        {children}
      </code>
    );

    if (!match) {
      return code;
    }

    return (
      <Suspense fallback={code}>
        <SyntaxHighlighter value={String(children).trim()} language={match ? match[1] : "plain"} />
      </Suspense>
    );
  },
};

const plugins = [gfm];

type MarkdownGithubProps = {
  markup: string;
};

const MarkdownGithub = (props: MarkdownGithubProps) => (
  <section className={cx(styles.markdownBody, styles.markdownBodyHh)}>
    <ReactMarkdown remarkPlugins={plugins} components={renderers} skipHtml>
      {props.markup}
    </ReactMarkdown>
  </section>
);

export default MarkdownGithub;
