import { LinePath } from "@visx/shape";

import styles from "../styles.module.css";

type TreeChartGroupNodeSummarySquareBorderProps = {
  startX: number;
  startY: number;
  height: number;
  width: number;
};

const TreeChartGroupNodeSummarySquareBorder = ({
  startX,
  startY,
  height,
  width,
}: TreeChartGroupNodeSummarySquareBorderProps) => (
  <LinePath
    className={styles.summaryBorder}
    d={`M ${startX} ${startY} L ${startX + width} ${startY} L ${startX + width} ${startY + height} L ${startX} ${startY + height}`}
  />
);

export default TreeChartGroupNodeSummarySquareBorder;
