import { AriaOnlyText } from "components/AriaOnlyText";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import TooltipInfoFloating from "ds/components/TooltipInfo";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { SelfHostedLicenseContext } from "views/SelfHosted/LicenseProvider/Context";
import { LicenseExpirationStatus } from "views/SelfHosted/LicenseProvider/types";

type AccountDetailsDrawerSelfHostedLicenseExpiryDateTextProps = {
  endDate: string;
};

const getLicenseExpirationStatusProps = (
  licenseExpirationStatus?: LicenseExpirationStatus
): { color: "danger" | "warning" | "primary"; tooltip: string | null } => {
  if (licenseExpirationStatus === LicenseExpirationStatus.EXPIRED) {
    return {
      color: "danger",
      tooltip: "The license expired",
    };
  }
  if (licenseExpirationStatus === LicenseExpirationStatus.IN_2_WEEKS) {
    return {
      color: "warning",
      tooltip: "The license will expire soon",
    };
  }
  if (licenseExpirationStatus === LicenseExpirationStatus.IN_2_MONTHS) {
    return {
      color: "warning",
      tooltip: "The license will expire soon",
    };
  }

  return {
    color: "primary",
    tooltip: null,
  };
};

const AccountDetailsDrawerSelfHostedLicenseExpiryDateText = ({
  endDate,
}: AccountDetailsDrawerSelfHostedLicenseExpiryDateTextProps) => {
  const { licenseExpirationStatus } = useTypedContext(SelfHostedLicenseContext);

  const properties = getLicenseExpirationStatusProps(licenseExpirationStatus);
  return (
    <FormField label="License expiry date">
      <Box gap="small">
        {properties.tooltip && (
          <TooltipInfoFloating standardIconColor={properties.color} widthMode="maxWidthSm">
            {properties.tooltip}
          </TooltipInfoFloating>
        )}
        <Typography variant="p-body2" tag="span" color={properties.color}>
          {endDate}
          {properties.tooltip && <AriaOnlyText>{`. Warning: ${properties.tooltip}`}</AriaOnlyText>}
        </Typography>
      </Box>
    </FormField>
  );
};

export default AccountDetailsDrawerSelfHostedLicenseExpiryDateText;
