import { useCallback, useState } from "react";
import { flushSync } from "react-dom";
import { Controller, useForm } from "react-hook-form";

import ButtonIconNew from "ds/components/ButtonIcon/New";
import { Trash, Edit } from "components/icons/generated";
import Box from "ds/components/Box";
import DraggableItemHandle from "ds/components/DraggableItem/Handle";
import Input from "ds/components/Input";
import { HookType } from "utils/hooks";
import { stringIsRequired } from "utils/formValidators";

import styles from "./styles.module.css";

type BeforeAfterCommandsListItemProps = {
  text: string;
  readOnly?: boolean;
  onRemoveCommand: (type: HookType, id: string) => void;
  type: HookType;
  id: string;
  onEditCommand: (type: HookType, id: string, text: string) => void;
};

type BeforeAfterCommandsListItemFormData = {
  text: string;
};

const BeforeAfterCommandsListItem = ({
  text,
  readOnly,
  onRemoveCommand,
  type,
  id,
  onEditCommand,
}: BeforeAfterCommandsListItemProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm<BeforeAfterCommandsListItemFormData>({
    defaultValues: {
      text,
    },
  });

  const { setFocus, handleSubmit, control } = form;

  const handleOpenEdit = useCallback(() => {
    flushSync(() => {
      setIsEditing(true);
    });

    setFocus("text");
  }, [setFocus]);

  const handleEdit = handleSubmit((formData: BeforeAfterCommandsListItemFormData) => {
    onEditCommand(type, id, formData.text);
    setIsEditing(false);
  });

  if (isEditing && !readOnly) {
    return (
      <form onSubmit={handleEdit} className={styles.form}>
        <Controller
          name="text"
          control={control}
          rules={{
            onBlur: handleEdit,
            validate: stringIsRequired(),
          }}
          render={({ field, fieldState }) => (
            <Input {...field} placeholder="Enter a command" error={!!fieldState.error} />
          )}
        />
      </form>
    );
  }

  return (
    <Box justify="between" fullWidth>
      <DraggableItemHandle>{text}</DraggableItemHandle>
      {!readOnly && (
        <Box gap="medium">
          <ButtonIconNew icon={Edit} onPress={handleOpenEdit} variant="ghost">
            Edit
          </ButtonIconNew>
          <ButtonIconNew
            icon={Trash}
            onPress={() => onRemoveCommand(type, id)}
            variant="ghostDanger"
          >
            Delete
          </ButtonIconNew>
        </Box>
      )}
    </Box>
  );
};

export default BeforeAfterCommandsListItem;
