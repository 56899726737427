import cx from "classnames";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import { withTestId } from "utils/withTestId";
import { iconByType } from "views/shared/run/helpers";
import Icon from "ds/components/Icon";
import { ArrowRight } from "components/icons/generated";
import { EntityChangeType } from "types/generated";
import ChangeTypeIcon from "views/shared/run/ChangeTypeIcon";

import styles from "./styles.module.css";
import { ReplanRequestChangeType } from "./types";
import { REPLAN_REQUEST_CHANGE_ADDRESS_TEST_ID } from "./constants";

type ReplanRequestChangeProps = {
  change: ReplanRequestChangeType;
};

function ReplanRequestChange({ change }: ReplanRequestChangeProps) {
  const { type, address, moved, previousAddress } = change;

  const changeType = moved ? EntityChangeType.Move : type;
  const changeTypeIcon = iconByType(changeType);
  const hasSupportedChangeTypeIcon = !!changeType && !!changeTypeIcon;

  const gridTemplate = cx(
    hasSupportedChangeTypeIcon && "min-content",
    moved && "minmax(0, max-content) min-content",
    "minmax(0, max-content)"
  );

  return (
    <Box
      className={styles.item}
      gap="medium"
      fullWidth
      align="center"
      grid
      gridTemplate={gridTemplate}
    >
      {changeType && <ChangeTypeIcon type={changeType} moved={moved} />}

      {moved && previousAddress && (
        <>
          <TextEllipsis tooltip={previousAddress} tooltipWidthMode="maxWidthXl">
            {(props) => (
              <Typography {...props} tag="span" variant="p-body3">
                {previousAddress}
              </Typography>
            )}
          </TextEllipsis>
          <Icon src={ArrowRight} color="secondary" noShrink />
        </>
      )}
      <TextEllipsis tooltip={address} tooltipWidthMode="maxWidthXl">
        {(props) => (
          <Typography
            {...props}
            tag="span"
            variant="p-body3"
            {...withTestId(REPLAN_REQUEST_CHANGE_ADDRESS_TEST_ID)}
          >
            {address}
          </Typography>
        )}
      </TextEllipsis>
    </Box>
  );
}

export default ReplanRequestChange;
