import { Copy } from "components/icons/generated";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import ButtonIconNew, { ButtonIconNewProps } from "ds/components/ButtonIcon/New";

type CopyFieldIconProps = {
  title: string;
  value: string;
  callback?: () => void;
} & Pick<ButtonIconNewProps, "disabled" | "tooltipMode" | "className">;

const CopyFieldIcon = ({ title, value, callback, ...restProps }: CopyFieldIconProps) => {
  const handleCopy = useCopyToClipboard(value, callback);

  return (
    <ButtonIconNew {...restProps} onPress={handleCopy} icon={Copy} variant="ghost">
      {title}
    </ButtonIconNew>
  );
};

export default CopyFieldIcon;
