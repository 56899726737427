import React, { MouseEvent, useState } from "react";
import cx from "classnames";
import { Handle, NodeProps, Position } from "reactflow";

import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import PopoverActionBar from "ds/components/PopoverActionBar";
import SpaceAccessBadge from "components/SpaceAccessBadge";
import { withTestId } from "utils/withTestId";
import BaseAction from "ds/components/BaseAction";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import CopyFieldIcon from "components/CopyField/Icon";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import { CodeBrowser, Lock, Plus, Stack, Trash, Webhook } from "components/icons/generated";
import ButtonIconNew from "ds/components/ButtonIcon/New";

import styles from "./styles.module.css";
import { SpacesViewContext } from "../Context";
import { SpaceItemType } from "../Diagram/types";
import { SPACE_NODE_TEST_ID } from "../Diagram/constants";
import { SpacesViewActionsContext } from "../ActionsContext";

const SpaceNode = ({ data }: NodeProps<SpaceItemType>) => {
  const { currentSpace, isRootAdmin } = useTypedContext(SpacesViewContext);
  const [isControlVisible, setIsControlVisible] = useState(false);

  const {
    onSelect: onSpaceSelect,
    onCreate: onSpaceCreate,
    onFilter,
    onDelete,
  } = useTypedContext(SpacesViewActionsContext);

  const isPredefinedSpace = data.item.id === "root" || data.item.id === "legacy";
  const isActive = data.item.id === currentSpace?.id;

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onSpaceSelect(data.item.id);
  };

  const handleCreateSpace = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    onSpaceCreate(data.item.id);
  };

  const handleFilter = (id: string, path: string) => (e?: MouseEvent) => {
    e?.stopPropagation();
    return onFilter(id, path);
  };

  return (
    <>
      <Box
        direction="row"
        align="center"
        justify="start"
        gap="medium"
        className={cx(styles.node, { [styles.active]: isActive })}
        onClick={handleOnClick}
        {...withTestId(SPACE_NODE_TEST_ID)}
      >
        {data.item.parentSpace && (
          <Handle
            id={data.item.id}
            type="target"
            position={Position.Top}
            className={styles.topPoint}
          />
        )}

        <TextEllipsis tooltip={data.item.name} tooltipWidthMode="maxWidthXl" tooltipMode="parent">
          {(props) => (
            <Typography {...props} tag="span" variant="p-t6">
              {data.item.name}
            </Typography>
          )}
        </TextEllipsis>

        {isPredefinedSpace && isRootAdmin && (
          <Tooltip mode="parent" on={(tooltipProps) => <Icon {...tooltipProps} src={Lock} />}>
            This space is not editable.
          </Tooltip>
        )}

        {!isRootAdmin && <SpaceAccessBadge accessLevel={data.item.accessLevel} />}

        {isRootAdmin && (
          <Tooltip
            mode="parent"
            on={(tooltipProps) => (
              <BaseAction
                {...tooltipProps}
                className={styles.createIcon}
                onClick={handleCreateSpace}
              >
                <Icon src={Plus} />
              </BaseAction>
            )}
            placement="bottom"
          >
            Create space
          </Tooltip>
        )}

        <PopoverActionBar className={cx(styles.controls, { [styles.visible]: isControlVisible })}>
          <DropdownMenuEllipsis
            onOpenChange={setIsControlVisible}
            tooltip="Show more actions"
            buttonVariant="ghost"
          >
            <DropdownMenuItem onAction={handleFilter(data.item.id, "/stacks")}>
              <Stack className={styles.filterIcon} />
              View Stacks
            </DropdownMenuItem>
            <DropdownMenuItem onAction={handleFilter(data.item.id, "/blueprints")}>
              <CodeBrowser className={styles.filterIcon} />
              View Blueprints
            </DropdownMenuItem>
            <DropdownMenuItem onAction={handleFilter(data.item.id, "/webhooks")}>
              <Webhook className={styles.filterIcon} />
              View Webhooks
            </DropdownMenuItem>
          </DropdownMenuEllipsis>

          <CopyFieldIcon tooltipMode="parent" title="Copy Space ID" value={data.item.id} />

          {isRootAdmin && !isPredefinedSpace && (
            <ButtonIconNew
              icon={Trash}
              variant="ghostDanger"
              onPress={() => onDelete(data.item.id)}
              tooltipMode="parent"
            >
              Delete space
            </ButtonIconNew>
          )}
        </PopoverActionBar>
      </Box>

      <Handle
        id={data.item.id}
        className={cx(styles.sourceHandle, { [styles.visible]: data.children.length > 0 })}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </>
  );
};

export default SpaceNode;
