import { ChevronDown } from "components/icons/generated";
import BaseAction from "ds/components/BaseAction";
import Icon from "ds/components/Icon";

import styles from "./styles.module.css";

type TagsListExpanderProps = {
  isExpanded?: boolean;
  alwaysExpanded: boolean;
  toggleLabelsVisibility: () => void;
};

const TagsListExpander = ({
  toggleLabelsVisibility,
  isExpanded,
  alwaysExpanded,
}: TagsListExpanderProps) =>
  !alwaysExpanded ? (
    <BaseAction onClick={toggleLabelsVisibility} className={styles.toggle} data-tags="toggle">
      <Icon src={ChevronDown} className={styles.icon} />
      {isExpanded ? "show less" : "show"}
    </BaseAction>
  ) : null;

export default TagsListExpander;
