import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { Point } from "d3-dag/dist/dag";
import { useResizeObserver } from "@react-aria/utils";
import { debounce } from "lodash-es";
import InfiniteLoader from "react-window-infinite-loader";
import { ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { convertToTreeStructure, isNodeVisible, updateTreeRendering } from "utils/tree";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import { ConfigNode, ConfigTypeComponents, Connection, Position, PositionedNode } from "./types";
import styles from "./styles.module.css";
import {
  COLUMN_WIDTH,
  CONNECTION_BORDER_RADIUS,
  CONNECTION_SPACE,
  LOADER_ROW_ID,
  TOOLTIP_TOP_OFFSET,
} from "./constants";
import { createEndId, createStartId } from "./utils";
import TreeChartEntity, { ListEntitiesBaseItemProps } from "./Entity";
import TreeChartVirtualizedItem, { TreeChartVirtualizedItemProps } from "./Virtualized";

const COLUMN_SPACE = COLUMN_WIDTH + CONNECTION_BORDER_RADIUS * 4 + CONNECTION_SPACE * 2;

type TreeChartProps<T extends string> = {
  nodes: ConfigNode<T>[];
  nodeTypes: ConfigTypeComponents<T>;
  margin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  loadingTreshold?: number;
  activeId?: string;
  analyticsPage?: AnalyticsPage;
  hasNextPageToLoad?: boolean;
  loadMoreItems: () => Promise<void>;
};

const TreeChart = <T extends string>({
  nodes,
  nodeTypes,
  margin,
  activeId,
  analyticsPage,
  loadingTreshold,
  hasNextPageToLoad,
  loadMoreItems,
}: TreeChartProps<T>) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip<{ text: ReactNode }>();
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { containerRef, TooltipInPortal, forceRefreshBounds } = useTooltipInPortal({
    detectBounds: false,
    scroll: true,
  });

  const { flatList, tree } = useMemo(
    () => convertToTreeStructure<ConfigNode<T>>(nodes, "id", "parent", "name"),
    [nodes]
  );

  const debouncedForceRefreshBounds = debounce(() => {
    forceRefreshBounds();
  }, 200);
  // Refresh tooltip position when container position changes, it solves collapse filters issue when content is moved to the left side but tooltip doesn't know about it
  useResizeObserver({ ref: wrapperRef, onResize: debouncedForceRefreshBounds });

  const allKeysSet = useMemo(() => {
    const allKeys = flatList.map((item) => item.id);
    return new Set(allKeys);
  }, [flatList]);

  const [connectionPoints, setConnectionPoints] = useState<Record<string, Position>>({});
  const [expandedKeys, setExpandedKeys] = useState(new Set<string>());
  // const [, setLoadingKeys] = useState(new Set<string>());

  const visibleNodes = useMemo(
    () => flatList.filter((node) => isNodeVisible(node, allKeysSet, expandedKeys)),
    [expandedKeys, allKeysSet, flatList]
  );

  useEffect(() => {
    if (activeId && !expandedKeys.has(activeId)) {
      const item = flatList.find(
        ({ id, item }) => id === activeId || !!item.group?.find(({ id }) => id === activeId)
      );

      if (item?.path && item.parentId && !expandedKeys.has(item.parentId)) {
        const { expandedNodes } = updateTreeRendering(flatList, [item?.path]);
        setExpandedKeys(expandedNodes);
      }
    }
  }, [activeId, expandedKeys, flatList]);

  // Expand node when only one is available
  useEffect(() => {
    if (!activeId && expandedKeys.size === 0 && tree.length === 1) {
      setExpandedKeys(allKeysSet);
    }
  }, [tree, allKeysSet, expandedKeys, activeId]);

  const [positionedNodes, containerWidth] = useMemo(() => {
    const positionedNodes: PositionedNode<T>[] = [];

    let containerWidth = 0;

    let y = margin?.top || 0;

    for (let i = 0; i < visibleNodes.length; i++) {
      const node = visibleNodes[i];
      const prevNode = visibleNodes[i - 1];

      // Change y position for first child
      if (prevNode && prevNode.path.length !== node.path.length && prevNode.id === node.parentId) {
        const prevNodeHeight = nodeTypes[prevNode.item.type].height(prevNode.item);
        y = y - prevNodeHeight;
      }

      let connection: Connection | undefined;

      // Create connection
      if (node.parentId) {
        const startPointId = createEndId(node.parentId);
        const startPoint = connectionPoints[startPointId];
        const endPointId = createStartId(node.id);
        const endPoint = connectionPoints[endPointId];

        if (startPoint && endPoint) {
          connection = {
            start: startPoint,
            end: endPoint,
            id: `${endPointId}-${startPointId}`,
          };
        }
      }

      positionedNodes.push({
        ...node,
        item: {
          ...node.item,
          position: {
            x: (margin?.left || 0) + (node.path.length - 1) * COLUMN_SPACE,
            y,
          },
          connection,
        },
      } as PositionedNode<T>);

      // Container size and next iteration y position
      const nodeHeight = nodeTypes[node.item.type].height(node.item);
      const nodeEndY = y + nodeHeight;
      const nodeEndX = node.path.length * COLUMN_SPACE;
      if (nodeEndY > y) {
        y = nodeEndY;
      }

      if (nodeEndX > containerWidth) {
        containerWidth = nodeEndX;
      }
    }

    return [positionedNodes, containerWidth + (margin?.left || 0) + (margin?.right || 0)];
  }, [visibleNodes, connectionPoints, nodeTypes, margin]);
  const width = Math.max(wrapperRef?.current?.offsetWidth || 0, containerWidth);

  const toggleKey = useCallback(
    (key: string, hasChildrenToLoad?: boolean, position?: Position) => {
      if (!hasChildrenToLoad) {
        setExpandedKeys((prev) => {
          const newKeys = new Set(prev);
          if (newKeys.has(key)) {
            newKeys.delete(key);
            trackSegmentAnalyticsEvent?.("Diagram node collapsed");
          } else {
            trackSegmentAnalyticsEvent?.("Diagram node expanded");
            newKeys.add(key);
            if (position) {
              // We have to wait unitl element will be rendered on the screen and then we call scroll into
              requestAnimationFrame(() => {
                wrapperRef.current?.scrollTo({
                  behavior: "smooth",
                  left: position.x + COLUMN_SPACE * 2,
                });
              });
            }
          }
          return newKeys;
        });
      }
      // TODO: add query to load children and the switch keys
      // } else {
      // setLoadingKeys((prev) => {
      //   const newKeys = new Set(prev);
      //   if (newKeys.has(key)) {
      //     newKeys.delete(key);
      //   } else {
      //     newKeys.add(key);
      //   }
      //   return newKeys;
      // });
      // TODO: then unset when loaded or error
      // }
    },
    [setExpandedKeys, trackSegmentAnalyticsEvent]
  );

  const handleMouseEnterForTooltip = useCallback(
    (text: ReactNode, coordinates: Point | null) => {
      showTooltip({
        tooltipData: { text },
        tooltipTop: coordinates?.y,
        tooltipLeft: coordinates?.x,
      });
    },
    [showTooltip]
  );

  const isItemLoaded = useCallback(
    (value: number) => value < positionedNodes.length,
    [positionedNodes]
  );

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      threshold={loadingTreshold}
      itemCount={positionedNodes.length + 50}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered }) => (
        <Box grow="1" className={styles.wrapper} ref={wrapperRef}>
          {tooltipOpen && tooltipData && (
            <TooltipInPortal
              top={tooltipTop}
              left={tooltipLeft}
              offsetTop={TOOLTIP_TOP_OFFSET - (scrollableContainerRef?.current?.scrollTop || 0)}
              offsetLeft={0}
              className={styles.tooltipContainer}
              unstyled
            >
              <Box className={styles.tooltip} direction="column" gap="medium">
                <Typography tag="span" variant="p-body3" color="on-inversed">
                  {tooltipData.text}
                </Typography>
              </Box>
            </TooltipInPortal>
          )}
          <AutoSizer disableWidth>
            {({ height }) => (
              <div ref={containerRef} className={styles.treeChart}>
                <TreeChartEntity<T>
                  height={height}
                  width={width}
                  itemCount={positionedNodes.length}
                  hasNextPageToLoad={hasNextPageToLoad}
                  itemProps={{
                    nodeTypes: nodeTypes as ConfigTypeComponents<T>,
                    items: positionedNodes,
                    onMouseEnter: handleMouseEnterForTooltip,
                    onMouseLeave: hideTooltip,
                    connectionPoints,
                    setConnectionPoints,
                    toggleKey,
                    expandedKeys,
                    columnWidth: COLUMN_WIDTH,
                    activeId: activeId,
                    analyticsPage: analyticsPage,
                  }}
                  virtualizedItem={
                    TreeChartVirtualizedItem as unknown as (
                      props: ListChildComponentProps<
                        TreeChartVirtualizedItemProps<T> & ListEntitiesBaseItemProps
                      >
                    ) => JSX.Element
                  }
                  itemKey={(index) => positionedNodes[index]?.id || `${LOADER_ROW_ID}-${index}`}
                  onItemsRendered={onItemsRendered}
                  outerContainerRef={scrollableContainerRef}
                />
              </div>
            )}
          </AutoSizer>
        </Box>
      )}
    </InfiniteLoader>
  );
};

export default TreeChart;
