import { NetworkStatus, useQuery } from "@apollo/client";
import { useCallback, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { VcsIntegration, VcsIntegrationUser } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getSortOptionFromURI } from "components/Filters/helpers";
import { uniqByKey } from "utils/uniq";

import { SEARCH_VCS_INTEGRATION_USED_BY } from "./gql";
import {
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
  ITEMS_LIMIT,
  POLL_INTERVAL,
} from "./constants";
import { getVcsProviderByUrlKey } from "../helpers";

export const useSearchVCSIntegrationUsedBy = () => {
  const { id, provider: rawProvider } = useParams<{ id: string; provider: string }>();
  const provider = getVcsProviderByUrlKey(rawProvider);

  const { onError } = useTypedContext(FlashContext);

  const cachedEdges = useRef<VcsIntegrationUser[]>([]);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const { data, loading, error, stopPolling, networkStatus, fetchMore, previousData } = useQuery<{
    vcsIntegration: VcsIntegration;
  }>(SEARCH_VCS_INTEGRATION_USED_BY, {
    variables: {
      id,
      provider,
      searchUsersInput: {
        predicates: null,
        fullTextSearch: searchInput,
        first: ITEMS_LIMIT,
        after: null,
        orderBy: sortOptionFields,
      },
      searchUsersSuggestionsInput: {
        fields: null,
        fullTextSearch: null,
        predicates: null,
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    skip: !provider || !id,
  });

  const [vcsIntegration, rawSearchUsers] = useMemo(() => {
    if (!data?.vcsIntegration && !previousData?.vcsIntegration) {
      return [null, null];
    }

    const { searchUsers, ...vcsIntegration } = {
      ...previousData?.vcsIntegration,
      ...data?.vcsIntegration,
    };

    return [vcsIntegration as VcsIntegration, searchUsers];
  }, [data?.vcsIntegration, previousData?.vcsIntegration]);

  const memoizedEntities = useMemo(() => {
    const sourceEdges = rawSearchUsers?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedEdges.current : sourceEdges;

    if (!loading) {
      cachedEdges.current = sourceEdges;
    }

    return edges;
  }, [loading, rawSearchUsers?.edges]);

  const totalEntitiesCount =
    data?.vcsIntegration?.searchUsersSuggestions?.filteredCount ||
    previousData?.vcsIntegration?.searchUsersSuggestions?.filteredCount ||
    0;

  const loadMoreItems = useCallback(async () => {
    try {
      if (
        data?.vcsIntegration?.searchUsers?.pageInfo?.endCursor &&
        data?.vcsIntegration?.searchUsers?.pageInfo?.hasNextPage
      ) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              fetchMoreResult?.vcsIntegration.searchUsers &&
              fetchMoreResult.vcsIntegration.searchUsers.edges.length > 0
            ) {
              return {
                vcsIntegration: {
                  ...prev.vcsIntegration,
                  ...fetchMoreResult.vcsIntegration,
                  searchUsers: {
                    ...fetchMoreResult.vcsIntegration.searchUsers,
                    edges: uniqByKey(
                      [
                        ...(prev.vcsIntegration.searchUsers?.edges || []),
                        ...fetchMoreResult.vcsIntegration.searchUsers.edges,
                      ],
                      "cursor"
                    ),
                  },
                },
              };
            }

            return prev;
          },
          variables: {
            searchUsersInput: {
              first: ITEMS_LIMIT,
              after: data.vcsIntegration.searchUsers.pageInfo.endCursor,
              fullTextSearch: searchInput,
              orderBy: sortOptionFields,
              predicates: null,
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  }, [
    data?.vcsIntegration?.searchUsers?.pageInfo?.endCursor,
    data?.vcsIntegration?.searchUsers?.pageInfo?.hasNextPage,
    fetchMore,
    onError,
    searchInput,
    sortOptionFields,
  ]);

  return {
    integration: vcsIntegration,
    usedByItems: memoizedEntities,
    totalEntitiesCount,
    error,
    stopPolling,
    isPageLoading: loading && !data?.vcsIntegration && networkStatus === NetworkStatus.loading,
    isPageRefetching: loading && networkStatus !== NetworkStatus.loading,
    isPageEmpty: !!data && !memoizedEntities.length && !searchInput,
    hasNextPage: !!data?.vcsIntegration?.searchUsers?.pageInfo?.hasNextPage,
    loadMoreItems,
    loading,
  };
};
