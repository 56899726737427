import { gql, TypedDocumentNode } from "@apollo/client";

import { Maybe, SlackAppConfig, SlackIntegration } from "types/generated";

export type SettingsSlackIntegration = Maybe<Pick<SlackIntegration, "teamName">>;
export type SettingsSlackAppConfig = Maybe<Pick<SlackAppConfig, "clientId" | "canUpdate">>;

type FetchGetAccountIntegrations = {
  slackIntegration: SettingsSlackIntegration;
  slackAppConfig: SettingsSlackAppConfig;
};

export const GET_ACCOUNT_INTEGRATIONS: TypedDocumentNode<FetchGetAccountIntegrations> = gql`
  query GetAccountIntegrations($isSelfHosted: Boolean!) {
    slackIntegration {
      teamName
    }
    slackAppConfig @include(if: $isSelfHosted) {
      clientId
      canUpdate
    }
  }
`;
