import { useState } from "react";

import Box from "ds/components/Box";
import { Policy } from "types/generated";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import { hasSpaceManageOrWriteAccess } from "utils/user";

import PolicySimulation from "../../PolicySimulation";
import PolicyDetailsBodyContent from "./Content";
import styles from "./styles.module.css";

type PolicyDetailsBodyProps = {
  policy: Policy;
  body: string;
  isBodyReadyToUpdate: boolean;
  setBody: (value: string) => void;
};

const PolicyDetailsBody = ({
  policy,
  body,
  isBodyReadyToUpdate,
  setBody,
}: PolicyDetailsBodyProps) => {
  const canManagePolicy = hasSpaceManageOrWriteAccess(policy.spaceDetails.accessLevel);

  const handleEditorChange = (value?: string) => {
    setBody(value || "");
  };

  const [isSimulationVisible, setIsSimulationVisible] = useState(false);

  return (
    <Box justify="stretch" grow="1">
      <PolicyDetailsBodyContent
        body={body}
        handleEditorChange={handleEditorChange}
        policy={policy}
        isBodyReadyToUpdate={isBodyReadyToUpdate}
        isSimulationVisible={isSimulationVisible}
        canManagePolicies={canManagePolicy}
      />

      {canManagePolicy && (
        <PolicySimulation
          className={styles.simulationWrapper}
          policyId={policy.id}
          body={body}
          type={policy.type}
          isSimulationVisible={isSimulationVisible}
          setIsSimulationVisible={setIsSimulationVisible}
          analyticsPage={AnalyticsPagePolicy.PolicyDetails}
        />
      )}
    </Box>
  );
};

export default PolicyDetailsBody;
