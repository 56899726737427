import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { ScheduledDeleteInput } from "types/generated";

import { UPDATE_STACK_SCHEDULING_SCHEDULED_DELETE } from "./gql";

type UseUpdateScheduledDelete = {
  stackId: string;
};

const useUpdateScheduledDelete = ({ stackId }: UseUpdateScheduledDelete) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [stackScheduledDeleteUpdate, { loading }] = useMutation(
    UPDATE_STACK_SCHEDULING_SCHEDULED_DELETE,
    {
      refetchQueries: ["GetStackScheduling"],
      awaitRefetchQueries: true,
      onError,
    }
  );

  const updateScheduledDelete = useCallback(
    (scheduleId: string, input: ScheduledDeleteInput, successCallback?: () => void) => {
      stackScheduledDeleteUpdate({
        variables: {
          scheduleId,
          stack: stackId,
          input,
        },
      })
        .then(({ data }) => {
          if (data?.stackScheduledDeleteUpdate) {
            reportSuccess({
              message: `Delete Stack schedule was updated successfully.`,
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [onError, reportSuccess, stackId, stackScheduledDeleteUpdate]
  );

  return {
    updateScheduledDelete,
    loading,
  };
};

export default useUpdateScheduledDelete;
