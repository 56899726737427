import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { AuditTrailEntry } from "types/generated";
import useApplyFilter from "components/Filters/useApplyFilter";

import AuditTrailListItem from ".";
import { showAuditTrailEntriesDetails } from "../Details";
import { AuditTrailEntrySuggestions, FILTER_ITEMS_DICTIONARY } from "../../constants";

type AuditTrailListItemVirtualizedProps = {
  items: AuditTrailEntry[];
};

const AuditTrailListItemVirtualized = (
  props: ListChildComponentProps<AuditTrailListItemVirtualizedProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);
  const onOpenDetails = () =>
    showAuditTrailEntriesDetails({
      item: data.items[index],
      applyFilter,
      id: data.items[index].id,
    });

  const { applyFilter } = useApplyFilter<AuditTrailEntrySuggestions>({
    searchSuggestionsDictionary: FILTER_ITEMS_DICTIONARY,
  });

  return (
    <div style={style}>
      <AuditTrailListItem
        key={data.items[index].id}
        item={data.items[index]}
        setRowHeight={handleSetRowHeight}
        onOpenDetails={onOpenDetails}
      />
    </div>
  );
};

export default memo(AuditTrailListItemVirtualized, areEqual);
