import { useNavigate } from "react-router-dom";

import DropdownMenuItem from "ds/components/DropdownMenu/Item";

const SettingsIntegrationsSlackManageAccessMenuItem = () => {
  const navigate = useNavigate();

  const handleManageAccess = () => {
    navigate("/settings/integrations/manage-slack-access");
  };

  return <DropdownMenuItem onAction={handleManageAccess}>Manage Access</DropdownMenuItem>;
};

export default SettingsIntegrationsSlackManageAccessMenuItem;
