import { LogoSlackColored } from "components/icons/generated";
import Tile from "ds/components/Tile";
import TileDescription from "ds/components/Tile/Description";
import { isSaasDistribution } from "utils/distribution";
import useSelfHostedFlags from "hooks/useSelfHostedFlags";
import Box from "ds/components/Box";
import TileTitle from "ds/components/Tile/Title";
import BadgeNextNew from "ds/components/BadgeNext/New";

import SettingsIntegrationsSlackTileIndicatorSaaS from "./TileIndicatorSaaS";
import SettingsIntegrationsSlackTileIndicatorSelfHosted from "./TileIndicatorSelfHosted";
import { SettingsSlackAppConfig, SettingsSlackIntegration } from "../gql";

const isSaas = isSaasDistribution();

type SettingsIntegrationsSlackProps = {
  integration?: SettingsSlackIntegration;
  appConfig?: SettingsSlackAppConfig;
};

const SettingsIntegrationsSlack = ({ integration, appConfig }: SettingsIntegrationsSlackProps) => {
  const { selfHostedV3InstallationFlow } = useSelfHostedFlags();

  return (
    <Tile
      title={
        <Box direction="row" gap="medium" align="center">
          <TileTitle>Slack</TileTitle>

          {integration && <BadgeNextNew variant="green" type="strong" text="Active" />}
        </Box>
      }
      icon={LogoSlackColored}
      indicator={
        isSaas || (!isSaas && !selfHostedV3InstallationFlow) ? (
          <SettingsIntegrationsSlackTileIndicatorSaaS hasIntegration={!!integration} />
        ) : (
          <SettingsIntegrationsSlackTileIndicatorSelfHosted
            hasIntegration={!!integration}
            appConfig={appConfig}
          />
        )
      }
      description={
        integration ? (
          <TileDescription>
            Spacelift is already integrated with {` `}
            <strong>{`${integration.teamName}`}</strong> workspace. You can disable the integration
            by removing the Spacelift app from your Slack workspace.
          </TileDescription>
        ) : (
          "Many tech companies use Slack for internal communication. As a Spacelift and Slack admin, you can link your Spacelift account to the Slack workspace"
        )
      }
    />
  );
};

export default SettingsIntegrationsSlack;
