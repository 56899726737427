import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import Typography from "ds/components/Typography";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { getDocsUrl } from "utils/getDocsUrl";
import DrawerFooter from "ds/components/Drawer/Footer";
import DocumentationButton from "components/DocumentationButton";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import styles from "./styles.module.css";

const IntegrationsInfoDrawer = createDrawer(() => {
  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Integrations" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Typography tag="h6" variant="p-t6">
          Integrations
        </Typography>
        <Typography tag="p" variant="p-body2" className={styles.description}>
          Send details about your runs and other activities to Slack or Microsoft Teams. View all
          metrics related to your Spacelift account in the tools you love such as Datadog, Grafana
          and others. You can integrate with any tool that supports webhooks by leveraging our
          Notification Policy, so there are no limits to what you can achieve.{" "}
          <ReadMoreDocsLink
            size="medium"
            docsUrl={getDocsUrl("/concepts/policy/notification-policy.html")}
          />
        </Typography>
        <DrawerFooter>
          {/* TODO: update link when documentation created */}
          <DocumentationButton
            to={getDocsUrl("/integrations/chatops/slack")}
            analyticsPage={AnalyticsPageOrganization.OrganizationIntegrations}
          />
        </DrawerFooter>
      </DrawerBody>
    </DrawerSimple>
  );
});

export const showIntegrationsInfoDrawer = createDrawerTrigger(IntegrationsInfoDrawer);
