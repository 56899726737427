import { memo, useCallback, useMemo } from "react";
import sortBy from "lodash-es/sortBy";

import CollapsibleList from "components/CollapsibleList";
import ContextAttachment from "components/ContextAttachment";

import { AttachedContexts } from "../types";

type NewModuleAttachedContextsProps = {
  title: string;
  contexts: AttachedContexts<boolean>;
  emptyPlaceholder?: React.ReactNode;
  moduleLabels: string[];
  onPriorityChange?: (id: string, value: number) => Promise<void>;
  onDetach?: (id: string) => Promise<void>;
  readonly?: boolean;
  action?: React.ReactNode;
};

const NewModuleAttachedContextsPanel = ({
  title,
  contexts,
  moduleLabels,
  onPriorityChange,
  onDetach,
  emptyPlaceholder,
  readonly,
  action,
}: NewModuleAttachedContextsProps) => {
  const manuallyAttachedContexts = useMemo(
    () =>
      sortBy(
        contexts.filter((context) => !context.isAutoattached),
        "priority"
      ) as AttachedContexts<false>,
    [contexts]
  );

  const autoAttachedContexts = useMemo(
    () => contexts.filter((context) => context.isAutoattached) as AttachedContexts<true>,
    [contexts]
  );

  const allContexts = useMemo(() => {
    return [...manuallyAttachedContexts, ...autoAttachedContexts];
  }, [autoAttachedContexts, manuallyAttachedContexts]);

  const handleDetach = useCallback(async (id: string) => onDetach?.(id), [onDetach]);
  const handlePriorityChange = useCallback(
    async (id: string, value: number) => onPriorityChange?.(id, value),
    [onPriorityChange]
  );

  return (
    <CollapsibleList
      ariaLevel={2}
      title={title}
      count={contexts.length}
      emptyPlaceholder={emptyPlaceholder}
      initialIsCollapsed={false}
      action={action}
    >
      {allContexts.map((context) =>
        context.isAutoattached ? (
          <ContextAttachment key={context.id} context={context} stackLabels={moduleLabels} />
        ) : (
          <ContextAttachment
            key={context.id}
            context={context}
            onPriorityChange={handlePriorityChange}
            onDetach={handleDetach}
            readonly={readonly}
          />
        )
      )}
    </CollapsibleList>
  );
};

export default memo(NewModuleAttachedContextsPanel);
