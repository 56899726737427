import { useCallback, useState } from "react";

import ButtonIcon from "ds/components/ButtonIcon";
import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { Trash, Edit } from "components/icons/generated";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";

import styles from "./styles.module.css";
import ContextMountedFilesEditForm from "../ContextMountedFiles/EditForm";
import { ContextAttachedMountedFile } from "../types";

type NewContextMountedFileProps = {
  item: ContextAttachedMountedFile;
  onSave?: (index: number, item: ContextAttachedMountedFile) => void;
  onRemove?: (index: number) => void;
  index: number;
  onShowFullDescription: (description: string) => void;
};

const NewContextMountedFile = ({
  item,
  onSave,
  onRemove,
  onShowFullDescription,
  index,
}: NewContextMountedFileProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const canEdit = !!onSave;

  const handleRemove = useCallback(() => onRemove?.(index), [index, onRemove]);

  const handleSave = useCallback(
    (item: ContextAttachedMountedFile) => {
      onSave?.(index, item);
      setIsEditing(false);
    },
    [index, onSave]
  );

  const handleEdit = useCallback(() => setIsEditing(true), []);

  if (isEditing) {
    return <ContextMountedFilesEditForm index={index} onSave={handleSave} />;
  }

  return (
    <Box
      align="center"
      gap="large"
      className={styles.item}
      padding="large"
      justify="between"
      fullWidth
    >
      <Box direction="column" zeroMinWidth>
        <TextEllipsis tooltip={item.path} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} variant="p-t6" tag="span">
              {item.path}
            </Typography>
          )}
        </TextEllipsis>
        <ListEntitiesItemDescription
          description={item.description}
          onShowFullDescription={onShowFullDescription}
        />
      </Box>
      {canEdit && (
        <Box shrink="0" gap="large">
          <ButtonIcon icon={Edit} onClick={handleEdit} variant="ghost">
            Edit
          </ButtonIcon>
          <ButtonIcon icon={Trash} onClick={handleRemove} variant="ghostDanger">
            Delete
          </ButtonIcon>
        </Box>
      )}
    </Box>
  );
};

export default NewContextMountedFile;
