import { Navigate, Route, Routes } from "react-router-dom";

import StackSettingsIntegrationsWebhooks from "./Webhooks";
import StackSettingsIntegrationsCloud from "./Cloud";

const StackSettingsIntegrations = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="cloud" replace />} />
      <Route path="cloud" element={<StackSettingsIntegrationsCloud />} />
      <Route path="webhooks" element={<StackSettingsIntegrationsWebhooks />} />
    </Routes>
  );
};

export default StackSettingsIntegrations;
