import { EmptystateStacksColored } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";

const VCSIntegrationEmpty = () => {
  return (
    <EmptyState
      title="There are no stacks or modules that use this integration yet"
      icon={EmptystateStacksColored}
      caption="Create a stack or module to start using the integration."
    />
  );
};

export default VCSIntegrationEmpty;
