import { memo, ReactNode, useCallback } from "react";
import { areEqual, ListChildComponentProps } from "react-window";
import { Point } from "d3-dag/dist/dag";
import { Group } from "@visx/group";

import { AnalyticsPage } from "hooks/useAnalytics";
import SkeletonSvg from "components/SkeletonSvg";

import { ConfigTypeComponents, Position, PositionedNode } from "./types";
import TreeChartConnection from "./Connection";
import { ListEntitiesBaseItemProps } from "./Entity";
import styles from "./styles.module.css";

export type TreeChartVirtualizedItemProps<T extends string> = {
  items: PositionedNode<T>[];
  nodeTypes: ConfigTypeComponents<T>;
  activeId?: string;
  onMouseEnter: (text: ReactNode, coordinates: Point | null) => void;
  onMouseLeave: () => void;
  connectionPoints: Record<string, Position>;
  setConnectionPoints: React.Dispatch<React.SetStateAction<Record<string, Position>>>;
  toggleKey: (key: string, hasChildrenToLoad?: boolean, position?: Position) => void;
  expandedKeys: Set<string>;
  columnWidth: number;
  analyticsPage?: AnalyticsPage;
};

const TreeChartVirtualizedItem = <T extends string>(
  props: ListChildComponentProps<TreeChartVirtualizedItemProps<T> & ListEntitiesBaseItemProps>
) => {
  const { index, data } = props;
  const { toggleKey, refreshItemsHeight } = data;

  const node = data.items[index];

  const onToggle = useCallback(
    (key: string, hasChildrenToLoad?: boolean, position?: Position) => {
      refreshItemsHeight();
      toggleKey(key, hasChildrenToLoad, position);
    },
    [toggleKey, refreshItemsHeight]
  );

  const isNotLoaded = index > data.items.length - 1;

  if (isNotLoaded) {
    const lastPositionedNode = data.items[data.items.length - 1];

    return (
      <Group
        className={styles.loadingText}
        left={lastPositionedNode.item.position.x}
        top={
          lastPositionedNode.item.position.y +
          data.nodeTypes[lastPositionedNode.item.type].height(lastPositionedNode.item)
        }
      >
        <SkeletonSvg width={128} height={16} borderRadius={4} />
      </Group>
    );
  }

  const Component = data.nodeTypes[node.item.type].Component;

  return (
    <>
      <Component
        onMouseEnter={data.onMouseEnter}
        onMouseLeave={data.onMouseLeave}
        connectionPoints={data.connectionPoints}
        setConnectionPoints={data.setConnectionPoints}
        onToggle={onToggle}
        id={node.id}
        key={node.id}
        item={node.item}
        isParent={!!node.children.length}
        isOpened={data.expandedKeys?.has(node.id)}
        columnWidth={data.columnWidth}
        activeId={data.activeId}
        analyticsPage={data.analyticsPage}
      />
      {node.item.connection && (
        <TreeChartConnection key={node.item.connection.id} {...node.item.connection} />
      )}
    </>
  );
};

export default memo(TreeChartVirtualizedItem, areEqual);
