import { Space } from "components/icons/generated";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import FormField from "ds/components/Form/Field";
import DescriptionDetails from "components/DescriptionDetails";
import { Module } from "types/generated";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import { workflowToolLabel } from "constants/terraform_workflow_tools";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import { useShowFullDescriptionWithBackArrow } from "ds/components/FullDescriptionDrawer/useShowFullDescriptionWithBackArrow";

type ModuleDetailsDrawerProps = {
  module: Module;
};

const ModuleDetailsDrawer = createDrawer(({ module }: ModuleDetailsDrawerProps) => {
  const onOpenFullDescription = useShowFullDescriptionWithBackArrow(module.description);

  return (
    <DrawerSimple isDismissable>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Module details" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <DrawerBody gap="x-large">
        <FormFieldViewText label="ID" value={module.id} noMargin />

        <FormField label="Space" noMargin>
          <MetaInfoListItem
            icon={Space}
            linkText={module.spaceDetails.name}
            href={`/spaces/${module.spaceDetails.id}`}
          />
        </FormField>

        <FormFieldViewText
          label="Workflow tool"
          value={workflowToolLabel(module.workflowTool)}
          noMargin
        />

        <FormFieldTimestamp label="Created at" timestamp={module.createdAt} noMargin />

        <DescriptionDetails
          description={module.description}
          onOpenFullDescription={onOpenFullDescription}
          noMargin
        />

        <FormField label="Labels" noMargin>
          <TagsList tags={module.labels} alwaysExpanded withEmptyPlaceholder />
        </FormField>
      </DrawerBody>
    </DrawerSimple>
  );
});
export const showModuleDetailsDrawer = createDrawerTrigger(ModuleDetailsDrawer);
