import EmptyState from "ds/components/EmptyState";
import { EmptystateSearchNoResultsColored } from "components/icons/generated";
import Box from "ds/components/Box";

const VCSIntegrationsHasNoResults = () => {
  return (
    // TODO: sync the design with the design team
    <Box direction="column" grow="1" fullWidth>
      <EmptyState
        title="No results"
        icon={EmptystateSearchNoResultsColored}
        caption="Try different filters."
      />
    </Box>
  );
};

export default VCSIntegrationsHasNoResults;
