import ButtonIcon from "ds/components/ButtonIcon";
import { Cross } from "components/icons/generated";

type DrawerCloseIconProps = {
  handleCloseDrawer: () => void;
};

const DrawerCloseIcon = ({ handleCloseDrawer }: DrawerCloseIconProps) => (
  <ButtonIcon icon={Cross} onClick={handleCloseDrawer} variant="ghost">
    Close
  </ButtonIcon>
);

export default DrawerCloseIcon;
