import { IdentityProvider, ManagedUser, UserStatus } from "types/generated";
import { LogoGithub, LogoGitlab, LogoGoogle, LogoMicrosoft } from "components/icons/generated";

enum IntegrationType {
  Slack = "Slack",
}

export const getProviderLogo = (provider: IdentityProvider) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (provider) {
    case IdentityProvider.Github:
      return LogoGithub;
    case IdentityProvider.Gitlab:
      return LogoGitlab;
    case IdentityProvider.Google:
      return LogoGoogle;
    case IdentityProvider.Microsoft:
      return LogoMicrosoft;
    case IdentityProvider.Oidc:
      return "OIDC";
    case IdentityProvider.Saml:
      return "SAML";
    default:
      return "UNKNOWN";
  }
};

export const getProviderName = (provider: IdentityProvider) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (provider) {
    case IdentityProvider.Github:
      return "GitHub";
    case IdentityProvider.Gitlab:
      return "GitLab";
    case IdentityProvider.Google:
      return "Google";
    case IdentityProvider.Microsoft:
      return "Microsoft";
    case IdentityProvider.Oidc:
      return "OIDC";
    case IdentityProvider.Saml:
      return "SAML";
    default:
      return "Unknown";
  }
};

const isManagedUser = (item: ManagedUser) =>
  item.status === UserStatus.Active || item.status === UserStatus.Inactive;

export const getUserIntegrationFormValue = (user: ManagedUser) => {
  if (user.slackMemberID) {
    return [
      {
        type: IntegrationType.Slack,
        id: user.slackMemberID,
      },
    ];
  }

  return [];
};

const sortUsers = (x: ManagedUser, y: ManagedUser) => {
  return (
    // show users before invitations
    Number(isManagedUser(y)) - Number(isManagedUser(x)) ||
    // sort users by lastLoginType
    Number(
      isManagedUser(x) && isManagedUser(y) && (y.lastLoginTime || 1) - (x.lastLoginTime || 1)
    ) ||
    // sort invitations by username
    Number(!isManagedUser(x) && !isManagedUser(y) && x?.username.localeCompare(y?.username))
  );
};

export const getSortedUsers = (items: ManagedUser[]) => {
  return [...items].sort(sortUsers);
};
