import { useMemo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useModal } from "@ebay/nice-modal-react";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import ButtonNew from "ds/components/Button/New";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { WorkerPool } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import Textarea from "ds/components/Textarea";
import FormFieldTags from "components/FormFields/Tags";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import FlashContext from "components/FlashMessages/FlashContext";
import DragDropFileUpload from "ds/components/FileUpload/DragDropFileUpload";
import { downloadFile } from "utils/file";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Link from "ds/components/Link";
import FormFieldSpace from "components/FormFields/Space";
import { getDocsUrl } from "utils/getDocsUrl";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";

import { CreateWorkerPoolFields } from "./types";
import useCreateWorkerPool from "./useCreateWorkerPool";
import useEditWorkerPool from "./useEditWorkerPool";
import { setFormDefaultValues } from "./helpers";

type CreateWorkerPoolProps = {
  workerPool?: WorkerPool;
  refetchQuery?: string;
};

const CreateWorkerPool = createDrawer(({ workerPool, refetchQuery }: CreateWorkerPoolProps) => {
  const isEditMode = !!workerPool;

  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const createWorkerPoolForm = useForm<CreateWorkerPoolFields>({
    defaultValues: useMemo(() => setFormDefaultValues(workerPool), [workerPool]),
    mode: "onChange",
  });

  const drawer = useModal();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = createWorkerPoolForm;

  const { onCreate } = useCreateWorkerPool();
  const { onEdit } = useEditWorkerPool(refetchQuery);

  const handleCloseDrawer = () => {
    drawer.hide();
  };

  const onSubmit: SubmitHandler<CreateWorkerPoolFields> = async (formData) => {
    try {
      if (isEditMode) {
        const { data } = await onEdit(formData, workerPool);

        if (data?.workerPoolUpdate) {
          reportSuccess({
            message: `Worker pool "${data.workerPoolUpdate.workerPool.name}" was successfully updated`,
          });

          handleCloseDrawer();
        }
      } else {
        const { data } = await onCreate(formData);

        if (data) {
          downloadFile(
            `worker-pool-${data.workerPoolCreate.workerPool.id}.config`,
            data.workerPoolCreate.config
          );
          reportSuccess({
            message: `Worker pool ${data.workerPoolCreate.workerPool.name} successfully created`,
          });

          handleCloseDrawer();
        }
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleSubmitAction = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <DrawerSimple>
      <FormProvider {...createWorkerPoolForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title={isEditMode ? "Edit worker pool" : "Create a new worker pool"} />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <FormField label="Name" error={errors?.name?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter the name here"
                error={!!errors?.name}
                {...register("name", { required: "Name field is required." })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          {!isEditMode && (
            <Controller
              name="certificateFile"
              control={control}
              rules={{ required: "Certificate field is required." }}
              render={({ field, fieldState }) => (
                <FormField error={fieldState.error?.message} label="Certificate">
                  <DragDropFileUpload
                    caption="Drag and drop the .csr file here"
                    file={field.value}
                    onChange={field.onChange}
                  />
                </FormField>
              )}
            />
          )}

          <FormFieldSpace />

          <FormField label="Description" isOptional>
            {({ ariaInputProps }) => (
              <Textarea
                placeholder="Enter the description here"
                {...register("description")}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldTags label="Labels" tagName="label" name="labels" isOptional />
          {!isEditMode && (
            <Box margin="large 0 0 0">
              <Banner variant="info">
                Upon worker pool creation, a file will be generated with the worker pool
                configuration, encoded using base64. Please save this file somewhere safe as it will
                never be available again. You will need to make the configuration data available to
                all workers joining this worker pool.
                <br />
                <Box margin="medium 0 0 0">
                  <Link
                    size="small"
                    href={getDocsUrl("/concepts/worker-pools#setting-up")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </Link>
                </Box>
              </Banner>
            </Box>
          )}
          <DrawerFooter>
            <DrawerFooterActions>
              <ButtonNew variant="secondary" onPress={handleCloseDrawer} disabled={isSubmitting}>
                Cancel
              </ButtonNew>
              <ButtonNew
                variant="primary"
                onPress={handleSubmitAction}
                disabled={!isValid || (isEditMode && !isDirty)}
                loading={isSubmitting}
              >
                {isEditMode ? "Save" : "Create"}
              </ButtonNew>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </DrawerSimple>
  );
});

export const showWorkerPoolFormDrawer = createDrawerTrigger(CreateWorkerPool);
