import { useCallback, useMemo } from "react";
import useLocalStorage from "@rehooks/local-storage";
import { useModal } from "@ebay/nice-modal-react";

import CustomizeView from "components/CustomizeView";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import ButtonNew from "ds/components/Button/New";
import DrawerFooter from "ds/components/Drawer/Footer";
import { getLocalStorageConfigSizesId } from "components/Table/utils";
import { CustomizeViewConfig } from "components/CustomizeView/types";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import { ColumnConfig } from "components/Table/types";

import { getLabel } from "./utils";
import { ROW_HEADER_ID, STACKS_COLUMN_ID, STACK_COLUMNS_STORAGE_KEY } from "../constants";
import { INITIAL_COLUMNS_CONFIG } from "./constants";
import { showResetConfirmation } from "./ResetConfirmation";
import useStackListAnalytics from "../useStackListAnalytics";

const ALWAYS_VISIBLE_ITEMS = [ROW_HEADER_ID];

type CustomizeViewDrawerProps = {
  columnConfig: ColumnConfig;
  onConfigReset: () => void;
};

const CustomizeViewDrawer = createDrawer(
  ({ columnConfig, onConfigReset }: CustomizeViewDrawerProps) => {
    const drawer = useModal();
    const [, , clearOrderConfig] = useLocalStorage(STACK_COLUMNS_STORAGE_KEY);
    const [, , clearSizesConfig] = useLocalStorage(
      getLocalStorageConfigSizesId(STACK_COLUMNS_STORAGE_KEY)
    );

    const trackAnalytics = useStackListAnalytics();

    const resetConfig = useCallback(() => {
      trackAnalytics("Table Configuration Reset");
      clearOrderConfig();
      clearSizesConfig();
      drawer.hide();
      // Move to end of queue
      setTimeout(onConfigReset);
    }, [clearOrderConfig, clearSizesConfig, drawer, onConfigReset, trackAnalytics]);

    const handleOnChange = useCallback(
      (config: CustomizeViewConfig<STACKS_COLUMN_ID>) => {
        trackAnalytics("Table configuration changed", { ...config, method: "customize drawer" });
      },
      [trackAnalytics]
    );

    const staticColumns = useMemo(
      () =>
        Object.keys(columnConfig)
          .filter((key) => columnConfig[key].static)
          .map((key) => key as STACKS_COLUMN_ID),
      [columnConfig]
    );

    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Customize list" />
          <DrawerCloseIcon />
        </DrawerHeader>
        <DrawerBody noPadding>
          <CustomizeView
            visibleSectionTitle="Visible items"
            hiddenSectionTitle="Hidden items"
            storageKey={STACK_COLUMNS_STORAGE_KEY}
            defaultConfig={INITIAL_COLUMNS_CONFIG}
            staticItems={staticColumns}
            getLabel={getLabel}
            onChange={handleOnChange}
            alwaysVisibleItems={ALWAYS_VISIBLE_ITEMS}
          />
        </DrawerBody>
        <DrawerFooter>
          <ButtonNew
            onPress={() => showResetConfirmation({ onReset: resetConfig })}
            variant="secondary"
          >
            Reset to default
          </ButtonNew>
        </DrawerFooter>
      </DrawerSimple>
    );
  }
);

export const showCustomizeViewDrawer = createDrawerTrigger(CustomizeViewDrawer);
