import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerSimple from "ds/components/DrawerNew/Simple";

import UsersInfoDrawerContent from "./Content";

type UsersInfoDrawerProps = {
  isInviteFormVisible: boolean;
};

const UsersInfoDrawer = createDrawer(({ isInviteFormVisible }: UsersInfoDrawerProps) => {
  return (
    <DrawerSimple>
      <UsersInfoDrawerContent isInviteFormVisible={isInviteFormVisible} />
    </DrawerSimple>
  );
});

export const showUsersInfoDrawer = createDrawerTrigger(UsersInfoDrawer);
