import { useModal, create, show } from "@ebay/nice-modal-react";
import { useState } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";
import { ConfirmationModalMessage } from "ds/components/ConfirmationModal/Message";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { ConfirmationModalInput } from "ds/components/ConfirmationModal/Input";
import { createDefaultMutationHook } from "hooks/useMutationHandler";

import { DELETE_OIDC_SSO } from "./gql";

const useDeleteOIDC = createDefaultMutationHook(DELETE_OIDC_SSO);

type SettingsSingleSignOnDisableOIDCProps = {
  hasManagedUsers?: boolean;
};

const SettingsSingleSignOnDisableOIDC = create(function SettingsSingleSignOnDisableOIDC({
  hasManagedUsers,
}: SettingsSingleSignOnDisableOIDCProps) {
  const modal = useModal();
  const [isValid, setIsValid] = useState(false);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSSO,
    defaultCallbackTrackProperties: { method: "OIDC" },
  });

  const { reportSuccess } = useTypedContext(FlashContext);

  const [deleteOIDC, { loading, data }] = useDeleteOIDC({
    refetchQueries: ["GetSSOSettings"],
  });

  const handleDelete = () => {
    trackSegmentAnalyticsEvent("Confirm disable click");
    deleteOIDC({}, (data) => {
      if (data?.oidcDelete) {
        reportSuccess({ message: "OIDC successfully disabled" });
        modal.hide();
      }
    });
  };

  return (
    <DeleteConfirmationModal
      title="Unlink OIDC SSO"
      onConfirm={handleDelete}
      isLoading={loading}
      isDismissable={!loading && !data?.oidcDelete?.clientId}
      confirmationButtonLabel="Unlink"
      isConfirmationDisabled={!isValid}
      size="large"
    >
      <ConfirmationModalMessage>
        Are you sure you want to unlink current OIDC integration?{" "}
        {hasManagedUsers && (
          <>
            <strong>OIDC managed users will be removed and this action cannot be undone.</strong>
          </>
        )}
      </ConfirmationModalMessage>

      <ConfirmationModalInput setIsValid={setIsValid} keyWord="unlink" />
    </DeleteConfirmationModal>
  );
});

export const showDisableConfirmation = (props: SettingsSingleSignOnDisableOIDCProps) =>
  show(SettingsSingleSignOnDisableOIDC, props);
