import { CloudArrows, Gear, Link, Policy, Puzzle } from "components/icons/generated";

import { StackCreationWizardStep } from "../types";

export const config = [
  {
    title: "Define stack behavior",
    description: "Define additional stack settings",
    action: {
      title: "Define behavior",
      step: StackCreationWizardStep.Behaviour,
    },
    icon: Gear,
  },
  {
    title: "Add hooks to stack",
    description: "Customize your workflow by adding hooks to phases",
    action: {
      title: "Add hooks",
      step: StackCreationWizardStep.Hooks,
    },
    icon: Link,
  },
  {
    title: "Attach cloud",
    description: "Attach cloud integration to your stack",
    action: {
      title: "Attach cloud",
      step: StackCreationWizardStep.Cloud,
    },
    icon: CloudArrows,
  },
  {
    title: "Attach policy",
    description: "Attach set of rules to your stack",
    action: {
      title: "Attach policy",
      step: StackCreationWizardStep.Policies,
    },
    icon: Policy,
  },
  {
    title: "Attach context",
    description: "Attach bundle of configuration elements to your stack",
    action: {
      title: "Attach context",
      step: StackCreationWizardStep.AttachContext,
    },
    icon: Puzzle,
  },
];
