import { SlackAppConfigInput } from "types/generated";

import { SettingsSlackAppConfig } from "../../gql";

export type SettingsIntegrationsSlackSetUpFormFields = SlackAppConfigInput;

export const getFormDefaultValues = (
  config?: SettingsSlackAppConfig
): SettingsIntegrationsSlackSetUpFormFields => ({
  clientId: config?.clientId || "",
  clientSecret: "",
  signingSecret: "",
});
