import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import { Policy, PolicyType } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";

import LoginPolicyList from "./List";
import { GET_POLICIES } from "./gql";
import LoginPolicyPageLayout from "./PageLayout";
import LoginPolicySortHeader from "./SortHeader";
import { PoliciesQueryResponse } from "./types";
import LoginPoliciesEmpty from "./Empty";

const LoginPolicies = () => {
  const { accountName } = useTypedContext(AccountContext);

  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data } = useQuery<PoliciesQueryResponse>(GET_POLICIES, {
    onError,
  });

  useTitle(`Organization Settings · Login Policies List · ${accountName}`);

  const [loginPolicies, takenPolicyNames] = useMemo(() => {
    const policies = data?.policies ?? [];
    return policies.reduce<[Policy[], string[]]>(
      ([loginPolicies, policyNames], policy): [Policy[], string[]] => {
        if (policy.type === PolicyType.Login) {
          loginPolicies.push(policy);
        }
        policyNames.push(policy.name.toLowerCase());
        return [loginPolicies, policyNames];
      },
      [[], []]
    );
  }, [data?.policies]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.policies) {
    return <PageLoading />;
  }

  if (!data?.policies) {
    return <NotFoundPage />;
  }

  return (
    <LoginPolicyPageLayout takenPolicyNames={takenPolicyNames}>
      {loginPolicies.length === 0 && <LoginPoliciesEmpty />}
      {loginPolicies.length > 0 && (
        <>
          <LoginPolicySortHeader />
          <LoginPolicyList loginPolicies={loginPolicies} />
        </>
      )}
    </LoginPolicyPageLayout>
  );
};

export default LoginPolicies;
