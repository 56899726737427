import DocumentationButton from "components/DocumentationButton";
import { LogoAnsible } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";
import { getDocsUrl } from "utils/getDocsUrl";

const ConfigManagementEmptyState = () => (
  <EmptyState
    title="You don’t have any Ansible tasks yet"
    caption="You can use Spacelift to monitor and manage your configuration"
    icon={LogoAnsible}
  >
    {/* TODO: docs link */}
    <DocumentationButton to={getDocsUrl("/vendors/ansible.html")} label="Documentation" />
  </EmptyState>
);

export default ConfigManagementEmptyState;
