import { BulkActionsResult } from "components/BulkActions/types";
import { Spinner } from "components/icons";
import { Cross, Hourglass, Tick, Skip, StopCircle } from "components/icons/generated";
import Icon, { IconProps } from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";

type BulkActionsDrawerItemProps = {
  result: BulkActionsResult;
};

type IconWithTooltipProps = { src: IconProps["src"]; color: IconProps["color"]; children: string };

const IconWithTooltip = ({ src, color, children }: IconWithTooltipProps) => (
  <Tooltip
    widthMode="maxWidthSm"
    on={(props) => <Icon {...props} src={src} color={color} noShrink />}
  >
    {children}
  </Tooltip>
);

const BulkActionsDrawerItemStatusIcon = ({ result }: BulkActionsDrawerItemProps) => {
  switch (result) {
    case BulkActionsResult.Completed:
      return <Icon src={Tick} color="success" noShrink />;
    case BulkActionsResult.Skipped:
      return (
        <IconWithTooltip src={Skip} color="danger">
          Skipped
        </IconWithTooltip>
      );
    case BulkActionsResult.Stopped:
      return (
        <IconWithTooltip src={StopCircle} color="danger">
          Stopped
        </IconWithTooltip>
      );
    case BulkActionsResult.Failed:
      return (
        <IconWithTooltip src={Cross} color="danger">
          Failed
        </IconWithTooltip>
      );
    case BulkActionsResult.Pending:
      return (
        <IconWithTooltip src={Spinner} color="secondary">
          Pending
        </IconWithTooltip>
      );
    case BulkActionsResult.Queued:
      return (
        <IconWithTooltip src={Hourglass} color="secondary">
          Queued
        </IconWithTooltip>
      );
  }
};

export default BulkActionsDrawerItemStatusIcon;
