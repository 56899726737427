import { useMemo } from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

type AttachedProject = {
  isModule: boolean;
  stackId: string;
  stackName: string;
};

type AttachedProjectsProps = {
  projects: AttachedProject[];
};

const byStackNameIgnoreCase = (x: AttachedProject, y: AttachedProject) =>
  x.stackName.toLowerCase().localeCompare(y.stackName.toLowerCase());

const AttachedProjects = ({ projects }: AttachedProjectsProps) => {
  const sortedProjects = useMemo(() => [...projects].sort(byStackNameIgnoreCase), [projects]);

  if (sortedProjects.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h4>Used by</h4>
      <ul>
        {sortedProjects.map(({ isModule, stackId, stackName }) => (
          <li key={stackId}>
            <Link className={styles.link} to={`/${isModule ? "module" : "stack"}/${stackId}`}>
              {stackName}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AttachedProjects;
