import { BadgeProps, BadgeVariants } from "ds/components/BadgeNext/types";
import BadgeNextNew from "ds/components/BadgeNext/New";
import { BlueprintState } from "types/generated";
import { ChevronDown } from "components/icons/generated";

type RunHistoryEntryConfig = {
  variant: BadgeVariants;
  text: string;
};

export const BLUEPRINT_STATES: Record<BlueprintState, RunHistoryEntryConfig> = {
  [BlueprintState.Deleted]: { variant: "red", text: "Deleted" },
  [BlueprintState.Draft]: { variant: "gray", text: "Draft" },
  [BlueprintState.Published]: { variant: "blue", text: "Published" },
  [BlueprintState.Unknown]: { variant: "white", text: "Unknown" },
};

type BlueprintStateBadgeProps = {
  state: BlueprintState;
  className?: string;
  onClick?: BadgeProps["onClick"];
  withDropdown?: boolean;
};

const BlueprintStateBadge = ({
  state,
  className,
  onClick,
  withDropdown,
}: BlueprintStateBadgeProps) => {
  if (!BLUEPRINT_STATES[state]) {
    return null;
  }

  return (
    <BadgeNextNew
      onClick={onClick}
      variant={BLUEPRINT_STATES[state].variant}
      type="strong"
      className={className}
      text={BLUEPRINT_STATES[state].text}
      endIcon={withDropdown ? ChevronDown : undefined}
    />
  );
};

export default BlueprintStateBadge;
