import { useEffect, useRef } from "react";

import { Space } from "components/icons/generated";
import { PolicyStackAttachment } from "types/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ProjectTypeBadge, { ProjectType } from "components/ProjectTypeBadge";

import { COLUMN_GAP, COLUMN_ORDER } from "./constants";

type PolicyDetailsUsedByEntityProps = {
  attachedStack: PolicyStackAttachment;
  setRowHeight?: (size: number) => void;
};

const PolicyDetailsUsedByEntity = ({
  attachedStack,
  setRowHeight,
}: PolicyDetailsUsedByEntityProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
    >
      <Box>
        <ProjectTypeBadge
          projectType={attachedStack.isModule ? ProjectType.Module : ProjectType.Stack}
        />
      </Box>
      <Box align="center">
        <ListEntitiesItemLink
          title={attachedStack.stackName}
          titleTag="h3"
          to={`/${attachedStack.isModule ? "module" : "stack"}/${attachedStack.stackId}`}
        />
      </Box>

      <MetaInfoListItem
        icon={Space}
        linkText={attachedStack.stackSpaceDetails.name}
        href={`/spaces/${attachedStack.stackSpaceDetails.id}`}
      />
    </ListEntitiesItem>
  );
};

export default PolicyDetailsUsedByEntity;
