import { Outlet } from "react-router-dom";

import SubSidebar from "components/SubSidebar";
import SubSidebarSection from "components/SubSidebar/Section";
import SubSidebarSectionItem from "components/SubSidebar/SectionItem";
import SubSidebarSectionTitle from "components/SubSidebar/SectionTitle";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Box from "ds/components/Box";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import { getStacksBackUrl } from "views/Stack/helpers";
import StackDetachedIntegrationCallout from "views/Stack/components/DetachedIntegrationCallout";

import { StackSettingsContextData } from "../Context";
import StackSettingsLeaveFeedbackButton from "./LeaveFeedbackButton";

const StackSettingsRoutesLayout = () => {
  const { stackSettings, canManageStack } = useTypedContext(StackSettingsContextData);

  useTitle(stackSettings?.name ? `Settings · ${stackSettings.name}` : "Settings");

  useBreadcrumbs(
    [
      {
        title: "Stacks",
        link: getStacksBackUrl(),
      },
      {
        title: stackSettings.name,
        link: `/stack/${stackSettings.id}`,
      },
      {
        title: "Stack settings",
      },
    ],
    [stackSettings.id, stackSettings.name]
  );

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper>
          <ViewHeaderTitle titleSize="p-t3">Stack settings</ViewHeaderTitle>
        </ViewHeaderWrapper>
        <ViewHeaderWrapper>
          <StackSettingsLeaveFeedbackButton />
        </ViewHeaderWrapper>
      </ViewHeader>

      <StackDetachedIntegrationCallout />

      <Box grow="1">
        <SubSidebar>
          <SubSidebarSection>
            <SubSidebarSectionItem to="." end>
              Stack details
            </SubSidebarSectionItem>
            <SubSidebarSectionItem to="source-code">Source code</SubSidebarSectionItem>
            <SubSidebarSectionItem to="vendor">Vendor</SubSidebarSectionItem>
            <SubSidebarSectionItem to="behavior">Behavior</SubSidebarSectionItem>
            <SubSidebarSectionItem to="integrations">Integrations</SubSidebarSectionItem>
          </SubSidebarSection>

          {canManageStack && (
            <SubSidebarSection>
              <SubSidebarSectionTitle>Danger area</SubSidebarSectionTitle>
              <SubSidebarSectionItem to="deletion">Stack deletion</SubSidebarSectionItem>
            </SubSidebarSection>
          )}
        </SubSidebar>

        <Box direction="column" surfaceColor="primary" grow="1">
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default StackSettingsRoutesLayout;
