import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import Input from "ds/components/Input";
import Button from "ds/components/Button";
import FormToggleField from "ds/components/Form/ToggleField";
import { StackDependency, StackDependencyReferenceUpdateInput } from "types/generated";
import { stringIsRequired } from "utils/formValidators";
import useTypedContext from "hooks/useTypedContext";
import { isStackVendorTerraform } from "utils/vendor";

import styles from "./styles.module.css";
import OutputReferencesDrawerSelectOption from "./SelectOption";
import OutputReferencesDrawerSelectOptionAddNonExisting from "./SelectOptionAddNonExisting";
import { SelectOptionOutput } from "./types";
import useAddOutputReference from "./useAddOutputReference";
import useUpdateOutputReference from "./useUpdateOutputReference";
import { NON_EXISTING_OUTPUT_OPTION_DESCRIPTION } from "./constants";
import { StackDependenciesOutputReferencesContextData } from "./Context";

type OutputReferenceFormFields = {
  outputName: string;
  inputName: string;
  triggerAlways: boolean;
};

type OutputReferencesDrawerFormProps = {
  outputReference?: StackDependencyReferenceUpdateInput;
  stackDependencyId: StackDependency["id"];
  onEditCancel?: () => void;
  onEditSuccess?: () => void;
  vendorTypename?: string;
};

const OutputReferencesDrawerForm = ({
  stackDependencyId,
  outputReference,
  onEditCancel,
  onEditSuccess,
  vendorTypename,
}: OutputReferencesDrawerFormProps) => {
  const isEditMode = !!outputReference;

  const [notExistingOutputOption, setNotExistingOutputOption] = useState<
    SelectOptionOutput | undefined
  >(undefined);

  const outputReferenceForm = useForm<OutputReferenceFormFields>({
    defaultValues: {
      outputName: outputReference?.outputName || "",
      inputName: outputReference?.inputName || "",
      triggerAlways: outputReference?.triggerAlways || false,
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = outputReferenceForm;

  const isTerraformVendor = isStackVendorTerraform(vendorTypename);
  const currentOutputName = watch("outputName");
  const inputNamePlaceholder =
    currentOutputName && isTerraformVendor ? `TF_VAR_${currentOutputName}` : "Enter input name";

  const { outputsOptions, loading } = useTypedContext(StackDependenciesOutputReferencesContextData);
  const { addOutputReference } = useAddOutputReference();
  const { updateOutputReference } = useUpdateOutputReference();

  const handleAddNotExistingOutput = (value: string, clb: () => void) => () => {
    const trimmedValue = value.trim();

    setNotExistingOutputOption({
      value: trimmedValue,
      label: trimmedValue,
      description: NON_EXISTING_OUTPUT_OPTION_DESCRIPTION,
    });

    setValue("outputName", trimmedValue, { shouldDirty: true, shouldValidate: true });
    clb();
  };

  const onSubmit: SubmitHandler<OutputReferenceFormFields> = (formData) => {
    if (isEditMode && outputReference) {
      updateOutputReference(
        [
          {
            ...outputReference,
            outputName: formData.outputName,
            inputName: formData.inputName,
            triggerAlways: formData.triggerAlways,
          },
        ],
        onEditSuccess
      );
    } else {
      addOutputReference(
        stackDependencyId,
        [
          {
            outputName: formData.outputName,
            inputName: formData.inputName,
            triggerAlways: formData.triggerAlways,
            type: null,
          },
        ],
        reset
      );
    }
  };

  const memoisedOutputsOptions = useMemo(
    () => [...(notExistingOutputOption ? [notExistingOutputOption] : []), ...outputsOptions],
    [notExistingOutputOption, outputsOptions]
  );

  useEffect(() => {
    // add a not existing output option to the list of outputs in case editing an output reference with not existing output
    if (
      isEditMode &&
      outputReference &&
      !outputsOptions.find((option) => option.value === outputReference.outputName)
    ) {
      setNotExistingOutputOption({
        value: outputReference.outputName,
        label: outputReference.outputName,
        description: NON_EXISTING_OUTPUT_OPTION_DESCRIPTION,
      });
    }
  }, [isEditMode, outputReference, outputsOptions]);

  return (
    <Box
      direction="column"
      padding="large"
      gap="large"
      margin={isEditMode ? "medium 0" : "0"}
      className={styles.formWrapper}
    >
      <Controller
        name="outputName"
        control={control}
        rules={{ required: "Output field is required." }}
        render={({ field, fieldState }) => (
          <FormField label="Select output" error={fieldState.error?.message} noMargin>
            {({ ariaInputProps }) => (
              <Select<SelectOptionOutput>
                options={memoisedOutputsOptions}
                value={field.value}
                onChange={field.onChange}
                renderOption={OutputReferencesDrawerSelectOption}
                dropdownListClassName={styles.dropdownList}
                error={!!fieldState.error?.message}
                autocomplete
                loading={loading}
                renderAutocompleteLastItemPlaceholder={({ query, closeSelect }) =>
                  !memoisedOutputsOptions.find(({ value }) => value === query) && (
                    <OutputReferencesDrawerSelectOptionAddNonExisting
                      text="Can't find your output?"
                      onClick={handleAddNotExistingOutput(query, closeSelect)}
                      query={query}
                    />
                  )
                }
                renderAutocompleteEmptyPlaceholder={({ query, closeSelect }) => (
                  <OutputReferencesDrawerSelectOptionAddNonExisting
                    text="No output found."
                    onClick={handleAddNotExistingOutput(query, closeSelect)}
                    query={query}
                  />
                )}
                ariaInputProps={ariaInputProps}
              />
            )}
          </FormField>
        )}
      />
      <FormField
        label="Input name"
        tooltipInfo="A name that this output maps to in the environment variables view."
        error={errors?.inputName?.message}
        noMargin
      >
        {({ ariaInputProps }) => (
          <Input
            placeholder={inputNamePlaceholder}
            error={!!errors?.inputName}
            {...register("inputName", {
              validate: stringIsRequired("Input name field is required."),
            })}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <Controller
        name="triggerAlways"
        control={control}
        render={({ field }) => (
          <FormToggleField
            variant="checkbox"
            title="Trigger always"
            description="when enabled, the dependent stack will be triggered regardless of whether the output has changed or not."
            checked={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Box direction="row" justify="end" gap="medium">
        {isEditMode && onEditCancel && (
          <Button variant="secondary" size="small" onClick={onEditCancel}>
            Cancel
          </Button>
        )}

        <Button
          variant="contrast"
          size="small"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || (isEditMode && !isDirty)}
        >
          {isEditMode ? "Save" : "Add"}
        </Button>
      </Box>
    </Box>
  );
};

export default OutputReferencesDrawerForm;
