import { ReactNode, useCallback } from "react";
import { useModal } from "@ebay/nice-modal-react";

import Drawer from "./index";
import { DrawerProps } from "./types";

export type DrawerSimpleProps = {
  children: ReactNode;
  onClose?: () => void | Promise<void>;
} & Pick<DrawerProps, "isDismissable" | "variant" | "dataTestId">;

const DrawerSimple = ({
  children,
  onClose,

  ...restProps
}: DrawerSimpleProps) => {
  const drawer = useModal();

  const handleClose = useCallback(async () => {
    await onClose?.();
    drawer.hide();
  }, [drawer, onClose]);

  return (
    <Drawer isOpen={drawer.visible} onClose={handleClose} onExit={drawer.remove} {...restProps}>
      {children}
    </Drawer>
  );
};

export default DrawerSimple;
