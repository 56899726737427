import {
  CalendarArrow,
  CheckList,
  CircleCrossed,
  Eye,
  ExclamationMarkCircle,
  Gear,
  Play,
  Tick,
  ThumbUp,
  ThumbDown,
  Trash,
  StopCircle,
  Edit,
} from "components/icons/generated";
import { StackState } from "types/generated";

import { RunHistoryEntryConfig } from "./types";

export const STACK_STATES: Record<StackState, RunHistoryEntryConfig> = {
  [StackState.Applying]: { icon: Play, variant: "blue", text: "Applying" },
  [StackState.Confirmed]: { icon: ThumbUp, variant: "gray", text: "Confirmed" },
  [StackState.Destroying]: { icon: Trash, variant: "blue", text: "Destroying" },
  [StackState.Discarded]: { icon: ThumbDown, variant: "red", text: "Discarded" },
  [StackState.Failed]: { icon: ExclamationMarkCircle, variant: "red", text: "Failed" },
  [StackState.Finished]: { icon: Tick, variant: "green", text: "Finished" },
  [StackState.Initializing]: { icon: Gear, variant: "blue", text: "Initializing" },
  [StackState.None]: { icon: CircleCrossed, variant: "gray", text: "None" },
  [StackState.Planning]: { icon: Edit, variant: "blue", text: "Planning" },
  [StackState.Preparing]: { icon: CheckList, variant: "blue", text: "Preparing" },
  [StackState.PreparingApply]: { icon: CheckList, variant: "blue", text: "Preparing" },
  [StackState.PreparingReplan]: { icon: CheckList, variant: "blue", text: "Preparing" },
  [StackState.ReplanRequested]: { icon: CalendarArrow, variant: "gray", text: "Replan requested" },
  [StackState.Stopped]: { icon: StopCircle, variant: "red", text: "Stopped" },
  [StackState.Unconfirmed]: { icon: Eye, variant: "yellow", text: "Unconfirmed" },
};
