import { Fragment, ReactNode, useCallback, useEffect, useRef } from "react";
import cx from "classnames";

import {
  MOUSE_LEAVE_TIMEOUT,
  SUMMARY_ITEM_BORDER_RADIUS,
  SUMMARY_ITEM_HEIGHT,
  SUMMARY_ITEM_WIDTH,
} from "../contstants";
import TreeChartGroupNodeSummaryRoundedBorder from "./RoundedBorder";
import TreeChartGroupNodeSummaryLeftRoundedBorder from "./LeftRoundedBorder";
import TreeChartGroupNodeSummarySquareBorder from "./SquareBorder";
import TreeChartGroupNodeSummaryRightRoundedBorder from "./RightRoundedBorder";
import styles from "../styles.module.css";

const SUMMARY_MAX_VALUE = 999;

type TreeChartGroupNodeSummaryProps = {
  items: [string, number][];
  createMouseEnterHandler: (
    tooltipValue: ReactNode
  ) => (event: React.MouseEvent<SVGTextElement | SVGRectElement>) => void;
  onMouseLeave: () => void;
  startX: number;
};

const TreeChartGroupNodeSummary = ({
  items,
  createMouseEnterHandler,
  onMouseLeave,
  startX,
}: TreeChartGroupNodeSummaryProps) => {
  const mouseLeaveTimeoutId = useRef<number>();

  useEffect(() => {
    // clear on component unmount
    return () => {
      clearTimeout(mouseLeaveTimeoutId.current);
    };
  }, []);

  const handleMouseRectLeave = useCallback(() => {
    mouseLeaveTimeoutId.current = window.setTimeout(() => {
      onMouseLeave();
    }, MOUSE_LEAVE_TIMEOUT);
  }, [onMouseLeave]);

  const createMouseEnterRectHandler = useCallback(
    (tooltipValue: ReactNode) => (event: React.MouseEvent<SVGTextElement>) => {
      if (mouseLeaveTimeoutId.current) clearTimeout(mouseLeaveTimeoutId.current);
      createMouseEnterHandler(tooltipValue)(event);
    },
    [createMouseEnterHandler]
  );

  return (
    <>
      {items.map(([name, value], i) => {
        const x = startX + i * SUMMARY_ITEM_WIDTH;
        // this is space we have to lift up this element to make it centered in comparision to rest of the content
        const y = -3;

        const first = i === 0 && items.length > 1;
        const last = i === items.length - 1 && items.length > 1;
        const onlyOne = i === 0 && items.length === 1;
        const middle = i > 0 && items.length - 1 > i;
        const visibleValue = value > SUMMARY_MAX_VALUE ? `${SUMMARY_MAX_VALUE}+` : value;

        return (
          <Fragment key={name}>
            {onlyOne && (
              <TreeChartGroupNodeSummaryRoundedBorder
                startX={x}
                startY={y}
                radius={SUMMARY_ITEM_BORDER_RADIUS}
                width={SUMMARY_ITEM_WIDTH}
                height={SUMMARY_ITEM_HEIGHT}
              />
            )}
            {first && (
              <TreeChartGroupNodeSummaryLeftRoundedBorder
                startX={x}
                startY={y}
                radius={SUMMARY_ITEM_BORDER_RADIUS}
                width={SUMMARY_ITEM_WIDTH}
                height={SUMMARY_ITEM_HEIGHT}
              />
            )}

            {middle && (
              <TreeChartGroupNodeSummarySquareBorder
                startX={x}
                startY={y}
                width={SUMMARY_ITEM_WIDTH}
                height={SUMMARY_ITEM_HEIGHT}
              />
            )}

            {last && (
              <TreeChartGroupNodeSummaryRightRoundedBorder
                startX={x}
                startY={y}
                radius={SUMMARY_ITEM_BORDER_RADIUS}
                width={SUMMARY_ITEM_WIDTH}
                height={SUMMARY_ITEM_HEIGHT}
              />
            )}

            <text
              className={cx(styles.summaryValue, name && styles[name.toLowerCase()])}
              textAnchor="middle"
              dominantBaseline="hanging"
              y={0}
              x={x + SUMMARY_ITEM_WIDTH / 2}
              width={SUMMARY_ITEM_WIDTH}
              onMouseLeave={handleMouseRectLeave}
              onMouseEnter={createMouseEnterRectHandler(`${name}: ${value}`)}
            >
              {visibleValue}
            </text>
          </Fragment>
        );
      })}
    </>
  );
};

export default TreeChartGroupNodeSummary;
