import prettyBytes from "pretty-bytes";
import { useQuery } from "@apollo/client";

import Typography from "ds/components/Typography";
import Counter from "ds/components/Counter";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import Box from "ds/components/Box";
import { File, Download } from "components/icons/generated";
import { TerraformProviderVersionArtifact } from "types/generated";
import { downloadFileFromURL } from "utils/file";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import Icon from "ds/components/Icon";
import ButtonIcon from "ds/components/ButtonIcon";

import styles from "./styles.module.css";
import {
  GetDownloadableProviderVersionArtifactUrlGql,
  ProviderVersionArtifactsProps,
} from "./type";
import { GET_DOWNLOADABLE_PROVIDER_VERSION_ARTIFACT_URL } from "../gql";

function ProviderVersionArtifacts({ version, provider }: ProviderVersionArtifactsProps) {
  const { onError, reportError } = useTypedContext(FlashContext);

  const { refetch: getLink, loading } = useQuery<GetDownloadableProviderVersionArtifactUrlGql>(
    GET_DOWNLOADABLE_PROVIDER_VERSION_ARTIFACT_URL,
    {
      onError,
      skip: true,
      fetchPolicy: "no-cache",
    }
    // APOLLO CLIENT UPDATE
  );

  const handleDownloadArtifact = (artifact: TerraformProviderVersionArtifact) => {
    getLink({
      artifactFilename: artifact.filename,
      versionNumber: version.number,
      providerId: provider.id,
    })
      .then(({ data }) => {
        if (data?.terraformProvider?.version?.artifactDownloadUrl) {
          downloadFileFromURL(data.terraformProvider.version.artifactDownloadUrl);
        } else {
          reportError({
            message: "Something went wrong while generating a download URL, please try again.",
          });
        }
      })
      .catch(onError);
  };

  return (
    <Box className={styles.tableWrapper} direction="column" fullWidth>
      <Box className={styles.tableHeader} align="center" gap="medium">
        <Typography tag="span" variant="p-t6">
          Assets
        </Typography>
        <Counter count={version.artifacts.length} />
      </Box>
      <FiltersSortHeaderWrapper
        columnOrder="minmax(40rem, 7fr) minmax(5rem, 1fr) 2.4rem"
        columnGap="large"
      >
        <FiltersSortHeaderStaticColumn>
          <Typography tag="span" variant="p-t6">
            Name
          </Typography>
        </FiltersSortHeaderStaticColumn>
        <FiltersSortHeaderStaticColumn>
          <Typography tag="span" variant="p-t6">
            Size
          </Typography>
        </FiltersSortHeaderStaticColumn>
      </FiltersSortHeaderWrapper>
      <Box direction="column">
        {version.artifacts.map((artifact) => (
          <Box
            className={styles.tableRow}
            key={artifact.filename}
            grid
            gap="large"
            gridTemplate="minmax(40rem, 7fr) minmax(5rem, 1fr) 2rem"
          >
            <Box align="center" gap="medium">
              <Icon src={File} />
              <Typography tag="span" variant="p-body2">
                {artifact.filename}
              </Typography>
            </Box>
            <Box align="center">
              <Typography tag="span" variant="p-body2">
                {prettyBytes(artifact.sizeBytes)}
              </Typography>
            </Box>
            <Box align="center">
              <ButtonIcon
                onClick={() => handleDownloadArtifact(artifact)}
                variant="secondary"
                size="small"
                disabled={loading}
                icon={Download}
                tooltipPlacement="left"
              >
                Download artifact
              </ButtonIcon>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default ProviderVersionArtifacts;
