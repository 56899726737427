import DrawerBody from "ds/components/Drawer/Body";
import { isSaasDistribution, isSelfHostedDistribution } from "utils/distribution";

import AccountDetailsSaas from "./Saas";
import AccountDetailsSelfHosted from "./SelfHosted";

const isSelfHosted = isSelfHostedDistribution();
const isSaas = isSaasDistribution();

const AccountDetailsDrawerContent = () => {
  return (
    <DrawerBody fullHeight>
      {isSaas && <AccountDetailsSaas />}
      {isSelfHosted && <AccountDetailsSelfHosted />}
    </DrawerBody>
  );
};

export default AccountDetailsDrawerContent;
