import { useCallback, useEffect, useRef, useState, KeyboardEvent } from "react";
import cx from "classnames";
import { useMergeRefs } from "@floating-ui/react";
import { usePress, useKeyboard, useFocus } from "react-aria";
import { mergeProps } from "@react-aria/utils";

import { ChevronRight } from "components/icons/generated";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import ButtonIcon from "ds/components/ButtonIcon";

import { BaseTreeListItemProps } from "../types";
import { TreeListHighlightLabel } from "../HighlightLabel";
import styles from "./styles.module.css";

const TreeListItem = <T extends object>({
  id,
  name,
  isExpanded,
  isDisabled,
  searchQuery,
  postfix,
  selected,
  innerRef,
  hasFocus,
  onToggle,
  onFocus,
  onBlur,
  onKeyDown,
  onClick,
}: BaseTreeListItemProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const mergedRef = useMergeRefs([innerRef, ref]);
  const [isFocusHidden, setIsFocusHidden] = useState(false);

  const handleToggleIconPressEnd = useCallback(() => {
    setIsFocusHidden(true);
    onFocus?.();
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    setIsFocusHidden(false);
    onBlur?.();
  }, [onBlur]);

  const handleKeyDown = useCallback((e: KeyboardEvent) => onKeyDown?.(e, id), [id, onKeyDown]);

  const handleKeyup = useCallback(() => {
    setIsFocusHidden(false);
  }, []);

  const { focusProps } = useFocus({
    onBlur: handleBlur,
  });

  const { pressProps: wrapperPressProps } = usePress({
    onPress: onClick,
    isDisabled,
  });

  const { keyboardProps } = useKeyboard({
    onKeyDown: handleKeyDown,
    onKeyUp: handleKeyup,
  });

  const { pressProps: togglePressProps } = usePress({
    onPressEnd: handleToggleIconPressEnd,
    onPress: onToggle,
    preventFocusOnPress: true,
  });

  useEffect(() => {
    const elementRef = ref.current;
    if (hasFocus && elementRef) {
      elementRef.focus();

      if (!isFocusHidden) {
        elementRef.scrollIntoView({ block: "center", inline: "nearest" });
      }
    }
  }, [hasFocus, isFocusHidden]);

  return (
    <Box
      {...mergeProps(focusProps, keyboardProps, wrapperPressProps)}
      fullWidth
      className={cx(styles.treeListItem, {
        [styles.selected]: selected,
        [styles.disabled]: isDisabled,
        [styles.focusHidden]: isFocusHidden,
      })}
      ref={mergedRef}
      tabIndex={-1}
      role="treeitem"
      aria-selected={selected}
      aria-disabled={isDisabled}
      aria-expanded={isExpanded}
    >
      {!!onToggle && (
        <ButtonIcon
          {...togglePressProps}
          icon={ChevronRight}
          iconRotate={isExpanded ? "90" : undefined}
          className={styles.toggle}
          aria-hidden
          variant="ghost"
          tabIndex={-1}
        >
          Toggle group
        </ButtonIcon>
      )}

      <Box align="center" className={styles.content} fullWidth>
        <TextEllipsis
          tooltip={name}
          tooltipPlacement="top-end"
          tooltipWidthMode="maxWidthXl"
          delay={400}
        >
          {(ellipsisProps) => (
            <Typography
              {...ellipsisProps}
              variant="p-body3"
              tag="span"
              className={cx(ellipsisProps.className, styles.name)}
            >
              {isDisabled ? name : <TreeListHighlightLabel query={searchQuery} label={name} />}
            </Typography>
          )}
        </TextEllipsis>
        {postfix && (
          <Box className={styles.postfix} shrink="0">
            {postfix}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TreeListItem;
