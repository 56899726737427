import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useModal } from "@ebay/nice-modal-react";

import ButtonNew from "ds/components/Button/New";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerBody from "ds/components/Drawer/Body";
import Select from "ds/components/Select";
import FormField from "ds/components/Form/Field";
import FormToggleField from "ds/components/Form/ToggleField";
import Textarea from "ds/components/Textarea";
import Input from "ds/components/Input";
import { stringIsRequired, validateRequiredURL } from "utils/formValidators";
import { createDefaultMutationHook } from "hooks/useMutationHandler";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import setLocation from "shared/setLocation";

import { SAML_NAME_ID_OPTIONS } from "../constants";
import { formDefaultValues, type SettingsSingleSignOnSAMLFormFields } from "./helpers";
import { CREATE_SSO_SAML } from "./gql";
import SettingsSingleSignOnConfigInfoSAML from "../../components/ConfigInfoSAML";

const useSAMLCreate = createDefaultMutationHook(CREATE_SSO_SAML);

const SettingsSingleSignOnCreateSAML = () => {
  const { reportSuccess } = useTypedContext(FlashContext);
  const modal = useModal();

  const [createSAML, { loading }] = useSAMLCreate({
    refetchQueries: ["GetSSOSettings"],
  });

  const {
    register,
    formState: { errors, isDirty },
    control,
    watch,
    handleSubmit,
    trigger,
  } = useForm<SettingsSingleSignOnSAMLFormFields>({
    mode: "onChange",
    defaultValues: formDefaultValues,
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationSAML,

    defaultCallbackTrackProperties: {
      method: "SAML",
    },
  });

  const watchDynamicFieldValue = watch("dynamic");
  const watchMetadataFieldValue = watch("metadata");

  const onSubmit = handleSubmit((data) => {
    trackSegmentAnalyticsEvent("Set Up Save");

    createSAML(
      { dynamic: data.dynamic, metadata: data.metadata, nameIDFormat: data.nameIDFormat },
      (data) => {
        if (data?.samlCreate) {
          // FYI: `/saml/start` is BE endpoint, do not replace it with "useNavigate", it works only with navigating to the external URL
          setLocation("/saml/start");
          reportSuccess({ message: "SAML successfully enabled" });
          modal.hide();
        }
      }
    );
  });

  useEffect(() => {
    if (isDirty && !!watchMetadataFieldValue) {
      trigger("metadata");
    }
  }, [isDirty, trigger, watchDynamicFieldValue, watchMetadataFieldValue]);

  return (
    <>
      <DrawerBody hasDivider gap="x-large">
        <SettingsSingleSignOnConfigInfoSAML />
      </DrawerBody>
      <form onSubmit={onSubmit}>
        <DrawerBody gap="x-large">
          <Controller
            name="nameIDFormat"
            control={control}
            rules={{ required: "NameID format is required" }}
            render={({ field, fieldState }) => (
              <FormField label="NameID format" error={fieldState.error?.message} noMargin>
                {({ ariaInputProps }) => (
                  <Select
                    options={SAML_NAME_ID_OPTIONS}
                    value={field.value}
                    onChange={field.onChange}
                    error={!!fieldState.error?.message}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            )}
          />

          <Controller
            name="dynamic"
            control={control}
            render={({ field }) => (
              <FormToggleField
                title="Dynamic configuration"
                variant="switch"
                onChange={field.onChange}
                checked={field.value}
              />
            )}
          />

          {watchDynamicFieldValue ? (
            <FormField label="IdP metadata URL" error={errors?.metadata?.message} noMargin>
              {({ ariaInputProps }) => (
                <Input
                  type="url"
                  placeholder="https://"
                  error={!!errors?.metadata}
                  {...register("metadata", {
                    validate: validateRequiredURL(),
                    setValueAs: (value: string) => value.trim(),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          ) : (
            <FormField label="IdP metadata" error={errors?.metadata?.message} noMargin>
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Static XML identity provider metadata"
                  error={!!errors?.metadata}
                  {...register("metadata", {
                    validate: stringIsRequired(),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          )}
        </DrawerBody>

        <DrawerFooter fullWidth>
          <DrawerFooterActions>
            <DrawerCancelButton onClick={() => trackSegmentAnalyticsEvent("SAML Set Up Cancel")} />
            <ButtonNew variant="primary" type="submit" loading={loading}>
              Save
            </ButtonNew>
          </DrawerFooterActions>
        </DrawerFooter>
      </form>
    </>
  );
};

export default SettingsSingleSignOnCreateSAML;
