import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import EmptyState from "ds/components/EmptyState";
import { EmptystateStacksColored } from "components/icons/generated";
import DocumentationButton from "components/DocumentationButton";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/Account/SpacesProvider";
import { getDocsUrl } from "utils/getDocsUrl";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";

import { Widget } from "../types";

type DashboardWidgetsEmptyStateStacksProps = {
  widget?: Widget;
};

const DashboardWidgetsEmptyStateStacks = ({ widget }: DashboardWidgetsEmptyStateStacksProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  return (
    <TileWrapper>
      <Box padding="small" fullWidth justify="center">
        <EmptyState
          title="You don’t have any stacks yet"
          icon={EmptystateStacksColored}
          caption="Spacelift is no fun without stacks and you haven't created one yet. Create one now, or learn more from our documentation."
        >
          <Box gap="medium">
            <DocumentationButton
              analyticsPage={AnalyticsPageDashboard.Dashboard}
              analyticsTitle="Widget - Documentation Clicked"
              analyticsProps={{ widget }}
              to={getDocsUrl("/concepts/stack")}
              label="Documentation"
            />
            {hasEntityCreateAccess && (
              <Button variant="primary" to="/new/stack">
                Create stack
              </Button>
            )}
          </Box>
        </EmptyState>
      </Box>
    </TileWrapper>
  );
};

export default DashboardWidgetsEmptyStateStacks;
