import useTypedContext from "hooks/useTypedContext";
import StackDetails from "views/Stack/components/Details";
import { showFullDescription } from "ds/components/FullDescriptionDrawer";
import { StackContext } from "views/Stack/Context";

import { StackSettingsContextData } from "../Context";

const StackSettingsDetailsView = () => {
  const { canManageSpace } = useTypedContext(StackContext);

  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const onOpenFullDescription = () => {
    showFullDescription({
      description: stackSettings.description,
    });
  };

  return (
    <StackDetails
      stack={stackSettings}
      onOpenFullDescription={onOpenFullDescription}
      canManageSpace={canManageSpace}
    />
  );
};

export default StackSettingsDetailsView;
