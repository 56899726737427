import { ReactNode } from "react";
import cx from "classnames";

import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import Tooltip from "ds/components/Tooltip";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";

type StackListItemValueProps = {
  tooltip?: ReactNode;
  children?: ReactNode;
  icon?: IconComponent;
};

const StackListItemValue = ({ tooltip, children, icon }: StackListItemValueProps) => {
  return (
    <Tooltip
      active={!!tooltip}
      on={(tooltipProps) => (
        <Box>
          <Box
            {...tooltipProps}
            justify="start"
            className={cx(styles.textContainer, tooltipProps.className)}
            gap="small"
          >
            {icon && (
              // Div wrapper to keep icon width under Box component
              <div>
                <Icon src={icon} />
              </div>
            )}
            {children && (
              <TextEllipsis tooltipPlacement="bottom" tooltip={children}>
                {(props) => (
                  <Typography {...props} tag="p" variant="p-body3">
                    {children}
                  </Typography>
                )}
              </TextEllipsis>
            )}
          </Box>
        </Box>
      )}
    >
      {tooltip}
    </Tooltip>
  );
};

export default StackListItemValue;
