import { CodeBrowser } from "components/icons/generated";
import { Blueprint } from "types/generated";

import MetaInfoListItem from "./Item";

type MetaInfoBlueprintProps = {
  blueprint: Blueprint;
};

const MetaInfoBlueprint = ({ blueprint }: MetaInfoBlueprintProps) => {
  return (
    <MetaInfoListItem
      href={`/blueprint/${blueprint.ulid}`}
      linkText={blueprint.name}
      icon={CodeBrowser}
      tooltip={`Stack created from blueprint ${blueprint.name}`}
      type="commit"
    />
  );
};

export default MetaInfoBlueprint;
