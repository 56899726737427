import Button from "ds/components/Button";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";

type MFASettingsSSOCalloutProps = {
  isAdmin: boolean;
};

const MFASettingsSSOCallout = ({ isAdmin }: MFASettingsSSOCalloutProps) => {
  const title = isAdmin ? "SSO not enabled" : "Setup SSO";

  const description = `To use Multi-Factor Authentication (MFA) on your account, ${isAdmin ? "please ensure that the Single Sign-On (SSO) feature is set up and enabled first." : "please ask your admin to set up and enable the Single Sign-On (SSO) feature first."}`;

  const action = isAdmin ? (
    <Button variant="contrast" size="small" to="/settings/sso">
      Setup SSO
    </Button>
  ) : undefined;

  return (
    <Callout variant="warning" title={title}>
      {description}
      {!!action && <FeedbackActions>{action}</FeedbackActions>}
    </Callout>
  );
};

export default MFASettingsSSOCallout;
