import { useCallback, useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import cx from "classnames";

import ButtonIcon from "ds/components/ButtonIcon";
import { ManagedEntity, Run, Stack } from "types/generated";
import { Cross } from "components/icons/generated";
import Box from "ds/components/Box";
import { VENDOR_CONFIG_TYPENAME } from "constants/vendor";

import styles from "./styles.module.css";
import Entity from "./Entity";
import { ENTITIES_LABELS } from "./helpers";

import "views/Account/Resources/Sidebar/styles.css";

const entitiesOrder = [
  "url",
  "id",
  "title",
  "stack",
  "module",
  "branch",
  "labels",
  "type",
  "state",
  "hash",
  "message",
  "address",
  "authorLogin",
  "authorName",
  "createdAt",
  "commit",
  "delta",
  "repository",
  "runtimeConfig",
  "driftDetection",
  "timestamp",
  "user",
];

type ManagedEntityProps = Record<string, string | undefined> & {
  stack?: Partial<Stack>;
  runtimeConfig?: Partial<Run["runtimeConfig"]>;
};

export type ResourcesSideBarProps = {
  entityDetails: ManagedEntity & ManagedEntityProps;
  fullScreen: boolean;
  handleFilterNewChange: (key: string, value: string) => void;
  setMenuVisible: (visible: boolean) => void;
  setZoomTarget: (id: string | null) => void;
  isVisible: boolean;
};

const ResourcesSideBar = (props: ResourcesSideBarProps) => {
  const {
    entityDetails,
    fullScreen,
    handleFilterNewChange,
    setMenuVisible,
    setZoomTarget,
    isVisible,
  } = props;
  const wrapperRef = useRef(null);

  const closeMenu = useCallback(() => {
    setMenuVisible(false);
    d3.select(".d3-run-entity-selected").classed("d3-run-entity-selected", false);
    setZoomTarget(null);
  }, [setMenuVisible, setZoomTarget]);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        closeMenu();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [closeMenu]);

  const entities = useMemo(
    () =>
      Object.keys(entityDetails)
        .map((key) => {
          if (key === "runtimeConfig") {
            const entity = (entityDetails[key] && { ...entityDetails[key] }) || undefined;
            const vendor = entityDetails.stack?.vendorConfig?.__typename;

            if (
              vendor !== VENDOR_CONFIG_TYPENAME.TERRAFORM &&
              // HACK: This is a temporary fix to showing the Terraform version and workflow tool for old Terragrunt runs
              !(vendor === VENDOR_CONFIG_TYPENAME.TERRAGRUNT && !entity?.terragrunt)
            ) {
              delete entity?.terraform;
            }

            if (
              vendor !== VENDOR_CONFIG_TYPENAME.TERRAGRUNT ||
              // HACK: This is a temporary fix to showing the Terraform version and workflow tool for old Terragrunt runs
              (vendor === VENDOR_CONFIG_TYPENAME.TERRAGRUNT && !entity?.terragrunt)
            ) {
              delete entity?.terragrunt;
            }

            return { key, entity };
          }

          return { key: key, entity: entityDetails[key] };
        })
        .sort((a, b) => entitiesOrder.indexOf(a.key) - entitiesOrder.indexOf(b.key)),
    [entityDetails]
  );

  const sidebarClass = cx("resources-sidebar", {
    "resources-sidebar--visible": isVisible,
    "resources-sidebar--fullscreen": fullScreen,
  });

  return (
    <div ref={wrapperRef} className={sidebarClass}>
      <Box relative padding="large">
        <ButtonIcon className={styles.closeAction} icon={Cross} onClick={closeMenu} variant="ghost">
          Close
        </ButtonIcon>
      </Box>
      {entities
        .filter((item) => Object.keys(ENTITIES_LABELS).includes(item.key))
        .map((item, index) => (
          <Entity
            entity={entityDetails}
            item={item}
            key={`${item.key}-${index}`}
            handleFilterNewChange={handleFilterNewChange}
          />
        ))}
    </div>
  );
};

export default ResourcesSideBar;
