import { ReactNode } from "react";

import Tooltip from "ds/components/Tooltip";
import Icon from "ds/components/Icon";
import { InfoCircle } from "components/icons/generated";

import styles from "./styles.module.css";

type OutputReferencesTooltipWrapperProps = {
  children: ReactNode;
};

const OutputReferencesTooltipWrapper = ({ children }: OutputReferencesTooltipWrapperProps) => {
  return (
    <Tooltip on={(props) => <Icon {...props} src={InfoCircle} className={styles.infoIcon} />}>
      {children}
    </Tooltip>
  );
};

export default OutputReferencesTooltipWrapper;
