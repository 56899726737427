import { isSelfHostedDistribution } from "utils/distribution";
import useTypedContext from "hooks/useTypedContext";

import { SelfHostedInstanceContext } from "./Context";
import { STORAGE_KEY_SELF_HOSTED_IS_ADMIN_USER_LOGGED_IN } from "../constants";

const isSelfHosted = isSelfHostedDistribution();

/**
 * @description SelfHosted only,
 * * the "gate" has the aim to prevent access to the app till the infrastructure admin user not created an account for the app.
 * * the "gate" has 3 cases:
 * * - close the "gate" to force logging the infrastructure admin user (not an app user)
 * * - close the "gate" to create an account for the app
 * * - open the "gate" when the account has been created
 */
const useInstanceProviderGateRules = () => {
  const { instanceInfo } = useTypedContext(SelfHostedInstanceContext);

  /**
   * * FYI: ¯\_(ツ)_/¯ this authorization flow is okay for the inner SH installation flow, don't use it for SaaS
   */
  const isAdminUserAuthorized =
    sessionStorage.getItem(STORAGE_KEY_SELF_HOSTED_IS_ADMIN_USER_LOGGED_IN) === "true";

  const isInstanceInfoLoading = isSelfHosted && !instanceInfo;

  const shouldLogin =
    isSelfHosted &&
    instanceInfo &&
    !isAdminUserAuthorized &&
    instanceInfo.isAdminLoginEnabled === true;

  // FYI: could be true only in case of shouldLogin is false
  const shouldCreateAccount =
    isSelfHosted &&
    !shouldLogin &&
    instanceInfo &&
    isAdminUserAuthorized &&
    instanceInfo.hasAccountBeenCreated === false;

  const isSelfHostedInstalled =
    isSelfHosted && instanceInfo && instanceInfo.hasAccountBeenCreated === true;

  return {
    isInstanceInfoLoading,
    shouldLogin,
    shouldCreateAccount,
    isSelfHostedInstalled,
    isAdminLoginEnabled: isSelfHosted && instanceInfo?.isAdminLoginEnabled === true,
  };
};

export default useInstanceProviderGateRules;
