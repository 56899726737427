import { useQuery } from "@apollo/client";
import { Route, Routes, useParams, useResolvedPath } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import { AccountContext } from "views/AccountWrapper";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import { Context as ContextType } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import { hasSpaceManageAccess, hasSpaceManageOrWriteAccess } from "utils/user";
import { getBreadcrumbsBackUrl } from "components/Breadcrumbs/helpers";

import { FILTERS_ORDER_SETTINGS_KEY } from "../Contexts/constants";
import { GET_CONTEXT } from "./gql";
import { ContextContext } from "./Context";
import ContextVariables from "./Variables";
import ContextMountedFiles from "./MountedFiles";
import ContextHeader from "./Header";
import ContextUsedBy from "./UsedBy";
import ContextHooks from "./Hooks";

const Context = () => {
  const resolvedPath = useResolvedPath(".");
  const { viewer } = useTypedContext(AccountContext);
  const { contextId } = useParams<{ contextId: string }>();
  const { onError } = useTypedContext(FlashContext);

  const { error, loading, data } = useQuery<{ context: ContextType }>(GET_CONTEXT, {
    onError,
    variables: { contextId },
  });

  useBreadcrumbs(
    [
      {
        title: "Contexts",
        link: getBreadcrumbsBackUrl("/contexts", FILTERS_ORDER_SETTINGS_KEY),
      },
      {
        title: data?.context?.name || "",
      },
    ],
    [data?.context?.name]
  );

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.context) {
    return <PageLoading />;
  }

  if (!data?.context) {
    return <NotFoundPage />;
  }

  const contextData = {
    context: data.context,
    contextUrl: resolvedPath.pathname,
    canManageSpace: hasSpaceManageAccess(data.context?.spaceDetails?.accessLevel),
    canManageContext:
      viewer?.admin || hasSpaceManageAccess(data.context?.spaceDetails?.accessLevel),
    hasAtLeastWriteAccess: hasSpaceManageOrWriteAccess(data.context?.spaceDetails?.accessLevel),
    isContextLoading: loading,
  };

  return (
    <ContextContext.Provider value={contextData}>
      <ContextHeader />
      <Routes>
        <Route index element={<ContextVariables />} />

        <Route path="files" element={<ContextMountedFiles />} />

        <Route path="used-by" element={<ContextUsedBy />} />

        <Route path="hooks" element={<ContextHooks />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ContextContext.Provider>
  );
};

export default Context;
