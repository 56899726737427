import { useFormContext } from "react-hook-form";

import CardWrapper from "components/CardWrapper";
import SegmentedControl from "ds/components/SegmentedControl";

import { SCHEDULE_TYPES } from "./constants";
import FormFieldCronExpressions from "../CronExpressionsField";
import FormFieldTimezone from "../Timezone";
import FormFieldDateTime from "../DateTime";
import { SCHEDULE_TYPE } from "./types";

type FormFieldSegmentedScheduleProps = {
  minDate?: Date;
  recurringFieldName?: string;
  cronScheduleFieldName?: string;
  timezoneFieldName?: string;
  dateTimeFieldName?: string;
};

type TFieldValues = Record<string, string | boolean | Date>;

const FormFieldSegmentedSchedule = ({
  minDate,
  recurringFieldName = "recurring",
  cronScheduleFieldName = "cronSchedule",
  dateTimeFieldName = "dateTime",
  timezoneFieldName = "timezone",
}: FormFieldSegmentedScheduleProps) => {
  const { watch, setValue } = useFormContext<TFieldValues>();
  const isRecurring = watch(recurringFieldName);

  const handleIntegrationTypeChange = (value: SCHEDULE_TYPE) => {
    setValue(recurringFieldName, value === SCHEDULE_TYPE.RECURRING, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <CardWrapper variant="filled" direction="column" gap="x-large">
      <SegmentedControl
        value={isRecurring ? SCHEDULE_TYPE.RECURRING : SCHEDULE_TYPE.SPECIFIC_TIME}
        options={SCHEDULE_TYPES}
        onChange={handleIntegrationTypeChange}
      />

      {isRecurring && (
        <>
          <FormFieldCronExpressions name={cronScheduleFieldName} />
          <FormFieldTimezone name={timezoneFieldName} />
        </>
      )}

      {!isRecurring && <FormFieldDateTime name={dateTimeFieldName} minDate={minDate} />}
    </CardWrapper>
  );
};

export default FormFieldSegmentedSchedule;
