import {
  ComponentType,
  useMemo,
  useRef,
  useEffect,
  RefObject,
  MutableRefObject,
  useCallback,
} from "react";
import { ListChildComponentProps, ListProps, VariableSizeList } from "react-window";

import { useHideOnScrollContext } from "components/HideOnScroll/HideOnScrollContext";

import { TreeChartVirtualizedItemProps } from "./Virtualized";

const LOADING_ROW_HEIGHT = 60;

export type ListEntitiesBaseItemProps = {
  refreshItemsHeight: () => void;
};

type TreeChartEntityProps<T extends string> = {
  itemProps: TreeChartVirtualizedItemProps<T>;
  virtualizedItem: ComponentType<
    ListChildComponentProps<TreeChartVirtualizedItemProps<T> & ListEntitiesBaseItemProps>
  >;
  itemKey: (index: number) => string;
  itemCount: number;
  onItemsRendered?: ListProps["onItemsRendered"];
  indexToScroll?: number;
  listClassName?: string;
  height: number;
  width: number;
  hasNextPageToLoad?: boolean;

  /**
   * Allows to inject things into the list scrolling container (like sticky elements)
   */
  outerContainerRef?: RefObject<HTMLElement>;
};

const TreeChartEntity = <T extends string>({
  itemProps,
  virtualizedItem,
  itemKey,
  itemCount,
  onItemsRendered,
  indexToScroll,
  listClassName,
  outerContainerRef,
  hasNextPageToLoad,
  height,
  width,
}: TreeChartEntityProps<T>) => {
  const listRef = useRef<VariableSizeList>(null);
  const { setScrollableElRef } = useHideOnScrollContext();

  const handleRefs = useCallback(
    (value: HTMLElement) => {
      setScrollableElRef(value);
      if (outerContainerRef) {
        (outerContainerRef as MutableRefObject<HTMLElement>).current = value;
      }
    },
    [outerContainerRef, setScrollableElRef]
  );

  const getRowHeight = useCallback(
    (index: number) => {
      const current = itemProps.items[index];
      const next = itemProps.items[index + 1];

      if (!current) {
        return LOADING_ROW_HEIGHT;
      }

      if (next) {
        return next.item.position.y - current.item.position.y;
      }

      return itemProps.nodeTypes[current.item.type].height(current.item);
    },
    [itemProps.items, itemProps.nodeTypes]
  );

  const itemData = useMemo(
    () => ({
      ...itemProps,
      refreshItemsHeight: () => listRef.current?.resetAfterIndex(0),
    }),
    [itemProps]
  );

  useEffect(() => {
    if (listRef?.current && (indexToScroll || indexToScroll === 0)) {
      listRef.current.scrollToItem(indexToScroll, "center");
    }
  }, [listRef, indexToScroll]);

  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [itemCount]);

  return (
    <VariableSizeList<TreeChartVirtualizedItemProps<T> & ListEntitiesBaseItemProps>
      innerElementType="svg"
      itemKey={itemKey}
      itemData={itemData}
      itemCount={itemCount + (hasNextPageToLoad ? 1 : 0)}
      estimatedItemSize={60}
      itemSize={getRowHeight}
      ref={listRef}
      outerRef={handleRefs}
      width={width}
      height={height}
      onItemsRendered={onItemsRendered}
      className={listClassName}
    >
      {virtualizedItem}
    </VariableSizeList>
  );
};

export default TreeChartEntity;
