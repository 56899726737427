import {
  EmptystateStacksColored,
  EmptystateSearchNoResultsColored,
} from "components/icons/generated";
import Box from "ds/components/Box";
import EmptyState from "ds/components/EmptyState";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Button from "ds/components/Button";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

type StacksEmptyProps = {
  hasNoResults?: boolean;
};

const StacksEmpty = ({ hasNoResults }: StacksEmptyProps) => {
  const { viewer } = useTypedContext(AccountContext);

  return (
    <Box align="center" justify="center" grow="1" className={styles.emptyContainer}>
      {hasNoResults && (
        <EmptyState
          title="No results"
          icon={EmptystateSearchNoResultsColored}
          caption={`Don’t give up! Check the spelling or try something less specific.`}
        />
      )}
      {!hasNoResults && viewer.admin && (
        <EmptyState
          title="Spacelift is no fun without stacks and you haven't created one yet"
          icon={EmptystateStacksColored}
        >
          <Box gap="medium">
            <DocumentationButton to={getDocsUrl("/concepts/stack")} label="Documentation" />
            <Button variant="primary" to="/new/stack">
              Create stack
            </Button>
          </Box>
        </EmptyState>
      )}
      {!hasNoResults && !viewer.admin && (
        <EmptyState
          title="Spacelift is no fun without stacks and you don't have access to any yet"
          icon={EmptystateStacksColored}
          caption="Please reach out to one of your friendly account admins to grant you access, or create a
        new stack for you to play with."
        />
      )}
    </Box>
  );
};

export default StacksEmpty;
