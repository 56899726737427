import { ReactNode } from "react";
import cx from "classnames";

import { InfoCircle, ExclamationMarkCircle, TickCircle, Diamond } from "components/icons/generated";
import { IconComponent } from "types/Icon";

import { FeedbackType, FeedbackVariant } from "./types";
import Icon from "../Icon";
import Box, { BoxProps } from "../Box";
import styles from "./styles.module.css";

type FeedbackWrapperProps = {
  variant: FeedbackVariant;
  children?: ReactNode;
  className?: string;
  suffix?: ReactNode;
  type: FeedbackType;
  fullWidth?: boolean;
  margin?: BoxProps["margin"];
  icon?: IconComponent;
};

const feedbackIcons = {
  info: InfoCircle,
  warning: ExclamationMarkCircle,
  danger: ExclamationMarkCircle,
  success: TickCircle,
  promo: Diamond,
};

const FeedbackWrapper = ({
  suffix,
  className,
  variant,
  children,
  type,
  fullWidth,
  margin,
  icon,
}: FeedbackWrapperProps) => {
  return (
    <Box
      className={cx(styles.feedback, styles[variant], styles[type], className)}
      fullWidth={fullWidth}
      margin={margin}
    >
      <Icon src={icon || feedbackIcons[variant]} className={cx(styles.icon, styles[variant])} />

      <Box direction="column" grow="1" zeroMinWidth>
        {children}
      </Box>
      {suffix}
    </Box>
  );
};

export default FeedbackWrapper;
