import { ForwardedRef, forwardRef } from "react";

type AriaOnlyTextProps = {
  children: string;
  tabIndex?: number;
};

export const AriaOnlyText = forwardRef(function AriaOnlyText(
  { children, tabIndex }: AriaOnlyTextProps,
  ref: ForwardedRef<HTMLSpanElement>
) {
  return (
    <span ref={ref} className="sr-only" tabIndex={tabIndex}>
      {children}
    </span>
  );
});
