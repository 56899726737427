import { NetworkStatus, useQuery } from "@apollo/client";
import { useMemo } from "react";

import { LogoAwsColored, LogoMsAzureColored } from "components/icons/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { StackCreationCloud, StackCreationWizardStep } from "../../types";
import { GET_ATTACHABLE_CLOUD_INTEGRATIONS } from "../gql";
import { GetCloudIntegrationsGql } from "../types";
import { StackFormContext } from "../../context";

const cloudConfig = [
  {
    type: StackCreationCloud.AWS,
    name: "AWS",
    logo: LogoAwsColored,
  },
  {
    type: StackCreationCloud.Azure,
    name: "Azure",
    logo: LogoMsAzureColored,
  },
];

const useCloudConfig = () => {
  const { onError } = useTypedContext(FlashContext);
  const { formData } = useTypedContext(StackFormContext);
  const spaceId = formData[StackCreationWizardStep.Details].space;

  const { loading, data, refetch, networkStatus, error } = useQuery<GetCloudIntegrationsGql>(
    GET_ATTACHABLE_CLOUD_INTEGRATIONS,
    {
      onError,
      variables: {
        spaceId,
      },
    }
  );

  const attachableAwsIntegrations = useMemo(() => {
    return (data?.attachableAwsIntegrationsInSpace || [])
      .filter((integration) => !integration.legacy)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [data?.attachableAwsIntegrationsInSpace]);

  const attachableAzureIntegrations = useMemo(() => {
    return Array.from(data?.attachableAzureIntegrationsInSpace || []).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [data?.attachableAzureIntegrationsInSpace]);

  const cloudConfigFiltered = useMemo(
    () =>
      cloudConfig.filter(({ type }) => {
        if (type === StackCreationCloud.AWS) {
          return attachableAwsIntegrations.length !== 0;
        }

        if (type === StackCreationCloud.Azure) {
          return attachableAzureIntegrations.length !== 0;
        }
        return true;
      }),

    [attachableAwsIntegrations, attachableAzureIntegrations]
  );

  const hasData =
    !!data?.attachableAwsIntegrationsInSpace && !!data?.attachableAzureIntegrationsInSpace;

  return {
    cloudConfig: cloudConfigFiltered,
    loading,
    attachableAwsIntegrations,
    attachableAzureIntegrations,
    refetch,
    refetching: networkStatus === NetworkStatus.refetch,
    hasData,
    error,
  };
};

export default useCloudConfig;
