import cx from "classnames";

import { Cross, Tick } from "components/icons/generated";

import MetaInfoListItem from "./Item";
import styles from "./styles.module.css";

type MetaInfoBooleanProps = {
  text: string;
  value: boolean;
};

const MetaInfoBoolean = (props: MetaInfoBooleanProps) => {
  const { text, value } = props;

  return (
    <MetaInfoListItem
      icon={value ? Tick : Cross}
      className={cx(value ? styles.success : styles.danger)}
    >
      {text}
    </MetaInfoListItem>
  );
};

export default MetaInfoBoolean;
