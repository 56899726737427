import { ReactNode, useState } from "react";

import Filters from "components/Filters";
import {
  FilterItem,
  FiltersItemsOptionsMap,
  SavedFilterView,
  SortOption,
} from "components/Filters/types";
import FiltersSplit from "components/Filters/Split";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import PageInfo from "components/PageWrapper/Info";
import Box from "ds/components/Box";
import PaginationIndicator from "components/PaginationIndicator";
import SearchInput from "components/SearchInput";
import { AnalyticsPageWorkerPool } from "hooks/useAnalytics/pages/workerPool";
import PrivateWorkersTierInfo from "views/Account/PrivateWorkersTierInfo";

import {
  FILTER_ITEMS_DICTIONARY,
  FILTERS_ORDER_SETTINGS_KEY,
  FILTERS_TYPE,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
} from "../constants";
import WorkerPoolQueuedRunsFiltersLayoutHeader from "./Header";

type WorkerPoolQueuedRunsFiltersLayoutProps = {
  children: ReactNode;
  filters: FilterItem[];
  filtersMap: FiltersItemsOptionsMap;
  sortOptions: SortOption[];
  isPageEmpty: boolean;
  renderedEntitiesCount: number;
  filtersLoading: boolean;
  loadingIndication: boolean;
  refetchActiveSections: (fields: string[]) => void;
  filteredCount: number;
  isPublic?: boolean;
  allSelected: boolean;
  onSelectAll: () => void;
  onResetAll: () => void;
};

const WorkerPoolQueuedRunsFiltersLayout = ({
  children,
  filters,
  filtersMap,
  sortOptions,
  isPageEmpty,
  renderedEntitiesCount,
  filtersLoading,
  loadingIndication,
  refetchActiveSections,
  filteredCount,
  isPublic,
  allSelected,
  onSelectAll,
  onResetAll,
}: WorkerPoolQueuedRunsFiltersLayoutProps) => {
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);

  return (
    <>
      <PageInfo title="Queue">
        {!isPageEmpty && (
          <Box direction="row" align="center" gap="0 large">
            <PaginationIndicator
              currentCount={renderedEntitiesCount}
              totalCount={filteredCount}
              loading={loadingIndication}
              minimumLoadingDuration={200}
            />

            <SearchInput
              placeholder="Search by stack name"
              filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
            />
          </Box>
        )}
      </PageInfo>

      {!isPublic && <PrivateWorkersTierInfo />}

      <Filters
        filters={filters}
        filtersItemsOptionsMap={filtersMap}
        filtersLoading={filtersLoading}
        sortOptions={sortOptions}
        initialSortOption={INITIAL_SORT_OPTION}
        initialSortDirection={INITIAL_SORT_DIRECTION}
        pollActiveSections={refetchActiveSections}
        filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        filtersType={FILTERS_TYPE}
        currentSavedView={currentSavedView}
        setCurrentSavedView={setCurrentSavedView}
        filtersDictionary={FILTER_ITEMS_DICTIONARY}
      >
        <FiltersSplit>
          <FiltersSidebar
            analyticsPage={
              isPublic
                ? AnalyticsPageWorkerPool.WorkerPoolPublicQueue
                : AnalyticsPageWorkerPool.WorkerPoolQueue
            }
          />

          <FiltersContentWrapper>
            {!isPageEmpty && (
              <WorkerPoolQueuedRunsFiltersLayoutHeader
                isPublic={isPublic}
                allSelected={allSelected}
                onSelectAll={onSelectAll}
                onResetAll={onResetAll}
              />
            )}

            {children}
          </FiltersContentWrapper>
        </FiltersSplit>
      </Filters>
    </>
  );
};

export default WorkerPoolQueuedRunsFiltersLayout;
