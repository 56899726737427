import { useQuery } from "@apollo/client";
import { Routes, Route, useParams } from "react-router-dom";

import FlashContext from "components/FlashMessages/FlashContext";
import NotFoundPage from "components/error/NotFoundPage";
import PageLoading from "components/loading/PageLoading";
import useTypedContext from "hooks/useTypedContext";
import { SpaceAccessLevel } from "types/generated";
import useErrorHandle from "hooks/useErrorHandle";

import ModuleVersion from "./Version";
import ModuleVersions from "./Versions";
import ModuleEnvironment from "./Environment";
// import ModuleConsumers from "./Consumers";
import ModulePullRequests from "./PullRequests";
import ModuleNotifications from "./Notifications";
import ModuleMainLayout from "./components/MainLayout";
import ModuleRun from "./Run";
import ModulePolicies from "./Policies";
import { ModuleContext } from "./Context";
import ModuleContexts from "./Contexts";
import { ModuleGQL } from "./types";
import { GET_MODULE } from "./gql";

const Module = () => {
  const { onError } = useTypedContext(FlashContext);
  const { moduleId } = useParams<{ moduleId: string }>();

  const { data, error, loading } = useQuery<ModuleGQL>(GET_MODULE, {
    onError,
    variables: { id: moduleId },
    fetchPolicy: "no-cache",
  });

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data?.module) {
    return <PageLoading />;
  }

  if (!data?.module) {
    return <NotFoundPage />;
  }

  const canManageModule = data.module.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  const moduleContext = {
    module: data.module,
    canManageModule,
    moduleUrl: "",
  };

  return (
    <ModuleContext.Provider value={moduleContext}>
      <Routes>
        <Route element={<ModuleMainLayout />}>
          <Route index element={<ModuleVersions />} />
          <Route path="environment" element={<ModuleEnvironment />} />
          {/* <Route path="consumers" element={<ModuleConsumers />} /> */}
          <Route path="policies" element={<ModulePolicies />} />
          <Route path="contexts" element={<ModuleContexts />} />
          <Route path="prs" element={<ModulePullRequests />} />
          <Route path="notifications" element={<ModuleNotifications />} />
        </Route>

        <Route path="run/:runId" element={<ModuleRun />} />
        <Route path="version/:versionId/*" element={<ModuleVersion />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ModuleContext.Provider>
  );
};

export default Module;
