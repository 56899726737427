import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import SystemMessage from "components/SystemMessage";
import useTitle from "hooks/useTitle";
import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import { stringIsRequired } from "utils/formValidators";
import Input from "ds/components/Input";
import SecretInput from "ds/components/SecretInput";
import ButtonNew from "ds/components/Button/New";

type ApiKeyTokenFormFields = {
  id: string;
  secret: string;
};

const ApiKeyToken = () => {
  useTitle("Exchange API key · Spacelift");

  const navigate = useNavigate();
  const { reportError, reportSuccess } = useTypedContext(FlashContext);

  const [isLoading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ApiKeyTokenFormFields>({
    mode: "onChange",
  });

  const onSubmit = (formData: ApiKeyTokenFormFields) => {
    setLoading(true);

    const params = new URLSearchParams();
    params.append("id", formData.id);
    params.append("secret", formData.secret);

    fetch("/api_key_user", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    })
      .then(async (response) => {
        if (!response.ok) {
          const responseMessage = await response.text();

          return reportError({
            title: "Exchange API key",
            message: responseMessage || "Invalid credentials",
          });
        }

        navigate("/", { replace: true });
        reportSuccess({ message: "Logged in using an API key" });

        return undefined;
      })
      .catch(() => {
        reportError({ title: "Exchange API key", message: "Something went wrong" });
      })
      .finally(() => setLoading(false));
  };

  return (
    <SystemMessage title="Exchange API key for a token" message="Debug mode">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box direction="column" gap="xx-large">
          <Box direction="column" gap="x-large">
            <FormField label="API key ID" error={errors?.id?.message} noMargin>
              {({ ariaInputProps }) => (
                <Input
                  error={!!errors?.id}
                  {...register("id", {
                    validate: stringIsRequired("Username field is required."),
                    setValueAs: (value) => value.trim(),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
            <FormField label="API key secret" error={errors?.secret?.message} noMargin>
              {({ ariaInputProps }) => (
                <SecretInput
                  error={!!errors?.secret}
                  {...register("secret", {
                    validate: stringIsRequired("Secret field is required."),
                    setValueAs: (value) => value.trim(),
                  })}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </Box>

          <ButtonNew type="submit" variant="primary" loading={isLoading} fullWidth>
            Exchange
          </ButtonNew>
        </Box>
      </form>
    </SystemMessage>
  );
};

export default ApiKeyToken;
