import { useEffect } from "react";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import useTypedContext from "hooks/useTypedContext";
import { SelfHostedLicenseContext } from "views/SelfHosted/LicenseProvider/Context";

import AccountDetailsDrawerContent from "./Content";

const AccountDetailsDrawer = createDrawer(() => {
  const { refetch } = useTypedContext(SelfHostedLicenseContext);

  useEffect(() => {
    refetch?.();
  }, [refetch]);

  return (
    <DrawerSimple>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Account details" />
        <DrawerCloseIcon />
      </DrawerHeader>
      <AccountDetailsDrawerContent />
    </DrawerSimple>
  );
});

export const showAccountDetailsDrawer = createDrawerTrigger(AccountDetailsDrawer);
