export enum FilterItemKeys {
  NAME = "name",
}

export const FILTER_ITEMS_DICTIONARY: Partial<Record<FilterItemKeys, string>> = {
  [FilterItemKeys.NAME]: "name",
};

export const TASK_SEARCH_KEY = "task";

export const LOADING_TRESHOLD = 18;
