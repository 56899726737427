import cx from "classnames";
import { useCallback } from "react";

import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import Box from "ds/components/Box";
import ThemedLogs from "components/ThemedLogs";
import { prettify } from "views/shared/run/ResourcesDiff/helpers";
import ThemedLogsContent from "components/ThemedLogs/Content";
import { Expand } from "components/icons/generated";
import ButtonIconNew from "ds/components/ButtonIcon/New";
import DownloadFileButton from "components/DownloadFileButton";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import { ConfigNode } from "../../types";
import styles from "./styles.module.css";

type ConfigManagementTreeGridDrawerLogsProps = {
  task: ConfigNode;
  toggleFullScreen: (value?: boolean) => void;
  fileName: string;
  analyticsPage?: AnalyticsPage;
};

const ConfigManagementTreeGridDrawerLogs = ({
  task,
  toggleFullScreen,
  fileName,
  analyticsPage,
}: ConfigManagementTreeGridDrawerLogsProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const openFullScreen = useCallback(() => {
    toggleFullScreen(true);
    trackSegmentAnalyticsEvent("Logs fullscreen opened");
  }, [toggleFullScreen, trackSegmentAnalyticsEvent]);

  const downloadCallback = useCallback(() => {
    trackSegmentAnalyticsEvent("Logs downloaded");
  }, [trackSegmentAnalyticsEvent]);

  return task.logs ? (
    <FormField
      className={cx(styles.label, styles.logsField)}
      labelGrow="1"
      label={
        <Box fullWidth align="center" justify="between">
          Logs
          <Box align="center" gap="small">
            <DownloadFileButton
              value={task.logs}
              title="Download logs"
              fileName={fileName}
              variant="ghost"
              onClickCapture={downloadCallback}
            />
            <ButtonIconNew icon={Expand} onPress={openFullScreen} variant="ghost">
              Enter full screen
            </ButtonIconNew>
          </Box>
        </Box>
      }
    >
      <ThemedLogs className={styles.logsEditor}>
        <ThemedLogsContent>{prettify(task.logs)}</ThemedLogsContent>
      </ThemedLogs>
    </FormField>
  ) : (
    <FormFieldViewText label="Log" value="No log available" />
  );
};

export default ConfigManagementTreeGridDrawerLogs;
