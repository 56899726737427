import { SamlNameIdFormat } from "types/generated";

export type SettingsSingleSignOnSAMLFormFields = {
  nameIDFormat: SamlNameIdFormat;
  dynamic: boolean;
  metadata: string;
};

export const formDefaultValues: SettingsSingleSignOnSAMLFormFields = {
  nameIDFormat: SamlNameIdFormat.Transient,
  dynamic: false,
  metadata: "",
};
