import { ReactNode } from "react";

import { Terminal } from "components/icons/generated";

import MetaInfoListItem from "./Item";

type MetaInfoProjectRootProps = {
  projectRoot: string;
  tooltip?: ReactNode;
  handleApplyFilter?: (value: string) => void;
};

const MetaInfoProjectRoot = ({
  projectRoot,
  tooltip,
  handleApplyFilter,
}: MetaInfoProjectRootProps) => {
  return (
    <MetaInfoListItem
      {...(handleApplyFilter && {
        applyFilter: () => handleApplyFilter(projectRoot),
      })}
      icon={Terminal}
      tooltip={tooltip}
      tooltipWidthMode="maxWidthSm"
    >
      {projectRoot}
    </MetaInfoListItem>
  );
};

export default MetaInfoProjectRoot;
