import { Outlet } from "react-router-dom";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Tab from "ds/components/Tab";
import TierInfo from "components/TierInfo";
import { BillingTierFeature } from "types/generated";

export const AuditTrail = () => {
  return (
    <>
      <TierInfo type="callout" variant="danger" feature={BillingTierFeature.Auditing}>
        Audit trail is not supported by your billing tier. You may still manage your audit trail
        configuration, but events will not be dispatched until you upgrade to a tier that supports
        it.
      </TierInfo>
      <ViewHeader firstLevel>
        <Box align="start" justify="between" fullWidth>
          <Box gap="medium" direction="column">
            <ViewHeaderTitle titleSize="p-t3">Audit Trail</ViewHeaderTitle>
            <Box gap="medium">
              <Tab to="." label="Logs" end />
              <Tab to="./config" label="Configuration" />
            </Box>
          </Box>
        </Box>
      </ViewHeader>
      <Outlet />
    </>
  );
};
