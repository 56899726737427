import { useMemo } from "react";

import { EmptystateStacksColored } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";
import { PolicyStackAttachment } from "types/generated";
import ListEntitiesNew from "components/ListEntitiesNew";
import { decodeURIParam } from "utils/urls";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";

import PolicyDetailsUsedByVirtualizedListItem from "./Virtualized";
import PolicyDetailsUsedByFiltersLayout from "./FiltersLayout";

type PolicyDetailsUsedByProps = {
  attachedStacks: PolicyStackAttachment[];
  loading: boolean;
};

const PolicyDetailsUsedBy = ({ attachedStacks, loading }: PolicyDetailsUsedByProps) => {
  const urlParams = useURLParams();

  const querySearch = decodeURIParam(urlParams.get(URL_SEARCH_KEY));

  const filteredAttachedStacks = useMemo(() => {
    return querySearch
      ? attachedStacks.filter((item) =>
          item.stackName.toLowerCase().includes(querySearch.toLowerCase())
        )
      : attachedStacks;
  }, [attachedStacks, querySearch]);

  return (
    <PolicyDetailsUsedByFiltersLayout
      isPageEmpty={!filteredAttachedStacks.length && !querySearch}
      renderedEntitiesCount={filteredAttachedStacks.length}
      loadingIndication={loading}
      // TODO: [Policy library] waiting for backend
      filteredCount={attachedStacks.length}
    >
      {!filteredAttachedStacks.length && !querySearch && (
        <EmptyState
          title="There are no stacks or modules that use this policy yet"
          caption="Attach this policy to stack or module to start using it."
          icon={EmptystateStacksColored}
        />
      )}
      {!filteredAttachedStacks.length && querySearch && (
        <EmptyState
          title="No results"
          icon={EmptystateStacksColored}
          caption="Try different filters."
        />
      )}
      {!!filteredAttachedStacks.length && (
        <ListEntitiesNew
          itemCount={filteredAttachedStacks.length}
          itemProps={{
            items: filteredAttachedStacks,
          }}
          virtualizedItem={PolicyDetailsUsedByVirtualizedListItem}
          itemKey={(index) => filteredAttachedStacks[index].id}
        />
      )}
    </PolicyDetailsUsedByFiltersLayout>
  );
};

export default PolicyDetailsUsedBy;
