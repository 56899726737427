import { Tick, Cross } from "components/icons/generated";
import Icon from "ds/components/Icon";

type FormBooleanIconType = {
  success: boolean;
};

const FormBooleanIcon = ({ success }: FormBooleanIconType) => {
  return success ? <Icon src={Tick} color="success" /> : <Icon src={Cross} color="danger" />;
};

export default FormBooleanIcon;
