import { ReactNode } from "react";

import Tab from "ds/components/Tab";

import StackSettingsPageLayout from "../../components/PageLayout";

type StackSettingsIntegrationsWrapperProps = {
  children?: ReactNode;
  actions?: ReactNode;
};

const StackSettingsIntegrationsWrapper = ({
  children,
  actions,
}: StackSettingsIntegrationsWrapperProps) => {
  return (
    <StackSettingsPageLayout
      title="Integrations"
      description="Integrate stack with your cloud provider or a webhook."
      navigation={
        <>
          <Tab to="../cloud" label="Cloud integrations" />
          <Tab to="../webhooks" label="Webhooks" />
        </>
      }
      actions={actions}
    >
      {children}
    </StackSettingsPageLayout>
  );
};

export default StackSettingsIntegrationsWrapper;
