import Box from "ds/components/Box";
import Counter from "ds/components/Counter";
import { EmptystateLinkColored, EmptystateMagnetColored } from "components/icons/generated";
import EmptyState from "ds/components/EmptyState";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";

import NewContextSummaryPanel from "./Panel";
import { ContextCreationWizardStep } from "../constants";
import { ContextFormContext } from "../context";
import NewContextAttachedProject from "../AttachedProject";

const NewContextSummaryAttached = () => {
  const { autoAttachedProjects, attachedProjects } = useTypedContext(ContextFormContext);
  const count = autoAttachedProjects?.length + attachedProjects?.length;

  return (
    <NewContextSummaryPanel
      name="Attached to projects"
      step={ContextCreationWizardStep.Attachment}
      count={count}
    >
      <Box direction="column" gap="x-large">
        <Box direction="column" gap="medium">
          <Box grow="1" align="center" gap="medium">
            <Typography tag="span" variant="p-t6">
              Manually attached
            </Typography>
            <Counter count={attachedProjects.length} size="medium" />
          </Box>
          <Box direction="column" gap="medium">
            {attachedProjects.length > 0 ? (
              attachedProjects.map((item, index) => (
                <NewContextAttachedProject item={item} key={index} />
              ))
            ) : (
              <EmptyState
                padding="large"
                icon={EmptystateLinkColored}
                title="Not attached to any projects yet"
                caption="This is where stacks and modules that you have manually attached your context to will appear."
              />
            )}
          </Box>
        </Box>
        <Box direction="column" gap="medium">
          <Box grow="1" align="center" gap="medium">
            <Typography tag="span" variant="p-t6">
              Auto-attached
            </Typography>
            <Counter count={autoAttachedProjects.length} />
          </Box>
          <Box direction="column" gap="medium">
            {autoAttachedProjects.length > 0 ? (
              autoAttachedProjects.map((item, index) => (
                <NewContextAttachedProject item={item} key={index} />
              ))
            ) : (
              <EmptyState
                padding="large"
                icon={EmptystateMagnetColored}
                title="Not auto-attached to any projects yet"
                caption="This is where auto-attached contexts will appear. Use autoattach label to attach context to many stacks at the same time."
              />
            )}
          </Box>
        </Box>
      </Box>
    </NewContextSummaryPanel>
  );
};

export default NewContextSummaryAttached;
