import cx from "classnames";
import Snowfall from "react-snowfall";
import useLocalStorage from "@rehooks/local-storage";

import Portal from "components/Portal";

import MenuItem from "../MenuItem";
import { Snow } from "./Icon";
import styles from "./styles.module.css";

const SnowMenuItem = ({ isExpanded }: { isExpanded: boolean }) => {
  const [isSnowEnabled, setIsSnowEnabled] = useLocalStorage("snow", false);

  const title = `Turn ${isSnowEnabled ? "off" : "on"} snow`;

  return (
    <>
      {isSnowEnabled && (
        <Portal>
          <Snowfall
            style={{ zIndex: "calc(var(--z-high-index) + 1000)", position: "fixed" }}
            snowflakeCount={100}
            color="#9DC8FA"
          />
        </Portal>
      )}
      <MenuItem
        short={!isExpanded}
        icon={Snow}
        onClick={() => setIsSnowEnabled(!isSnowEnabled)}
        className={cx(isSnowEnabled ? styles.snowMenuItemActive : styles.snowMenuItem)}
        ariaLabel={title}
      >
        {title}
      </MenuItem>
    </>
  );
};

export default SnowMenuItem;
