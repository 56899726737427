import { useCallback, useState } from "react";
import { useModal } from "@ebay/nice-modal-react";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import { AuditTrailEntry } from "types/generated";
import Tab from "ds/components/Tab";
import Box from "ds/components/Box";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";

import AuditTrailEntriesDetailsBody from "./Body";
import AuditTrailEntriesDetailsMetadata from "./Metadata";
import { AuditTrailEntrySuggestions } from "../../constants";

type AuditTrailEntriesDetailsProps = {
  item: AuditTrailEntry;
  applyFilter: (filterName: AuditTrailEntrySuggestions) => (value: string) => void;
};

const AuditTrailEntriesDetails = createDrawer(
  ({ item, applyFilter }: AuditTrailEntriesDetailsProps) => {
    const [tab, setTab] = useState<"metadata" | "body">("metadata");
    const drawer = useModal();

    const handleApplyFilter = useCallback(
      (filterName: AuditTrailEntrySuggestions) => (value: string) => {
        applyFilter(filterName)(value);
        drawer.hide();
      },
      [applyFilter, drawer]
    );

    return (
      <DrawerSimple>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Log details" />
          <DrawerCloseIcon />
        </DrawerHeader>

        <DrawerBody fullHeight>
          <Box gap="medium" margin="0 0 large">
            <Tab
              isActive={tab === "metadata"}
              id="tab-metadata"
              onClick={() => setTab("metadata")}
              label="Metadata"
            />
            <Tab
              isActive={tab === "body"}
              id="tab-body"
              onClick={() => setTab("body")}
              label="Body"
            />
          </Box>

          {tab === "metadata" && (
            <AuditTrailEntriesDetailsMetadata item={item} applyFilter={handleApplyFilter} />
          )}
          {tab === "body" && <AuditTrailEntriesDetailsBody value={item.body} />}
        </DrawerBody>
      </DrawerSimple>
    );
  }
);

export const showAuditTrailEntriesDetails = createDrawerTrigger(AuditTrailEntriesDetails);
