import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import TreeChart from "components/TreeChart";
import TreeChartTextNode from "components/TreeChart/TextNode";
import { ConfigNode as ChartConfigNode } from "components/TreeChart/types";
import Box from "ds/components/Box";
import TreeChartGroupNode from "components/TreeChart/GroupNode";
import { getNodeHeight } from "components/TreeChart/GroupNode/getNodeHeight";
import { AnalyticsPage } from "hooks/useAnalytics";

import ConfigManagementTreeGridList from "./List";
import { LOADING_TRESHOLD, TASK_SEARCH_KEY } from "./constants";
import ConfigManagementTreeGridDrawer from "./Drawer";
import { Components, ConfigNode } from "../types";
import { LayoutMode } from "./types";
import ConfigManagementTreeGridLegend from "./Legend";

type ConfigManagementTreeGridProps = {
  nodes: ConfigNode[];
  layoutMode: LayoutMode;
  sortable?: boolean;
  chartClassName?: string;
  analyticsPage?: AnalyticsPage;
  hasNextPageToLoad?: boolean;
  loadMoreItems: () => Promise<void>;
};

const nodeTypes = {
  [Components.Text]: {
    Component: TreeChartTextNode,
    height: () => 60,
  },
  [Components.Group]: {
    Component: TreeChartGroupNode,
    height: getNodeHeight,
  },
};

const ConfigManagementTreeGrid = ({
  nodes,
  sortable,
  layoutMode,
  chartClassName,
  analyticsPage,
  hasNextPageToLoad,
  loadMoreItems,
}: ConfigManagementTreeGridProps) => {
  const [urlParams, setUrlParams] = useSearchParams();

  const chartNodes = useMemo(() => nodes.filter((node) => !!node.type), [nodes]);

  const taskId = urlParams.get(TASK_SEARCH_KEY) as string;
  const task = taskId ? nodes.find((node) => node.id === taskId) : undefined;

  useEffect(() => {
    if (!task && urlParams.has(TASK_SEARCH_KEY)) {
      const newUrlParams = new URLSearchParams(urlParams);
      newUrlParams.delete(TASK_SEARCH_KEY);
      setUrlParams(newUrlParams);
    }
  }, [task, urlParams, taskId, setUrlParams]);

  return (
    <>
      {layoutMode === LayoutMode.Diagram ? (
        <Box direction="column" surfaceColor="primary" className={chartClassName}>
          <TreeChart
            hasNextPageToLoad={hasNextPageToLoad}
            analyticsPage={analyticsPage}
            nodes={chartNodes as ChartConfigNode<Components>[]}
            margin={{ top: 30, left: 30, right: 30 }}
            nodeTypes={nodeTypes}
            activeId={taskId}
            loadMoreItems={loadMoreItems}
            loadingTreshold={LOADING_TRESHOLD}
          />
          <ConfigManagementTreeGridLegend />
        </Box>
      ) : (
        <ConfigManagementTreeGridList
          hasNextPageToLoad={hasNextPageToLoad}
          analyticsPage={analyticsPage}
          sortable={sortable}
          nodes={nodes}
          activeId={taskId}
          loadMoreItems={loadMoreItems}
          loadingTreshold={LOADING_TRESHOLD}
        />
      )}
      <ConfigManagementTreeGridDrawer task={task} />
    </>
  );
};

export default ConfigManagementTreeGrid;
