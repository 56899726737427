import React, { forwardRef, MouseEvent, MouseEventHandler, RefObject, useCallback } from "react";
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom";
import cx from "classnames";
import camelCase from "lodash-es/camelCase";

import { Spinner } from "components/icons";
import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";
import { FlexAlign, FlexJustify } from "types/Flex";
import { FLEX_ALIGN_PREFIX, FLEX_JUSTIFY_PREFIX } from "constants/style";

import styles from "./styles.module.css";

type BaseProps = AnalyticsCommonProps & {
  fullWidth?: boolean;
  loading?: boolean;
  isNavLink?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  align?: FlexAlign;
  justify?: FlexJustify;
};
type ButtonActionProps = React.ButtonHTMLAttributes<HTMLButtonElement> & BaseProps;
type LinkActionProps = LinkProps & BaseProps;
type NavLinkActionProps = NavLinkProps & BaseProps;
type AnchorLinkActionProps = Omit<React.HTMLProps<HTMLAnchorElement>, "ref" | "size"> & BaseProps;

export type BaseActionProps =
  | ButtonActionProps
  | LinkActionProps
  | NavLinkActionProps
  | AnchorLinkActionProps;

const BaseAction = forwardRef(function BaseAction(
  props: BaseActionProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const {
    fullWidth,
    loading,
    children,
    className,
    isNavLink,
    analyticsPage,
    analyticsTitle,
    analyticsProps,
    onClick,
    justify = "center",
    align = "center",
    ...restProps
  } = props;

  const actionClassName = cx(
    styles.baseAction,
    styles[camelCase(`${FLEX_ALIGN_PREFIX}-${align}`)],
    styles[camelCase(`${FLEX_JUSTIFY_PREFIX}-${justify}`)],
    { [styles.fullWidth]: fullWidth },
    className
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (restProps.disabled) {
        e.preventDefault();
      } else if (analyticsTitle) {
        trackSegmentAnalyticsEvent?.(analyticsTitle, analyticsProps);
      }
    },
    [analyticsProps, analyticsTitle, restProps.disabled, trackSegmentAnalyticsEvent]
  );

  // router link
  if ("to" in restProps && restProps.to) {
    if (isNavLink) {
      return (
        <NavLink
          ref={ref as RefObject<HTMLAnchorElement>}
          className={actionClassName}
          onClick={(onClick as MouseEventHandler<HTMLAnchorElement>) || handleLinkClick}
          {...(restProps as NavLinkProps)}
        >
          {children}
        </NavLink>
      );
    }

    return (
      <Link
        ref={ref as RefObject<HTMLAnchorElement>}
        className={actionClassName}
        onClick={(onClick as MouseEventHandler<HTMLAnchorElement>) || handleLinkClick}
        {...(restProps as LinkProps)}
      >
        {children}
      </Link>
    );
  }

  // external link
  if ("href" in props && props.href) {
    const linkProps = restProps as AnchorLinkActionProps;
    const linkOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
      (onClick as MouseEventHandler<HTMLAnchorElement>)?.(e);
      if (analyticsTitle) {
        trackSegmentAnalyticsEvent?.(analyticsTitle, analyticsProps);
      }
    };

    return (
      // TODO: jsx-a11y
      /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
      <a
        ref={ref as RefObject<HTMLAnchorElement>}
        className={actionClassName}
        {...{
          ...linkProps,
          onClick: linkOnClick,
        }}
      >
        {children}
      </a>
    );
  }

  const buttonProps = restProps as ButtonActionProps;
  const buttonOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    (onClick as MouseEventHandler<HTMLButtonElement>)?.(e);
    if (analyticsTitle) {
      trackSegmentAnalyticsEvent?.(analyticsTitle, analyticsProps);
    }
  };

  return (
    <button
      ref={ref as RefObject<HTMLButtonElement>}
      className={actionClassName}
      type="button"
      {...{ ...buttonProps, onClick: buttonOnClick }}
    >
      {loading && <Spinner className={styles.spinner} />}
      {!loading ? <>{children}</> : <span className={styles.hiddenText}>{children}</span>}
    </button>
  );
});

export default BaseAction;
