import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useModal } from "@ebay/nice-modal-react";

import FormFieldTags from "components/FormFields/Tags";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import useTypedContext from "hooks/useTypedContext";
import { TerraformProvider } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import { containsLowercaseLettersOnly } from "utils/strings";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldViewText from "components/FormFields/ViewText";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import ButtonNew from "ds/components/Button/New";
import { getDrawerFormFix } from "utils/css";

import { CREATE_PROVIDER, UPDATE_PROVIDER } from "../gql";
import {
  CreateProviderFormFields,
  CreateProviderGql,
  ProviderDrawerProps,
  UpdateProviderFormFields,
  UpdateProviderGql,
} from "./types";

const ProviderDrawer = createDrawer(({ provider }: ProviderDrawerProps) => {
  const drawer = useModal();
  const isEditMode = !!provider?.id;

  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const builderForm = useForm<CreateProviderFormFields>({
    defaultValues: {
      type: provider ? provider?.id : "",
      description: provider?.description || "",
      space: provider?.space || "",
      labels: provider?.labels.map((value) => ({ value })) || [],
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [createProvider] = useMutation<CreateProviderGql>(CREATE_PROVIDER, {
    refetchQueries: ["GetProviders", "GetProviderWithVersions"],
  });

  const [updateProvider] = useMutation<UpdateProviderGql>(UPDATE_PROVIDER, {
    refetchQueries: ["GetProviders", "GetProviderWithVersions"],
  });

  const onCreateSubmit = (formData: CreateProviderFormFields) =>
    createProvider({
      variables: {
        type: formData.type,
        description: formData.description,
        space: formData.space,
        labels: formData.labels.map((item) => item.value),
      },
    })
      .then(({ data }) => {
        if (data?.terraformProviderCreate?.id) {
          reportSuccess({
            message: `Terraform provider "${data.terraformProviderCreate.id}" was successfully created`,
          });
        } else {
          reportError({
            message: "Something went wrong while creating Terraform provider, please try again.",
          });
        }

        drawer.hide();
      })
      .catch(onError);

  const onEditSubmit = (formData: UpdateProviderFormFields, provider: TerraformProvider) =>
    updateProvider({
      variables: {
        id: provider.id,
        description: formData.description,
        space: formData.space,
        labels: formData.labels.map((item) => item.value),
      },
    })
      .then(({ data }) => {
        if (data?.terraformProviderUpdate?.id) {
          reportSuccess({
            message: `Terraform provider "${data.terraformProviderUpdate.id}" was successfully saved`,
          });
        } else {
          reportError({
            message: "Something went wrong while saving Terraform provider, please try again.",
          });
        }

        drawer.hide();
      })
      .catch(onError);

  const onSubmit: SubmitHandler<CreateProviderFormFields> = async (formData) => {
    if (isEditMode) {
      await onEditSubmit(formData, provider);
    } else {
      await onCreateSubmit(formData);
    }
  };

  const typeValidator = (value: string) => {
    if (!value) return "";
    return containsLowercaseLettersOnly(value) || "Type must consist of lowercase letters only.";
  };

  return (
    <DrawerSimple>
      <FormProvider {...builderForm}>
        <form onSubmit={handleSubmit(onSubmit)} {...getDrawerFormFix()}>
          <DrawerHeader justify="between">
            <DrawerHeaderTitle
              title={isEditMode ? "Edit Terraform provider" : "Create Terraform provider"}
            />
            <DrawerCloseIcon />
          </DrawerHeader>
          <DrawerBody fullHeight>
            {isEditMode && <FormFieldViewText label="Type" value={provider.id} withCopy />}
            {!isEditMode && (
              <FormField
                label="Type"
                error={errors?.type?.message}
                helperText="Unique name within the account. It must consist of lowercase letters only."
              >
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter the unique name here..."
                    error={!!errors?.type}
                    {...register("type", {
                      required: "Type field is required.",
                      validate: typeValidator,
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            )}

            <FormFieldSpace />

            <FormField label="Description" isOptional>
              {({ ariaInputProps }) => (
                <Textarea
                  placeholder="Enter description here..."
                  maxRows={15}
                  {...register("description")}
                  {...ariaInputProps}
                />
              )}
            </FormField>

            <FormFieldTags
              stopEnterPropagation
              label="Labels"
              tagName="label"
              name="labels"
              isOptional
            />

            <DrawerFooter>
              <DrawerFooterActions>
                <DrawerCancelButton />
                <ButtonNew variant="primary" disabled={!isValid || !isDirty} type="submit">
                  {isEditMode ? "Save" : "Create"}
                </ButtonNew>
              </DrawerFooterActions>
            </DrawerFooter>
          </DrawerBody>
        </form>
      </FormProvider>
    </DrawerSimple>
  );
});

export const showProviderDrawer = createDrawerTrigger(ProviderDrawer);
