import moment from "moment";
import userflow from "userflow.js";

import { AccountViewer } from "components/form/graphql/types";
import { BillingTier } from "types/generated";
import { ThemePalette } from "views/Theme";
import { COLOR_THEME_LOCAL_STORAGE_KEY } from "views/Theme/constants";

import { trackUserEvent } from "./server-mutation/track";
import { identifyUserEvent } from "./server-mutation/identify";
import { groupUserEvent } from "./server-mutation/group";

const USERFLOW_TOKEN = process.env.REACT_APP_USERFLOW_TOKEN;

type DataLayerProps = {
  [key: string]: string | boolean;
};

type HubspotDataArgs = {
  accountId: string;
  email: string;
  id: string;
  provider: string;
  name: string;
  tier?: string;
};

type SegmentIdentifyUserProps = {
  theme: ThemePalette;
};

type UserflowUserProps = {
  admin: boolean;
  id: string;
  email: string;
  name: string;
  since?: number;
  theme: ThemePalette;
};

type PylonWidgetProps = {
  email: string;
  name: string;
  avatar: string;
};

export const getSubdomain = () => {
  return window.location.hostname.split(".", 1)[0];
};

const getFirstLastName = (value: string) => {
  // for some IdPs, the name is the email and we want to return null for both
  if (value.includes("@")) {
    return { firstName: null, lastName: null };
  }

  const parts = value.split(" ");
  const firstName = parts.shift();
  const lastName = parts.join(" ");

  return { firstName, lastName };
};

const environment = process.env.REACT_APP_RUM_ENV || "development";

export const initHubspot = (id?: string) => {
  if (!id) return;

  const script = document.createElement("script");
  script.async = true;
  script.defer = true;
  script.type = "text/javascript";
  script.src = `//js.hs-scripts.com/${id}.js`;
  script.id = "hs-script-loader";

  document.body.appendChild(script);
};

export const initBeamer = ({
  beamerId,
  viewer,
  tier,
  isTrial,
  theme,
}: {
  beamerId: string;
  viewer: AccountViewer;
  tier: BillingTier;
  isTrial: boolean;
  theme: ThemePalette;
}) => {
  const scriptId = "beamer-script-loader";

  if (document.getElementById(scriptId)) return false;

  const nonceHash = process.env.REACT_APP_MANUAL_INLINE_JS_NONCE_HASH || undefined;

  const scriptVariable = document.createElement("script");
  const [firstName, lastName] = viewer.name.split(" ");
  const subdomain = getSubdomain();

  const scriptBody = document.createTextNode(`
    var beamer_config = {
      product_id: "${beamerId}", //DO NOT CHANGE: This is your product code on Beamer
      selector: "#beamerButton, #beamerButtonOpen",
      display_position: "top",
      theme: "${theme}",
      bounce: false,
      user_id: "${subdomain}/${viewer.id}",
      user_email: "${viewer.email}",
      user_firstname: ${JSON.stringify(firstName)},
      user_lastname: ${JSON.stringify(lastName)},
      user_since: "${viewer.since ? moment(viewer.since * 1000).format("YYYY/MM/DD") : ""}",
      user_created_at: "${viewer.since ? new Date(viewer.since * 1000).toISOString() : ""}",
      account_admin: ${viewer.admin.toString()},
      account_tier: "${tier}",
      account_subdomain: "${subdomain}",
      account_is_trial: ${isTrial.toString()},
      callback: () => {
        if (typeof window.updateBeamerTooltipPosition === 'function') {
          window.updateBeamerTooltipPosition();
        }
      },
    };
  `);
  scriptVariable.appendChild(scriptBody);

  if (nonceHash) {
    scriptVariable.setAttribute("nonce", nonceHash);
  }

  document.body.appendChild(scriptVariable);

  const script = document.createElement("script");
  script.id = scriptId;
  script.defer = true;
  script.type = "text/javascript";
  script.src = "https://app.getbeamer.com/js/beamer-embed.js";

  if (nonceHash) {
    script.setAttribute("nonce", nonceHash);
  }

  document.body.appendChild(script);

  return undefined;
};

export const initGtm = (id?: string) => {
  if (!id) return;

  const nonceHash = process.env.REACT_APP_MANUAL_INLINE_JS_NONCE_HASH || undefined;

  const script = document.createElement("script");
  const scriptBody = document.createTextNode(`
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
    n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');
  `);
  script.type = "text/javascript";
  script.appendChild(scriptBody);

  if (nonceHash) {
    script.setAttribute("id", "data-google-tag-manager");
    script.setAttribute("nonce", nonceHash);
    script.setAttribute("data-nonce", nonceHash);
  }

  document.head.insertBefore(script, document.head.childNodes[0]);

  const noscript = document.createElement("noscript");

  const iframe = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
  height="0" width="0" style="display:none;visibility:hidden">`;

  noscript.innerHTML = iframe;

  document.body.insertBefore(noscript, document.body.childNodes[0]);
};

export type TrackAnalyticsEventProperties = Record<
  string,
  string | undefined | number | object | boolean
>;

export const trackAnalyticsEvent = (
  message: string,
  properties?: TrackAnalyticsEventProperties
) => {
  trackSegmentEvent(message, properties);
};

export const updateHubspotUser = (email: string, fieldName: string, value: string) => {
  const _hsq = (window._hsq = window._hsq || []);
  if (!window._hsq) return;

  _hsq.push([
    "identify",
    {
      email: email,
      [fieldName]: value,
    },
  ]);
};

export const identifyHubspotUser = ({
  accountId,
  email,
  id,
  provider,
  name,
  tier,
}: HubspotDataArgs) => {
  const _hsq = (window._hsq = window._hsq || []);
  if (!window._hsq) return;

  const subdomain = getSubdomain();

  const { firstName, lastName } = getFirstLastName(name);

  _hsq.push([
    "identify",
    {
      spacelift_app_account_ulid: accountId,
      email: email,
      account_name: subdomain,
      github_handle: id,
      user_id: `${id}$${subdomain}`,
      interest: email ? "Product Sign Up" : null,
      tier: tier ? tier : null,
      identity_provider: provider,
      firstname: firstName,
      lastname: lastName,
    },
  ]);
};

export const updateSegmentUser = (key: string, value: string | boolean) => {
  identifyUserEvent({
    [key]: value,
  });
};

export const identifySegmentUser = ({ theme }: SegmentIdentifyUserProps) => {
  identifyUserEvent({ colorTheme: theme });
};

export const trackSegmentEvent = (
  name: string,
  properties?: Record<string, string | number | undefined | object | boolean>
) => {
  const subdomain = getSubdomain();

  const storageTheme = localStorage.getItem(COLOR_THEME_LOCAL_STORAGE_KEY);

  const context = {
    groupId: subdomain,
    traits: {
      colorTheme: storageTheme || "light",
    },
  };

  trackUserEvent(name, properties, context);
};

export const setSegmentGroup = () => {
  groupUserEvent();
};

export const createPlainDataLayer = (payload: DataLayerProps) => {
  if (!window.dataLayer || !payload) return;

  window.dataLayer.push(payload);
};

export const createUserPropertyDataLayer = (userName: string, admin: boolean, provider: string) => {
  const subdomain = getSubdomain();

  createPlainDataLayer({
    account: subdomain,
    username: userName,
    identityProvider: provider,
    admin: admin,
    user_id: `${userName}$${subdomain}`,
  });
};

export const identifyUserflowUser = ({
  admin,
  id,
  email,
  name,
  since,
  theme,
}: UserflowUserProps) => {
  if (!USERFLOW_TOKEN) {
    return;
  }

  const subdomain = getSubdomain();
  const [firstName, lastName] = name.split(" ");

  userflow.identify(`${subdomain}/${id}`, {
    email: email,
    accountName: subdomain,
    admin: admin,
    firstname: firstName,
    lastname: lastName,
    userId: `${subdomain}/${id}`,
    userSince: since ? new Date(since * 1000).toISOString() : null,
    userEnvironment: environment,
    colorTheme: theme,
    groupId: subdomain,
  });

  userflow.group(subdomain);
};

export const updateUserflowUser = (key: string, value: string | boolean) => {
  if (!USERFLOW_TOKEN) {
    return;
  }

  userflow.updateUser({
    [key]: value,
  });
};

export const isUserflowEnabled = () => {
  if (!USERFLOW_TOKEN) {
    return;
  }

  return userflow.isIdentified();
};

export const initPylon = (id?: string) => {
  if (!id) return;
  const nonceHash = process.env.REACT_APP_MANUAL_INLINE_JS_NONCE_HASH || undefined;
  const script = document.createElement("script");
  script.async = true;
  script.defer = true;
  const scriptBody = document.createTextNode(`
  (function(){var e=window;var t=document;var n=function(){n.e(arguments)};n.q=[];n.e=function(e){n.q.push(e)};e.Pylon=n;var r=function(){var e=t.createElement("script");e.setAttribute("type","text/javascript");e.setAttribute("async","true");e.setAttribute("src","https://widget.usepylon.com/widget/${id}");var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};if(t.readyState==="complete"){r()}else if(e.addEventListener){e.addEventListener("load",r,false)}})();
  `);
  script.type = "text/javascript";
  script.appendChild(scriptBody);
  if (nonceHash) {
    script.setAttribute("nonce", nonceHash);
  }

  document.body.appendChild(script);
};

export const initPylonChat = ({ email, name, avatar }: PylonWidgetProps) => {
  const APP_ID = process.env.REACT_APP_PYLON_APP_ID;
  if (!APP_ID || !window.Pylon) {
    return;
  }

  window.pylon = {
    chat_settings: {
      app_id: APP_ID,
      email: email,
      name: name,
      avatar_url: avatar,
    },
  };
};
