import { ChevronDown } from "components/icons/generated";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import { SplitControlContext } from "./context";
import ButtonIconNew from "../ButtonIcon/New";
import DropdownMenu, { DropdownMenuProps } from "../DropdownMenu";

type SplitControlDropdownProps<T> = Omit<DropdownMenuProps<T>, "triggerComponent"> & {
  tooltip: string;
  loading?: boolean;
  disabled?: boolean;
};

const SplitControlDropdown = <T extends object>({
  tooltip,
  loading,
  disabled,
  children,
  ...restProps
}: SplitControlDropdownProps<T>) => {
  const { variant, size } = useTypedContext(SplitControlContext);

  return (
    <DropdownMenu
      {...restProps}
      triggerComponent={
        <ButtonIconNew
          justify="center"
          variant={variant}
          size={size}
          loading={loading}
          disabled={disabled}
          className={styles.splitControlDropdownTrigger}
          icon={ChevronDown}
        >
          {tooltip}
        </ButtonIconNew>
      }
    >
      {children}
    </DropdownMenu>
  );
};

export default SplitControlDropdown;
