import { Download } from "components/icons/generated";
import Button, { ButtonProps } from "ds/components/Button";
import Tooltip from "ds/components/Tooltip";

import { createUrl, toBlob } from "./utils";

type DownloadFileButtonProps = {
  fileName: string;
  value: string;
  title: string;
  tooltip?: string;
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  onClickCapture?: () => void;
};

const DownloadFileButton = ({
  fileName,
  value,
  title,
  tooltip,
  size = "small",
  variant = "secondary",
  onClickCapture,
}: DownloadFileButtonProps) => {
  const href = createUrl(toBlob(value));

  return (
    <Tooltip
      active={!!tooltip}
      on={(props) => (
        <Button
          {...props}
          download={fileName}
          variant={variant}
          startIcon={Download}
          size={size}
          href={href}
          onClickCapture={onClickCapture}
        >
          {title}
        </Button>
      )}
    >
      {tooltip}
    </Tooltip>
  );
};

export default DownloadFileButton;
