import { RunIgnoredTrigger, RunIgnoredTriggerReasonType, Stack } from "types/generated";
import Button from "ds/components/Button";

type StackRunsIgnoredRunCalloutPrimaryActionProps = {
  stack: Stack;
  ignoredRun: RunIgnoredTrigger;
};

const getButtonUrl = (stack: Stack, ignoredRun: RunIgnoredTrigger) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (ignoredRun?.reasonType) {
    case RunIgnoredTriggerReasonType.DisabledByLabel:
      return `/stack/${stack.id}/settings`;
    case RunIgnoredTriggerReasonType.NoAffectedFilesInProjectRoot:
      return `/stack/${stack.id}/settings/source-code`;
    default:
      return null;
  }
};

const StackRunsIgnoredRunCalloutPrimaryAction = ({
  stack,
  ignoredRun,
}: StackRunsIgnoredRunCalloutPrimaryActionProps) => {
  const buttonUrl = getButtonUrl(stack, ignoredRun);
  return buttonUrl ? (
    <Button
      variant="contrast"
      size="small"
      to={buttonUrl}
      analyticsTitle="Ignored run primary action clicked"
      analyticsProps={{
        warningType: ignoredRun.reasonType,
      }}
    >
      Go to stack settings
    </Button>
  ) : null;
};

export default StackRunsIgnoredRunCalloutPrimaryAction;
