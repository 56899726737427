import { ArrowRight } from "components/icons/generated";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import { EntityChangeType } from "types/generated";
import Code from "ds/components/Code";

import styles from "./styles.module.css";

type ReplacementIndicatorProps = {
  changeType: EntityChangeType;
};

const ArrowIcon = <Icon src={ArrowRight} key="icon" />;

const CreateLabel = (
  <Typography variant="s-body3" tag="span" className={styles.createLabel} key="createLabel">
    create
  </Typography>
);

const DestroyLabel = (
  <Typography variant="s-body3" tag="span" className={styles.destroyLabel} key="destroyLabel">
    destroy
  </Typography>
);

function ReplacementIndicator({ changeType }: ReplacementIndicatorProps) {
  return (
    <Code className={styles.replacement}>
      {changeType === EntityChangeType.ReplaceCreateBeforeDestroy && [
        CreateLabel,
        ArrowIcon,
        DestroyLabel,
      ]}
      {changeType === EntityChangeType.ReplaceDestroyBeforeCreate && [
        DestroyLabel,
        ArrowIcon,
        CreateLabel,
      ]}
    </Code>
  );
}

export default ReplacementIndicator;
