import { useForm } from "react-hook-form";
import { useQuery } from "@apollo/client";

import CodeEditor from "components/CodeEditor";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerCloseIcon from "ds/components/DrawerNew/CloseIcon";
import DrawerHeaderTitle from "ds/components/DrawerNew/HeaderTitle";
import DrawerSimple from "ds/components/DrawerNew/Simple";
import { createDrawer, createDrawerTrigger } from "ds/components/DrawerNew/utils";
import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import Button from "ds/components/Button";
import { External } from "components/icons/generated";
import Box from "ds/components/Box";
import Input from "ds/components/Input";
import { stringIsRequired } from "utils/formValidators";
import SecretInput from "ds/components/SecretInput";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import DrawerCancelButton from "ds/components/DrawerNew/CancelButton";
import ButtonNew from "ds/components/Button/New";
import { createDefaultMutationHook } from "hooks/useMutationHandler";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { MANIFEST_TEMPLATE } from "./constants";
import { getFormDefaultValues, SettingsIntegrationsSlackSetUpFormFields } from "./helpers";
import { GET_SLACK_APP_MANIFEST, SELF_HOSTED_SLACK_APP_CONFIG_SET } from "./gql";
import useConnectSlackIntegration from "../useConnectSlackIntegration";
import { SettingsSlackAppConfig } from "../../gql";

const useSlackAppConfigSet = createDefaultMutationHook(SELF_HOSTED_SLACK_APP_CONFIG_SET);

type SettingsIntegrationsSlackSetUpProps = {
  appConfig?: SettingsSlackAppConfig;
};

const SettingsIntegrationsSlackSetUp = createDrawer(function SettingsIntegrationsSlackSetUp({
  appConfig,
}: SettingsIntegrationsSlackSetUpProps) {
  const isEditMode = !!appConfig;
  const { reportSuccess, onError } = useTypedContext(FlashContext);

  const { data, loading: manifestLoading } = useQuery(GET_SLACK_APP_MANIFEST, {
    fetchPolicy: "cache-first",
    onError,
  });

  const [slackAppConfigSet, { loading }] = useSlackAppConfigSet({
    refetchQueries: ["GetAccountIntegrations"],
  });
  const { connectSlackIntegration } = useConnectSlackIntegration();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SettingsIntegrationsSlackSetUpFormFields>({
    mode: "onChange",
    defaultValues: getFormDefaultValues(appConfig),
  });

  const onSubmit = handleSubmit((data) => {
    slackAppConfigSet(
      {
        input: {
          clientId: data.clientId,
          clientSecret: data.clientSecret,
          signingSecret: data.signingSecret,
        },
      },
      (data) => {
        if (data?.slackAppConfigSet.clientId) {
          connectSlackIntegration();
          reportSuccess({
            message: isEditMode
              ? "Slack application successfully updated"
              : "Slack application successfully created",
          });
        }
      }
    );
  });

  const editorBody = data?.slackAppManifest || MANIFEST_TEMPLATE;

  return (
    <DrawerSimple isDismissable={false}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle
          title={isEditMode ? "Edit Slack integration" : "Set up Slack integration"}
        />
        <DrawerCloseIcon />
      </DrawerHeader>

      {!isEditMode && (
        <DrawerBody hasDivider gap="x-large">
          <Typography variant="p-t5" tag="h3">
            Create a Slack app
          </Typography>

          <FormField label="Copy app manifest" withCopy={editorBody} noMargin>
            <CodeEditor
              body={editorBody}
              language="json"
              skeletonCount={6}
              fixedHeight={180}
              readOnly={manifestLoading}
              rounded
            />
          </FormField>

          <Box>
            <Button
              href="https://api.slack.com/apps?new_app=1"
              target="_blank"
              variant="secondary"
              size="medium"
              endIcon={External}
            >
              Create Slack app
            </Button>
          </Box>
        </DrawerBody>
      )}

      <form onSubmit={onSubmit}>
        <DrawerBody gap="x-large">
          <FormField label="Client ID" error={errors?.clientId?.message} noMargin>
            {({ ariaInputProps }) => (
              <Input
                error={!!errors?.clientId}
                {...register("clientId", {
                  validate: stringIsRequired(),
                  setValueAs: (value: string) => value.trim(),
                })}
                {...ariaInputProps}
                disabled={isEditMode}
              />
            )}
          </FormField>

          <FormField label="Client Secret" error={errors?.clientSecret?.message} noMargin>
            {({ ariaInputProps }) => (
              <SecretInput
                error={!!errors?.clientSecret}
                {...register("clientSecret", {
                  validate: stringIsRequired(),
                  setValueAs: (value: string) => value.trim(),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormField label="Signing Secret" error={errors?.signingSecret?.message} noMargin>
            {({ ariaInputProps }) => (
              <SecretInput
                error={!!errors?.signingSecret}
                {...register("signingSecret", {
                  validate: stringIsRequired(),
                  setValueAs: (value: string) => value.trim(),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        </DrawerBody>

        <DrawerFooter fullWidth>
          <DrawerFooterActions>
            <DrawerCancelButton />
            <ButtonNew variant="primary" type="submit" loading={loading}>
              {isEditMode ? "Save" : "Save & Connect"}
            </ButtonNew>
          </DrawerFooterActions>
        </DrawerFooter>
      </form>
    </DrawerSimple>
  );
});

export const openSetUpDrawer = createDrawerTrigger(SettingsIntegrationsSlackSetUp);
