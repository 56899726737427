import { useEffect, useRef } from "react";
import cx from "classnames";

import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import { Dots } from "components/icons/generated";
import Typography from "ds/components/Typography";
import { ManagedUserGroup, UserGroupStatus } from "types/generated";
import { pluralize } from "shared/Pluralize";
import TextEllipsis from "ds/components/TextEllipsis";
import Timestamp from "components/time/Timestamp";
import { getProviderLogo } from "views/Account/Settings/Users/helpers";
import { withTestId } from "utils/withTestId";
import useTypedContext from "hooks/useTypedContext";
import { SettingsContext } from "views/Account/Settings/Context";
import { UserManagementActivationStatus } from "views/Account/Settings/types";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import IdpGroupMappingListDropdown from "./Dropdown";
import styles from "./styles.module.css";
import { GROUP_IDP_LIST_ITEM_TEST_ID } from "./constants";
import UserGroupStatusBadge from "../UserGroupStatusBadge";

type IdpGroupMappingListItemProps = {
  item: ManagedUserGroup;
  handleGroupClick: (details: ManagedUserGroup) => unknown;
  setRowHeight?: (size: number) => void;
};

const IdpGroupMappingListItem = ({
  item,
  setRowHeight,
  handleGroupClick,
}: IdpGroupMappingListItemProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const ProviderIcon = getProviderLogo(item.identityProvider);

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      className={cx(
        (activationStatus === UserManagementActivationStatus.INACTIVE ||
          item.status === UserGroupStatus.Inactive) &&
          styles.item
      )}
      {...withTestId(GROUP_IDP_LIST_ITEM_TEST_ID)}
    >
      <Box direction="row" align="center">
        <TextEllipsis tooltip={item.groupName} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="span" variant="p-t6">
              {item.groupName}
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      <Box direction="row" align="center">
        <Typography tag="span" variant="p-body2">
          {`${item.spaceCount} ${pluralize("space", item.spaceCount)}`}
        </Typography>
      </Box>
      <Box direction="row" align="center">
        <Typography tag="span" variant="p-body2">
          {`${item.memberCount} ${pluralize("user", item.memberCount)}`}
        </Typography>
      </Box>
      <Box direction="row" align="center">
        {item.lastLoginTime && (
          <TextEllipsis tooltip={<Timestamp timestamp={item.lastLoginTime} />}>
            {(props) => (
              <Typography {...props} tag="span" variant="p-body2">
                {item.lastLoginTime && <Timestamp timestamp={item.lastLoginTime} />}
              </Typography>
            )}
          </TextEllipsis>
        )}
      </Box>
      <Box direction="row" align="center">
        {ProviderIcon && (
          <Typography tag="span" variant="p-body2">
            {typeof ProviderIcon === "string" ? (
              ProviderIcon
            ) : (
              <Icon size="large" src={ProviderIcon} />
            )}
          </Typography>
        )}
      </Box>
      <Box direction="row" align="center">
        <UserGroupStatusBadge status={item.status} />
      </Box>

      <Box direction="row" align="center">
        <IdpGroupMappingListDropdown item={item} handleGroupDetailsClick={handleGroupClick} />
        <Icon src={Dots} />
      </Box>
    </ListEntitiesItem>
  );
};

export default IdpGroupMappingListItem;
