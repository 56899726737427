import DeltaCountsCell from "./Cell";
import { DeltaType } from "./types";
import styles from "./styles.module.css";
import { deltaConfig } from "./constants";

type DeltaCountsIMFBarProps = {
  importCount: number;
  moveCount: number;
  forgetCount: number;
};

const DeltaCountsIMFBar = ({ importCount, moveCount, forgetCount }: DeltaCountsIMFBarProps) => (
  <div className={styles.group}>
    {importCount > 0 && <DeltaCountsCell {...deltaConfig[DeltaType.IMPORT](importCount)} />}
    {moveCount > 0 && <DeltaCountsCell {...deltaConfig[DeltaType.MOVE](moveCount)} />}
    {forgetCount > 0 && <DeltaCountsCell {...deltaConfig[DeltaType.FORGET](forgetCount)} />}
  </div>
);

export default DeltaCountsIMFBar;
