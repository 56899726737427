import Box from "ds/components/Box";
import { Diagram, List } from "components/icons/generated";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";
import { AnsibleTaskStatus } from "types/generated";

import { LayoutMode } from "./TreeGrid/types";
import { AnsibleGroupBy } from "./types";

export const POLL_INTERVAL = 10000;

export const FILTERS_ORDER_SETTINGS_KEY = "ansibleFiltersOrder";

export const ANSIBLE_LIST_VIEW_ITEMS_SETTINGS_KEY = "ansibleListViewItems";

export const ANSIBLE_ITEMS_LIMIT = 20;

export const ANSIBLE_INITIAL_ITEMS_SETTINGS = [];
export const LAYOUT_MODE_LOCAL_STORAGE_KEY = "config-management-layout-mode";
export const GROUP_BY_MODE_LOCAL_STORAGE_KEY = "config-management-group-by-mode";
export const DEFAULT_LAYOUT_MODE = LayoutMode.Diagram;
export const DEFAULT_GROUP_BY_MODE = AnsibleGroupBy.Hosts;

export const ANSIBLE_DICTIONARY: Record<AnsibleTaskStatus, string> = {
  [AnsibleTaskStatus.Changed]: "Changed",
  [AnsibleTaskStatus.Ignored]: "Ignored",
  [AnsibleTaskStatus.Ok]: "Ok",
  [AnsibleTaskStatus.Rescued]: "Rescued",
  [AnsibleTaskStatus.Skipped]: "Skipped",
  [AnsibleTaskStatus.Unreachable]: "Unreachable",
  [AnsibleTaskStatus.Failed]: "Failed",
};

export const ANSIBLE_GROUP_BY_OPTIONS = [
  { value: AnsibleGroupBy.Hosts, label: "Hosts" },
  { value: AnsibleGroupBy.Tasks, label: "Tasks" },
];

export const LAYOUT_MODE_OPTIONS = [
  {
    label: (
      <Box gap="medium" align="center">
        <Icon src={List} />
        <Typography tag="span" variant="p-t7">
          List
        </Typography>
      </Box>
    ),
    value: LayoutMode.List,
    tooltip: "Switch to list view",
  },
  {
    label: (
      <Box gap="medium" align="center">
        <Icon src={Diagram} />
        <Typography tag="span" variant="p-t7">
          Diagram
        </Typography>
      </Box>
    ),
    value: LayoutMode.Diagram,
    tooltip: "Switch to diagram view",
  },
];

export const DEPRECATED_FILTER_NAMES = ["name"];
