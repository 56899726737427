import { ColumnConfig } from "components/Table/types";
import { SearchQueryOrderDirection, SearchQueryPredicate, StackState } from "types/generated";
import { StarFilled } from "components/icons/generated";
import { StackSuggestions } from "constants/stack";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import styles from "./styles.module.css";

export const STACK_LIST_NEW_FEEDBACK_ID = "feedback-id-for-stack-list-new";
export const STACK_LIST_CUSTOMIZE_VIEW_BUTTON_ID = "stack-list-customize-view-button";

export const FILTER_TYPE = "stacks";

export const initialSortOption = "starred";
export const initialSortDirection = SearchQueryOrderDirection.Desc;

export const POLL_INTERVAL = 10000;
export const STACKS_LIMIT = 50;

export const FILTERS_ORDER_SETTINGS_KEY = "stacksFiltersOrder";

export const STACK_LIMIT_STORAGE_KEY = "stacksLimit";
export const STACK_COLUMNS_STORAGE_KEY = "stacksColumnsConfig";

export enum STACKS_COLUMN_ID {
  FAVORITE = "FAVORITE",
  STATE = "STATE",
  NAME = "NAME",
  LABELS = "LABELS",
  SPACE = "SPACE",
  VENDOR = "VENDOR",
  BRANCH = "BRANCH",
  COMMIT = "COMMIT",
  REPOSITORY = "REPOSITORY",
  PROJECT_ROOT = "PROJECT_ROOT",
  DELTA_COUNTS = "DELTA_COUNTS",
  UPDATED_AT = "UPDATED_AT",
  DESCRIPTION = "DESCRIPTION",
  BLUEPRINT = "BLUEPRINT",
  DROPDOWN = "DROPDOWN",
  ISSUES = "ISSUES",
  SETTINGS = "SETTINGS",
}

export const ROW_HEADER_ID = STACKS_COLUMN_ID.NAME;

const MAX_CELL_WIDTH = 600;

export const STACKS_COLUMN_CONFIG: ColumnConfig = {
  [STACKS_COLUMN_ID.STATE]: {
    title: "State",
    minWidth: 120,
    defaultWidth: 200,
    maxWidth: MAX_CELL_WIDTH,
  },
  [STACKS_COLUMN_ID.NAME]: {
    title: "Name",
    minWidth: 150,
    defaultWidth: 300,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
  },
  [STACKS_COLUMN_ID.ISSUES]: {
    title: "Issues",
    minWidth: 98,
    defaultWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
  },
  [STACKS_COLUMN_ID.SETTINGS]: {
    title: "Settings",
    minWidth: 110,
    defaultWidth: 110,
    maxWidth: MAX_CELL_WIDTH,
  },
  [STACKS_COLUMN_ID.FAVORITE]: {
    title: "Favorite",
    iconOnly: true,
    icon: StarFilled,
    maxWidth: 88,
    minWidth: 88,
    width: 88,
    resizable: false,
    allowsSorting: true,
  },
  [STACKS_COLUMN_ID.LABELS]: {
    title: "Labels",
    minWidth: 100,
    defaultWidth: 140,
    maxWidth: MAX_CELL_WIDTH,
  },
  [STACKS_COLUMN_ID.SPACE]: {
    title: "Space",
    minWidth: 112,
    defaultWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
  },
  [STACKS_COLUMN_ID.VENDOR]: {
    title: "Vendor",
    minWidth: 103,
    defaultWidth: 110,
    maxWidth: MAX_CELL_WIDTH,
  },
  [STACKS_COLUMN_ID.BRANCH]: {
    title: "Branch",
    minWidth: 118,
    defaultWidth: 200,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
  },
  [STACKS_COLUMN_ID.COMMIT]: {
    title: "Commit",
    minWidth: 108,
    defaultWidth: 150,
    maxWidth: MAX_CELL_WIDTH,
  },
  [STACKS_COLUMN_ID.REPOSITORY]: {
    title: "Repository",
    minWidth: 140,
    defaultWidth: 250,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
  },
  [STACKS_COLUMN_ID.PROJECT_ROOT]: {
    title: "Project root",
    minWidth: 145,
    defaultWidth: 160,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
  },
  [STACKS_COLUMN_ID.DELTA_COUNTS]: {
    title: "Delta",
    minWidth: 100,
    defaultWidth: 100,
    maxWidth: MAX_CELL_WIDTH,
  },
  [STACKS_COLUMN_ID.UPDATED_AT]: {
    title: "Updated at",
    minWidth: 145,
    defaultWidth: 160,
    maxWidth: MAX_CELL_WIDTH,
    allowsSorting: true,
  },
  [STACKS_COLUMN_ID.DESCRIPTION]: {
    title: "Description",
    minWidth: 130,
    defaultWidth: 240,
    maxWidth: MAX_CELL_WIDTH,
  },
  [STACKS_COLUMN_ID.BLUEPRINT]: {
    title: "Blueprint",
    minWidth: 120,
    defaultWidth: 140,
    maxWidth: MAX_CELL_WIDTH,
  },
};

export const STACKS_CUSTOM_END_COLUMN_CONFIG: ColumnConfig = {
  [STACKS_COLUMN_ID.DROPDOWN]: {
    title: "More actions",
    noHeader: true,
    static: true,
    maxWidth: 60,
    minWidth: 60,
    width: 60,
    sticky: true,
    resizable: false,
    cellClassName: styles.dropdownCell,
  },
};

export const SORT_CONFIG: Record<string, string> = {
  [STACKS_COLUMN_ID.NAME]: "name",
  [STACKS_COLUMN_ID.FAVORITE]: "starred",
  [STACKS_COLUMN_ID.UPDATED_AT]: "stateSetAt",
  [STACKS_COLUMN_ID.BRANCH]: "branch",
  [STACKS_COLUMN_ID.REPOSITORY]: "repository",
  [STACKS_COLUMN_ID.SPACE]: "space",
  [STACKS_COLUMN_ID.PROJECT_ROOT]: "projectRoot",
};

export enum STACKS_SELECT_ALL_OPTIONS {
  ALL = "ALL",
  FAILED = "FAILED",
  UNCONFIRMED = "UNCONFIRMED",
}

export const SELECT_ALL_FILTERS: Record<STACKS_SELECT_ALL_OPTIONS, Array<SearchQueryPredicate>> = {
  [STACKS_SELECT_ALL_OPTIONS.ALL]: [],
  [STACKS_SELECT_ALL_OPTIONS.FAILED]: [
    {
      field: StackSuggestions.State,
      exclude: null,
      constraint: {
        booleanEquals: null,
        enumEquals: [StackState.Failed],
        stringMatches: null,
        hierarchyNodeValueEquals: null,
        timeInRange: null,
        timeInLast: null,
      },
    },
  ],
  [STACKS_SELECT_ALL_OPTIONS.UNCONFIRMED]: [
    {
      field: StackSuggestions.State,
      exclude: null,
      constraint: {
        booleanEquals: null,
        enumEquals: [StackState.Unconfirmed],
        stringMatches: null,
        hierarchyNodeValueEquals: null,
        timeInRange: null,
        timeInLast: null,
      },
    },
  ],
};

export const ANALYTICS = {
  analyticsPage: AnalyticsPageStack.StacksList,
};
