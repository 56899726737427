import { useCallback } from "react";

import { StackSuggestions } from "constants/stack";
import useApplyFilter from "components/Filters/useApplyFilter";
import TagsListFilterableNew from "components/TagsListFilterableNew";

import { APPLY_FILTERS_SUGGESTIONS } from "./constants";
import useStackListAnalytics from "../useStackListAnalytics";
import { CellComponentProps } from "./types";

type StackListItemLabelsCellProps = CellComponentProps;

const StackListItemLabelsCell = ({ stack }: StackListItemLabelsCellProps) => {
  const { applyFilter, applyLabelFilter } =
    useApplyFilter<StackSuggestions>(APPLY_FILTERS_SUGGESTIONS);

  const trackAnalytics = useStackListAnalytics();

  const handleApplyLabelFilter = useCallback(
    (value: string) => {
      trackAnalytics("Label clicked", { type: StackSuggestions.Label });
      applyLabelFilter(StackSuggestions.Label)(value);
    },
    [trackAnalytics, applyLabelFilter]
  );

  const handleApplyFolderFilter = useCallback(
    (value: string) => {
      trackAnalytics("Label clicked", { type: StackSuggestions.Folder });
      applyFilter(StackSuggestions.Folder)(value);
    },
    [trackAnalytics, applyFilter]
  );

  return (
    <TagsListFilterableNew
      tags={stack.labels}
      applyLabelFilter={handleApplyLabelFilter}
      applyFolderFilter={handleApplyFolderFilter}
    />
  );
};

export default StackListItemLabelsCell;
