import { StackState } from "types/generated";
import DropdownMenu from "ds/components/DropdownMenu";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";
import BaseActionButton from "ds/components/BaseAction/Button";
import StackStateBadgeNewDropdown from "components/StackStateBadge/New";

import styles from "./styles.module.css";

type DropdownBadgeProps = {
  link?: string;
  state: StackState;
  applyFilter: (value: StackState) => void;
};

const DropdownBadge = ({ link, state, applyFilter }: DropdownBadgeProps) => {
  return (
    <DropdownMenu
      placement="bottom start"
      triggerComponent={
        <BaseActionButton className={styles.badgeWrapper}>
          <StackStateBadgeNewDropdown state={state} withDropdown />
        </BaseActionButton>
      }
    >
      {link && <DropdownMenuItem href={link}>Go to run</DropdownMenuItem>}
      <DropdownMenuItem onAction={() => applyFilter(state)}>Add to filters</DropdownMenuItem>
    </DropdownMenu>
  );
};

export default DropdownBadge;
