import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import { Lock } from "components/icons/generated";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import useUnlockStack from "shared/Stack/useUnlockStack";

type UnlockButtonProps = { stackId: string; analyticsPage?: AnalyticsPage };

const UnlockButton = ({ stackId, analyticsPage }: UnlockButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
  });

  const [stackUnlock, { loading }] = useUnlockStack({ refetchQueries: ["GetStack"] });

  const onClick = () => {
    stackUnlock({ id: stackId }, (data) => {
      if (data?.stackUnlock) {
        reportSuccess({ message: "Stack successfully unlocked" });
        trackSegmentAnalyticsEvent?.("Stack unlocked");
      }
    });
  };

  return (
    <Button
      disabled={loading}
      loading={loading}
      onClick={onClick}
      startIcon={Lock}
      variant="secondary"
    >
      Unlock
    </Button>
  );
};

export default UnlockButton;
