import { ThumbUp } from "components/icons/generated";
import Button from "ds/components/Button";
import { isSelfHostedDistribution } from "utils/distribution";
import { isUserflowEnabled } from "shared/Analytics";

type FeedbackButtonProps = {
  id: string;
};

const FeedbackButton = ({ id }: FeedbackButtonProps) => {
  const isSelfHosted = isSelfHostedDistribution();
  const isUserflowReady = isUserflowEnabled();

  if (isSelfHosted || !isUserflowReady) {
    return null;
  }

  return (
    <Button id={id} variant="secondary" startIcon={ThumbUp}>
      Leave feedback
    </Button>
  );
};

export default FeedbackButton;
