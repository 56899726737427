export const getAuthStatusRedirectUrl = (status?: number) => {
  if (status === 401 || status === 403) {
    return "/unauthorized";
  }

  if (status === 402) {
    return "/login-limit-exceeded";
  }

  return;
};
