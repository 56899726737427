import ButtonIcon from "ds/components/ButtonIcon";
import { Maybe } from "types/generated";
import { createUrl, toBlob } from "components/config/helpers";
import { Download } from "components/icons/generated";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileTitle from "ds/components/Tile/Title";
import { downloadFileFromURL } from "utils/file";
import Box from "ds/components/Box";

type EnvironmentPreviewFileProps = {
  relativePath: string;
  value: Maybe<string>;
  writeOnly: boolean;
};

const EnvironmentPreviewFile = ({
  relativePath,
  value,
  writeOnly,
}: EnvironmentPreviewFileProps) => {
  const handleOnClick = () => {
    if (!value) {
      return;
    }

    downloadFileFromURL(createUrl(toBlob(value)), relativePath);
  };

  return (
    <TileWrapper>
      <Box gap="x-large" justify="between" fullWidth>
        <TileTitle variant="p-t6">{relativePath}</TileTitle>
        {!writeOnly && value && (
          <ButtonIcon icon={Download} onClick={handleOnClick} variant="ghost">
            Download
          </ButtonIcon>
        )}
      </Box>
    </TileWrapper>
  );
};

export default EnvironmentPreviewFile;
