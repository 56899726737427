import { Controller, useFormContext } from "react-hook-form";

import Box from "ds/components/Box";
import FormField from "ds/components/Form/Field";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import { VcsCheck } from "types/generated";
import FormToggleField from "ds/components/Form/ToggleField";

import { VCSCheckLabels } from "../../components/Details/helpers";

const VCSChecksToggle = () => {
  const { watch, control, setValue } = useFormContext();
  const currentValue = watch("vcsChecks");

  const handleAggregatedChange = (type: VcsCheck) => () => {
    if (currentValue === type || currentValue === VcsCheck.All) {
      if (type === VcsCheck.Individual) {
        setValue("vcsChecks", VcsCheck.Aggregated, { shouldDirty: true });
      } else {
        setValue("vcsChecks", VcsCheck.Individual, { shouldDirty: true });
      }
    } else {
      setValue("vcsChecks", VcsCheck.All, { shouldDirty: true });
    }
  };

  return (
    <Controller
      name="vcsChecks"
      control={control}
      render={() => (
        <FormField
          label="VCS checks"
          tooltipInfo={
            <TooltipModalBody align="start">
              At least one option should be selected
            </TooltipModalBody>
          }
        >
          <Box gap="medium" direction="column">
            <FormToggleField
              variant="switch"
              align="center"
              onChange={handleAggregatedChange(VcsCheck.Individual)}
              checked={currentValue === VcsCheck.Individual || currentValue === VcsCheck.All}
              title={VCSCheckLabels[VcsCheck.Individual]}
              description="One VCS check for each stack"
            />
            <FormToggleField
              variant="switch"
              align="center"
              onChange={handleAggregatedChange(VcsCheck.Aggregated)}
              checked={currentValue === VcsCheck.Aggregated || currentValue === VcsCheck.All}
              title={VCSCheckLabels[VcsCheck.Aggregated]}
              description="Summarize checks across all affected stacks into a predefined set"
            />
          </Box>
        </FormField>
      )}
    />
  );
};

export default VCSChecksToggle;
