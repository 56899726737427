import Skeleton from "react-loading-skeleton";

import Box from "ds/components/Box";

const DashboardManageDrawerSkeleton = () => (
  <Box gap="x-large" direction="column">
    <Box gap="medium">
      <Skeleton height={32} width={74} borderRadius={8} />
      <Skeleton height={32} width={74} borderRadius={8} />
      <Skeleton height={32} width={74} borderRadius={8} />
    </Box>
    <Box gap="x-large">
      <Skeleton height={164} width={215} borderRadius={12} />
      <Skeleton height={164} width={215} borderRadius={12} />
    </Box>
    <Box gap="medium" direction="column">
      <Skeleton height={42} width={447} borderRadius={12} />
      <Skeleton height={42} width={447} borderRadius={12} />
      <Skeleton height={42} width={447} borderRadius={12} />
      <Skeleton height={42} width={447} borderRadius={12} />
      <Skeleton height={42} width={447} borderRadius={12} />
    </Box>
  </Box>
);

export default DashboardManageDrawerSkeleton;
