import { useCallback } from "react";
import { Outlet } from "react-router-dom";

import ViewHeader from "components/ViewHeader";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import ButtonNew from "ds/components/Button/New";
import Tab from "ds/components/Tab";
import TabWithCounter from "ds/components/Tab/WithCounter";
import useTypedContext from "hooks/useTypedContext";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useTitle from "hooks/useTitle";
import useFavicon from "hooks/useFavicon";
import { VersionState } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import YankedBadge from "views/Account/Module/Versions/Components/YankedBadge";
import { EMPTY_COMMIT_MESSAGE_VERSION_NAME } from "views/Account/Module/Versions/constants";
import DropdownMenuEllipsis from "ds/components/DropdownMenu/Ellipsis";
import CopyFieldDropdownMenuItem from "components/CopyField/DropdownMenuItem";
import DropdownMenuItem from "ds/components/DropdownMenu/Item";

import { showModuleVersionAddDescriptionDrawer } from "../AddDescriptionDrawer";
import DeleteButton from "../DeleteButton";
import MarkAsBadDropdownItem from "../MarkAsBad/DropdownItem";
import VersionStateBadge from "../StateBadge";
import VersionDropdown from "../VersionDropdown";
import { VersionContext } from "../../Context";
import { showModuleVersionDetailsDrawer } from "../DetailsDrawer";
import ModuleVersionMarkedAsBadCallout from "../MarkedAsBadCallout";
import { showModuleVersionMarkedAsBadNoteDrawer } from "../MarkedAsBadNoteDrawer";
import TriggerAgainDropdownItem from "../TriggerAgainDropdownItem";

const ModuleVersionMainLayout = () => {
  const { version, module, canManageModule } = useTypedContext(VersionContext);

  const showDescriptionDrawer = () => {
    showModuleVersionAddDescriptionDrawer({
      moduleId: module.id,
      versionId: version.id,
      description: version.notes,
    });
  };

  const handleOpenDetailsDrawer = () => {
    showModuleVersionDetailsDrawer({
      moduleProvider: module.provider,
      version,
    });
  };

  const handleOpenMarkedAsBadNoteDrawer = () => {
    showModuleVersionMarkedAsBadNoteDrawer({
      note: version.yankNote,
    });
  };

  useBreadcrumbs(
    [
      {
        title: "Modules",
        link: "/modules",
      },
      {
        title: module.id,
        link: `/module/${module.id}`,
      },
      {
        title: version.commit?.message || "",
      },
    ],
    [version.number, version.commit?.message, module.id]
  );

  useTitle(`${module.id} · ${version.number}`);

  useFavicon(version.yanked ? VersionState.Failed : version.state);

  const trackSegmentAnalyticsEvent = useAnalytics();

  const handleDropdownOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        trackSegmentAnalyticsEvent("Module Registry - Version Dropdown Opened");
      }
    },
    [trackSegmentAnalyticsEvent]
  );

  const handleClick = useCallback(
    (button: string, callback?: () => void) => () => {
      trackSegmentAnalyticsEvent("Module Registry - Version Dropdown Clicked", {
        button,
      });

      callback?.();
    },
    [trackSegmentAnalyticsEvent]
  );

  return (
    <>
      <ViewHeader>
        <ViewHeaderWrapper justify="between" fullWidth>
          <ViewHeaderWrapper align="center">
            <ViewHeaderTitle>
              {version?.commit?.message || EMPTY_COMMIT_MESSAGE_VERSION_NAME}
            </ViewHeaderTitle>
            <VersionStateBadge state={version.state} text={version.number} />
            {version.yanked && <YankedBadge notes={version.yankNote} />}
          </ViewHeaderWrapper>

          <ViewHeaderWrapper>
            <VersionDropdown
              moduleId={module.id}
              versionNumber={version.number}
              isLatest={version.number === module.current?.number}
            />
            <ButtonNew variant="secondary" onPress={handleOpenDetailsDrawer}>
              Details
            </ButtonNew>
            <DropdownMenuEllipsis
              dotsSize="medium"
              tooltip="Version actions"
              onOpenChange={handleDropdownOpenChange}
            >
              {canManageModule && version.state === VersionState.Failed && (
                <TriggerAgainDropdownItem id={version.id} moduleId={module.id} />
              )}
              <CopyFieldDropdownMenuItem
                title="Copy module ID"
                value={module.id}
                onCopy={handleClick("Copy module ID")}
              />
              <CopyFieldDropdownMenuItem
                title="Copy version ID"
                value={version.id}
                onCopy={handleClick("Copy version ID")}
              />
              {canManageModule && (
                <DropdownMenuItem
                  onAction={handleClick("Edit description", () => showDescriptionDrawer())}
                >
                  Edit description
                </DropdownMenuItem>
              )}
              {canManageModule && !version.yanked && (
                <MarkAsBadDropdownItem
                  id={version.id}
                  moduleId={module.id}
                  versionState={version.state}
                  versionNumber={version.number}
                  onClickCb={handleClick("Mark version as bad")}
                />
              )}
              {canManageModule && (
                <DeleteButton
                  id={version.id}
                  moduleId={module.id}
                  number={version.number}
                  onClickCb={handleClick("Delete version")}
                />
              )}
            </DropdownMenuEllipsis>
          </ViewHeaderWrapper>
        </ViewHeaderWrapper>
        <ViewHeaderNavigation>
          <Tab to="overview" label="Overview" />
          <TabWithCounter
            count={version.metadata?.submodules?.length || 0}
            to="submodules"
            label="Submodules"
          />
          <TabWithCounter
            count={version.metadata?.examples?.length || 0}
            to="examples"
            label="Examples"
          />
        </ViewHeaderNavigation>
      </ViewHeader>
      {version.yanked && (
        <ModuleVersionMarkedAsBadCallout onShowMore={handleOpenMarkedAsBadNoteDrawer} />
      )}
      <Outlet />
    </>
  );
};

export default ModuleVersionMainLayout;
