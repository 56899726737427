import DeltaCounts from "components/DeltaCounts";
import { isAnsibleStackVendor } from "utils/stack";

import { CellComponentProps } from "./types";

type StackListItemDeltaCellProps = CellComponentProps;

const StackListItemDeltaCell = ({ stack }: StackListItemDeltaCellProps) => {
  if (!stack.delta) {
    return null;
  }

  return (
    <DeltaCounts
      size="small"
      align="start"
      variant="flexible"
      direction="column"
      delta={stack.delta}
      isAnsible={isAnsibleStackVendor(stack)}
      responsive
    />
  );
};

export default StackListItemDeltaCell;
