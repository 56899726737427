import { createContext, ReactNode, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useTypedContext from "hooks/useTypedContext";

import { SpacesContext } from "../SpacesProvider";
import { createNavigationLink, makeFilterUrlParams } from "./utils";
import { SpacesViewContext } from "./Context";
import { CREATE_NEW_URL_PARAM, PARENT_ID_URL_PARAM } from "./constants";
import { showDeleteConfirmation } from "./DeleteConfirmation";
import { SpacesDrawerMode } from "./types";

type SpacesViewActionsContextProps = {
  onCreate: (parentId?: string) => void;
  onSelect: (id: string) => void;
  onCloseDrawer: (replace?: boolean) => void;
  onFilter: (id: string, path: string) => void;
  onDelete: (id: string) => void;
};

export const SpacesViewActionsContext = createContext<SpacesViewActionsContextProps | undefined>(
  undefined
);
SpacesViewActionsContext.displayName = "SpacesViewActionsContext";

type SpacesViewActionsProviderProps = {
  children: ReactNode;
};

export const SpacesViewActionsProvider = ({ children }: SpacesViewActionsProviderProps) => {
  const { spaces, spaceHierarchy } = useTypedContext(SpacesContext);
  const navigate = useNavigate();
  const { currentSpace, drawerMode } = useTypedContext(SpacesViewContext);
  const { spaceId } = useParams<{ spaceId?: string }>();

  const isCreateDrawer = drawerMode === SpacesDrawerMode.Create;

  const handleCreateSpace = useCallback(
    (parentId?: string) => {
      const newUrlParams = new URLSearchParams();
      newUrlParams.set(CREATE_NEW_URL_PARAM, "true");

      if (parentId) {
        newUrlParams.set(PARENT_ID_URL_PARAM, parentId);
      }

      navigate(createNavigationLink(`/spaces`, newUrlParams));
    },
    [navigate]
  );

  const handleSpaceSelect = useCallback(
    (id: string) => {
      navigate(createNavigationLink(`/spaces/${id}`));
    },
    [navigate]
  );

  const handleOnFilter = (id: string, path: string) => {
    const spacePath = spaceHierarchy[id];

    const urlParams = makeFilterUrlParams(spacePath);

    navigate(`${path}?${urlParams}`);
  };

  const handleCloseDrawer = useCallback(
    (replace?: boolean) => {
      if (!spaceId && !isCreateDrawer) {
        // If no space is selected, drawer is already closed,
        // No need to navigate
        return;
      }

      navigate(createNavigationLink(`/spaces`), {
        replace,
      });
    },
    [isCreateDrawer, navigate, spaceId]
  );

  const handleOnDelete = useCallback(
    (id: string) => {
      const space = spaces.find((space) => space.id === id);

      if (!space) {
        return;
      }

      showDeleteConfirmation({ name: space.name, id: space.id }).then(() => {
        if (currentSpace?.id === space.id) {
          handleCloseDrawer(true);
        }
      });
    },
    [spaces, currentSpace?.id, handleCloseDrawer]
  );

  return (
    <SpacesViewActionsContext.Provider
      value={{
        onCreate: handleCreateSpace,
        onSelect: handleSpaceSelect,
        onCloseDrawer: handleCloseDrawer,
        onFilter: handleOnFilter,
        onDelete: handleOnDelete,
      }}
    >
      {children}
    </SpacesViewActionsContext.Provider>
  );
};
