import { ApolloClient, ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import FlashMessages from "components/FlashMessages";
import { FlashContextProvider } from "components/FlashMessages/FlashContext";
import AriaRouterProvider from "components/AriaRouterProvider";
import SelfHostedInstanceProvider from "views/SelfHosted/InstanceProvider";

import Routes from "./Routes";

type AppProps = {
  client: ApolloClient<unknown>;
};

const App = ({ client }: AppProps) => {
  return (
    <BrowserRouter>
      <AriaRouterProvider>
        <FlashContextProvider>
          <FlashMessages />
          <ApolloProvider client={client}>
            <CookiesProvider>
              <SelfHostedInstanceProvider>
                <Routes />
              </SelfHostedInstanceProvider>
            </CookiesProvider>
          </ApolloProvider>
        </FlashContextProvider>
      </AriaRouterProvider>
    </BrowserRouter>
  );
};

export default App;
