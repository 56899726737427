import { useMemo } from "react";

import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import { ApiKey } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import { showApiKeysDetailsDrawer } from "components/ApiKeys/DetailsDrawer";
import ApiKeysSortHeader from "components/ApiKeys/SortHeader";
import ApiKeysEmptyList from "components/ApiKeys/EmptyList";
import ApiKeysList from "components/ApiKeys/List";
import { showApiKeysCreateDrawer } from "components/ApiKeys/CreateDrawer";
import { showApiKeysInfoDrawer } from "components/ApiKeys/InfoDrawer";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import ApiKeysPageLayout from "./PageLayout";
import { getManagementStrategy } from "../helpers";
import { SettingsContext } from "../Context";

const byMostRecentUsage = (x: ApiKey, y: ApiKey) => (y.lastUsedAt || 0) - (x.lastUsedAt || 0);

type ApiKeysProps = {
  apiKeys: ApiKey[];
};

const REFETCH_QUERIES = ["GetAccountSettings"];

const ApiKeys = ({ apiKeys }: ApiKeysProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);

  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Organization Settings · API Keys · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationApiKeys,
    defaultCallbackTrackProperties: { managementStrategy: getManagementStrategy(activationStatus) },
  });

  const memoizedApiKeys = useMemo<ApiKey[]>(() => {
    return [...apiKeys].sort(byMostRecentUsage);
  }, [apiKeys]);

  const handleApiKeyOpenDetails = (apiKey: ApiKey) => {
    trackSegmentAnalyticsEvent("Edit Details");
    showApiKeysDetailsDrawer({
      id: apiKey.id,
      apiKey,
      activationStatus,
    });
  };

  const openInfoDrawer = () => {
    trackSegmentAnalyticsEvent("Info Click");
    showApiKeysInfoDrawer({
      analyticsPage: AnalyticsPageOrganization.OrganizationApiKeys,
    });
  };

  const openCreateApiKeyDrawer = () => {
    trackSegmentAnalyticsEvent("Create Key");
    showApiKeysCreateDrawer({
      activationStatus,
      refetchQueries: REFETCH_QUERIES,
      analyticsPage: AnalyticsPageOrganization.OrganizationApiKeys,
    });
  };

  return (
    <ApiKeysPageLayout
      openCreateApiKeyDrawer={openCreateApiKeyDrawer}
      openInfoDrawer={openInfoDrawer}
    >
      {memoizedApiKeys.length > 0 && (
        <>
          <ApiKeysSortHeader />
          <ApiKeysList
            apiKeys={memoizedApiKeys}
            handleApiKeyOpenDetails={handleApiKeyOpenDetails}
            refetchQueriesOnDelete={REFETCH_QUERIES}
          />
        </>
      )}
      {memoizedApiKeys.length === 0 && <ApiKeysEmptyList />}
    </ApiKeysPageLayout>
  );
};

export default ApiKeys;
