import FormFieldViewText from "components/FormFields/ViewText";
import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { getBaseUrl } from "utils/getBaseUrl";

const baseUrl = getBaseUrl();

const SettingsSingleSignOnConfigInfoOIDC = () => {
  return (
    <>
      <Box direction="column" gap="small">
        <Typography variant="p-t5" tag="h3">
          OIDC settings
        </Typography>
        <Typography variant="p-body3" tag="p">
          When setting up Spacelift as an OIDC application in your identity provider, please use the
          following setting:
        </Typography>
      </Box>
      <FormFieldViewText
        withCopy
        noMargin
        label="Authorized redirect URL"
        value={`${baseUrl}/oidc/exchange`}
      />

      <Banner variant="info">
        In addition to the standard openid scope, Spacelift also requires the email scope. Spacelift
        will also ask for the profile and groups scopes if supported by your Identity Provider to
        provide as input to Login Policies.
      </Banner>
    </>
  );
};

export default SettingsSingleSignOnConfigInfoOIDC;
