import cx from "classnames";

import { InfoCircle, ExclamationMarkCircle, TickCircle } from "components/icons/generated";
import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";
import Box from "../Box";
import Icon from "../Icon";
import ToastClose from "./Close";

export enum ToastVariant {
  Error = "error",
  Info = "info",
  Positive = "positive",
}

type ToastProps = {
  children: React.ReactNode;
  variant: ToastVariant;
  timeout?: number;
  isPaused?: boolean;
  onClose: () => void;
};

const TOAST_ICON_MAP: Record<ToastVariant, IconComponent> = {
  error: ExclamationMarkCircle,
  info: InfoCircle,
  positive: TickCircle,
};

const ToastNew = ({
  variant = ToastVariant.Info,
  onClose,
  timeout,
  isPaused,
  children,
}: ToastProps) => {
  return (
    <Box className={cx(styles.toast, styles[variant])} gap="medium" align="start">
      <Box align="center" shrink="0" className={styles.iconWrapper}>
        <Icon src={TOAST_ICON_MAP[variant]} className={styles.icon} />
      </Box>

      <Box direction="column" className={styles.toastContent}>
        {children}
      </Box>

      <ToastClose timeout={timeout} onClose={onClose} isPaused={isPaused} />
    </Box>
  );
};

export default ToastNew;
