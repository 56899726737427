import { useEffect, useState } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import { gql, useLazyQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";

import { Maybe, User } from "types/generated";
import useMFA from "hooks/useMFA";
import SpaceliftLoader from "components/loading/SpaceliftLoader";

export const CHECK_ACCESS = gql`
  query CheckAccess {
    viewer {
      id
    }
  }
`;

type CheckAccessGql = {
  viewer: Maybe<User>;
};

const SSOLogin = () => {
  const [called, setCalled] = useState(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);

  const [redirectTo, , deleteRedirectTo] = useLocalStorage("redirectTo");

  const [, , deleteTemporaryToken] = useLocalStorage("temporaryToken");

  const { shouldUseMFA, shouldRegisterSecurityKey } = useMFA();

  const determineRedirect = ({ viewer }: CheckAccessGql) => {
    if (called) {
      return;
    }

    setCalled(true);

    if (viewer === null) {
      setNextUrl("/unauthorized");
      return;
    }

    const target = redirectTo || "/";
    deleteRedirectTo();
    setNextUrl(target);
  };

  const checkMFA = () => {
    if (shouldRegisterSecurityKey) {
      setNextUrl("/enable_mfa");
      return true;
    }

    if (shouldUseMFA) {
      setNextUrl("/mfa");
      return true;
    }

    return false;
  };

  const [checkAccess] = useLazyQuery<CheckAccessGql>(CHECK_ACCESS, {
    onCompleted: determineRedirect,
    onError: () => {
      setNextUrl("/unauthorized");
    },
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    deleteTemporaryToken();

    const isMFA = checkMFA();

    if (!isMFA) {
      checkAccess();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (nextUrl) {
    return <Navigate to={nextUrl} replace />;
  }

  return <SpaceliftLoader />;
};

export default SSOLogin;
