import { User, GridTiles } from "components/icons/generated";
import { NotificationType, Notification } from "types/generated";
import { IconComponent } from "types/Icon";

export const iconPerType: Record<NotificationType, IconComponent> = {
  [NotificationType.Stack]: GridTiles,
  [NotificationType.Account]: User,
  [NotificationType.AwsIntegration]: GridTiles,
  [NotificationType.Policy]: GridTiles,
  [NotificationType.WorkerPool]: GridTiles,
  [NotificationType.Module]: GridTiles,
};

export const getAllItemsForSelectAll = (items: Notification[]) =>
  new Set(items.map((notification) => notification.id));
