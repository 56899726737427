import { useId } from "react";
import InfiniteLoader from "react-window-infinite-loader";

import useTypedContext from "hooks/useTypedContext";
import useTitle from "hooks/useTitle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageInfo from "components/PageWrapper/Info";
import PageWrapper from "components/PageWrapper";
import FiltersSortHeaderStatic from "components/Filters/SortHeader/Static";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import NotFoundPage from "components/error/NotFoundPage";
import ListEntitiesNew from "components/ListEntitiesNew";
import { isStackVendorTerraform } from "utils/vendor";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import PageWrapperTitle from "components/PageWrapper/Title";
import EmptyState from "ds/components/EmptyState";
import { EmptystateClockRewindColored } from "components/icons/generated";

import { StackContext } from "../Context";
import StackHeader from "../components/Header";
import { COLUMNS, COLUMN_GAP, COLUMN_ORDER, ITEMS_LIMIT } from "./constants";
import useGetStateVersions from "./useGetStateVersions";
import StackStateHistoryVirtualizedListItem from "./ListItem/Virtualized";
import StackLockOrUnlockButton from "../components/LockOrUnlockButton";
import { getStacksBackUrl } from "../helpers";
import StackStateUploadButton from "../components/UploadStateButton";

const StackStateHistory = () => {
  const { stack } = useTypedContext(StackContext);
  const isTerraformVendor = isStackVendorTerraform(stack.vendorConfig?.__typename);
  const titleId = useId();
  const {
    managedStateVersions,
    isPageLoading,
    isPageNotFound,
    isPageEmpty,
    error,
    loadMoreItems,
    hasNextPage,
  } = useGetStateVersions();

  const isItemLoaded = (value: number) => !hasNextPage || value < managedStateVersions.length;

  useTitle(`State history · ${stack.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (isPageLoading) {
    return <PageLoading />;
  }

  if (isPageNotFound || !isTerraformVendor || !stack.managesStateFile) {
    return <NotFoundPage />;
  }

  return (
    <>
      <StackHeader />
      <PageInfo title={<PageWrapperTitle id={titleId}>State history</PageWrapperTitle>}>
        <StackStateUploadButton />
        <StackLockOrUnlockButton analyticsPage={AnalyticsPageStack.StackStateHistory} />
      </PageInfo>
      <PageWrapper margin="0" direction="column" aria-labelledby={titleId} role="table">
        {isPageEmpty && (
          <EmptyState
            title="You don’t have any states yet"
            icon={EmptystateClockRewindColored}
            caption="State history allows you to view and manage the state file for this stack."
            ariaInTable
          />
        )}

        {managedStateVersions.length > 0 && (
          <>
            <FiltersSortHeaderStatic
              sortOptions={COLUMNS}
              columnGap={COLUMN_GAP}
              columnOrder={COLUMN_ORDER}
              ariaTableHeader
            />

            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={
                hasNextPage
                  ? managedStateVersions.length + ITEMS_LIMIT
                  : managedStateVersions.length
              }
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered }) => (
                <ListEntitiesNew
                  itemCount={managedStateVersions.length}
                  itemProps={{
                    items: managedStateVersions,
                  }}
                  virtualizedItem={StackStateHistoryVirtualizedListItem}
                  itemKey={(index) => managedStateVersions[index].id}
                  onItemsRendered={onItemsRendered}
                />
              )}
            </InfiniteLoader>
          </>
        )}
      </PageWrapper>
    </>
  );
};

export default StackStateHistory;
