import { gql, TypedDocumentNode } from "@apollo/client";

import { AccountData } from "components/form/graphql/types";

export const GET_ACCOUNT_DATA: TypedDocumentNode<AccountData> = gql`
  query GetAccount {
    accountId: id
    accountName: name
    accountType: type
    installationId
    viewer {
      id
      admin
      avatarURL
      cannotEvaluateAccessPolicies
      email
      identityProvider
      isFirstUser
      name
      since
      validUntil
      teams
    }
    genericFormsList {
      name
    }
  }
`;
